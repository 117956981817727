import React, { Component } from 'react'
import SVG from 'svg.js/dist/svg.min.js'
import $ from 'jquery'
import accounting from 'accounting'

export default class FloorSvg extends Component {
	componentDidMount() {
		if (!this.container) return null
		this.handleLoad()
	}

	componentDidUpdate() {
		$(this.container)
			.find('svg')
			.remove()
		this.handleLoad()
	}

	shouldComponentUpdate(props) {
		return props.list !== this.props.list
	}

	render() {
		const { img } = this.props
		return (
			<div ref={this.setContainerRef} className="parking-scheme">
				<img src={img} alt="" id="floor-img" />
			</div>
		)
	}

	setContainerRef = (ref) => {
		this.container = ref
	}

	createSvg = (width, height) => {
		const { list, priceMid } = this.props
		this._draw = SVG(this.container).size(width, height)
		this._draw.viewbox(0, 0, width, height)
		let rect, rect2
		let line
		let title, square1, square2, price1, price2, status1, status2, floor1, floor2

		list.map((el, i) => {
			if (!el['coords']) return false

			const formatPrice = accounting.formatNumber(el.price, 0, ' ')
			const color = +el.price >= +priceMid

			const coords = el['coords'].split(',')
			const x1 = coords[0]
			const y1 = coords[1]
			const x2 = coords[2]
			const y2 = coords[3]

			const position = [x2 - (x2 - x1) / 2, y1]
			const position2 = [x2 - (x2 - x1) / 2, y2 - (y2 - y1) / 2]

			this._draw
				.polygon([x1, y1, x2, y1, x2, y2, x1, y2])
				.fill(color ? '#b37db7' : '#e2068c')
				.stroke('#fff')
				.on('mouseenter', () => {
					rect.fill(color ? '#b37db7' : '#e2068c')
						.move(...position)
						.addClass('active')
						.dx(0)
						.dy(-296)

					rect2
						.move(...position)
						.addClass('active')
						.dx(8)
						.dy(-233)

					line.load(color ? '/img/parking/arrow1.svg' : '/img/parking/arrow2.svg', 26, 50)
						.move(...position)
						.addClass('active')
						.dx(-1)
						.dy(-53)

					title
						.text((add) => {
							add.tspan('машиноместо - ' + el.number)
						})
						.move(...position)
						.addClass('active')
						.dx(145)
						.dy(-258)

					square1
						.move(...position)
						.addClass('active')
						.dx(40)
						.dy(-183)

					square2
						.text((add) => {
							add.tspan(el.square + ' м2')
						})
						.move(...position)
						.addClass('active')
						.dx(258)
						.dy(-183)

					price1
						.move(...position)
						.addClass('active')
						.dx(40)
						.dy(-158)

					price2
						.text((add) => {
							add.tspan(formatPrice + ' руб')
						})
						.move(...position)
						.addClass('active')
						.dx(258)
						.dy(-158)

					status1
						.move(...position)
						.addClass('active')
						.dx(40)
						.dy(-133)

					status2
						.text((add) => {
							add.tspan('Свободно')
						})
						.move(...position)
						.addClass('active')
						.dx(258)
						.dy(-133)

					floor1
						.move(...position)
						.addClass('active')
						.dx(40)
						.dy(-108)

					floor2
						.text((add) => {
							add.tspan(el.floor)
						})
						.move(...position)
						.addClass('active')
						.dx(258)
						.dy(-108)
				})

				.on('mouseout', () => {
					rect.removeClass('active')
					rect2.removeClass('active')
					line.removeClass('active')
					title.removeClass('active')
					square1.removeClass('active')
					square2.removeClass('active')
					price1.removeClass('active')
					price2.removeClass('active')
					status1.removeClass('active')
					status2.removeClass('active')
					floor1.removeClass('active')
					floor2.removeClass('active')
				})

				.on('click', (ev) => {
					this.props.openParkingForm(el)
					this.props.setFormPlaceType('Паркинг')
				})

			this._draw
				.text((add) => {
					add.tspan(el.number)
				})
				.move(...position2)
				.dy(-10)
				.addClass('active event-none')
				.size(10)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'middle', leading: '1.5em' })
		})

		if (!list.length) return

		line = this._draw.image('/img/parking/arrow1.svg', 26, 50).addClass('arrow')

		rect = this._draw.rect(289, 246)

		rect2 = this._draw.rect(272, 176).fill('#fff')

		title = this._draw
			.text('')
			.addClass('text-decoration')
			.size(22)
			.font({ fill: '#fff', family: 'Acrom', anchor: 'middle', leading: '1em' })

		square1 = this._draw
			.text((add) => {
				add.tspan('Площадь')
			})
			.size(14)
			.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })

		square2 = this._draw
			.text('')
			.size(14)
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'end', leading: '1.5em' })

		price1 = this._draw
			.text((add) => {
				add.tspan('Стоимость')
			})
			.size(14)
			.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })

		price2 = this._draw
			.text('')
			.size(14)
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'end', leading: '1.5em' })

		status1 = this._draw
			.text((add) => {
				add.tspan('Статус')
			})
			.size(14)
			.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })

		status2 = this._draw
			.text('')
			.size(14)
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'end', leading: '1.5em' })

		floor1 = this._draw
			.text((add) => {
				add.tspan('Этаж')
			})
			.size(14)
			.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })

		floor2 = this._draw
			.text('')
			.size(14)
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'end', leading: '1.5em' })
	}

	handleLoad = (callback) => {
		$.get(
			this.props.img,
			(svgxml) => {
				var attrs = svgxml.documentElement.attributes
				this.createSvg(parseInt(attrs.width.value), parseInt(attrs.height.value))
			},
			'xml'
		)
	}
}
