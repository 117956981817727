import React, { Component } from 'react'
import { connect } from 'react-redux'

import { sectionChangeRooms } from '../../actions'

class Filter extends Component {
	render() {
		const { filtres, filterRooms } = this.props
		return Object.keys(filterRooms).map((value) => {
			return (
				<div
					key={value}
					className={`section__filter__item ${filtres.includes(value) ? 'active' : ''}`}
					onClick={this.handleChange}
					value={value}
				>
					<div>{value == 0 ? 'Студии' : `${value}-комнатные`}</div>
					<span>{filterRooms[value].length}</span>
				</div>
			)
		})
	}

	handleChange = (ev) => {
		const { sectionChangeRooms, filtres } = this.props
		const rooms = ev.currentTarget.getAttribute('value')
		if (filtres.includes(rooms)) {
			sectionChangeRooms(filtres.filter((value) => value !== rooms))
			return
		}
		window.scroll({ top: 170, left: 0, behavior: 'smooth' })
		sectionChangeRooms([...new Set([...filtres, rooms])])
	}

	getClassname = (rooms) => (this.props.filtres.includes(rooms) ? 'active' : '')
}

export default connect(null, { sectionChangeRooms })(Filter)
