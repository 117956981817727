import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import history from './history'
import { connect } from 'react-redux'

import { isMobile, loadFormData, loadPurchase } from './actions'
import { YMInitializer } from 'react-yandex-metrika'

import Header from './js/header'
import About from './js/About'
import Realty from './js/realty'
import CallModal from './js/realty/forms/call'
import Main from './js/main'
import News from './js/news'
import NewsDetail from './js/news/detail'
import Infra from './js/About/infra'
import Developer from './js/About/developer'
import Residential from './js/About/residential'
import Gallery from './js/About/gallery'
import Docs from './js/About/docs'
import Progress from './js/About/progress'
import Purchase from './js/purchase'
import Mortgage from './js/mortgage'
import Payment from './js/payment'
import Promotions from './js/promotions'
import Promotion from './js/promotions/detail'
import Commerce from './js/commerce'
import Parking from './js/parking'
import Contacts from './js/contacts'
import ErrorPage from './js/error'
import Privacy from './js/privacy'
import Agreement from './js/agreement'
import Panorama from './js/pano'
import Installments from './js/installments'
import QuestionModal from './js/modal/question'
import SubscribeModal from './js/modal/subscribe'
import Faq from './js/faq'
import $ from 'jquery'

class App extends Component {
	componentDidMount() {
		const mobile = window.innerWidth <= 1000
		this.props.isMobile(mobile)
		this.props.loadFormData()
		this.props.loadPurchase()
		window.addEventListener('resize', this._onResize)

		let lastScrollTop = 0
		const win = $(window)
		win.scroll(() => {
			var st = win.scrollTop()
			if (st > lastScrollTop && st > 0) {
				$('.header-block').addClass('actions-hidden')
			} else {
				$('.header-block').removeClass('actions-hidden')
			}
			lastScrollTop = st
		})
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._onResize)
	}

	render() {
		return (
			<ConnectedRouter history={history}>
				<div>
					<YMInitializer accounts={[47129109]} />
					<Header menu={false} />
					<div
						className={
							this.props.pageData.actions.time && +this.props.pageData.actions.time
								? 'page page-counter'
								: 'page'
						}
					>
						<Switch>
							<Route path="/" exact component={Main} />
							<Route path="/realty" component={Realty} />

							<Route path="/commerce" component={Commerce} />
							<Route path="/commerce-params" exact component={Commerce} />

							<Route path="/parking" component={this.parkingPage} />

							<Route path="/news" component={this.newsPage} />

							<Route path="/about" exact component={About} />
							<Route path="/about/residential" exact component={Residential} />
							<Route path="/about/infrastructure" exact component={Infra} />
							<Route path="/about/gallery" exact component={Gallery} />
							<Route path="/about/developer" exact component={Developer} />
							<Route path="/about/docs" exact component={Docs} />
							<Route path="/about/progress" exact component={Progress} />

							<Route path="/purchase" exact component={Purchase} />
							<Route path="/purchase/mortgage/flats" exact component={Mortgage} />
							<Route path="/purchase/mortgage/commerce" exact component={Mortgage} />
							{/*<Route path="/purchase/payment" exact component={Payment} />*/}
							<Route path="/purchase/promotions" exact component={Promotions} />
							<Route path="/purchase/promotions/:id" exact component={this.getPromotion} />

							<Route path="/privacy" exact component={Privacy} />
							<Route path="/agreement" exact component={Agreement} />

							<Route path="/contacts" exact component={Contacts} />
							<Route path="/panorama" exact component={Panorama} />

							<Route path="/installments" exact component={Installments} />

							<Route path="/faq" component={this.faqPage} />

							<Route path="*" component={ErrorPage} />
						</Switch>
						<CallModal />
						<QuestionModal />
						<SubscribeModal />
					</div>
					<div className="preloader-line">
						<div className="preloader-line__loader" />
					</div>
				</div>
			</ConnectedRouter>
		)
	}

	newsPage = ({ match }) => {
		if (match.isExact) return <Redirect to="/news/1" />
		return <Route path="/news/:page" render={this.getNewsPage} />
	}

	getNewsPage = ({ match }) => {
		if (!isNaN(match.params.page)) return <News page={match.params.page} />
		return <NewsDetail page={match.params.page} />
	}

	faqPage = ({ match }) => {
		if (match.isExact) return <Redirect to="/faq/1" />
		return <Route path="/faq/:page" render={this.getFaqPage} />
	}

	getFaqPage = ({ match }) => {
		if (!isNaN(match.params.page)) return <Faq page={match.params.page} />
		return <NewsDetail page={match.params.page} />
	}

	parkingPage = ({ match }) => {
		if (match.isExact) return <Redirect to="/parking/1" />
		return <Route path="/parking/:floor" render={this.getParkingPage} />
	}

	getParkingPage = ({ match }) => <Parking floor={match.params.floor} key={match.params.floor} />

	getPromotion = ({ match }) => {
		return <Promotion id={match.params.id} />
	}

	_onResize = () => {
		const mobile = window.innerWidth <= 1000

		if ((mobile && !this.props.mobile) || (!mobile && this.props.mobile)) {
			this.props.isMobile(mobile)
		}
	}
}
export default connect(
	(state) => ({
		mobile: state.mobile,
		pageData: state.form.data
	}),
	{ isMobile, loadFormData, loadPurchase }
)(App)
