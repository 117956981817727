import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadHome } from '../../actions'
import Loader from '../common/loader'
import { itemsSelector } from '../../selectors'
import Desctope from './desctope'
import Mobile from './mobile'
import { hit } from '../common/metrics'
import $ from 'jquery'
import TagManager from 'react-gtm-module'

class Main extends Component {
	componentDidMount() {
		TagManager.initialize({ gtmId: 'GTM-586W32X' })

		document.title =
			'ЖК «Новое Бутово» - официальный сайт. Квартиры в Москве, 15 минут пешком до метро Бунинская аллея.'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Общая площадь жилого комплекса «Новое Бутово» — 38 гектаров. На благоустроенной территории находятся 15 корпусов, детский сад, игровые площадки, парковочные зоны, кафе, магазины и собственный ТРЦ «Бутово Молл». Комплекс окружают парки, пруды, река и лесопарки'
			)
		hit(window.location.pathname)

		const { loaded, loading, loadHome } = this.props
		if (!loaded && !loading) loadHome()

		if (!loaded) return

		$('body').removeClass('main-loading')
		setTimeout(() => {
			$('body').addClass('animation-ready')
		}, 1000)
	}

	componentDidUpdate(oldProps) {
		if (this.props.items !== oldProps.items) {
			$('body').removeClass('main-loading')
			setTimeout(() => {
				$('body').addClass('animation-ready')
			}, 1000)
		}
	}

	componentWillUnmount() {
		$('body').addClass('main-loading')
		$('body').removeClass('animation-ready')
	}

	render() {
		const { loading, loaded, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />
		return (
			<div>
				{mobile ? (
					<div className="home-mobile">
						<Mobile />
					</div>
				) : (
					<Desctope />
				)}
			</div>
		)
	}
}

export default connect(
	(state, props) => ({
		loading: state.home.loading,
		loaded: state.home.loaded,
		data: state.home.data,
		items: itemsSelector(state),
		mobile: state.mobile
	}),
	{ loadHome }
)(Main)
