import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Ecology extends Component {
	render() {
		return (
			<div className="main__item--ecology">
				<div className="main__item--title">
					<div dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
				</div>
				<Link className="main__item--but main__item--but__white" to="/about/infrastructure">
					<i className="icon-i_tree" />
					<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
				</Link>
			</div>
		)
	}
}
