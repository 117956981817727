import {
	FORM_DATA,
	FORM_MAIL,
	SUCCESS,
	OPEN,
	CLOSE,
	FORM_REQUEST,
	FORM_CALL,
	FORM_REQUEST_LAYOUT,
	FORM_PARKING,
	SET_FORM_PLACE_TYPE,
	PENDING,
	QUESTION,
	FORM_SUBSCRIBE
} from '../constants'

const defaultState = {
	data: {
		rel: null,
		callback: {
			time: {
				0: 'любое'
			}
		},
		actions: {
			title: null,
			title2: null,
			time: null,
			count: null
		}
	},
	mail: {
		open: false,
		id: null,
		sent: false,
		type: null
	},
	request: {
		flat: null,
		open: false,
		sent: false,
		pending: false
	},
	call: {
		open: false,
		sent: false
	},
	requestLayout: {
		layout: null,
		open: false,
		sent: false
	},
	parking: {
		place: null,
		open: false,
		sent: false
	},
	formPlaceType: null,
	question: {
		open: false,
		sent: false
	},
	subscribe: {
		open: false,
		sent: false
	}
}

export default (state = defaultState, action) => {
	const { type, payload, response, formPlaceType } = action

	switch (type) {
		case FORM_DATA + SUCCESS:
			return {
				...state,
				data: response
			}

		case FORM_MAIL + OPEN:
			return {
				...state,
				mail: { ...state.mail, open: true, id: payload.id, type: payload.type }
			}

		case FORM_MAIL + CLOSE:
			return {
				...state,
				mail: { ...state.mail, open: false, sent: false }
			}

		case FORM_MAIL + SUCCESS:
			return {
				...state,
				mail: { ...state.mail, open: false, sent: true }
			}

		case FORM_REQUEST + OPEN:
			return {
				...state,
				request: { ...state.request, open: true, flat: payload.flat }
			}

		case FORM_REQUEST + CLOSE:
			return {
				...state,
				request: { ...state.request, open: false, sent: false }
			}

		case FORM_REQUEST + PENDING:
			return {
				...state,
				request: { ...state.request, pending: true }
			}

		case FORM_REQUEST + SUCCESS:
			return {
				...state,
				request: { ...state.request, open: false, sent: true, pending: false }
			}

		case FORM_CALL + OPEN:
			return {
				...state,
				call: { ...state.call, open: true }
			}

		case FORM_CALL + CLOSE:
			return {
				...state,
				call: { ...state.call, open: false, sent: false }
			}

		case FORM_CALL + SUCCESS:
			return {
				...state,
				call: { ...state.call, open: false, sent: true }
			}

		case FORM_REQUEST_LAYOUT + OPEN:
			return {
				...state,
				requestLayout: { ...state.requestLayout, open: true, layout: payload.layout }
			}

		case FORM_REQUEST_LAYOUT + CLOSE:
			return {
				...state,
				requestLayout: { ...state.requestLayout, open: false, sent: false }
			}

		case FORM_REQUEST_LAYOUT + SUCCESS:
			return {
				...state,
				requestLayout: { ...state.requestLayout, open: false, sent: true }
			}

		case FORM_PARKING + OPEN:
			return {
				...state,
				parking: { ...state.parking, open: true, place: payload.place }
			}

		case FORM_PARKING + CLOSE:
			return {
				...state,
				parking: { ...state.parking, open: false, sent: false }
			}

		case FORM_PARKING + SUCCESS:
			return {
				...state,
				parking: { ...state.parking, open: false, sent: true }
			}

		case SET_FORM_PLACE_TYPE:
			return {
				...state,
				formPlaceType
			}

		case QUESTION + OPEN:
			return {
				...state,
				question: { ...state.question, open: true }
			}

		case QUESTION + CLOSE:
			return {
				...state,
				question: { ...state.question, open: false, sent: false }
			}

		case QUESTION + SUCCESS:
			return {
				...state,
				question: { ...state.question, open: false, sent: true }
			}

		case FORM_SUBSCRIBE + OPEN:
			return {
				...state,
				subscribe: { ...state.subscribe, open: true }
			}

		case FORM_SUBSCRIBE + CLOSE:
			return {
				...state,
				subscribe: { ...state.subscribe, open: false, sent: false }
			}

		case FORM_SUBSCRIBE + SUCCESS:
			return {
				...state,
				subscribe: { ...state.subscribe, open: false, sent: true }
			}

		default:
			return state
	}
}
