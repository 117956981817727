import React from 'react'
import { connect } from 'react-redux'
import { openCallForm, setFormPlaceType, openQuestionForm } from '../../actions'
import Desctope from './desctope'
import Mobile from './mobile'

class Header extends React.Component {
	state = {
		open: false,
		submenu: null,
		hoversubmenu: null
	}

	componentDidMount() {
		const active = document.querySelector('.menu__in a.active')
		this.setState({
			submenu: active ? active.getAttribute('data-submenu') : null,
			current: active ? active.getAttribute('data-submenu') : null
		})
	}

	toggleMenu = (ev) => {
		if (!ev.target.getAttribute('data-submenu') && !ev.currentTarget.getAttribute('data-submenu')) {
			this.setState({ open: !this.state.open })
			return
		}

		this.setState({
			open: !this.state.open,
			submenu: ev.target.getAttribute('data-submenu')
				? ev.target.getAttribute('data-submenu')
				: ev.currentTarget.getAttribute('data-submenu')
		})
	}

	openSubmenuMenu = (ev) => {
		const submenu = ev.target.getAttribute('data-submenu')
			? ev.target.getAttribute('data-submenu')
			: ev.currentTarget.getAttribute('data-submenu')

		this.setState({
			submenu: submenu ? submenu : this.state.current
		})
	}

	closeSubmenuMenu = () => {
		this.setState({
			submenu: this.state.current
		})
	}
	render() {
		const { open, submenu, hoversubmenu } = this.state
		const { mobile, openCallForm, pageData, setFormPlaceType } = this.props
		return (
			<div>
				{mobile ? (
					<Mobile
						actions={pageData.actions}
						open={open}
						submenu={submenu}
						openCallForm={openCallForm}
						setFormPlaceType={setFormPlaceType}
						toggleMenu={this.toggleMenu}
						openQuestionForm={this.props.openQuestionForm}
					/>
				) : (
					<Desctope
						open={open}
						hoversubmenu={hoversubmenu}
						closeSubmenuMenu={this.closeSubmenuMenu}
						openSubmenuMenu={this.openSubmenuMenu}
						submenu={submenu}
						openCallForm={openCallForm}
						setFormPlaceType={setFormPlaceType}
						toggleMenu={this.toggleMenu}
						actions={pageData.actions}
						openQuestionForm={this.props.openQuestionForm}
					/>
				)}
			</div>
		)
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		pageData: state.form.data
	}),
	{ openCallForm, setFormPlaceType, openQuestionForm }
)(Header)
