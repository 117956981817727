import React, { Component } from 'react'
import $ from 'jquery'

export default class Map extends Component {
	componentDidMount() {
		const ymaps = window.ymaps
		this.markers = []
		const self = this

		ymaps.ready(() => {
			this.map = new ymaps.Map(
				'infra-map',
				{
					center: [this.props.object['lat'], this.props.object['lng']],
					zoom: 16,
					controls: [],
					margin: this.props.mobile ? [0, 80, 0, 0] : [0, 0, 0, 500]
				},
				{
					searchControlProvider: 'yandex#search'
				}
			)
			this.map.behaviors.disable('scrollZoom')

			// if (this.props.mobile) {
			// 	this.map.behaviors.disable('drag')
			// 	this.map.behaviors.disable('multiTouch')
			// }

			const zoomControl = new ymaps.control.ZoomControl({
				options: {
					size: 'small',
					position: {
						right: 10,
						bottom: 40
					}
				}
			})

			this.map.controls.add(zoomControl)

			// !this.props.mobile && this.createMetro()
			this.createBaloon()

			this.createCluster()
			this.createPoints()
			this.createMainPoint()

			// this.map.setBounds(this.map.geoObjects.getBounds(), {
			// 	checkZoomRange: true,
			// 	zoomMargin: this.props.mobile ? [0, 80, 0, 0] : [120, 40, 0, 400],
			// 	callback: function(err) {
			// 		this.map.setZoom(15)
			// 	}
			// })

			// this.map.margin.setDefaultMargin()
			// this.map.setZoom(15)

			this.map.events.add('click', function() {
				self.mainMarker.balloon.close()
			})
		})
	}

	shouldComponentUpdate(oldProps) {
		return oldProps.points !== this.props.points || oldProps.noPoints !== this.props.noPoints
	}

	setCenterWithOffset = () => {
		var pixelCenter = this.map.getGlobalPixelCenter([this.props.object['lat'], this.props.object['lng']])
		pixelCenter = [pixelCenter[0] - (window.innerWidth <= 1000 ? 0 : 300), pixelCenter[1] - 40]
		var geoCenter = this.map.options.get('projection').fromGlobalPixels(pixelCenter, this.map.getZoom())
		this.map.setCenter(geoCenter)
	}

	componentDidUpdate() {
		if (!this.map) return
		this.clearPoints()
		this.createMainPoint()
		this.createCluster()
		this.createPoints()
		this.map.setBounds(this.map.geoObjects.getBounds(), {
			checkZoomRange: true,
			zoomMargin: [120, 40, 0]
		})
	}
	render() {
		return (
			<div ref={this.setContainerRef}>
				<div className="office-map" id="infra-map" />
				<div className="mobile-popover" />
			</div>
		)
	}

	setContainerRef = (ref) => {
		this.container = ref
	}

	getBalloon = (point, icon) => {
		return {
			balloonContent: `
				<div class="infra__balloon">
					<div class="infra__balloon__content">
						${point.name}
					</div>
				</div>`
		}
	}

	get markerOptions() {
		return {
			iconLayout: 'default#image',
			iconImageSize: [38, 38],
			iconImageOffset: [0, -38],
			balloonShadow: false,
			hideIconOnBalloonOpen: true,
			balloonLayout: this.balloonLayout,
			balloonContentLayout: this.balloonContentLayout,
			balloonPanelMaxMapArea: 0,
			balloonOffset: [0, -160]
		}
	}

	get mcOptions() {
		const ymaps = window.ymaps
		return {
			clusterIcons: [
				{
					href: '/img/about/cluster.svg',
					size: [44, 44],
					offset: [22, -22]
				}
			],
			zoomMargin: [80, 0, 80, 400],
			gridSize: 128,
			clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
				'<div class="cluster-count">{{ properties.geoObjects.length }}</div>'
			)
		}
	}

	createMainPoint = () => {
		const { object, mobile } = this.props
		const ymaps = window.ymaps
		const self = this

		self.mainMarker = new ymaps.Placemark(
			[object['lat'], object['lng']],
			{ name: object.title, address: object.address },
			{
				iconLayout: 'default#imageWithContent',
				iconImageHref: '/img/marker.png',
				/*iconContentLayout: ymaps.templateLayoutFactory.createClass(
					`<div class="main-icon-content">
            		<div class="main-icon-content__title">$[properties.name]</div>
            		<div class="main-icon-content__body">$[properties.address]</div>
            	</div>`
				),
				iconContentOffset: [7, 0],
				iconImageSize: mobile ? [189, 142] : [289, 242],
				iconImageOffset: mobile ? [0, -142] : [0, -242]*/
				iconImageSize: [81, 109],
				iconImageOffset: [0, -109],
				balloonLayout: ymaps.templateLayoutFactory.createClass(
					`<div class="main-icon-tooltip">
						<div class="main-icon-close"></div>
						<div class="main-icon-content">
							<div class="main-icon-content__title">$[properties.name]</div>
							<div class="main-icon-content__body">$[properties.address]</div>
						</div>
					</div>`,
					{
						build: function() {
							this.constructor.superclass.build.call(this)

							$(this._element)
								.find('.main-icon-close')
								.click(() => {
									self.mainMarker.balloon.close()
								})
						},
						clear: function() {
							this.constructor.superclass.clear.call(this)
							$(this._element)
								.find('.main-icon-close')
								.unbind('click')
						}
					}
				)
			}
		)

		this.map.geoObjects.add(this.mainMarker)
	}

	createMetro = () => {
		const { metro } = this.props
		const { ymaps } = window

		metro.map((point) => {
			const marker = new ymaps.Placemark(
				[point['lat'], point['lng']],
				{ iconContent: point.name },
				{
					iconLayout: 'default#imageWithContent',
					iconImageHref: `/img/about/metro-marker.png`,
					iconContentLayout: ymaps.templateLayoutFactory.createClass(
						'<div class="icon-content">$[properties.iconContent]</div>'
					),
					iconContentOffset: [28, 32],
					iconImageSize: [201, 165],
					iconImageOffset: [0, -165]
				}
			)
			this.map.geoObjects.add(marker)
			return marker
		})
	}

	createPoints = () => {
		const { points } = this.props
		const ymaps = window.ymaps
		points.map((point) => {
			const marker = new ymaps.Placemark([point['lat'], point['lng']], this.getBalloon(point), {
				iconImageHref: `/img/about/${point['category_id']}.svg`,
				...this.markerOptions
			})
			this.map.geoObjects.add(marker)
			this.markers.push(marker)

			marker.events
				.add('mouseenter', function(e) {
					e.get('target').options.set('iconImageHref', `/img/about/${point['category_id']}_hover.svg`)
				})
				.add('mouseleave', function(e) {
					e.get('target').options.set('iconImageHref', `/img/about/${point['category_id']}.svg`)
				})

			return marker
		})

		this.clusterer.add(this.markers)
	}

	createCluster = () => {
		const ymaps = window.ymaps
		this.clusterer = new ymaps.Clusterer({
			...this.mcOptions
		})
		this.map.geoObjects.add(this.clusterer)
	}

	clearPoints = () => {
		this.markers.map((placemark) => this.map.geoObjects.remove(placemark))
		this.map.geoObjects.remove(this.mainMarker)
		this.map.geoObjects.remove(this.clusterer)
		this.markers = []
	}
	createBaloon = () => {
		const ymaps = window.ymaps
		const self = this
		const { getPage } = this.props

		this.balloonLayout = ymaps.templateLayoutFactory.createClass(
			'<div class="popover">$[[options.contentLayout observeSize]]<div class="popover__arrow"></div></div>',
			{
				build: function() {
					this.constructor.superclass.build.call(this)

					this._$element = $('.popover', this.getParentElement())

					this.applyElementOffset()

					this._$element.find('.map__balloon__close').on('click', $.proxy(this.onCloseClick, this))
					this._$element.find('.map__balloon').on('click', $.proxy(this.onLayoutClick, this))
				},

				clear: function() {
					this._$element.find('.map__balloon__close').off('click')
					this._$element.find('.map__balloon').off('click')

					this.constructor.superclass.clear.call(this)
				},

				onSublayoutSizeChange: function() {
					self.balloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments)

					if (!this._isElement(this._$element)) {
						return false
					}

					this.applyElementOffset()

					this.events.fire('shapechange')
				},

				applyElementOffset: function() {
					this._$element.css({
						left: -(this._$element[0].offsetWidth / 2),
						top: -(this._$element[0].offsetHeight + this._$element.find('.popover__arrow')[0].offsetHeight)
					})
				},

				onCloseClick: function(e) {
					e.preventDefault()

					this.events.fire('userclose')
				},

				onLayoutClick: function(e) {
					e.preventDefault()
					const id = $(this._$element[0])
						.find('.map__balloon')
						.data('id')

					getPage('/realty/genplan/' + id)
				},

				getShape: function() {
					if (!this._isElement(this._$element)) {
						return self.balloonLayout.superclass.getShape.call(this)
					}

					var position = this._$element.position()

					return new ymaps.shape.Rectangle(
						new ymaps.geometry.pixel.Rectangle([
							[position.left, position.top],
							[
								position.left + this._$element[0].offsetWidth,
								position.top +
									this._$element[0].offsetHeight +
									this._$element.find('.popover__arrow')[0].offsetHeight
							]
						])
					)
				},

				_isElement: function(element) {
					return element && element[0] && element.find('.popover__arrow')[0]
				}
			}
		)

		this.balloonContentLayout = ymaps.templateLayoutFactory.createClass(
			'<div class="popover-content">$[properties.balloonContent]</div>'
		)
	}
}
