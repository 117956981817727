import { LOAD_ABOUT, SUCCESS, START } from '../constants'

const defaultObjects = {
	data: null,
	loading: false,
	loaded: false
}

export default (state = defaultObjects, action) => {
	const { type, response } = action

	switch (type) {
		case LOAD_ABOUT + START:
			return { ...state, loading: true }

		case LOAD_ABOUT + SUCCESS:
			return {
				...state,
				data: response,
				loading: false,
				loaded: true
			}

		default:
			return state
	}
}
