import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadNew, loadNews, replacePage } from '../../actions'
import { newsIdsSelector } from '../../selectors'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'

class Detail extends Component {
	componentDidMount() {
		const { loaded, loadedNews, loadNew, loadNews, detail, page: id } = this.props
		if (detail[id]) {
			document.title = detail[id].name
			document.querySelector('meta[name=description]').setAttribute('content', '')
			hit(window.location.pathname)
		}

		if (!loaded || !detail[id]) loadNew(id)
		if (!loadedNews) loadNews(id)
	}

	componentDidUpdate(oldProps) {
		const { detail, page: id, loaded, loadNew, error, replacePage } = this.props

		if (oldProps.detail !== detail && detail[id]) {
			document.title = detail[id].name
			document.querySelector('meta[name=description]').setAttribute('content', '')
			hit(window.location.pathname)
		}

		if (error) replacePage('/error')
		if (oldProps.page === id) return

		if (!loaded || !detail[id]) loadNew(id)
	}

	render() {
		const { loading, page: id, detail } = this.props
		const item = detail[id]
		if (loading || !item) return <Loader />

		return (
			<div className="content__block new">
				<div className="box">
					<div className="breadcrumb">
						<h1>{item.name}</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/news">Новости</Link> /{item.name}
						</div>
					</div>
					<Fade key={id}>{this.body}</Fade>
				</div>
			</div>
		)
	}

	get body() {
		const { detail, page: id, newsIds, mobile } = this.props
		const item = detail[id]
		return (
			<div key={item.id} className={!item.img ? 'news__item news__item__noimage' : 'news__item'}>
				{!mobile || <div className="news__date">{item['date']}</div>}
				{!item.img || (
					<span className="news__item__image">
						<span style={{ backgroundImage: `url(${item.img})` }} />
					</span>
				)}
				<div className="news__item__body">
					{mobile || <div className="news__date">{item['date']}</div>}
					<div className="news__description" dangerouslySetInnerHTML={{ __html: item['descr'] }} />
					<div className="news__btn">
						<Link to={`/news`}>Все новости</Link>
					</div>
					{this.getArrows(newsIds, id)}
				</div>
			</div>
		)
	}

	getArrows = (ids, id) => {
		if (!ids) return null

		const num = ids.indexOf(id)
		const next = !ids[num + 1] ? ids[0] : ids[num + 1]
		const prev = !ids[num - 1] ? ids[ids.length - 1] : ids[num - 1]

		return (
			<div className="news__arrows">
				<Link to={`/news/${prev}`} className="news__arrows__prev ico-10" />
				<Link to={`/news/${next}`} className="news__arrows__next ico-8" />
			</div>
		)
	}
}

export default connect(
	(state, ownProps) => ({
		loading: state.newsPage.loadingNew,
		loaded: state.newsPage.loadedNew,
		loadedNews: state.newsPage.loaded,
		detail: state.newsPage.detail,
		newsIds: newsIdsSelector(state),
		error: state.newsPage.error,
		mobile: state.mobile
	}),
	{ loadNew, loadNews, replacePage }
)(Detail)
