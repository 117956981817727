import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class FlatPrice extends Component {
	render() {
		return (
			<div className="main__item--flatprice">
				<div className="main__item--title">
					<div dangerouslySetInnerHTML={{ __html: this.props.el.title }} />
					<p dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
				</div>
				<div className="main__item--flatprice__but">
					<Link className="main__item--but main__item--but__star" to="/realty/params">
						<i className="icon-star"> </i>
						<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
					</Link>
				</div>
			</div>
		)
	}
}
