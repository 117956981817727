import React from 'react'
import loaders from './fixtures'

export default class LoadersList extends React.Component {
	showList() {
		return loaders.map((loader) => {
			return (
				<div key={loader.id} className={`logo_item logo_item` + loader.id}>
					<img src={loader.img} alt={`logo_i` + loader.id} />
				</div>
			)
		})
	}
	render() {
		return (
			<div>
				<div className="logo_in">
					<img src="/img/logo.svg" alt="logo" />
					<img src="/img/logo_else.svg" alt="logo" />
				</div>
				{this.showList()}
			</div>
		)
	}
}
