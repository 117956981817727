import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadAgreement } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'

class Agreement extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Пользовательское соглашение'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Соглашение регулирует порядок работы сайта и определяет условия использования материалов и сервисов сайта'
			)
		hit(window.location.pathname)

		const { loading, loaded, loadAgreement } = this.props
		if (!loading && !loaded) loadAgreement()
	}
	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="content__block privacy">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Пользовательское соглашение</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / Пользовательское соглашение
						</div>
					</div>
					<Fade>{this.body}</Fade>
				</div>
			</div>
		)
	}

	get body() {
		return <div dangerouslySetInnerHTML={{ __html: this.props.agreement.data }} />
	}
}

export default connect(
	(state) => ({
		loading: state.agreement.loading,
		loaded: state.agreement.loaded,
		agreement: state.agreement.data
	}),
	{ loadAgreement }
)(Agreement)
