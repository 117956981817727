import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadContacts } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import ContactsMap from './map'
import { hit } from '../common/metrics'

class Contacts extends Component {
	state = {
		tabIndex: '0'
	}

	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Контакты'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'ОФИС ПРОДАЖ г. Москва, ул. Чечёрский проезд, д. 122, корп. 1. | Тел.: +7 (925) 434-03-23 ЧАСЫ | РАБОТЫ 10.00-19.00 без выходных'
			)
		hit(window.location.pathname)

		const { loading, loaded, loadContacts } = this.props
		if (!loading && !loaded) loadContacts()
	}

	render() {
		const { loading, loaded, contacts, mobile } = this.props
		const { tabIndex } = this.state
		if (loading) return <Loader />
		if (!loaded) return <Loader />
		return (
			<div className="content__block contacts">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Контакты</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / Контакты
						</div>
					</div>
				</div>
				<div className="content">
					<Fade>{this.map}</Fade>
					<div className="title">Как проехать</div>
					{mobile ? this.mobilePanel : this.panel}
					<Fade key={tabIndex}>
						<div
							className="contacts-tab"
							dangerouslySetInnerHTML={{ __html: contacts.tabs[tabIndex].text }}
						/>
					</Fade>
				</div>
			</div>
		)
	}

	get panel() {
		return (
			<div className="realty-panel">
				{this.props.contacts.tabs.map((el, i) => {
					return (
						<div key={i} className="realty-panel__item" value={i} onClick={this.handleClick}>
							<div className={+this.state.tabIndex === i ? 'active' : ''}>
								<span className={`realty-panel__icon1 ico-${52 + i}`}>{el.title}</span>
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	get mobilePanel() {
		return (
			<div className="select-mobile select-mobile--break">
				<span className="select-mobile__title">
					<span>{this.props.contacts.tabs[this.state.tabIndex].title}</span>
				</span>
				<select value={this.state.tabIndex} onChange={this.handleChange}>
					{this.mobileOptions}
				</select>
				<i className={`select-mobile__icon  ico-${52 + +this.state.tabIndex}`} />
				<span className="select__indicator select__indicator--mobile" />
			</div>
		)
	}

	get mobileOptions() {
		return this.props.contacts.tabs.map((el, i) => (
			<option key={i} value={i}>
				{el.title}
			</option>
		))
	}

	handleClick = (ev) => {
		this.setState({
			tabIndex: ev.currentTarget.getAttribute('value')
		})
	}

	handleChange = (ev) => {
		this.setState({
			tabIndex: ev.target.value
		})
	}

	get map() {
		return <ContactsMap point={this.props.contacts.point} mobile={this.props.mobile} />
	}
}

export default connect(
	(state) => ({
		loading: state.contacts.loading,
		loaded: state.contacts.loaded,
		contacts: state.contacts.data,
		mobile: state.mobile
	}),
	{ loadContacts }
)(Contacts)
