import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadAbout, openPopupInstallments } from '../../actions'
import Slider from '../common/slider'

class AboutAdvList extends React.Component {
	componentDidMount() {
		const { loading, loaded, loadAbout } = this.props
		if (!loading && !loaded) loadAbout()
	}

	render() {
		const { loaded, mobile, isCommerce } = this.props
		if (!loaded || !isCommerce) return null

		return (
			<div className="advants">
				<div className="box">
					{mobile ? (
						<Slider items={this.body} options={{ arrows: true }} />
					) : (
						<div className="advants__links" data-len={this.body.length}>
							{this.body}
						</div>
					)}
				</div>
			</div>
		)
	}

	get body() {
		return this.props.about.advants.map((el, i) => {
			if (el.url === '/installments') {
				return (
					<div className="advants__item" key={i} onClick={() => this.props.openPopupInstallments()}>
						<div className="advants__item__img">
							<span style={{ backgroundImage: `url(${el.img})` }} />
						</div>
						<p className="advants__item__title">
							<i className={'icon-i_advants' + (i + 1)} />
							<span dangerouslySetInnerHTML={{ __html: el.text }} />
						</p>
					</div>
				)
			} else {
				return (
					<Link to={el.url} className="advants__item" key={i}>
						<div className="advants__item__img">
							<span style={{ backgroundImage: `url(${el.img})` }} />
						</div>
						<p className="advants__item__title">
							<i className={'icon-i_advants' + (i + 1)} />
							<span dangerouslySetInnerHTML={{ __html: el.text }} />
						</p>
					</Link>
				)
			}
		})
	}
}

export default connect(
	(state) => ({
		loading: state.about.loading,
		loaded: state.about.loaded,
		about: state.about.data,
		mobile: state.mobile
	}),
	{ loadAbout, openPopupInstallments }
)(AboutAdvList)
