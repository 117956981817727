import * as Cookies from 'js-cookie'

export default (store) => (next) => (action) => {
	const { viewedCommerce, id, ...rest } = action

	if (!viewedCommerce) return next(action)

	if (!Cookies.get('viewedCommerce')) {
		Cookies.set('viewedCommerce', [], { path: '' })
	}

	let viewedList = JSON.parse(Cookies.get('viewedCommerce'))

	if (id && !viewedList.includes(id)) {
		Cookies.set('viewedCommerce', [...viewedList, id], { path: '' })
	}

	viewedList = JSON.parse(Cookies.get('viewedCommerce'))

	next({
		...rest,
		viewedCommerce: viewedList
	})
}
