import {
	SUCCESS,
	START,
	OPEN,
	CLOSE,
	GET_COMMERCE_FLAT,
	GET_COMMERCE,
	FAIL,
	GET_COMMERCE_CORPUS,
	POPUP_SCHEME,
	POPUP_INSTALLMENTS
} from '../constants'

const defaultObjects = {
	entities: [
		{
			id: null,
			number: null,
			floor: null,
			square: null,
			price: null,
			plan: null,
			corpus: null,
			on_sale: null
		}
	],
	loading: false,
	loaded: false,

	corpus: {},
	loadingCommerceCorpus: false,
	corpusError: null,

	detail: {},
	loadingCommerceFlat: false,
	viewed: [],
	error: null,
	popupSheme: {
		open: false
	},
	popupInstallments: {
		open: false
	}
}

export default (state = defaultObjects, action) => {
	const { type, payload, response, viewedCommerce, error } = action

	switch (type) {
		case GET_COMMERCE + START:
			return { ...state, loading: true }

		case GET_COMMERCE + SUCCESS:
			return { ...state, loading: false, loaded: true, entities: response }

		case GET_COMMERCE_CORPUS + START:
			return { ...state, loadingCommerceCorpus: true }

		case GET_COMMERCE_CORPUS + SUCCESS:
			return {
				...state,
				corpus: {
					...state.detail,
					[payload.id]: response
				},
				loadingCommerceCorpus: false
			}

		case GET_COMMERCE_CORPUS + FAIL:
			return {
				...state,
				corpusError: error
			}

		case GET_COMMERCE_FLAT + START:
			return { ...state, loadingCommerceFlat: true }

		case GET_COMMERCE_FLAT + SUCCESS:
			return {
				...state,
				viewed: viewedCommerce,
				detail: {
					...state.detail,
					[payload.id]: response
				},
				loadingCommerceFlat: false
			}

		case GET_COMMERCE_FLAT + FAIL:
			return {
				...state,
				error
			}

		case POPUP_SCHEME + OPEN:
			return {
				...state,
				popupSheme: { ...state.popupSheme, open: true }
			}

		case POPUP_SCHEME + CLOSE:
			return {
				...state,
				popupSheme: { ...state.popupSheme, open: false }
			}

		case POPUP_INSTALLMENTS + OPEN:
			return {
				...state,
				popupInstallments: { ...state.popupInstallments, open: true }
			}

		case POPUP_INSTALLMENTS + CLOSE:
			return {
				...state,
				popupInstallments: { ...state.popupInstallments, open: false }
			}

		default:
			return state
	}
}
