import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import ScrollTop from '../../common/scroll-top'
import { loadInfra, changeInfraGroup } from '../../../actions'
import InfraMap from './map'
import Loader from '../../common/loader'
import AboutMenuList from '../about-menu-list'
import AboutFlatChooseList from '../about-choose-flat'
import AboutAdvList from '../about-advants'
import { filtratedInfraSelector, infraSelector, infraFiltersSelector } from '../../../selectors'
import { hit } from '../../common/metrics'
import Fade from 'react-reveal/Fade'

class Infra extends Component {
	state = {
		open: true
	}

	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Местоположение и инфраструктура.'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Здесь Вы можете ознакомиться с местоположение и инфраструктурой жилого комплекса «Новое Бутово»'
			)
		hit(window.location.pathname)
		if (!this.props.loaded) this.props.loadInfra()
	}

	render() {
		const { points, loading, object, metro, mobile } = this.props
		const { open } = this.state
		if (loading || !points.length) return <Loader />

		return (
			<section className="content__block about-infrastructure">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>О проекте</h1>
						<div className="breadcrumb__links">
							<NavLink to="/">Главная</NavLink> / <NavLink to="/about">О проекте</NavLink> /
							Инфраструктура
						</div>
					</div>
				</div>
				<AboutMenuList />
				<Fade>
					<div className="infra-map-container content">
						<InfraMap points={points} object={object} metro={metro} mobile={mobile} />
						<div className={open ? 'infra-filter' : 'infra-filter close'}>
							<div className="infra-filter__title">
								Инфраструктура
								<i
									className={
										open ? 'infra-filter__title__arrow ico-22' : 'infra-filter__title__arrow ico-23'
									}
									onClick={this.handleToggle}
								/>
							</div>
							<div className="infra-filter__body">{this.filter}</div>
						</div>
					</div>
				</Fade>
				<AboutFlatChooseList />
				<AboutAdvList />
			</section>
		)
	}

	handleToggle = () => {
		this.setState({
			open: !this.state.open
		})
	}

	get filter() {
		const { infraList, filters } = this.props
		const values = []
		const options = infraList.filter((item, key) => {
			const value = infraList[key]['category']

			if (values.indexOf(value) === -1) {
				values.push(value)
				return item
			}

			return false
		})
		return options.map((point) => {
			const isActive = filters.indexOf(point.category_id) !== -1 || !filters.length ? 'active' : ''
			return (
				<div
					className={`map-ico-${point['category_id']} 
						infra-filter__item 
						${isActive}`}
					key={point['category']}
					value={point['category_id']}
					onClick={this.handleClick}
				>
					<span>{point['category']}</span>
				</div>
			)
		})
	}

	handleClick = (ev) => {
		const { filters, changeInfraGroup } = this.props
		const value = ev.currentTarget.getAttribute('value')

		const selected =
			filters.indexOf(value) === -1 ? [...filters, value] : filters.filter((category) => category !== value)

		changeInfraGroup(selected)
	}
}

export default connect(
	(state) => ({
		infraList: infraSelector(state),
		loading: state.infra.loading,
		loaded: state.infra.loaded,
		points: filtratedInfraSelector(state),
		filters: infraFiltersSelector(state).selected,
		object: state.infra.object,
		metro: state.infra.metro,
		mobile: state.mobile
	}),
	{ loadInfra, changeInfraGroup }
)(Infra)
