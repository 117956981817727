import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import 'rc-slider/assets/index.css'
import { changeFloor } from '../../actions'
import { floorMinSelector, floorMaxSelector } from '../../selectors'
const Range = Slider.createSliderWithTooltip(Slider.Range)

class FloorFilter extends Component {
	handleChange = (value) => {
		const selected = {
			from: value[0],
			to: value[1]
		}
		this.props.changeFloor(selected)
	}

	render() {
		const { from, to } = this.props.floor
		const value = [from || Math.floor(this.props.min * 10) / 10, to || this.props.max]
		return (
			<Range
				min={Math.floor(this.props.min * 10) / 10}
				max={this.props.max}
				value={value}
				onChange={this.handleChange}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
			/>
		)
	}
}

export default connect(
	(state) => ({
		floor: state.filters.floor,
		min: floorMinSelector(state),
		max: floorMaxSelector(state)
	}),
	{ changeFloor }
)(FloorFilter)
