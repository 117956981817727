import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import 'rc-slider/assets/index.css'
import { changeTerm } from '../../actions'
import { termMortgageSelector } from '../../selectors'
const SliderWithTooltip = Slider.createSliderWithTooltip(Slider)

class TermFilter extends Component {
	handleChange = (value) => {
		this.props.changeTerm(value)
	}

	render() {
		const { term } = this.props
		return (
			<SliderWithTooltip
				min={36}
				max={360}
				value={term}
				onChange={this.handleChange}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
			/>
		)
	}
}

export default connect(
	(state) => ({
		term: state.mortgageFilter.term2
	}),
	{ changeTerm }
)(TermFilter)
