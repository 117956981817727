export const aboutMenu = [
	{
		id: 1,
		href: '/about/residential',
		name: 'Подробнее о ЖК'
	},
	{
		id: 2,
		href: '/about/infrastructure',
		name: 'Местоположение<br/> и инфраструктура'
	},
	{
		id: 3,
		href: '/about/gallery',
		name: 'Галерея'
	},
	{
		id: 4,
		href: '/about/docs',
		name: 'Документы'
	},
	{
		id: 5,
		href: '/about/developer',
		name: 'О застройщике'
	},
	{
		id: 6,
		href: '/about/progress',
		name: 'Дневник стройки'
	}
]

export const purchaseMenu = [
	{
		id: 1,
		href: '/purchase/mortgage/flats',
		name: 'Ипотека'
	},
	// {
	// 	id: 2,
	// 	href: '/purchase/payment',
	// 	name: 'Рассрочка'
	// },
	{
		id: 3,
		href: '/purchase/promotions',
		name: 'Акции'
	}
]
