import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import AboutMenuList from '../about-menu-list'
import Loader from '../../common/loader'
import { loadDeveloper } from '../../../actions'
import ScrollTop from '../../common/scroll-top'
import Slider from '../../common/slider'
import { hit } from '../../common/metrics'
import Fade from 'react-reveal/Fade'

class Developer extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Девелопер проекта'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Девелопером ЖК «Новое Бутово» выступает MD Group. Более 10 лет MD Group специализируется на жилых и коммерческих проектах в Москве и области.'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadDeveloper } = this.props
		if (!loading && !loaded) loadDeveloper()
	}

	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<section className="content__block developer">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>О проекте</h1>
						<div className="breadcrumb__links">
							<NavLink to="/">Главная</NavLink> / <NavLink to="/about">О проекте</NavLink> / О застройщике
						</div>
					</div>
				</div>
				<AboutMenuList />
				<Fade>{this.body}</Fade>
				{this.awards}
			</section>
		)
	}

	get body() {
		const { block } = this.props.developer

		return (
			<div className="developer__body box">
				{this.props.mobile ? null : (
					<div className="developer__img" style={{ backgroundImage: `url(${block.img})` }} />
				)}

				<div className="developer__body__text">
					<div className="developer__title" dangerouslySetInnerHTML={{ __html: block.title }} />
					{!this.props.mobile || (
						<div className="developer__img" style={{ backgroundImage: `url(${block.img})` }} />
					)}
					<div dangerouslySetInnerHTML={{ __html: block.text }} />
				</div>
			</div>
		)
	}

	get awards() {
		const { mobile } = this.props
		const len = mobile ? 1 : 3
		return (
			<div className="awards box">
				<div className="awards__title">Награды</div>
				<Slider
					items={this.awardsItems}
					options={{
						slidesToShow: len,
						slidesToScroll: len,
						dots: mobile ? false : true,
						arrows: mobile ? true : false
					}}
				/>
			</div>
		)
	}

	get awardsItems() {
		const { awards } = this.props.developer
		if (!awards) return null

		return awards.map((el) => {
			return (
				<div className="awards__item" key={el.id}>
					{!el.img || (
						<div className="awards__image">
							<div className="awards__image__bg" style={{ backgroundImage: `url(${el.img})` }} />
						</div>
					)}
					<div
						className="awards__body"
						dangerouslySetInnerHTML={{ __html: el.text }}
						style={!el.img ? { padding: 0 } : {}}
					/>
				</div>
			)
		})
	}
}

export default connect(
	(state) => ({
		loading: state.developer.loading,
		loaded: state.developer.loaded,
		developer: state.developer.data,
		mobile: state.mobile
	}),
	{ loadDeveloper }
)(Developer)
