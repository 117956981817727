import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '../common/button'

class Additional extends Component {
	render() {
		return (
			<div className="content additional">
				<div
					className="additional_left"
					style={{
						backgroundImage: `url(${
							this.props.mobile ? '/img/realty/decoration-mobile.jpg' : '/img/realty/decoration.jpg'
						})`
					}}
				>
					<div className="additional_body">
						<div className="additional__title">
							Комфорт
							<br />
							«под ключ»
						</div>
						Квартиры с отделкой в ЖК "Новое Бутово" - это решение для тех, кто ценит время, комфорт и хочет
						избавить себя от бесконечного ремонта и лишних затрат. При выборе квартиры с отделкой, вы
						переезжаете сразу в новое полностью отремонтированное жилье.
						<div className="additional__btn">
							<Button to="/realty/decoration">Подробнее об отделке</Button>
						</div>
					</div>
				</div>
				<div
					className="additional_right"
					style={{
						backgroundImage: `url(${
							this.props.mobile ? '/img/realty/pano-mobile.jpg' : '/img/realty/pano.jpg'
						})`
					}}
				>
					<div className="additional_body">
						<div className="additional__title">Аэропанорама</div>
						Пройдитесь по ЖК виртуально, будто вы находитесь там.
						<div className="additional__btn">
							<Button to="/panorama">смотреть аэропанораму</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile
	}),
	{}
)(Additional)
