import React, { Component } from 'react'
import SVG from 'svg.js/dist/svg.min.js'
import $ from 'jquery'

export default class FloorSvg extends Component {
	state = {
		height: null,
		width: null
	}
	componentDidMount() {
		if (!this.container) return null
		this.handleLoad()
	}

	componentDidUpdate() {
		$(this.container)
			.find('svg')
			.remove()
		this.handleLoad()
	}

	shouldComponentUpdate(props) {
		return props.sections !== this.props.sections || props.active !== this.props.active
	}

	render() {
		const { img } = this.props
		return (
			<div ref={this.setContainerRef} className="building-scheme">
				<img src={img} alt="" id="floor-img" />
			</div>
		)
	}

	setContainerRef = (ref) => {
		this.container = ref
	}

	createSvg = (width, height) => {
		const { sections, active, isSection, corpus, getPage, sectionsInstock } = this.props
		this._draw = SVG(this.container).size(width, height)
		this._draw.viewbox(0, 0, width, height)
		Object.keys(sections).map((value) => {
			const poly = this._draw
				.polygon(sections[value])
				//.fill('#f4f4f4')
				.fill('#e2068c')
				.fill(sectionsInstock && sectionsInstock.indexOf(value) === -1 ? '#f4f4f4' : '#e2068c')
				.stroke({ width: 1 })
				.addClass(value === active ? 'active' : '')
				.addClass(sectionsInstock && sectionsInstock.indexOf(value) === -1 ? '' : 'init-hover')

				.on('click', () => {
					if (!isSection || sectionsInstock.indexOf(value) === -1) return false
					this.props.getPage(`/realty/genplan/${corpus}/${value}`)
					window.scroll({ top: 170, left: 0, behavior: 'smooth' })
					//getPage(`/realty/genplan/${corpus}/${value}`)
					//getPage(`/realty/genplan/${corpus}/`)
				})

			const bbox = poly.rbox(this._draw)

			this._draw
				.text((add) => {
					add.tspan(value)
				})
				.size(7)
				//.font({ fill: value === active ? '#fff' : '#adadad', family: 'Acrom' })
				.font({
					fill: sectionsInstock && sectionsInstock.indexOf(value) === -1 ? '#adadad' : '#fff',
					family: 'Acrom'
				})
				.dx(bbox.cx - 2)
				.dy(bbox.cy + 4)

			return true
		})
	}

	handleLoad = (callback) => {
		$.get(
			this.props.img,
			(svgxml) => {
				var attrs = svgxml.documentElement.attributes
				const height = parseInt(attrs.height.value)
				const width = parseInt(attrs.width.value)
				this.setState({
					height,
					width
				})
				this.createSvg(width, height)
			},
			'xml'
		)
	}
}
