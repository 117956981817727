import React, { Component, Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import LoadersList from '../common/loader-main'
import AboutMenu from '../Menu/about-menu'
import PurchaseMenu from '../Menu/purchase-menu'
import Timer from './timer'

export default class Header extends Component {
	state = {
		visible: false
	}

	openForm = () => {
		this.props.openCallForm()
		this.props.setFormPlaceType('Шапка')
	}

	handleVideoClick = () => this.setState({ visible: !this.state.visible })

	render() {
		return (
			<Fragment>
				<header className="header" id="header">
					<div className="box">
						{/*<div className="head__tel">
							<a href="tel:+74951180374">+7 (495) 118-03-74</a>
							<div className="head__tel__txt">
								Информационный
								<br />
								центр
							</div>
						</div>*/}
						<span className="ico-58" onClick={this.props.toggleMenu} />
					</div>
				</header>
				<div className="loader">
					<NavLink to="/" className="logo">
						<LoadersList />
					</NavLink>
				</div>
				{this.props.open ? <div className="overlay" onClick={this.props.toggleMenu} /> : null}
				{this.menu}
				{this.props.actions.time && +this.props.actions.time ? (
					<div className="header-block">
						<Link to="/purchase/promotions" className="header-block__left">
							<div className="header-block__left__title">{this.props.actions.title2}</div>
							<div className="header-block__left__body">
								<span className="ico-64">
									<i>{this.props.actions.count}</i>
								</span>
							</div>
						</Link>
						<div className="header-block__right">
							<div className="header-block__right__title">{this.props.actions.title}</div>
							<div className="header-block__right__body">
								<Timer actions={this.props.actions} />
							</div>
						</div>
					</div>
				) : null}
			</Fragment>
		)
	}

	get menu() {
		return (
			<div className={this.props.open ? 'mobile-menu open' : 'mobile-menu'} id="menu">
				<div className="menu__out">
					<div onClick={this.props.toggleMenu} className="menu__detect">
						Меню
						<i className="menu__detect__close ico-37" />
					</div>
					<div className="menu__content">
						<div className="menu__in">
							<NavLink onClick={this.props.toggleMenu} to={'/realty'} data-submenu="sub1">
								<span>Подобрать квартиру</span>
							</NavLink>
							{this.props.submenu === 'sub1' ? (
								<div className="mobile-submenu">
									<NavLink to="/realty/genplan" onClick={this.props.toggleMenu}>
										<p>На генплане</p>
									</NavLink>
									<NavLink to="/realty/params" onClick={this.props.toggleMenu}>
										<p>По параметрам</p>
									</NavLink>
									<NavLink to="/realty/layout" onClick={this.props.toggleMenu}>
										<p>По видам планировок</p>
									</NavLink>
									<NavLink to="/realty/decoration" onClick={this.props.toggleMenu}>
										<p>Отделка</p>
									</NavLink>
								</div>
							) : null}
							<NavLink onClick={this.props.toggleMenu} to={'/about'} data-submenu="sub2">
								<span>О проекте</span>
							</NavLink>
							{this.props.submenu === 'sub2' ? (
								<div className="mobile-submenu">
									<AboutMenu toggleMenu={this.props.toggleMenu} />
								</div>
							) : null}
							<NavLink onClick={this.props.toggleMenu} to="/purchase" data-submenu="sub3">
								<span>Условия покупки</span>
							</NavLink>
							{this.props.submenu === 'sub3' ? (
								<div className="mobile-submenu">
									<PurchaseMenu toggleMenu={this.props.toggleMenu} />
								</div>
							) : null}
							<NavLink onClick={this.props.toggleMenu} to="/commerce" data-submenu="none">
								<span>Коммерческие помещения</span>
							</NavLink>
							<NavLink onClick={this.props.toggleMenu} to="/parking" data-submenu="none">
								<span>Паркинг</span>
							</NavLink>
							<NavLink onClick={this.props.toggleMenu} to="/news" data-submenu="none">
								<span>Новости</span>
							</NavLink>
							<NavLink onClick={this.props.toggleMenu} to="/faq" data-submenu="none">
								<span>Вопросы и ответы</span>
							</NavLink>
							<NavLink onClick={this.props.toggleMenu} to="/contacts" data-submenu="none">
								<span>Контакты</span>
							</NavLink>
						</div>
						<NavLink to={'/realty'} className="but" onClick={this.props.toggleMenu}>
							Подобрать квартиру
						</NavLink>
						{this.props.actions.time && +this.props.actions.time ? (
							<div className="mobile-actions">
								<Link
									to="/purchase/promotions"
									onClick={this.props.toggleMenu}
									className="header-block__left"
								>
									<div className="header-block__left__title">{this.props.actions.title2}</div>
									<div className="header-block__left__body">
										<span className="ico-64">
											<i>{this.props.actions.count}</i>
										</span>
									</div>
								</Link>
								<div className="header-block__right">
									<div className="header-block__right__title">{this.props.actions.title}</div>
									<div className="header-block__right__body">
										<Timer actions={this.props.actions} />
									</div>
								</div>
							</div>
						) : null}
						<div className="head__tel">
							<a href="tel:+74951180374">+7 (495) 118-03-74</a>
							<div className="head__tel__txt">Информационный центр</div>
            </div>
						<div className="head__buts">
							<Link
								to="/about/progress"
								onClick={this.props.toggleMenu}
								className="popup__lnk progress__lnk iconsnew-1"
							/>
							{/*<div className="popup__lnk video__lnk icon-i_video" onClick={this.handleVideoClick} />*/}
							<Link to="/panorama" onClick={this.props.toggleMenu} className="popup__lnk panoram__lnk">
								<span className="icon-i_panoram" />
								360
							</Link>
						</div>
						<div className="question-btn" onClick={this.props.openQuestionForm}>
							задать вопрос
						</div>
						<div className="head__tel head__tel--white">
							<a href="tel:+7 (925) 434-03-23">+7 (925) 434-03-23</a>
							<div className="head__tel__txt">отдел продаж</div>
							<br />
							<a href="tel:+7 (909) 990-06-11" target="_blank">
								+7 (909) 990-06-11
							</a>
							<div className="head__tel__txt">
								по вопросам
								<br />
								покупки машиномест
							</div>
							<br />
							<div onClick={this.props.openCallForm} className="popup__lnk">
								заказать звонок
							</div>
						</div>
						{this.state.visible && (
							<div className="video__lnk__popup-mobile">
								<a
									href="https://open.ivideon.com/embed/v2/?server=100-tILuXokSZMmXqcrIGAQFVo&camera=1376256&width=1200height=675&lang=ru"
									target="_blank"
									rel="noopener noreferrer"
								>
									14 корп.
								</a>
								<a
									href="https://open.ivideon.com/embed/v2/?server=100-tILuXokSZMmXqcrIGAQFVo&camera=1441792&width=&height=&lang=ru"
									target="_blank"
									rel="noopener noreferrer"
								>
									16 корп.
								</a>
							</div>
						)}
						<div className="menu__socials">
							<div className="mdgroup" />
							<a
								href="https://vk.com/novobutovo2018"
								target="_blank"
								className="menu_vk icon-vk"
								rel="noopener noreferrer"
							>
								vk
							</a>
							<a
								href="https://www.instagram.com/novobutovo/"
								target="_blank"
								className="menu_inst icon-inst"
								rel="noopener noreferrer"
							>
								inst
							</a>
						</div>
						<div className="menu__bottom">
							© 2018 Жилой комплекс "Новое Бутово" - квартиры в новостройке в Москве
							<br />
							<br />
							<Link to="/privacy" onClick={this.props.toggleMenu} className="terms__links">
								Политика конфиденциальности
							</Link>
							<br />
							<Link to="/agreement" onClick={this.props.toggleMenu} className="terms__links">
								Пользовательское соглашение
							</Link>
							<br />
							<br />
							Вся представленная на данном сайте информация носит исключительно информационный характер и
							не является публичной офертой, определяемой положениями ст. 437 ГК РФ.
							<br />
							<br />
							Для получения подробной информации о проекте просьба обращаться к девелоперу проекта.
							Опубликованная на данном сайте информация может быть изменена в любое время без
							предварительного уведомления.
							<br />
							<br />
							<a
								href="http://www.amio.ru/"
								rel="noopener noreferrer"
								target="_blank"
								className="made__in"
							>
								<span>
									Разработано в &nbsp;<span>A M I O</span>
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
