import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'
import App from './app'
import './css/animations.css'
import './css/app.scss'
import './css/app_adaptive.scss'
import './css/realty.scss'
import './css/realty_adaptive.scss'
import '@babel/polyfill'
import 'raf/polyfill'
import 'match-media'
//-------------main------------
ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('main')
)
//-------------end main------------
