import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import LoadersList from '../common/loader-main'
import AboutMenu from '../Menu/about-menu'
import PurchaseMenu from '../Menu/purchase-menu'
import Timer from './timer'
import $ from 'jquery'
let tid
export default class Header extends Component {
	state = {
		left: 602,
		visible: false
	}

	openForm = () => {
		this.props.openCallForm()
		this.props.setFormPlaceType('Шапка')
	}

	handleVideoClick = (ev) => {
		ev.stopPropagation()
		const parent = ev.target.parentNode
		this.setState({
			left: parent.offsetLeft + parent.offsetWidth / 2.1,
			visible: !this.state.visible
		})
	}

	handleVideoClose = () => this.setState({ visible: false })

	render() {
		return (
			<Fragment>
				<header className="header" id="header" onClick={this.handleVideoClose}>
					<div className="box">
						<div className="loader">
							<NavLink exact={true} to="" className="logo">
								<LoadersList />
							</NavLink>
						</div>

						<div className="header__area">
							<div className="head__tel head__tel--left">
								<a href="tel:+7 (495) 118-03-74" target="_blank">
									+7 (495) 118-03-74
								</a>
								<span>Информационный центр</span>
							</div>
							<div className="head__buts">
								<NavLink to="/about/progress" className="popup__lnk progress__lnk iconsnew-1" />
								<NavLink to="/panorama" className="popup__lnk panoram__lnk">
									<span className="icon-i_panoram" />
									360
								</NavLink>
							</div>
							<div className="head-links">
								<NavLink to={'/realty/genplan'} className="head-links__item">
									Квартиры
								</NavLink>
								<NavLink to={'/parking/1'} className="head-links__item">
									Машиноместа
								</NavLink>
								<NavLink to={'/commerce'} className="head-links__item head-links__item--hid">
									Коммерческие помещения
								</NavLink>
							</div>
							<div className="head-links">
								<NavLink to={'/news'} className="but">
									Новости
								</NavLink>
							</div>
							<div className="head__tel head__tel--right">
								<a href="tel:+7 (925) 434-03-23" target="_blank">
									+7 (925) 434-03-23
								</a>
								<span>отдел продаж</span>
								<a href="tel:+7 (909) 990-06-11" target="_blank">
									+7 (909) 990-06-11
								</a>
								<span>по вопросам покупки машиномест</span>
							</div>

							{this.props.open ? <div className="overlay" onClick={this.props.toggleMenu} /> : null}
						</div>
					</div>
				</header>
				<div
					className={this.state.visible ? 'video__lnk__popup visible' : 'video__lnk__popup'}
					style={{ left: this.state.left }}
					onMouseLeave={this.handleVideoClose}
				>
					<a
						href="https://open.ivideon.com/embed/v2/?server=100-tILuXokSZMmXqcrIGAQFVo&camera=1376256&width=1200height=675&lang=ru"
						target="_blank"
						rel="noopener noreferrer"
					>
						14 корп.
					</a>
					<a
						href="https://open.ivideon.com/embed/v2/?server=100-tILuXokSZMmXqcrIGAQFVo&camera=1441792&width=&height=&lang=ru"
						target="_blank"
						rel="noopener noreferrer"
					>
						16 корп.
					</a>
				</div>
				{this.menu}
				{this.props.actions.time && +this.props.actions.time ? (
					<div className="header-block">
						<NavLink to="/purchase/promotions" className="header-block__left">
							<div className="header-block__left__title">{this.props.actions.title2}</div>
							<div className="header-block__left__body">
								<span className="ico-64">
									<i>{this.props.actions.count}</i>
								</span>
							</div>
						</NavLink>
						<div className="header-block__right">
							<div className="header-block__right__title">{this.props.actions.title}</div>
							<div className="header-block__right__body">
								<Timer actions={this.props.actions} />
							</div>
						</div>
					</div>
				) : null}
			</Fragment>
		)
	}

	get menu() {
		return (
			<menu className={this.props.open ? 'open fixed' : ''} id="menu">
				<div className="menu__out">
					<div onClick={this.props.toggleMenu} className="menu__detect">
						Меню
						<span className="burger">
							<span />
						</span>
					</div>
					<div className="menu__content">
						<div className="menu__in">
							<NavLink
								onClick={this.props.toggleMenu}
								onMouseEnter={this.props.openSubmenuMenu}
								to={'/realty'}
								data-submenu="sub1"
								data-hassubmenu
							>
								Подобрать квартиру
							</NavLink>
							<NavLink
								onClick={this.props.toggleMenu}
								onMouseEnter={this.props.openSubmenuMenu}
								to={'/about'}
								data-submenu="sub2"
								data-hassubmenu
							>
								О проекте
							</NavLink>
							<NavLink
								onClick={this.props.toggleMenu}
								onMouseEnter={this.props.openSubmenuMenu}
								to="/purchase"
								data-submenu="sub3"
								data-hassubmenu
							>
								Условия покупки
							</NavLink>
							<NavLink
								onClick={this.props.toggleMenu}
								onMouseEnter={this.props.closeSubmenuMenu}
								to="/commerce"
								data-submenu="none"
							>
								Коммерческие помещения
							</NavLink>
							<NavLink
								onClick={this.props.toggleMenu}
								onMouseEnter={this.props.closeSubmenuMenu}
								to="/parking"
								data-submenu="none"
							>
								Паркинг
							</NavLink>
							<NavLink
								onClick={this.props.toggleMenu}
								onMouseEnter={this.props.closeSubmenuMenu}
								to="/news"
								data-submenu="none"
							>
								Новости
							</NavLink>
							<NavLink
								onClick={this.props.toggleMenu}
								onMouseEnter={this.props.closeSubmenuMenu}
								to="/faq"
								data-submenu="none"
							>
								Вопросы и ответы
							</NavLink>
							<NavLink
								onClick={this.props.toggleMenu}
								onMouseEnter={this.props.closeSubmenuMenu}
								to="/contacts"
								data-submenu="none"
							>
								Контакты
							</NavLink>
						</div>
						<div className="question-btn" onClick={this.props.openQuestionForm}>
							задать вопрос
						</div>
						<div className="head__tel head__tel--white2">
							<a href="tel:+7 (925) 434-03-23">+7 (925) 434-03-23</a>
							<div className="head__tel__txt">отдел продаж</div>
							<br />
							<a href="tel:+7 (909) 990-06-11" target="_blank">
								+7 (909) 990-06-11
							</a>
							<div className="head__tel__txt">
								по вопросам
								<br />
								покупки машиномест
							</div>
							<br />
							<div onClick={this.props.openCallForm} className="popup__lnk">
								заказать звонок
							</div>
						</div>
						<div className="menu__socials">
							<a
								href="https://vk.com/novobutovo2018"
								target="_blank"
								className="menu_vk icon-vk"
								rel="noopener noreferrer"
							>
								vk
							</a>
							<a
								href="https://www.instagram.com/novobutovo/"
								target="_blank"
								className="menu_inst icon-inst"
								rel="noopener noreferrer"
							>
								inst
							</a>
						</div>
						<div className="menu__bottom">
							<div className="mdgroup" />
							© 2018 Жилой комплекс "Новое Бутово" - квартиры в новостройке в Москве
							<br />
							<br />
							<NavLink to="/privacy" onClick={this.props.toggleMenu} className="terms__links">
								Политика конфиденциальности
							</NavLink>
							<br />
							<NavLink to="/agreement" onClick={this.props.toggleMenu} className="terms__links">
								Пользовательское соглашение
							</NavLink>
							<br />
							<br />
							Вся представленная на данном сайте информация носит исключительно информационный характер и
							не является публичной офертой, определяемой положениями ст. 437 ГК РФ.
							<br />
							<br />
							Для получения подробной информации о проекте просьба обращаться к девелоперу проекта.
							Опубликованная на данном сайте информация может быть изменена в любое время без
							предварительного уведомления.
							<br />
							<br />
							<a
								href="http://www.amio.ru/"
								rel="noopener noreferrer"
								target="_blank"
								className="made__in"
							>
								Разработано в &nbsp;<span>A M I O</span>
							</a>
						</div>
					</div>
				</div>
				{this.submenu}
			</menu>
		)
	}

	get submenu() {
		return (
			<div>
				{this.props.submenu === 'sub2' ? (
					<div className="submenu submenu2 active">
						<div className="submenu__in">
							<AboutMenu toggleMenu={this.props.toggleMenu} />
						</div>
					</div>
				) : null}
				{this.props.submenu === 'sub3' ? (
					<div className="submenu submenu3 active">
						<div className="submenu__in">
							<PurchaseMenu toggleMenu={this.props.toggleMenu} />
						</div>
					</div>
				) : null}
				{this.props.submenu === 'sub1' ? (
					<div className="submenu submenu1 active">
						<div className="submenu__in">
							<NavLink to="/realty/genplan" onClick={this.props.toggleMenu}>
								<p>На генплане</p>
							</NavLink>
							<NavLink to="/realty/params" onClick={this.props.toggleMenu}>
								<p>По параметрам</p>
							</NavLink>
							<NavLink to="/realty/layout" onClick={this.props.toggleMenu}>
								<p>По видам планировок</p>
							</NavLink>
							<NavLink to="/realty/decoration" onClick={this.props.toggleMenu}>
								<p>Отделка</p>
							</NavLink>
						</div>
					</div>
				) : null}
				{this.props.submenu !== 'none' ? (
					<span className={'submenu-arrow submenu-arrow_' + this.props.submenu} />
				) : null}
			</div>
		)
	}
}
