import React from 'react'
import { NavLink } from 'react-router-dom'

export default class AboutPreviewList extends React.Component {
	render() {
		const { block } = this.props
		if (!block) return null

		return (
			<div className="box">
				<div className="about__in">
					<img src={block.img} alt="" />
					<div className="about__in--text">
						<div dangerouslySetInnerHTML={{ __html: block.text }} />
						<NavLink className="main__item--but main__item--but__pink--tr" to="/about/residential">
							<p>
								<b>подробнее о жк</b>
							</p>{' '}
						</NavLink>
					</div>
				</div>
			</div>
		)
	}
}
