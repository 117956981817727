import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Life extends Component {
	render() {
		return (
			<div className="main__item--life">
				<div className="main__item--title" dangerouslySetInnerHTML={{ __html: this.props.el.title }} />
				<div className="main__item--text" dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
				<Link className="main__item--but main__item--but__pink" to="/realty/params">
					<i className="icon-i_heart"> </i>
					<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
				</Link>
			</div>
		)
	}
}
