import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import AboutMenuList from '../about-menu-list'
import Loader from '../../common/loader'
import { loadGallery, toggleGallery } from '../../../actions'
import AboutFlatChooseList from '../about-choose-flat'
import AboutAdvList from '../about-advants'
import Fade from 'react-reveal/Fade'
import ScrollTop from '../../common/scroll-top'
import Slider from '../../common/slider'
import { albumsSelector, gallerySelector, albumGallarySelector, albumIdSelector } from '../../../selectors'
import { hit } from '../../common/metrics'

class Gallery extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Галерея'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Здесь Вы можете ознакомиться с фотографиями отделки квартир, близлежащих окрестностей, парков, 3D-панорам, а также общими видами жилого комплекса'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadGallery } = this.props
		if (!loading && !loaded) loadGallery()
		window.scrollTo(0, 0)
	}

	render() {
		const { loading, loaded, id } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<Fragment>
				<ScrollTop />
				<section className="content__block gallery">
					<div className="box">
						<div className="breadcrumb">
							<h1>О проекте</h1>
							<div className="breadcrumb__links">
								<NavLink to="/">Главная</NavLink> / <NavLink to="/about">О проекте</NavLink> / Подробнее
								о ЖК
							</div>
						</div>
					</div>
					<AboutMenuList />
					<Fade key={id}>
						<div className="gallery-slider">{this.gallery}</div>
					</Fade>
					<div className="box">
						<div className="albums">{this.albums}</div>
					</div>
					<AboutFlatChooseList />
					<AboutAdvList />
				</section>
				{this.modal}
			</Fragment>
		)
	}

	get gallery() {
		const { gallery, mobile } = this.props
		if (!gallery) return null
		return (
			<Slider
				items={this.items}
				options={{ dots: mobile ? false : true, arrows: true, lazyLoad: true, fade: true }}
			/>
		)
	}

	get items() {
		return this.props.gallery.map((el, i) => (
			<div className="slider__item" key={i}>
				<div>
					<div style={{ backgroundImage: `url(${el.img})` }}>
						{!el.title || (
							<div className="slider__item__text" dangerouslySetInnerHTML={{ __html: el.title }} />
						)}
					</div>
				</div>
			</div>
		))
	}

	get albums() {
		const { albums } = this.props
		return albums.map((el) => (
			<div key={el.id} className="album" onClick={this.handleToggle} value={el.id}>
				<div className="album__cover">
					<span style={{ backgroundImage: `url(${el.cover})` }} />
					<i className="ico-34">Cмотреть галерею</i>
				</div>
				<div className="album__name">
					<span>{el.name}</span>
				</div>
			</div>
		))
	}

	get modal() {
		const { mobile, openId } = this.props
		return (
			<div className={openId ? 'modal modal-gallery active' : 'modal modal-gallery'}>
				<div className="modal-overlay" onClick={this.handleToggle} />
				<div className="modal__body">
					<div className="modal__inner">
						<div className="close__modal" onClick={this.handleToggle} />
						<Slider
							items={this.modalItems}
							options={{
								arrows: mobile ? false : true,
								dots: mobile ? true : false,
								lazyLoad: true,
								fade: true
							}}
							key={openId}
						/>
					</div>
				</div>
			</div>
		)
	}

	get modalItems() {
		if (!this.props.albumGallary) return null

		return this.props.albumGallary.map((el, i) => (
			<div className="slider__item" key={i}>
				<div>
					<div className={el.contain ? 'slider__item-contain' : null}>
						<div className="slider__item-bg" style={{ backgroundImage: `url(${el.img})` }} />
						<div className="slider__item__gallery-text">
							<div dangerouslySetInnerHTML={{ __html: el.title }} />
							<div>
								<span>{i + 1}</span> / {this.props.albumGallary.length}
							</div>
						</div>
					</div>
				</div>
			</div>
		))
	}

	handleToggle = (ev) => {
		const id = ev.currentTarget.getAttribute('value')
		this.props.toggleGallery(id)
	}
}

export default connect(
	(state, props) => ({
		loading: state.gallery.loading,
		loaded: state.gallery.loaded,
		albums: albumsSelector(state),
		gallery: gallerySelector(state),
		albumGallary: albumGallarySelector(state, props),
		openId: albumIdSelector(state),
		mobile: state.mobile
	}),
	{ loadGallery, toggleGallery }
)(Gallery)
