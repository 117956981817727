import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import 'rc-slider/assets/index.css'
import { changeSquare } from '../../actions'
import accounting from 'accounting'
import { squareMinSelector, squareMaxSelector } from '../../selectors'
const Range = Slider.createSliderWithTooltip(Slider.Range)

class SquareFilter extends Component {
	handleChange = (value) => {
		const selected = {
			from: value[0],
			to: value[1]
		}
		this.props.changeSquare(selected)
	}

	render() {
		const { from, to } = this.props.square
		const value = [from || Math.floor(this.props.min), to || this.props.max]
		return (
			<Range
				min={Math.floor(this.props.min)}
				max={this.props.max}
				value={value}
				step={0.1}
				onChange={this.handleChange}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
				tipFormatter={(value) => accounting.formatNumber(value, 0, ',', ',')}
			/>
		)
	}
}

export default connect(
	(state) => ({
		square: state.filters.square,
		min: squareMinSelector(state),
		max: squareMaxSelector(state)
	}),
	{ changeSquare }
)(SquareFilter)
