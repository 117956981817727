import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { closePopupInstallments } from '../../../actions'

class PopupInstallments extends Component {
	render() {
		const { popup } = this.props
		return (
			<Modal
				closeTimeoutMS={300}
				className="modalwin modalwin--installments"
				isOpen={popup.open}
				onAfterOpen={this.afterOpenModal}
				onRequestClose={this.closeModal}
			>
				<div onClick={this.closeModal} className="overlay__modal" />
				<div onClick={this.closeModal} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">Рассрочка</div>
					<p>
						Условия приобретения нежилого помещения в&nbsp;рассрочку обсуждаются в&nbsp;индивидуальном
						порядке
					</p>
				</div>
			</Modal>
		)
	}

	afterOpenModal = () => {
		let outer = document.createElement('div')
		outer.style.visibility = 'hidden'
		outer.style.width = '100px'
		outer.style.msOverflowStyle = 'scrollbar'
		document.body.appendChild(outer)
		let widthNoScroll = outer.offsetWidth
		outer.style.overflow = 'scroll'
		let inner = document.createElement('div')
		inner.style.width = '100%'
		outer.appendChild(inner)
		let widthWithScroll = inner.offsetWidth
		outer.parentNode.removeChild(outer)
		document.getElementsByClassName('header')[0].style.paddingRight = widthNoScroll - widthWithScroll + 'px'
		if (!this.props.mobile)
			document.getElementsByTagName('menu')[0].style.right = widthNoScroll - widthWithScroll + 'px'
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop
		if (this.props.mobile) {
			document.body.setAttribute('data-scroll', scrollTop)
			document.body.style.position = 'fixed'
			document.body.style.top = -scrollTop + 'px'
		}
		document.body.style.paddingRight = widthNoScroll - widthWithScroll + 'px'
	}

	closeModal = () => {
		this.props.closePopupInstallments()
		setTimeout(() => {
			document.getElementsByClassName('header')[0].removeAttribute('style')
			if (!this.props.mobile) document.getElementsByTagName('menu')[0].removeAttribute('style')
			document.body.removeAttribute('style')
			if (this.props.mobile) {
				window.scrollTo(0, document.body.getAttribute('data-scroll'))
			}
		}, 300)
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		popup: state.commerce.popupInstallments
	}),
	{ closePopupInstallments }
)(PopupInstallments)
