import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

export default class Present extends Component {
	render() {
		return (
			<Fragment>
				<div className="main__item_present">
					<div>
						<div className="main__item--present main__item--tc main__item--present-shad">
							<div
								className="main__item--title"
								dangerouslySetInnerHTML={{ __html: this.props.el.title }}
							/>
							<div
								className="main__item--text"
								dangerouslySetInnerHTML={{ __html: this.props.el.text }}
							/>
							<div className="main__item--but main__item--but--whithout-link">
								<span>Ежедневно</span>с 10 до 18 часов
							</div>
							<div className="main__item--small">
								Предварительная запись на встречу
								<br />
								по телефону
								<br />
								<br />
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
