import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { purchaseMenu } from './data'

function Menu({ toggleMenu, purchase }) {
	if (!purchase.data) return null
	return (
		<>
			<NavLink to="/purchase/mortgage/flats" onClick={toggleMenu}>
				<p>Ипотека</p>
			</NavLink>
			{/*<NavLink to='/purchase/payment' onClick={toggleMenu}>
      <p>Рассрочка</p>
    </NavLink>*/}
			{purchase.data.promotions.length > 0 && (
				<NavLink to="/purchase/promotions" onClick={toggleMenu}>
					<p>Акции</p>
				</NavLink>
			)}
		</>
	)
}
export default connect(
	(state) => ({
		purchase: state.purchase
	}),
	{}
)(Menu)
