import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadPayment } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'

class Payment extends Component {
	componentDidMount() {
		const { loading, loaded, loadPayment } = this.props
		if (!loading && !loaded) loadPayment()
	}
	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<section className="content__block payment">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Условия покупки</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/purchase">Условия покупки</Link> / Рассрочка
						</div>
					</div>
					<Fade>
						<div>
							<div className="payment__block">{this.block1}</div>
							<div className="payment__block">{this.block2}</div>
						</div>
					</Fade>
				</div>
			</section>
		)
	}

	get block1() {
		const { block1 } = this.props.payment
		if (!block1) return null

		return (
			<div className="purchase__item purchase__item_payment">
				<div
					className="purchase__item__image"
					style={{ backgroundImage: `url(${this.props.mobile ? block1.mobile_img : block1.img})` }}
				/>
				<div className="purchase__item__body">
					<div className="purchase__item__title" dangerouslySetInnerHTML={{ __html: block1.title }} />
					<div className="purchase__item__text" dangerouslySetInnerHTML={{ __html: block1.text }} />
				</div>
			</div>
		)
	}

	get block2() {
		const { block2 } = this.props.payment
		if (!block2) return null
		return (
			<div className="payment-block">
				<div className="payment-block__title" dangerouslySetInnerHTML={{ __html: block2.title }} />
				<div className="payment-block__body">
					<div className="payment-block__image">
						<span style={{ backgroundImage: `url(${block2.img})` }} />
					</div>
					<div className="payment-block__text" dangerouslySetInnerHTML={{ __html: block2.text }} />
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		loading: state.payment.loading,
		loaded: state.payment.loaded,
		payment: state.payment.data,
		mobile: state.mobile
	}),
	{ loadPayment }
)(Payment)
