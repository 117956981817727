import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadPromotion, replacePage } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Button from '../common/button'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'
const regex = /(<([^>]+)>)/gi

class Payment extends Component {
	componentDidMount() {
		const { loadingDetail, loadPromotion, detail, id } = this.props

		if (!loadingDetail && !detail[id]) loadPromotion(id)
		if (detail[id]) {
			document.title = detail[id].title
			document.querySelector('meta[name=description]').setAttribute('content', detail[id].text.replace(regex, ''))
			hit(window.location.pathname)
		}
	}

	componentDidUpdate(oldProps) {
		const { detail, id, loadingDetail, loadPromotion, error, replacePage } = this.props

		if (detail[id]) {
			document.title = detail[id].title
			document.querySelector('meta[name=description]').setAttribute('content', detail[id].text.replace(regex, ''))
			hit(window.location.pathname)
		}
		if (error) return replacePage('/error')
		if (oldProps.id === id) return

		if (!loadingDetail && !detail[id]) loadPromotion(id)
	}

	render() {
		const { loadingDetail, detail, id } = this.props
		if (loadingDetail) return <Loader />
		const el = detail[id]
		if (!el) return null

		return (
			<section className="content__block promotion">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1 dangerouslySetInnerHTML={{ __html: el.title }} />
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/purchase/promotions">Акции</Link> /{' '}
							<span dangerouslySetInnerHTML={{ __html: el.title }} />
						</div>
					</div>
					<Fade key={id}>
						<div>
							<div className="payment__block">{this.body}</div>
						</div>
					</Fade>
					<div className="title">другие Акции</div>
					{this.promotions}
				</div>
			</section>
		)
	}

	get body() {
		const { detail, id } = this.props
		const el = detail[id]

		return (
			<div className="payment-block">
				<div className="payment-block__body">
					<div className="payment-block__image">
						<span style={{ backgroundImage: `url(${el.img})` }} />
					</div>
					<div className="payment-block__text">
						<div dangerouslySetInnerHTML={{ __html: el.text }} />
						<div className="promotions__btn">
							<Button to="/purchase/promotions">Все акции</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	get promotions() {
		const { detail, id } = this.props
		const promotions = detail[id].similar
		if (!promotions) return null

		return promotions.map((el) => (
			<Link to={`/purchase/promotions/${el.id}`} key={el.id} className="promotions__item">
				<div className="promotions__item__image">
					<span style={{ backgroundImage: `url(${el.img})` }} />
				</div>
				<div className="promotions__item__body">
					<div className="promotions__item__title">
						<span dangerouslySetInnerHTML={{ __html: el.title }} />
					</div>
					<div className="promotions__item__text" dangerouslySetInnerHTML={{ __html: el.text }} />
				</div>
			</Link>
		))
	}
}

export default connect(
	(state) => ({
		loadingDetail: state.promotions.loadingDetail,
		detail: state.promotions.detail,
		error: state.promotions.error
	}),
	{ loadPromotion, replacePage }
)(Payment)
