import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import { priceMinSelector, priceMaxSelector } from '../../selectors'
import { changePrice } from '../../actions'
import accounting from 'accounting'
import 'rc-slider/assets/index.css'
const Range = Slider.createSliderWithTooltip(Slider.Range)

class PriceFilter extends Component {
	handleChange = (value) => {
		const selected = {
			from: value[0],
			to: value[1]
		}
		this.props.changePrice(selected)
	}

	render() {
		const { from, to } = this.props.price
		const value = [from || Math.floor(this.props.min * 10) / 10, to || this.props.max]
		return (
			<Range
				min={Math.floor(this.props.min * 10) / 10}
				max={this.props.max}
				value={value}
				onChange={this.handleChange}
				step={0.1}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
				tipFormatter={(value) => accounting.formatNumber(value, 1, ',', ',')}
			/>
		)
	}
}

export default connect(
	(state) => ({
		price: state.filters.price,
		min: priceMinSelector(state),
		max: priceMaxSelector(state)
	}),
	{ changePrice }
)(PriceFilter)
