import {
	CHANGE_ROOMS,
	CHANGE_CORPUS,
	CHANGE_FLOOR,
	CHANGE_SQUARE,
	CHANGE_PRICE,
	CLEAR_FILTER,
	LOAD_MORE,
	SORT_FLATS,
	CHANGE_DECORATION,
	CHANGE_VIEW,
	CHANGE_LAYOUT_DECORATION,
	CHANGE_PROMOTION
} from '../constants'

const defaultFilters = {
	rooms: [],
	corpus: [],
	floor: {
		from: null,
		to: null
	},
	square: {
		from: null,
		to: null
	},
	price: {
		from: null,
		to: null
	},
	decoration: null,
	promotion: null,
	layoutDecoration: null,
	view: null,
	visibleLength: 9,
	step: 9,
	sort: {
		field: null,
		order: 'desc'
	}
}

export default (filters = defaultFilters, action) => {
	const { type, payload } = action

	switch (type) {
		case CHANGE_ROOMS:
			return { ...filters, rooms: payload.rooms, visibleLength: defaultFilters.visibleLength }

		case CHANGE_CORPUS:
			return { ...filters, corpus: payload.corpus, visibleLength: defaultFilters.visibleLength }

		case CHANGE_FLOOR:
			return { ...filters, floor: payload.floor, visibleLength: defaultFilters.visibleLength }

		case CHANGE_SQUARE:
			return { ...filters, square: payload.square, visibleLength: defaultFilters.visibleLength }

		case CHANGE_PRICE:
			return { ...filters, price: payload.price, visibleLength: defaultFilters.visibleLength }

		case CHANGE_DECORATION:
			return { ...filters, decoration: payload.decoration, visibleLength: defaultFilters.visibleLength }

		case CHANGE_PROMOTION:
			return { ...filters, promotion: payload.promotion, visibleLength: defaultFilters.visibleLength }

		case CHANGE_LAYOUT_DECORATION:
			return { ...filters, layoutDecoration: payload.decoration, visibleLength: defaultFilters.visibleLength }

		case CHANGE_VIEW:
			return { ...filters, view: payload.view, visibleLength: defaultFilters.visibleLength }

		case LOAD_MORE:
			return { ...filters, visibleLength: filters.visibleLength + filters.step }

		case CLEAR_FILTER:
			return {
				...defaultFilters
			}

		case SORT_FLATS:
			return {
				...filters,
				sort: {
					field: payload.field,
					order: payload.order
				}
			}

		default:
			return filters
	}
}
