import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from '../../common/loader'
import Scheme from './scheme'
import { loadGenplan, getPage } from '../../../actions'
import { NavLink } from 'react-router-dom'
import Panel from '../panel'
import ScrollTop from '../../common/scroll-top'
import Fade from 'react-reveal/Fade'
import AboutAdvList from '../../About/about-advants'
import { hit } from '../../common/metrics'
import { openPopupScheme } from '../../../actions'
import Slider from '../../common/slider'

class GenplanPage extends Component {
	componentDidMount() {
		document.title =
			'ЖК «Новое Бутово». Подобрать коммерческое помещение на генплане в Москве, 15 минут пешком до метро Бунинская аллея.'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Здесь на генплане ЖК «Новое Бутово» можно подобрать нежилые коммерческие помещения.'
			)
		hit(window.location.pathname)
		document.querySelector('.make-connect').setAttribute('src', 'https://js.make-connect.ru/mcs.js')
		const { loading, loaded, loadGenplan } = this.props

		if (!loading && !loaded) loadGenplan()
	}

	componentWillUnmount() {
		document.querySelector('.make-connect').setAttribute('src', '')
	}

	render() {
		const { genplan, loaded, loading, getPage, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="genplan">
				<ScrollTop />
				{this.path}
				<div className="content">
					<Panel />
					<Fade>
						<div>
							<Scheme img={genplan.img} buildings={genplan.buildings} getPage={getPage} mobile={mobile} />
						</div>
					</Fade>
				</div>
				<div className="content">
					<div className="routeLink" onClick={() => this.props.openPopupScheme()}>
						<i className="iconsnew-11"></i>
						<span>трасса Воскресенское-Каракашево-Щербинка</span>
					</div>
				</div>
				<Fade>
					{mobile ? (
						<div className="box residental-slider benefits">
							<Slider items={this.icons} options={{ arrows: true }} />
						</div>
					) : (
						<div className="content">
							<div className="benefits">{this.icons}</div>
						</div>
					)}
				</Fade>
				<AboutAdvList isCommerce />
			</div>
		)
	}

	get path() {
		return (
			<div className="box">
				<div className="breadcrumb">
					<h1>Коммерческие помещения</h1>
					<div className="breadcrumb__links">
						<NavLink to="/">Главная</NavLink> / Коммерческие помещения
					</div>
				</div>
			</div>
		)
	}

	get icons() {
		const icons = [
			{
				title: 'Большой диапазон площадей<br />для любого бизнеса',
				iconsnew: 2,
				color: 'clr-pink'
			},
			{
				title: 'Помещения в готовых корпусах<br />и на этапе строительства',
				iconsnew: 3,
				color: 'clr-green'
			},
			{
				title: 'Плотно заселенный<br />микрорайон (16&nbsp;000 жителей)',
				iconsnew: 4,
				color: 'clr-peach'
			},
			{
				title: 'Новая транспортная развязка<br />(высокий трафик проходимости)',
				iconsnew: 5,
				color: 'clr-mint'
			},
			{
				title:
					'Непосредственная близость<br />торгового центра<br />(ежедневная проходимость<br />20&nbsp;000 человек)',
				iconsnew: 6,
				color: 'clr-blue'
			},
			{
				title: 'Свободная планировка<br />помещений',
				iconsnew: 7,
				color: 'clr-violet'
			},
			{
				title: 'Высокие потолки',
				iconsnew: 8,
				color: 'clr-lilac'
			},
			{
				title: 'Помещения<br />на первой линии',
				iconsnew: 9,
				color: 'clr-orange'
			},
			{
				title: 'Первые этажи<br />в жилых домах',
				iconsnew: 10,
				color: 'clr-pink'
			}
		]
		return icons.map((el, i) => {
			return (
				<div className="benefits__item" key={i}>
					<i className={`iconsnew-${el.iconsnew} ${el.color}`}></i>
					<div className="benefits__text" dangerouslySetInnerHTML={{ __html: el.title }} />
				</div>
			)
		})
	}
}

export default connect(
	(state) => ({
		genplan: state.genplan.response,
		loading: state.genplan.loading,
		loaded: state.genplan.loaded,
		mobile: state.mobile
	}),
	{ loadGenplan, getPage, openPopupScheme }
)(GenplanPage)
