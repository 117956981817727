import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { closeMailForm, sendMailForm } from '../../../actions'

class Mail extends Component {
	state = {
		email: '',
		name: '',
		check: 0,
		checkForm: false
	}

	componentDidUpdate(oldProps) {
		if (this.props.form.sent !== oldProps.form.sent && this.props.form.sent) {
			this.setState({
				email: '',
				name: '',
				check: 0,
				checkForm: false
			})
		}
	}

	render() {
		return (
			<div>
				{this.form}
				{this.success}
			</div>
		)
	}

	get success() {
		const { form, closeMailForm } = this.props
		return (
			<Modal closeTimeoutMS={300} isOpen={form.sent} className="modalwin modalwin--thanks layout-thx">
				<div onClick={closeMailForm} className="overlay__modal" />
				<div onClick={closeMailForm} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">Планировка отправлена</div>
				</div>
			</Modal>
		)
	}

	get form() {
		const { form, closeMailForm } = this.props
		return (
			<Modal closeTimeoutMS={300} className="modalwin modalwin--callback mail-modal" isOpen={form.open}>
				<div onClick={closeMailForm} className="overlay__modal" />
				<div onClick={closeMailForm} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">
						Отправить на e-mail
						<div className="modal__error-text">Одно или несколько полей заполнены с ошибкой!</div>
					</div>
					<form onSubmit={this.handleSubmit}>
						<div className={this.getClassName('name')}>
							<input
								type="text"
								placeholder="Имя"
								value={this.state.name}
								onChange={this.handleChange('name')}
							/>
						</div>
						<div className={this.getClassName('email')}>
							<input
								type="text"
								placeholder="E-mail"
								value={this.state.email}
								onChange={this.handleChange('email')}
							/>
						</div>

						<div className={`modal__input--checkbox ${this.getClassName('check')}`}>
							<label>
								<input
									onChange={this.handleCheckboxChange('check')}
									value={this.state.check}
									checked={this.state.check === 1}
									type="checkbox"
								/>
								<span className="icon-check-symbol" />Я согласен на обработку персональных данных и с
								условиями пользовательского соглашения
							</label>
						</div>
						<div className="modal__input modal__input--but">
							<button type="submit">
								<i className="ico-4" />
								Отправить
							</button>
						</div>
					</form>
				</div>
			</Modal>
		)
	}

	handleSubmit = (ev) => {
		ev.preventDefault()

		if (!this.isValidForm()) {
			this.setState({
				checkForm: true
			})
			return false
		}

		let w = this.props.form.type
		if (this.props.location) {
			if (
				this.props.location
					.toString()
					.split('/')
					.indexOf('layout') !== -1
			) {
				w = 'layout'
			}
		}

		if (window.Comagic && typeof window.Comagic == 'object') {
			window.Comagic.addOfflineRequest({
				name: this.state.name,
				email: this.state.email,
				message: 'Отправка планировки на e-mail / ' + this.props.formPlaceType
			})
		}

		this.props.sendMailForm({
			w: w,
			name: this.state.name,
			email: this.state.email,
			asc: this.props.data.rel,
			id: this.props.form.id,
			form: 'Отправка планировки на e-mail / ' + this.props.formPlaceType
		})
	}

	isValidForm = () => ['email', 'name', 'check'].every(this.isValidField)

	isValidField = (type) => {
		if (limits[type]) return limits[type].regex.test(this.state[type])

		if (type === 'check') return !!this.state[type]
	}

	getClassName = (type) => {
		return (!this.state[type].length && !this.state.checkForm) || this.isValidField(type)
			? 'modal__input'
			: 'modal__input error'
	}

	handleChange = (type) => (ev) => {
		const { value } = ev.target
		this.setState({
			[type]: value
		})
	}

	handleCheckboxChange = (type) => (ev) => {
		const { value } = ev.target
		this.setState({
			[type]: +value === 0 ? 1 : 0
		})
	}
}

const limits = {
	email: {
		regex: /^[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+\.[A-Za-z]+$/
	},
	name: {
		regex: /^[а-яА-Яa-zA-Z ]+$/
	}
}

export default connect(
	(state) => ({
		data: state.form.data,
		form: state.form.mail,
		formPlaceType: state.form.formPlaceType
	}),
	{ closeMailForm, sendMailForm }
)(Mail)
