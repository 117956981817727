import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadPromotions } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Button from '../common/button'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'

class Promotions extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Акции'
		document
			.querySelector('meta[name=description]')
			.setAttribute('content', 'Здесь Вы можете ознакомиться со всеми акциями ЖК «Новое Бутово»')
		hit(window.location.pathname)
		const { loading, loaded, loadPromotions } = this.props
		if (!loading && !loaded) loadPromotions()
	}
	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<section className="content__block promotions">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Акции</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/purchase">Условия покупки</Link> / Акции
						</div>
					</div>
					<Fade>
						<div>
							<div className="purchase__promotions">{this.promotions}</div>
						</div>
					</Fade>
				</div>
			</section>
		)
	}

	get mortgage() {
		const { mortgage } = this.props.promotions
		if (!mortgage) return null
		return (
			<div className="purchase__item purchase__item_mortgage">
				<div className="purchase__item__image" style={{ backgroundImage: `url(${mortgage.img})` }} />
				<div className="purchase__item__body">
					<div className="purchase__item__title" dangerouslySetInnerHTML={{ __html: mortgage.title }} />
					<div className="purchase__item__text" dangerouslySetInnerHTML={{ __html: mortgage.text }} />
					<Button to="/purchase/mortgage/flats">подробнее</Button>
				</div>
			</div>
		)
	}

	get payment() {
		const { payment } = this.props.promotions
		if (!payment) return null
		return (
			<div className="purchase__item purchase__item_payment">
				<div className="purchase__item__image" style={{ backgroundImage: `url(${payment.img})` }} />
				<div className="purchase__item__body">
					<div className="purchase__item__title" dangerouslySetInnerHTML={{ __html: payment.title }} />
					<div className="purchase__item__text" dangerouslySetInnerHTML={{ __html: payment.text }} />
					<Button to="/purchase/payment">подробнее</Button>
				</div>
			</div>
		)
	}

	get promotions() {
		const { promotions } = this.props.promotions
		if (!promotions) return null
		return promotions.map((el) => (
			<Link to={`/purchase/promotions/${el.id}`} key={el.id} className="promotions__item">
				<div className="promotions__item__image">
					<span style={{ backgroundImage: `url(${el.img})` }} />
				</div>
				<div className="promotions__item__body">
					<div className="promotions__item__title">
						<span dangerouslySetInnerHTML={{ __html: el.title }} />
					</div>
					<div className="promotions__item__text" dangerouslySetInnerHTML={{ __html: el.text }} />
				</div>
			</Link>
		))
	}
}

export default connect(
	(state) => ({
		loading: state.promotions.loading,
		loaded: state.promotions.loaded,
		promotions: state.promotions.data
	}),
	{ loadPromotions }
)(Promotions)
