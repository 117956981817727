import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeCommerceCorpus } from '../../../actions'
import { commerceCorpusOptions, filterCommerceSelector } from '../../../selectors'

class CorpusFilter extends Component {
	handleChange = (e) => {
		const { selected, changeCommerceCorpus } = this.props
		const value = e.currentTarget.getAttribute('value') + ''

		const result = selected.indexOf(value) === -1 ? [...selected, value] : selected.filter((room) => room !== value)

		changeCommerceCorpus(result)
	}

	get options() {
		const { options, selected } = this.props

		return options.map((el) => {
			return (
				<div
					className={selected.indexOf(el.corpus) === -1 ? 'check-field' : 'check-field active'}
					key={el.corpus}
					value={el.corpus}
					onClick={this.handleChange}
				>
					{el.corpus}
				</div>
			)
		})
	}

	render() {
		return <div className="checkbox-list">{this.options}</div>
	}
}

export default connect(
	(state) => ({
		options: commerceCorpusOptions(state),
		selected: filterCommerceSelector(state).corpus
	}),
	{ changeCommerceCorpus }
)(CorpusFilter)
