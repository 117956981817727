import React from 'react'
import AboutMenuInner from '../Menu/about-menu-inner'

export default class AboutMenuList extends React.Component {
	render() {
		return (
			<div className="content__menu">
				<div className="box">
					<AboutMenuInner />
				</div>
			</div>
		)
	}
}
