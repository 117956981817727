import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Tc extends Component {
	render() {
		return (
			<div className="main__item--comfort main__item--tc">
				<div className="main__item--title" dangerouslySetInnerHTML={{ __html: this.props.el.title }} />
				<Link
					className="main__item--but"
					to={{ pathname: '/about/infrastructure', state: { scrollTo: 'icons-scroll-healper' } }}
				>
					<i className="ico-60"> </i>
					<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
				</Link>
			</div>
		)
	}
}
