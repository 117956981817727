import {
	LOAD_MORTGAGE,
	SUCCESS,
	START,
	SORT_BANK,
	CHANGE_MORTAGE_PRICE,
	CHANGE_MORTAGE_CONTRIBUTION,
	CHANGE_MORTAGE_TERM
} from '../constants'

const defaultObjects = {
	mortgage: null,
	entities: [],
	loading: false,
	loaded: false
}

export default (state = defaultObjects, action) => {
	const { type, response, payload } = action

	switch (type) {
		case LOAD_MORTGAGE + START:
			return { ...state, loading: true }

		case LOAD_MORTGAGE + SUCCESS:
			return {
				...state,
				mortgage: response.mortgage,
				entities: response.banks,
				loading: false,
				loaded: true
			}

		default:
			return state
	}
}
