import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import history from '../history'
import about from './about'
import home from './home'
import mobile from './mobile'
import genplan from './genplan'
import flats from './flats'
import sorting from './sort'
import form from './form'
import sections from './sections'
import sectionFilter from './section-filter'
import newsPage from './news'
import infra from './infra'
import infraFilter from './infra-filter'
import developer from './developer'
import residental from './residental'
import gallery from './gallery'
import docs from './docs'
import progress from './progress'
import filters from './filters'
import layout from './layout'
import purchase from './purchase'
import mortgage from './mortgage'
import mortgageFilter from './mortgage-filter'
import payment from './payment'
import promotions from './promotions'
import commerce from './commerce'
import commerceFilter from './commerce-filter'
import sortCommerce from './sort-commerce'
import parking from './parking'
import decoration from './decoration'
import privacy from './privacy'
import contacts from './contacts'
import agreement from './agreement'
import faqPage from './faq'

const allReducers = combineReducers({
	home,
	about,
	mobile,
	genplan,
	flats,
	sorting,
	form,
	router: connectRouter(history),
	sections,
	sectionFilter,
	newsPage,
	infra,
	infraFilter,
	developer,
	residental,
	gallery,
	docs,
	progress,
	filters,
	layout,
	purchase,
	mortgage,
	mortgageFilter,
	payment,
	promotions,
	commerce,
	commerceFilter,
	sortCommerce,
	parking,
	decoration,
	privacy,
	contacts,
	agreement,
	faqPage
})

export default allReducers
