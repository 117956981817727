import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import $ from 'jquery'

export default class Contacts extends Component {
	componentDidMount() {
		if (!$('body').is('.ready')) {
			$('body')
				.removeClass('loading')
				.addClass('ready')
			return
		}
		$('body').removeClass('loading3')
	}
	render() {
		return (
			<div className="error-page">
				<Fade>
					<div className="error-block">
						<div className="error-block__inner">
							<i className="ico-51" />
							<span>ошибка</span>
							Данная страница
							<br />
							не существует
							<Link to="/">На главную</Link>
						</div>
					</div>
				</Fade>
			</div>
		)
	}
}
