import React from 'react'
import { NavLink } from 'react-router-dom'
import { aboutMenu } from './data'

function Menu({ toggleMenu }) {
	return aboutMenu.map((el) => {
		return (
			<NavLink key={el.id} to={el.href} onClick={toggleMenu}>
				<p dangerouslySetInnerHTML={{ __html: el.name }} />
			</NavLink>
		)
	})
}

export default Menu
