import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import { changeMortgageRate } from '../../actions'
import accounting from 'accounting'
import 'rc-slider/assets/index.css'
const Range = Slider.createSliderWithTooltip(Slider.Range)
const SliderWithTooltip = Slider.createSliderWithTooltip(Slider)

class RateFilter extends Component {
	handleChange = (value) => {
		this.props.changeMortgageRate(value)
	}

	render() {
		const { rate } = this.props
		return (
			<SliderWithTooltip
				min={5.4}
				max={30}
				value={rate}
				onChange={this.handleChange}
				step={0.1}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
			/>
		)
	}
}

export default connect(
	(state) => ({
		rate: state.mortgageFilter.rate
	}),
	{ changeMortgageRate }
)(RateFilter)
