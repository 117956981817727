import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Video extends Component {
	componentDidUpdate(oldProps) {
		const { play } = this.props
		if (play !== oldProps.play) {
			play ? this.refs.vidRef.play() : this.refs.vidRef.pause()
		}
	}
	render() {
		const { el, mobile } = this.props
		if (!el.video) return null
		return (
			<div>
				{mobile || (
					<div className="main__item--video">
						<video width="100%" height="100%" muted ref="vidRef" poster={el.img}>
							<source src={el.video} type="video/mp4" />
						</video>
					</div>
				)}
				<div>
					<div className="main__item--video__content">
						<div className="main__item--title">Подобрать квартиру</div>
						<div className="main__item--video__links">
							<Link to="/realty/genplan">
								<i className="icon-i_flat1" />
								на генплане
							</Link>
							<Link to="/realty/params">
								<i className="icon-i_flat2" />
								по параметрам
							</Link>
							<Link to="/realty/layout">
								<i className="icon-i_flat3" />
								по видам планировки
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
