import { push, replace } from 'connected-react-router'
import flats from './flats'
import commerce, { mort } from './commerce'
// import index from './index-mock'
import $ from 'jquery'

import {
	IS_MOBILE,
	LOAD_GENPLAN,
	GET_FLAT,
	GET_FLATS,
	SORT_FLATS,
	OPEN,
	CLOSE,
	FORM_DATA,
	FORM_MAIL,
	FORM_REQUEST,
	FORM_CALL,
	SECTION_CHANGE_ROOMS,
	LOAD_ABOUT,
	LOAD_NEWS,
	LOAD_NEW,
	CHANGE_NEWS_YEAR,
	LOAD_HOME,
	LOAD_INFRA,
	CHANGE_INFRA_GROUP,
	LOAD_DEVELOPER,
	LOAD_RESIDENTAL,
	LOAD_GALLERY,
	TOGGLE_GALLERY,
	LOAD_DOCS,
	CHANGE_DOCS_PAGE,
	CHANGE_DOCS_TYPE,
	CHANGE_DOCS_CORPUS,
	CHANGE_DOCS_YEAR,
	LOAD_PROGRESS,
	TOGGLE_PROGRESS_GALLERY,
	CHANGE_PROGRESS_YEAR,
	PLAY_PROGRESS_VIDEO,
	CHANGE_PROGRESS_MONTH,
	CHANGE_PROGRESS_CORPUS,
	CHANGE_ROOMS,
	CHANGE_CORPUS,
	CHANGE_SQUARE,
	CHANGE_FLOOR,
	CHANGE_PRICE,
	CLEAR_FILTER,
	LOAD_MORE,
	SORT_PARAMS_FLATS,
	CHANGE_DECORATION,
	CHANGE_VIEW,
	CLEAR_FILTER_LAYOUT,
	CLEAR_FILTER_PARAMS,
	GET_LAYOUT,
	FORM_REQUEST_LAYOUT,
	CHANGE_LAYOUT_DECORATION,
	LOAD_PURCHASE,
	LOAD_MORTGAGE,
	SORT_BANK,
	LOAD_PAYMENT,
	LOAD_PROMOTIONS,
	LOAD_PROMOTION,
	GET_COMMERCE,
	GET_COMMERCE_FLAT,
	CHANGE_COMMERCE_CORPUS,
	CHANGE_COMMERCE_SQUARE,
	CHANGE_COMMERCE_PRICE,
	CLEAR_COMMERCE_FILTER,
	LOAD_COMMERCE_MORE,
	SORT_COMMERCE_FLATS,
	GET_COMMERCE_CORPUS,
	GET_PARKING,
	FORM_PARKING,
	GET_DECORATION,
	LOAD_PRIVACY,
	LOAD_AGREEMENT,
	LOAD_CONTACTS,
	CHANGE_PROMOTION,
	SET_FORM_PLACE_TYPE,
	POPUP_SCHEME,
	CHANGE_MORTAGE_PRICE,
	CHANGE_MORTAGE_CONTRIBUTION,
	CHANGE_MORTAGE_TERM,
	CHANGE_MORTAGE_BANK,
	POPUP_INSTALLMENTS,
	REALTY,
	SET_COMMERCE,
	CHANGE_COMMERCE_PROMOTION,
	CHANGE_MORTAGE_RATE,
	CHANGE_MORTAGE_FAMILY_RATE,
	QUESTION,
	LOAD_FAQ,
	FORM_SUBSCRIBE
} from '../constants'

export function getPage(url, lastURL) {
	return (dispatch) => {
		dispatch(push(url, lastURL))
	}
}
export function replacePage(url) {
	return (dispatch) => {
		dispatch(replace(url))
	}
}

export function isMobile(mobile) {
	return {
		type: IS_MOBILE,
		mobile
	}
}

export function loadFormData(mobile) {
	return {
		type: FORM_DATA,
		callAPI: { w: 'form' }
	}
}
export function loadGenplan() {
	// return (dispatch) => {
	// 	dispatch({ type: 'LOAD_GENPLAN_START' })
	// 	setTimeout(() => {
	// 		dispatch({
	// 			type: 'LOAD_GENPLAN_SUCCESS',
	// 			response: {
	// 				img: '/img/realty/genplan.jpg',
	// 				parking: [
	// 					{
	// 						icon: 1,
	// 						num: 15,
	// 						title: 'Паркинг: ',
	// 						position: [836, 359]
	// 					}
	// 				],
	// 				buildings: [
	// 					{
	// 						id: '1',
	// 						num: '16',
	// 						position: ['463', '474'],
	// 						action: '1',
	// 						coords:
	// 							'490,431,503,427,501,425,515,420,523,423,524,420,535,428,541,471,535,489,543,547,539,555,490,571,462,566,457,555,455,538,446,533,431,472,427,444,437,431,458,426,463,437,470,431,484,427',
	// 						done: '0',
	// 						plan: '/img/realty/builds/16.svg',
	// 						sections: {
	// 							'7':
	// 								'52.7 68.1 55.6 68.1 57.3 66.3 60 66.3 60.5 66.3 67.4 66.3 74.2 66.3 75.9 68.1 76.4 68.1 76.4 70.1 78 70.1 80.1 71.8 80.1 79.3 76 79.3 76 82.1 72.3 82.1 72.3 86.1 67.7 86.2 66 87.8 61.7 87.8 60 86 52.7 85.8',
	// 							'6':
	// 								'27.6 68.1 30.8 68.2 32.6 66.3 35.1 66.3 45.9 66.4 47.5 68 50.5 68 50.5 85.8 43.2 85.8 41.4 87.8 36.9 87.9 35.2 85.8 27.6 85.8',
	// 							'5':
	// 								'25.5 85.6 19.2 85.6 18.1 84.6 14.5 84.6 14.5 86.3 4 86.3 3.9 82.4 2.2 82.4 2.2 78 0.5 76.4 0.5 67.3 2.2 65.6 2.2 58.3 18 58.3 18 68.1 25.5 68.1',
	// 							'4':
	// 								'2.3 32.2 18.1 32.2 18.1 36.1 19.8 38.1 19.8 50.7 18.1 52.1 18.1 55.9 2.3 55.9 2.3 52.4 0.6 50.6 0.5 38.1 2.3 36.1',
	// 							'3':
	// 								'4.1 6.1 6 6.1 7.7 4.3 9.8 4.2 9.8 3.3 18 3.3 18 4.3 20.5 4.3 22.3 5.9 25.2 5.9 25.2 20.1 18 20.1 18 30 2.3 29.9 2.1 28.7 0.5 27.1 0.5 12.3 2.3 10.6 2.3 9.9 4 9.9',
	// 							'2':
	// 								'27.6 6.1 29.5 4.3 31.5 4.3 33.1 2.7 35.1 2.7 35.1 0.5 43.2 0.5 43.2 2.7 45.5 2.7 47.2 4.3 50.5 4.3 50.4 20 43.3 20 41.5 22 37 22.1 35.2 20 27.6 20',
	// 							'1':
	// 								'52.8 4.4 56.2 4.3 58 2.6 60.2 2.5 60.2 0.5 68.1 0.5 68.2 2.2 74.1 2.2 76.2 3.9 76.4 6.1 78.1 6.1 80 8.2 79.9 15.3 76.2 15.1 76.2 17.7 72.4 17.7 72.4 20.3 67.9 20.5 66.1 22.1 61.8 22 60.1 20.2 52.8 20'
	// 						},
	// 						commerce: '4',
	// 						rooms: ['0', '2', '1', '7'],
	// 						roomsToSection: [[], [3, 5], [5], [1, 4, 5]]
	// 					},
	// 					{
	// 						id: '2',
	// 						num: '14',
	// 						position: ['640', '392'],
	// 						action: '0',
	// 						coords:
	// 							'700,400,710,397,710,390,734,383,739,392,743,417,742,422,741,478,688,500,669,487,657,466,651,465,645,451,645,441,640,430,636,392,644,375,665,368,670,376,672,393,677,406',
	// 						done: '0',
	// 						plan: '/img/realty/builds/14.svg',
	// 						sections: {
	// 							'4':
	// 								'65.8,75.8 65.8,91.9 62,91.9 62,95 42.9,95 42.9,91.4 37.9,91.4 37.9,75.6 47.4,75.6 47.4,73.6 57.1,73.6 57.1,76',
	// 							'3':
	// 								'35.6,91.4 19.5,91.4 1.6,76.8 1.6,68.9 3.3,68.9 3.3,59 19.7,59 19.7,63.2 21.9,65.5 23,64.5 29.9,71.4 29.1,72.6 32,75.6 35.6,75.6',
	// 							'2':
	// 								'3.3,56.6 3.3,52.2 0.5,52.2 0.5,33.4 3.4,33.4 3.4,30.7 20.1,30.7 20.1,37.5 21.4,37.5 21.4,47.1 19.7,47.1 19.7,56.6',
	// 							'1': '3.4,28.3 3.4,9 11.9,0.5 29.9,0.5 29.9,15.7 26.5,15.7 20.1,22.1 20.1,28.3'
	// 						},
	// 						commerce: '4',
	// 						rooms: ['0', '1', '0', '0'],
	// 						roomsToSection: [[], [1], [], []]
	// 					},
	// 					{
	// 						id: '3',
	// 						num: '15',
	// 						position: ['1042', '300'],
	// 						action: '0',
	// 						coords:
	// 							'1047,282,1047,276,1052,254,1056,242,1068,245,1100,278,1097,287,1088,335,1019,361,1002,328,1014,300,1020,298,1020,290,1031,286,1036,290',
	// 						done: '0',
	// 						plan: '/img/realty/builds/15.svg',
	// 						sections: {
	// 							'1':
	// 								'0.5 57.1 0.5 74.9 4.1 75.1 4.4 77.2 6.5 79.8 10.6 80 11 78.7 26.4 79.2 26.4 76.2 30.9 76.4 30.5 56.5 20.9 56.2 20.4 53.9 12.5 53.9 12.5 56.5',
	// 							'2':
	// 								'33.4 56.7 33.4 75.7 38.1 76 37.9 78.7 54.2 79 54.4 79.8 57.6 80 60.4 77.7 60.2 74.9 64.9 74.9 64.7 56.5 53.8 56.5 53.4 54.1 43.1 54.3 42.9 57.1',
	// 							'3':
	// 								'66.8 58.6 67 74.7 78.4 74.9 78.4 77 86.9 77 95.1 68.5 97.4 69.7 106 61.4 106 50.9 104.3 50.7 104.3 39.7 85.9 40.2 85.9 45.5 73.9 58.4',
	// 							'4':
	// 								'84.8 37.9 104.7 37.7 104.7 33.4 106.6 33.4 106.2 13.7 103.4 11.1 103.2 5.1 96.1 5.3 92.2 0.7 83.7 9.4 84.1 16 81.1 16.2 81.8 27.4 84.6 27.4'
	// 						},
	// 						commerce: '3',
	// 						rooms: ['1', '0', '0', '0'],
	// 						roomsToSection: [[3], [], [], []]
	// 					},
	// 					{
	// 						id: '4',
	// 						num: '1',
	// 						position: ['426', '380'],
	// 						action: '0',
	// 						coords:
	// 							'371,344,392,338,409,360,415,381,447,367,482,356,515,349,535,351,543,368,550,418,533,428,513,422,489,430,484,428,466,433,457,441,432,459,427,454,416,459,411,447,400,450,378,393',
	// 						done: '0',
	// 						plan: '/img/realty/builds/1.svg',
	// 						sections: {
	// 							'1':
	// 								'0.5 57.1 0.5 74.9 4.1 75.1 4.4 77.2 6.5 79.8 10.6 80 11 78.7 26.4 79.2 26.4 76.2 30.9 76.4 30.5 56.5 20.9 56.2 20.4 53.9 12.5 53.9 12.5 56.5',
	// 							'2':
	// 								'33.4 56.7 33.4 75.7 38.1 76 37.9 78.7 54.2 79 54.4 79.8 57.6 80 60.4 77.7 60.2 74.9 64.9 74.9 64.7 56.5 53.8 56.5 53.4 54.1 43.1 54.3 42.9 57.1',
	// 							'3':
	// 								'66.8 58.6 67 74.7 78.4 74.9 78.4 77 86.9 77 95.1 68.5 97.4 69.7 106 61.4 106 50.9 104.3 50.7 104.3 39.7 85.9 40.2 85.9 45.5 73.9 58.4',
	// 							'4':
	// 								'84.8 37.9 104.7 37.7 104.7 33.4 106.6 33.4 106.2 13.7 103.4 11.1 103.2 5.1 96.1 5.3 92.2 0.7 83.7 9.4 84.1 16 81.1 16.2 81.8 27.4 84.6 27.4'
	// 						},
	// 						commerce: '0',
	// 						rooms: ['1', '0', '0', '0'],
	// 						roomsToSection: [[3], [], [], []]
	// 					}
	// 				]
	// 			}
	// 		})
	// 	}, 1)
	// }
	return {
		type: LOAD_GENPLAN,
		callAPI: { w: 'genplan' }
	}
}

export function loadFlats() {
	// return (dispatch) => {
	// 	dispatch({ type: 'GET_FLATS_START' })
	// 	setTimeout(() => {
	// 		dispatch({
	// 			type: 'GET_FLATS_SUCCESS',
	// 			response: flats
	// 		})
	// 	}, 1000)
	// }

	return {
		type: GET_FLATS,
		callAPI: { w: 'flats' }
	}
}

export function changeDecoration(decoration) {
	return { type: CHANGE_DECORATION, payload: { decoration } }
}

export function changePromotion(promotion) {
	return { type: CHANGE_PROMOTION, payload: { promotion } }
}

export function changeLayoutDecoration(decoration) {
	return { type: CHANGE_LAYOUT_DECORATION, payload: { decoration } }
}

export function changeView(view) {
	return { type: CHANGE_VIEW, payload: { view } }
}

export function changeRooms(rooms) {
	return { type: CHANGE_ROOMS, payload: { rooms } }
}

export function changeCorpus(corpus) {
	return { type: CHANGE_CORPUS, payload: { corpus } }
}

export function changeFloor(floor) {
	return { type: CHANGE_FLOOR, payload: { floor } }
}

export function changeSquare(square) {
	return { type: CHANGE_SQUARE, payload: { square } }
}

export function changePrice(price) {
	return { type: CHANGE_PRICE, payload: { price } }
}

export function sortParamsFlats(field, order) {
	return { type: SORT_PARAMS_FLATS, payload: { field, order } }
}

export function clearFilter() {
	return { type: CLEAR_FILTER }
}
export function clearParamsFilter() {
	return { type: CLEAR_FILTER_PARAMS }
}
export function clearLayoutFilter() {
	return { type: CLEAR_FILTER_LAYOUT }
}

export function loadMore() {
	return { type: LOAD_MORE }
}

export function loadFlat(id) {
	// return (dispatch) => {
	// 	dispatch({ type: 'GET_FLAT_START' })
	// 	setTimeout(() => {
	// 		dispatch({
	// 			type: 'GET_FLAT_SUCCESS',
	// 			payload: { id },
	// 			id,
	// 			viewed: true,
	// 			response: {
	// 				id: id,
	// 				number: id,
	// 				corpus: '15',
	// 				section: '\u0412',
	// 				floor: '12',
	// 				num_on_floor: '2',
	// 				rooms: '0',
	// 				square: '28.64',
	// 				price: '3900000',
	// 				files: {
	// 					pdf: '/pdf/?w=flatplan&id=182'
	// 				},
	// 				instock: '1',
	// 				decoration: 0,
	// 				plan: 'http://butovo.multicontent.ru/plans/15_v_8-12_2.svg',
	// 				similar: [],
	// 				description: '',
	// 				promotion: '200000',
	// 				floor_plan: 'http://butovo.multicontent.ru/plans_floor/15_v_8-12_2.svg',
	// 				complex_plan: 'http://butovo.multicontent.ru/plans_complex/15_v.svg',
	// 				utp: []
	// 			}
	// 		})
	// 	})
	// }

	return {
		type: GET_FLAT,
		payload: { id },
		id,
		viewed: true,
		callAPI: { w: 'flat', id }
	}
}

export function sortFlats(field, order) {
	return { type: SORT_FLATS, payload: { field, order } }
}

export const setFormPlaceType = (formPlaceType) => {
	return { type: SET_FORM_PLACE_TYPE, formPlaceType }
}

export function closeMailForm() {
	return { type: FORM_MAIL + CLOSE }
}

export function openMailForm(id, type) {
	return { type: FORM_MAIL + OPEN, payload: { id, type } }
}

export function sendMailForm(data) {
	return {
		type: FORM_MAIL,
		form: true,
		callAPI: { ...data }
	}
}

export function closeRequestForm() {
	return { type: FORM_REQUEST + CLOSE }
}

export function openRequestForm(flat) {
	return { type: FORM_REQUEST + OPEN, payload: { flat } }
}

export function sendRequestForm(data) {
	return {
		type: FORM_REQUEST,
		callAPI: { ...data, w: 'form' }
	}
}

export function closeRequestLayoutForm() {
	return { type: FORM_REQUEST_LAYOUT + CLOSE }
}
export function openRequestLayoutForm(layout) {
	return { type: FORM_REQUEST_LAYOUT + OPEN, payload: { layout } }
}
export function sendRequestLayoutForm(data) {
	return {
		type: FORM_REQUEST_LAYOUT,
		callAPI: { ...data, w: 'form' }
	}
}

export function closeCallForm() {
	return { type: FORM_CALL + CLOSE }
}

export function openCallForm(flat) {
	return { type: FORM_CALL + OPEN, payload: { flat } }
}

export function sendCallForm(data) {
	return {
		type: FORM_CALL,
		callAPI: { ...data, w: 'form' }
	}
}

export function closeSubscribeForm() {
	return { type: FORM_SUBSCRIBE + CLOSE }
}

export function openSubscribeForm() {
	return { type: FORM_SUBSCRIBE + OPEN }
}

export function sendSubscribeForm(data) {
	return {
		type: FORM_SUBSCRIBE,
		callAPI: { ...data, form: true }
	}
}

export function closeParkingForm() {
	return { type: FORM_PARKING + CLOSE }
}

export function openParkingForm(place) {
	return { type: FORM_PARKING + OPEN, payload: { place } }
}

export function sendParkingForm(data) {
	return {
		type: FORM_PARKING,
		callAPI: { ...data, w: 'form' }
	}
}

export function sectionChangeRooms(rooms) {
	return {
		type: SECTION_CHANGE_ROOMS,
		payload: { rooms }
	}
}

export function loadAbout() {
	return {
		type: LOAD_ABOUT,
		callAPI: { w: 'about' }
	}
}
export function loadNews() {
	return {
		type: LOAD_NEWS,
		callAPI: { w: 'news' }
	}
}
export function loadNew(id) {
	return {
		type: LOAD_NEW,
		payload: { id },
		callAPI: { w: 'news', code: id }
	}
}
export function changeNewsYear(selected) {
	return {
		type: CHANGE_NEWS_YEAR,
		payload: { selected }
	}
}

export function loadHome() {
	// return (dispatch) => {
	// 	dispatch({ type: 'LOAD_HOME_START' })
	// 	setTimeout(() => {
	// 		dispatch({
	// 			type: 'LOAD_HOME_SUCCESS',
	// 			response: index
	// 		})
	// 	})
	// }
	return {
		type: LOAD_HOME,
		callAPI: { w: 'index' }
	}
}

export function loadInfra() {
	return {
		type: LOAD_INFRA,
		callAPI: { w: 'infra' }
	}
}

export function changeInfraGroup(selected) {
	return {
		type: CHANGE_INFRA_GROUP,
		payload: { selected }
	}
}

export function loadDeveloper() {
	return {
		type: LOAD_DEVELOPER,
		callAPI: { w: 'about-developer' }
	}
}
export function loadResidental() {
	return {
		type: LOAD_RESIDENTAL,
		callAPI: { w: 'about-residential' }
	}
}
export function loadGallery() {
	return {
		type: LOAD_GALLERY,
		callAPI: { w: 'about-gallery' }
	}
}
export function toggleGallery(id) {
	return {
		type: TOGGLE_GALLERY,
		payload: { id }
	}
}

export function loadDocs() {
	return {
		type: LOAD_DOCS,
		callAPI: { w: 'about-docs' }
	}
}
export function changeDocsPage(selected) {
	return {
		type: CHANGE_DOCS_PAGE,
		payload: { selected }
	}
}
export function changeDocsType(selected) {
	return {
		type: CHANGE_DOCS_TYPE,
		payload: { selected }
	}
}
export function changeDocsCorpus(selected) {
	return {
		type: CHANGE_DOCS_CORPUS,
		payload: { selected }
	}
}
export function changeDocsYear(selected) {
	return {
		type: CHANGE_DOCS_YEAR,
		payload: { selected }
	}
}

export function loadProgress() {
	return {
		type: LOAD_PROGRESS,
		callAPI: { w: 'about-progress' }
	}
}
export function toggleProgressGallery(id) {
	return {
		type: TOGGLE_PROGRESS_GALLERY,
		payload: { id }
	}
}
export function changeProgressYear(selected) {
	return {
		type: CHANGE_PROGRESS_YEAR,
		payload: { selected }
	}
}
export function changeProgressMonth(selected) {
	return {
		type: CHANGE_PROGRESS_MONTH,
		payload: { selected }
	}
}
export function changeProgressCorpus(selected) {
	return {
		type: CHANGE_PROGRESS_CORPUS,
		payload: { selected }
	}
}
export function playProgressVideo(id) {
	return {
		type: PLAY_PROGRESS_VIDEO,
		payload: { id }
	}
}
export function loadLayout() {
	return {
		type: GET_LAYOUT,
		callAPI: { w: 'layout' }
	}
}

export function loadPurchase() {
	return {
		type: LOAD_PURCHASE,
		callAPI: { w: 'purchase' }
	}
}

export function loadMortgage() {
	// return (dispatch) => {
	// 	dispatch({ type: 'LOAD_MORTGAGE_START' })
	// 	setTimeout(() => {
	// 		dispatch({
	// 			type: 'LOAD_MORTGAGE_SUCCESS',
	// 			response: mort
	// 		})
	// 	})
	// }
	return {
		type: LOAD_MORTGAGE,
		callAPI: { w: 'purchase-mortgage' }
	}
}

export function sortBank(field, order) {
	return { type: SORT_BANK, payload: { field, order } }
}

export function loadPayment() {
	return {
		type: LOAD_PAYMENT,
		callAPI: { w: 'purchase-payment' }
	}
}

export function loadPromotions() {
	return {
		type: LOAD_PROMOTIONS,
		callAPI: { w: 'purchase-promotions' }
	}
}

export function loadPromotion(id) {
	return {
		type: LOAD_PROMOTION,
		payload: { id },
		callAPI: { w: 'purchase-promotions', id }
	}
}

// commerce
export function loadCommerce() {
	// return (dispatch) => {
	// 	dispatch({ type: 'GET_COMMERCE_START' })
	// 	setTimeout(() => {
	// 		dispatch({
	// 			type: 'GET_COMMERCE_SUCCESS',
	// 			response: commerce
	// 		})
	// 	})
	// }

	return {
		type: GET_COMMERCE,
		callAPI: { w: 'commerce' }
	}
}
export function changeCommerceCorpus(corpus) {
	return { type: CHANGE_COMMERCE_CORPUS, payload: { corpus } }
}
export function changeCommerceSquare(square) {
	return { type: CHANGE_COMMERCE_SQUARE, payload: { square } }
}

export function changeCommercePrice(price) {
	return { type: CHANGE_COMMERCE_PRICE, payload: { price } }
}
export function changeCommercePromotion(promotion) {
	return { type: CHANGE_COMMERCE_PROMOTION, payload: { promotion } }
}

export function sortCommerceFlats(field, order) {
	return { type: SORT_COMMERCE_FLATS, payload: { field, order } }
}

export function clearCommerceFilter() {
	return { type: CLEAR_COMMERCE_FILTER }
}
export function loadCommerceMore() {
	return { type: LOAD_COMMERCE_MORE }
}

export function loadCommerceCorpus(id) {
	// return (dispatch) => {
	// 	dispatch({ type: 'GET_COMMERCE_CORPUS_START' })
	// 	setTimeout(() => {
	// 		dispatch({
	// 			type: 'GET_COMMERCE_CORPUS_SUCCESS',
	// 			payload: { id },
	// 			response:
	// 				id === '16'
	// 					? {
	// 							id: '2',
	// 							// "plan": "\/upload\/mlrealestate\/House\/b09\/b092ef3402ae79eeb6ae9f07a01269b7.svg",
	// 							plan: '/img/commerce/corpus/16.svg',
	// 							floor_plans: [
	// 								{
	// 									floor: 1,
	// 									plan: '/img/commerce/corpus/16.svg'
	// 								},
	// 								{
	// 									floor: 2,
	// 									plan: '/img/commerce/corpus/16-2.svg'
	// 								}
	// 							],
	// 							number: '16',
	// 							address:
	// 								'\u0433. \u041c\u043e\u0441\u043a\u0432\u0430, \u0443\u043b. \u0427\u0435\u0447\u0451\u0440\u0441\u043a\u0438\u0439 \u043f\u0440\u043e\u0435\u0437\u0434, \u0434. 122, \u043a\u043e\u0440\u043f. 1',
	// 							description:
	// 								'\u041a\u043e\u0440\u043f\u0443\u0441 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d \u043f\u0440\u0438 \u0432\u044a\u0435\u0437\u0434\u0435 \u0432 \u0436\u0438\u043b\u043e\u0439 \u043a\u043e\u043c\u043f\u043b\u0435\u043a\u0441, \u043d\u0430\u043f\u0440\u043e\u0442\u0438\u0432 \u0442\u043e\u0440\u0433\u043e\u0432\u043e-\u0440\u0430\u0437\u0432\u043b\u0435\u043a\u0430\u0442\u0435\u043b\u044c\u043d\u043e\u0433\u043e \u0446\u0435\u043d\u0442\u0440\u0430 \u00ab\u0411\u0443\u0442\u043e\u0432\u043e \u043c\u043e\u043b\u043b\u00bb. <p>\u0411\u043b\u0438\u0437\u043e\u0441\u0442\u044c \u043a \u0422\u0420\u0426, \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0435 \u043e\u0431\u0449\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0433\u043e \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442\u0430 \u0438 \u043c\u0435\u0441\u0442\u043e\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0438\u0432\u0430\u044e\u0442 \u043f\u043b\u043e\u0442\u043d\u044b\u0439 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0438 \u043f\u0435\u0448\u0435\u0445\u043e\u0434\u043d\u044b\u0439 \u043f\u043e\u0442\u043e\u043a. \u041a\u043e\u0440\u043f\u0443\u0441 \u0432\u0432\u0435\u0434\u0435\u043d \u0432 \u044d\u043a\u0441\u043f\u043b\u0443\u0430\u0442\u0430\u0446\u0438\u044e \u0438 \u0430\u043a\u0442\u0438\u0432\u043d\u043e \u0437\u0430\u0441\u0435\u043b\u044f\u0435\u0442\u0441\u044f.</p>',
	// 							img: '/images/realty/img_146_526x361c.jpg',
	// 							complex_plan: ''
	// 					  }
	// 					: {
	// 							id: '1',
	// 							plan: '/img/commerce/corpus/15.svg',
	// 							number: '15',
	// 							address: '',
	// 							text_ready:
	// 								'\u0418\u0434\u0435\u0442 \u043a\u043e\u043c\u0438\u0441\u0441\u0438\u044f \u041c\u0413\u0421\u041d. \u0416\u0434\u0435\u043c \u0417\u041e\u0421. \u041a\u043b\u044e\u0447\u0438 \u043e\u043a\u0442\u044f\u0431\u0440\u044c 2019 \u0433.',
	// 							description:
	// 								'\u041a\u043e\u0440\u043f\u0443\u0441 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d \u043f\u0440\u0438 \u0432\u044a\u0435\u0437\u0434\u0435 \u0432 \u0436\u0438\u043b\u043e\u0439 \u043a\u043e\u043c\u043f\u043b\u0435\u043a\u0441, \u043d\u0430\u043f\u0440\u043e\u0442\u0438\u0432 \u0442\u043e\u0440\u0433\u043e\u0432\u043e-\u0440\u0430\u0437\u0432\u043b\u0435\u043a\u0430\u0442\u0435\u043b\u044c\u043d\u043e\u0433\u043e \u0446\u0435\u043d\u0442\u0440\u0430 \u00ab\u0411\u0443\u0442\u043e\u0432\u043e \u043c\u043e\u043b\u043b\u00bb. \u0411\u043b\u0438\u0437\u043e\u0441\u0442\u044c \u043a \u0422\u0420\u0426, \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0435 \u043e\u0431\u0449\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0433\u043e \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442\u0430 \u0438 \u043c\u0435\u0441\u0442\u043e\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0438\u0432\u0430\u044e\u0442 \u043f\u043b\u043e\u0442\u043d\u044b\u0439 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0438 \u043f\u0435\u0448\u0435\u0445\u043e\u0434\u043d\u044b\u0439 \u043f\u043e\u0442\u043e\u043a.',
	// 							img: '/images/realty/img_149_526x361c.jpg',
	// 							complex_plan: '/upload/mlrealestate/House/435/435ca0c5a9142605a959a41a8062d7c0.png'
	// 					  }
	// 		})
	// 	})
	// }
	// if (id === '10' || id === '11') {
	// 	return (dispatch) => {
	// 		dispatch({ type: 'GET_COMMERCE_CORPUS_START' })
	// 		$.get('http://butovo.multicontent.ru/ajax/json.php', { w: 'commerce', corpus: id })
	// 			.done((response) => response.data)
	// 			.done((response) => {
	// 				dispatch({
	// 					type: 'GET_COMMERCE_CORPUS_SUCCESS',
	// 					payload: { id },
	// 					response: {
	// 						...response,
	// 						id: '1',
	// 						plan: '/img/commerce/corpus/' + id + '.svg',
	// 						number: id,
	// 						address: '',
	// 						text_ready:
	// 							'\u0418\u0434\u0435\u0442 \u043a\u043e\u043c\u0438\u0441\u0441\u0438\u044f \u041c\u0413\u0421\u041d. \u0416\u0434\u0435\u043c \u0417\u041e\u0421. \u041a\u043b\u044e\u0447\u0438 \u043e\u043a\u0442\u044f\u0431\u0440\u044c 2019 \u0433.',
	// 						description:
	// 							'\u041a\u043e\u0440\u043f\u0443\u0441 \u0440\u0430\u0441\u043f\u043e\u043b\u043e\u0436\u0435\u043d \u043f\u0440\u0438 \u0432\u044a\u0435\u0437\u0434\u0435 \u0432 \u0436\u0438\u043b\u043e\u0439 \u043a\u043e\u043c\u043f\u043b\u0435\u043a\u0441, \u043d\u0430\u043f\u0440\u043e\u0442\u0438\u0432 \u0442\u043e\u0440\u0433\u043e\u0432\u043e-\u0440\u0430\u0437\u0432\u043b\u0435\u043a\u0430\u0442\u0435\u043b\u044c\u043d\u043e\u0433\u043e \u0446\u0435\u043d\u0442\u0440\u0430 \u00ab\u0411\u0443\u0442\u043e\u0432\u043e \u043c\u043e\u043b\u043b\u00bb. \u0411\u043b\u0438\u0437\u043e\u0441\u0442\u044c \u043a \u0422\u0420\u0426, \u043e\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0435 \u043e\u0431\u0449\u0435\u0441\u0442\u0432\u0435\u043d\u043d\u043e\u0433\u043e \u0442\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442\u0430 \u0438 \u043c\u0435\u0441\u0442\u043e\u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u043e\u0431\u0435\u0441\u043f\u0435\u0447\u0438\u0432\u0430\u044e\u0442 \u043f\u043b\u043e\u0442\u043d\u044b\u0439 \u0430\u0432\u0442\u043e\u043c\u043e\u0431\u0438\u043b\u044c\u043d\u044b\u0439 \u0438 \u043f\u0435\u0448\u0435\u0445\u043e\u0434\u043d\u044b\u0439 \u043f\u043e\u0442\u043e\u043a.',
	// 						img: '/images/realty/img_149_526x361c.jpg',
	// 						complex_plan: ''
	// 					}
	// 				})
	// 			})
	// 	}
	// }

	return {
		type: GET_COMMERCE_CORPUS,
		payload: { id },
		callAPI: { w: 'commerce', corpus: id }
	}
}

export function loadCommerceFlat(id) {
	return {
		type: GET_COMMERCE_FLAT,
		payload: { id },
		id,
		viewedCommerce: true,
		callAPI: { w: 'commerce', id }
	}
}

export function loadParking() {
	return {
		type: GET_PARKING,
		callAPI: { w: 'parking' }
	}
}

export function loadDecoration() {
	return {
		type: GET_DECORATION,
		callAPI: { w: 'realty-decoration' }
	}
}

export function loadPrivacy() {
	return {
		type: LOAD_PRIVACY,
		callAPI: { w: 'privacy' }
	}
}
export function loadAgreement() {
	return {
		type: LOAD_AGREEMENT,
		callAPI: { w: 'agreement' }
	}
}

export function loadContacts() {
	return {
		type: LOAD_CONTACTS,
		callAPI: { w: 'contacts' }
	}
}

export function closePopupScheme() {
	return { type: POPUP_SCHEME + CLOSE }
}

export function openPopupScheme() {
	return { type: POPUP_SCHEME + OPEN }
}

export function changeMortgagePrice(price) {
	return { type: CHANGE_MORTAGE_PRICE, payload: { price } }
}
export function changeMortgageСontribution(contribution) {
	return { type: CHANGE_MORTAGE_CONTRIBUTION, payload: { contribution } }
}
export function changeMortgageTerm(term) {
	return { type: CHANGE_MORTAGE_TERM, payload: { term } }
}
export function changeMortgageBank(bank) {
	return { type: CHANGE_MORTAGE_BANK, payload: { bank } }
}

export function closePopupInstallments() {
	return { type: POPUP_INSTALLMENTS + CLOSE }
}

export function openPopupInstallments() {
	return { type: POPUP_INSTALLMENTS + OPEN }
}

export function setCommerce(commerce) {
	return { type: SET_COMMERCE, payload: { commerce } }
}

export function changeСontribution(payment) {
	return { type: CHANGE_MORTAGE_CONTRIBUTION + REALTY, payload: { payment } }
}
export function changeTerm(term2) {
	return { type: CHANGE_MORTAGE_TERM + REALTY, payload: { term2 } }
}
export function changeMortgagePrice2(price2) {
	return { type: CHANGE_MORTAGE_PRICE + REALTY, payload: { price2 } }
}
export function changeMortgageRate(rate) {
	return { type: CHANGE_MORTAGE_RATE + REALTY, payload: { rate } }
}
export function changeMortgageFamilyRate(family_rate) {
	return { type: CHANGE_MORTAGE_FAMILY_RATE + REALTY, payload: { family_rate } }
}

export function closeQuestionForm() {
	return { type: QUESTION + CLOSE }
}

export function openQuestionForm(place) {
	return { type: QUESTION + OPEN }
}

export function sendQuestionForm(data) {
	return {
		type: QUESTION,
		callAPI: { ...data, form: true }
	}
}
export function loadFaq() {
	return {
		type: LOAD_FAQ,
		callAPI: { w: 'faq' }
	}
}
