import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadMortgage, openCallForm, setFormPlaceType } from '../../actions'
import { sortedMortgageSelector } from '../../selectors'
import ScrollTop from '../common/scroll-top'
import Button from '../common/button'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'
import MortgageBlock from './mortgage'

class Mortgage extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Условия ипотеки'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Для Вас в ЖК «Новое Бутово» действуют специальные программы ипотечного кредитования от банков-партнёров'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadMortgage } = this.props
		if (!loading && !loaded) loadMortgage()
	}

	componentWillUnmount() {
		document.querySelector('body').classList.remove('no-scroll')
	}

	openForm = () => {
		this.props.openCallForm()
		this.props.setFormPlaceType('консультация по ипотеки')
	}

	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />
		return (
			<section className="content__block mortgage">
				{!document.body.classList.contains('no-scroll') && <ScrollTop />}
				<div className="box">
					<div className="breadcrumb">
						<h1>Условия покупки</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/purchase">Условия покупки</Link> / Ипотека
						</div>
					</div>
					<div>
						<div className="mortgage__block">{this.mortgage}</div>
						<MortgageBlock />
					</div>
				</div>
			</section>
		)
	}

	get mortgage() {
		const { mortgage } = this.props.mortgage
		if (!mortgage) return null

		return (
			<div className="purchase__item">
				<div
					className="purchase__item__image"
					style={{ backgroundImage: `url(${this.props.mobile ? mortgage.mobile_img : mortgage.img})` }}
				/>
				<div className="purchase__item__body">
					<div className="purchase__item__title" dangerouslySetInnerHTML={{ __html: mortgage.title }} />
					<div className="purchase__item__text" dangerouslySetInnerHTML={{ __html: mortgage.text }} />
					<Button onClick={this.openForm}>консультация</Button>
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		loading: state.mortgage.loading,
		loaded: state.mortgage.loaded,
		mortgage: state.mortgage,
		banks: sortedMortgageSelector(state),
		mobile: state.mobile
	}),
	{ loadMortgage, openCallForm, setFormPlaceType }
)(Mortgage)
