import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from '../common/loader'
import Scheme from './scheme'
import { loadGenplan, getPage, sectionChangeRooms } from '../../actions'
import { NavLink } from 'react-router-dom'
import AboutAdvList from '../About/about-advants'
import Additional from '../realty/additional'
import Panel from '../realty/panel'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'

class GenplanPage extends Component {
	componentDidMount() {
		document.title =
			'ЖК «Новое Бутово». Подобрать квартиру на генплане в Москве, 15 минут пешком до метро Бунинская аллея.'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Жилой комплекс «Новое Бутово» - это уютный микрорайон в самом экологичном месте столицы. Здесь Вы сможете подобрать себе квартиру на генплане'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadGenplan } = this.props
		if (!loading && !loaded) loadGenplan()
	}

	render() {
		const { genplan, loaded, loading, getPage, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="genplan">
				<ScrollTop />
				{this.path}
				<div className="content">
					<Panel />
					<Fade>
						<div>
							<Scheme
								img={genplan.img}
								buildings={genplan.buildings}
								parking={genplan.parking}
								getPage={getPage}
								mobile={mobile}
								changeRoomsFilter={this.handleChange}
							/>
						</div>
					</Fade>
				</div>
				{/*
				<Additional />
				<AboutAdvList />
				*/}
			</div>
		)
	}

	get path() {
		return (
			<div className="box">
				<div className="breadcrumb">
					<h1>Подобрать квартиру</h1>
					<div className="breadcrumb__links">
						<NavLink to="/">Главная</NavLink> /<NavLink to="/realty">Подобрать квартиру</NavLink> / На
						генплане
					</div>
				</div>
			</div>
		)
	}

	handleChange = (rooms) => {
		const { sectionChangeRooms } = this.props
		sectionChangeRooms([...new Set([rooms])])
	}
}

export default connect(
	(state) => ({
		genplan: state.genplan.response,
		loading: state.genplan.loading,
		loaded: state.genplan.loaded,
		mobile: state.mobile
	}),
	{ loadGenplan, getPage, sectionChangeRooms }
)(GenplanPage)
