export const objectToMap = (object, prop) => {
	return object.reduce((acc, value) => {
		const oldValue = acc[value[prop]] ? acc[value[prop]] : []
		return {
			...acc,
			[value[prop]]: [...oldValue, value]
		}
	}, {})
}
export const arrayToMap = (object, prop) => {
	return object.reduce((acc, value) => {
		return {
			...acc,
			[value[prop]]: value
		}
	}, {})
}
export const distinct = (items, prop) => {
	const unique = []
	const distinctItems = []

	items.map((item) => {
		if (!unique[item[prop]]) distinctItems.push(item)

		return (unique[item[prop]] = 1)
	})

	return distinctItems
}

export const compare = (a, b) => (a < b ? -1 : a > b ? 1 : 0)
