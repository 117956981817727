import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Genplan from './genplan'
import Flat from './flat'
import Corpus from './corpus'
import Mail from '../realty/forms/mail'
import Request from '../realty/forms/request-commerce'
import PopupScheme from './scheme'
import PopupInstallments from './installments'

import Params from './params'
import { loadCommerce } from '../../actions'
import TagManager from 'react-gtm-module'

class Realty extends Component {
	componentDidMount() {
		TagManager.initialize({ gtmId: 'GTM-MJS272M' })

		const { loading, loaded, loadCommerce } = this.props

		if (!loading && !loaded) loadCommerce()
	}

	render() {
		return (
			<div className="content__block realty realty-commerce">
				<Route path="/commerce" exact component={Genplan} />
				<Route path="/commerce-params" exact component={Params} />

				<Route path="/commerce/:corpus" exact component={this.getCorpus} />
				<Route path="/commerce/:corpus/:id" exact component={this.getFlat} />
				<Mail />
				<Request />
				<PopupScheme />
				<PopupInstallments />
			</div>
		)
	}

	getCorpus = ({ match }) => {
		return <Corpus {...match.params} />
	}

	getFlat = ({ match }) => {
		return <Flat {...match.params} key={match.id} />
	}
}

export default connect(
	(state) => ({
		loading: state.commerce.loading,
		loaded: state.commerce.loaded
	}),
	{ loadCommerce }
)(Realty)
