import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { closeRequestForm, sendRequestForm } from '../../../actions'
import InputMask from 'react-input-mask'
import accounting from 'accounting'

class Request extends Component {
	state = {
		phone: '',
		name: '',
		check: 0,
		checkForm: false
	}

	componentDidUpdate(oldProps) {
		if (this.props.form.sent !== oldProps.form.sent && this.props.form.sent) {
			this.setState({
				phone: '',
				name: '',
				check: 0,
				checkForm: false
			})
		}
	}

	render() {
		return (
			<div>
				{this.form}
				{this.success}
			</div>
		)
	}

	get success() {
		const { form, closeRequestForm } = this.props
		return (
			<Modal closeTimeoutMS={300} isOpen={form.sent} className="modalwin modalwin--thanks">
				<div onClick={closeRequestForm} className="overlay__modal" />
				<div onClick={closeRequestForm} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">Спасибо!</div>
					Ваша заявка была отправлена.
					<br />
					Очень скоро с вами свяжется наш менеджер.
				</div>
			</Modal>
		)
	}

	get form() {
		const { form, closeRequestForm } = this.props
		return (
			<Modal closeTimeoutMS={300} className="modalwin modalwin--callback request-modal" isOpen={form.open}>
				<div onClick={closeRequestForm} className="overlay__modal" />
				<div onClick={closeRequestForm} className="close__modal" />
				<div className="modalwin__box">
					<div>
						<div className="modal__title">
							Оставить заявку на помещение
							<div className="modal__error-text">Одно или несколько полей заполнены с ошибкой!</div>
						</div>
						<form onSubmit={this.handleSubmit}>
							<div className={this.getClassName('name')}>
								<input
									type="text"
									placeholder="Имя"
									value={this.state.name}
									onChange={this.handleChange('name')}
								/>
							</div>
							<div className={this.getClassName('phone')}>
								<InputMask
									type="tel"
									value={this.state.phone}
									onChange={this.handleChange('phone')}
									placeholder="Телефон"
									mask="+7 (999) 999-99-99"
								/>
							</div>

							<div className={`modal__input--checkbox ${this.getClassName('check')}`}>
								<label>
									<input
										onChange={this.handleCheckboxChange('check')}
										value={this.state.check}
										checked={this.state.check === 1}
										type="checkbox"
									/>
									<span className="icon-check-symbol" />Я согласен на обработку персональных данных и
									с условиями пользовательского соглашения
								</label>
							</div>
							<div className="modal__input modal__input--but">
								<button type="submit" className={this.props.form.pending ? 'btn-disabled' : ''}>
									<i className="ico-4" />
									Отправить
								</button>
							</div>
						</form>
					</div>
					{this.body}
				</div>
			</Modal>
		)
	}

	get body() {
		const { flat } = this.props.form
		if (!flat) return null

		return (
			<div className="request-flat">
				<div className="request-flat__title">
					Помещение <span>№ {flat['number']}</span>
				</div>
				<div className="flat__info">
					<div className="flat__info__item">
						<div className="flat__info__title">Стоимость:</div>
						<div className="flat__info__body">
							{accounting.formatNumber(flat.price, 0, ' ')} <span>руб.</span>
						</div>
					</div>
					<div className="flat__info__item">
						<div className="flat__info__title">Корпус:</div>
						<div className="flat__info__body">{flat['corpus']}</div>
					</div>
					<div className="flat__info__item">
						<div className="flat__info__title">Общая площадь:</div>
						<div className="flat__info__body">
							{accounting.formatNumber(flat['square'], 0, '')} м<sup>2</sup>
						</div>
					</div>
				</div>
				<div className="plan-image">
					<img alt="" src={flat.plan} />
				</div>
			</div>
		)
	}

	handleSubmit = (ev) => {
		ev.preventDefault()

		if (!this.isValidForm()) {
			this.setState({
				checkForm: true
			})
			return false
		}
		if (window.Comagic && typeof window.Comagic == 'object') {
			window.Comagic.addOfflineRequest({
				name: this.state.name,
				phone: this.state.phone,
				message: 'Заявка на коммерческое помещение / ' + this.props.formPlaceType
			})
		}

		this.props.sendRequestForm({
			name: this.state.name,
			phone: this.state.phone,
			rel: this.props.data.rel,
			flat: this.props.form.flat.id,
			id: 'request',
			form: 'Заявка на коммерческое помещение / ' + this.props.formPlaceType
		})
	}

	isValidForm = () => ['phone', 'name', 'check'].every(this.isValidField)

	isValidField = (type) => {
		if (limits[type] && limits[type].regex) return limits[type].regex.test(this.state[type])

		if (type === 'check') return !!this.state[type]
		return this.state[type].length >= limits[type].min
	}

	getClassName = (type) => {
		return (!this.state[type].length && !this.state.checkForm) || this.isValidField(type)
			? 'modal__input'
			: 'modal__input error'
	}

	handleChange = (type) => (ev) => {
		const { value } = ev.target
		this.setState({
			[type]: value
		})
	}

	handleCheckboxChange = (type) => (ev) => {
		const { value } = ev.target
		this.setState({
			[type]: +value === 0 ? 1 : 0
		})
	}
}

const limits = {
	phone: {
		regex: /^[0-9()\-+ ]+$/
	},
	name: {
		regex: /^[а-яА-Яa-zA-Z ]+$/
	}
}

export default connect(
	(state) => ({
		data: state.form.data,
		form: state.form.request,
		formPlaceType: state.form.formPlaceType
	}),
	{ closeRequestForm, sendRequestForm }
)(Request)
