import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './mobile.scss'
import Map from './map'
import News from './news'
import Video from './video'
import Photos from './photos'
import Comfort from './comfort'
import Ecology from './ecology'
import Life from './life'
import FindFlat from './findflat'
import FlatPrice from './flatprice'
import Tc from './tc'
import Arrow from './arrow'
import $ from 'jquery'
import Mortgage from './mortgage'
import Ny from './ny'
import Ny2 from './ny2'
import Ny3 from './ny3'
import Presentmob from './presentmob'

var URI = document.location.hostname === 'localhost' ? 'http://butovo.multicontent.ru' : ''
class Main extends Component {
	componentDidMount() {
		let vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}

	render() {
		return <div>{this.body}</div>
	}

	get body() {
		const { home } = this.props

		return home.items.map((el, i) => {
			if (el.type === 'video') return null
			if (el.id === '215' || el.id === '214' || el.id === '248' || el.id === '247' || el.id === '244') return null
			return (
				<div key={i} className={`home__item home__item_${el.type ? el.type : el.id}`}>
					<div className="animated">
						<span
							className="home__item__bg portrait"
							style={{ backgroundImage: `url(${URI}${el['mobile']})` }}
						/>
						<span
							className="home__item__bg landscape"
							style={{ backgroundImage: `url(${URI}${el['img']})` }}
						/>

						{(el.id === '202' || el.id === '228') && <Ny3 el={el} />}
						{(el.id === '213' || el.id === '228') && <Ny el={el} />}
						{(el.id === '229' || el.id === '212') && <Ny2 el={el} />}
						{(el.id === '236' || el.id === '269') && <Presentmob el={el} />}
						{el.type === 'mortgage' && <Mortgage el={el} />}
						{el.type === 'flatprice' && <FlatPrice el={el} />}
						{el.type === 'news' && <News news={home.news} />}
						{el.type === 'map' && <Map el={el} id={`map_${i + 1}`} mobile={true} />}
						{el.type === 'photos' && <Photos el={el} mobile={true} />}
						{el.type === 'comfort' && <Comfort el={el} mobile={true} />}
						{el.type === 'ecology' && <Ecology el={el} />}
						{el.type === 'life' && <Life el={el} />}
						{el.type === 'findflat' && <FindFlat el={el} mobile={true} />}
						{el.type === 'tc' && <Tc el={el} mobile={true} />}

						{el.type ||
						el.id === '202' ||
						el.id === '228' ||
						el.id === '229' ||
						el.id === '212' ||
						el.id === '213' ||
						el.id === '214' ||
						el.id === '236' ||
						el.id === '269' ||
						el.id === '215' ? (
							<Arrow />
						) : (
							<Link
								className="main__item--but main__item--but__white main__item--but--bott"
								to="/purchase/promotions"
							>
								<i className="icon-i_tree" />
								<p>Подробнее об акции</p>
							</Link>
						)}
					</div>
				</div>
			)
		})
	}
}

export default connect(
	(state, props) => ({
		loading: state.home.loading,
		loaded: state.home.loaded,
		home: state.home.data
	}),
	{}
)(Main)
