import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadPurchase } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'
import $ from 'jquery'

class Installments extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Рассрочка'
		//document
		//	.querySelector('meta[name=description]')
		//	.setAttribute(
		//		'content',
		//		'Вы можете приобрести жилье в «Новом Бутово», оформив ДДУ или договор купли-продажи, если дом уже построен и сдан. Способы оплаты: 100% оплата, беспроцентная рассрочка при первоначальном взносе от 50%, ипотека от крупнейших банков страны'
		//	)
		hit(window.location.pathname)
	}
	render() {
		return (
			<section className="content__block">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Рассрочка</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / Рассрочка
						</div>
					</div>
					<Fade>
						<div>
							<p>
								Условия приобретения нежилого помещения в рассрочку обсуждаются в индивидуальном порядке
							</p>
						</div>
					</Fade>
				</div>
			</section>
		)
	}
}

export default connect(
	(state) => ({
		//loading: state.purchase.loading,
		//loaded: state.purchase.loaded,
		//purchase: state.purchase.data,
		//mobile: state.mobile
	}),
	{}
)(Installments)
