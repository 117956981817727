import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeRooms } from '../../actions'
import { roomsOptions, filtersSelector } from '../../selectors'

class RoomsFilter extends Component {
	handleChange = (e) => {
		const { selected, changeRooms } = this.props
		const value = e.currentTarget.getAttribute('value') + ''

		const result = selected.indexOf(value) === -1 ? [...selected, value] : selected.filter((room) => room !== value)

		changeRooms(result)
	}

	get options() {
		const { options, selected } = this.props

		return options.map((el) => {
			return (
				<div
					className={selected.indexOf(el.rooms) === -1 ? 'check-field' : 'check-field active'}
					key={el.rooms}
					value={el.rooms}
					onClick={this.handleChange}
				>
					{el.rooms === '0' ? 'C' : el.rooms}
				</div>
			)
		})
	}

	render() {
		return <div className="checkbox-list">{this.options}</div>
	}
}

export default connect(
	(state) => ({
		options: roomsOptions(state),
		selected: filtersSelector(state).rooms
	}),
	{ changeRooms }
)(RoomsFilter)
