import React, { Component } from 'react'

export default class Timer extends Component {
	state = {
		time: {},
		seconds: 0
	}

	constructor() {
		super()
		this.timer = 0
	}

	secondsToTime(secs) {
		let seconds = parseInt(this.state.seconds, 10)
		const days = Math.floor(seconds / (3600 * 24))
		seconds -= days * 3600 * 24
		const hours = Math.floor(seconds / 3600)
		seconds -= hours * 3600
		const minutes = Math.floor(seconds / 60)
		seconds -= minutes * 60

		return {
			days,
			hours,
			minutes,
			seconds
		}
	}

	componentDidMount() {
		if (this.props.actions.time) {
			this.setState({ seconds: this.props.actions.time })
		}
	}

	componentDidUpdate(oldProps, oldState) {
		if (this.state.seconds && this.state.seconds !== oldState.seconds && !this.state.ready) {
			let timeLeftVar = this.secondsToTime(this.props.actions.time)
			this.setState({ time: timeLeftVar, seconds: this.props.actions.time, ready: true })
			this.startTimer()
		}
	}

	startTimer = () => {
		if (this.timer == 0 && this.state.seconds > 0) {
			this.timer = setInterval(this.countDown, 1000)
		}
	}

	countDown = () => {
		let seconds = this.state.seconds - 1
		this.setState({
			time: this.secondsToTime(seconds),
			seconds: seconds
		})

		if (seconds == 0) clearInterval(this.timer)
	}

	render() {
		const h = this.state.time.hours < 10 ? '0' + this.state.time.hours : this.state.time.hours
		const m = this.state.time.minutes < 10 ? '0' + this.state.time.minutes : this.state.time.minutes
		const s = this.state.time.seconds < 10 ? '0' + this.state.time.seconds : this.state.time.seconds

		return (
			<div>
				<div className="header-counter">
					{this.state.time.days} <span>{this.declOfNum(this.state.time.days, ['день', 'дня', 'дней'])}</span>{' '}
					{h}:{m}:{s}
				</div>
			</div>
		)
	}

	declOfNum = (number, titles) => {
		var cases = [2, 0, 1, 1, 1, 2]
		return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
	}
}
