import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Sections from '../section/sections'
import Fade from 'react-reveal/Fade'

import AboutAdvList from '../About/about-advants'
import Additional from '../realty/additional'

import { sectionChangeRooms, loadGenplan } from '../../actions'
import {
	sectionFlatsMapSelector,
	sectionFilterRoomsSelector,
	corpusFlatsMapSelector,
	corpusFlatsMapAllFloorsSelector,
	corpusFilterRoomsSelector
} from '../../selectors'

import ScrollTop from '../common/scroll-top'
import Panel from '../realty/panel'
import Buildings from '../section/buildings'

class Section extends Component {
	componentDidMount() {
		const { loadGenplan, loadingGenplan, loadedGenplan } = this.props

		if (!loadedGenplan && !loadingGenplan) loadGenplan()
	}

	componentDidUpdate(oldProps) {
		const { loadGenplan, loadingGenplan, loadedGenplan } = this.props

		if (!loadedGenplan && !loadingGenplan) loadGenplan()
	}

	render() {
		const { loading, loaded, section, corpus, filterRooms, filtres, mobile, filterRoomsCorpus } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="section">
				<ScrollTop />
				{this.path}
				<div className="content">
					<Panel />
					<Fade>
						<div>
							<div className="back">
								<Link to="/realty" className="ico-6">
									<span>Назад к выбору корпуса</span>
								</Link>
							</div>
							<div className="section__content section__content--desktop">
								<div>
									{/*
									<div className="section__title">
										<span>{corpus}</span> корпус
									</div>*/}
									<Buildings corpus={corpus} hidePlan={true} />
									<Sections mobile={mobile} hideHead={true} corpus={corpus} />
								</div>
							</div>
						</div>
					</Fade>
				</div>
				<Additional />
				<AboutAdvList />
			</div>
		)
	}
	get path() {
		const { section, corpus } = this.props

		return (
			<div className="box">
				<div className="breadcrumb">
					<h1>Подобрать квартиру</h1>
					<div className="breadcrumb__links">
						{/*<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> / Секция{' '}*/}
						<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> / Корпус{' '}
						<span> {corpus}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	(state, props) => ({
		loading: state.flats.loading,
		loaded: state.flats.loaded,

		flatsCorpus: corpusFlatsMapSelector(state, props),
		flatsAllFloors: corpusFlatsMapAllFloorsSelector(state, props),

		flats: sectionFlatsMapSelector(state, props),

		filtres: state.sectionFilter.rooms,
		loadedGenplan: state.genplan.loaded,
		loadingGenplan: state.genplan.loading,
		filterRooms: sectionFilterRoomsSelector(state, props),
		filterRoomsCorpus: corpusFilterRoomsSelector(state, props),

		mobile: state.mobile
	}),
	{ sectionChangeRooms, loadGenplan }
)(Section)
