import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { changeProgressYear, changeProgressMonth, changeProgressCorpus } from '../../../actions'
import {
	progressYearOptionsSelector,
	progressMonthOptionsSelector,
	progressCorpusOptionsSelector,
	progressFilterSelector
} from '../../../selectors'

class Filter extends Component {
	render() {
		const { filter } = this.props
		return (
			<div className="docs-filter box">
				<Select
					className="select"
					classNamePrefix="select"
					options={[{ label: 'Все объекты', value: '' }, ...this.corpusOptions]}
					value={filter.corpus}
					onChange={this.hangleCorpusChange}
					placeholder="Все объекты"
				/>
				<Select
					className="select"
					classNamePrefix="select"
					options={[{ label: 'Все года', value: '' }, ...this.yearOptions]}
					value={filter.year}
					onChange={this.hangleYearChange}
					placeholder="Все года"
					// defaultMenuIsOpen={true}
				/>
				<Select
					className="select"
					classNamePrefix="select"
					options={[{ label: 'Все месяцы', value: '' }, ...this.monthOptions]}
					value={filter.month}
					onChange={this.hangleMonthChange}
					placeholder="Все месяцы"
				/>
			</div>
		)
	}

	hangleYearChange = (selected) => this.props.changeProgressYear(selected)

	hangleMonthChange = (selected) => this.props.changeProgressMonth(selected)

	hangleCorpusChange = (selected) => this.props.changeProgressCorpus(selected)

	get yearOptions() {
		return this.props.year.map((el) => ({ label: `${el.year} год`, value: el.year }))
	}

	get monthOptions() {
		return this.props.month.map((el) => ({ label: `${el.month}`, value: el.month }))
	}

	get corpusOptions() {
		return this.props.corpus.map((el) => ({ label: el.name, value: el.name }))
	}
}

export default connect(
	(state) => ({
		year: progressYearOptionsSelector(state),
		month: progressMonthOptionsSelector(state),
		corpus: progressCorpusOptionsSelector(state),
		filter: progressFilterSelector(state)
	}),
	{ changeProgressYear, changeProgressMonth, changeProgressCorpus }
)(Filter)

// import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { changeProgressYear} from '../../../actions'
// import {
// 	progressYearOptionsSelector,
// 	progressYearSelector
// } from '../../../selectors'

// class Filter extends Component {
// 	render() {
// 		return <div className="news__panel">{this.panel}</div>
// 	}

// 	get panel() {
// 		const { options, selected } = this.props
// 		return options.map((el) => (
// 				<div
// 					key={el.year}
// 					to="/news/1"
// 					onClick={this.handleChangeYear}
// 					value={el.year}
// 					className={selected === el.year ? 'active' : ''}
// 				>
// 					<span>{el.year}</span>
// 				</div>
// 			))
// 	}

// 	handleChangeYear = (e) => {
// 		const value = e.currentTarget.getAttribute('value')
// 		this.props.changeProgressYear(value)
// 	}
// }

// export default connect(
// 	(state) => ({
// 		options: progressYearOptionsSelector(state),
// 		selected: progressYearSelector(state)
// 	}),
// 	{ changeProgressYear }
// )(Filter)
