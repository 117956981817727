import { Component } from 'react'
import $ from 'jquery'

export default class Loader extends Component {
	componentDidMount() {
		const body = $('body')
		const menu = $('menu')

		if (body.is('.ready')) {
			body.addClass('loading3')
			return
		}

		body.addClass('loading')
		if (!menu.length) return
		menu.addClass('open fixed')

		setTimeout(() => {
			menu.removeClass('open fixed')
			body.addClass('menu-ready')
		}, 2000)
	}
	componentWillUnmount() {
		const body = $('body')

		if (!body.is('.ready')) {
			body.removeClass('loading')
			body.addClass('ready')
			return
		}

		body.removeClass('loading3')
	}

	render() {
		return null
	}
}
