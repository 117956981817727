import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sectionFlatsMapSelector } from '../../selectors'

class Scheme extends Component {
	render() {
		const { loaded } = this.props
		if (!loaded) return null
		return <div className="section-plan-min">{this.body}</div>
	}
	get body() {
		return (
			<div className="section__body">
				<div className="section-view">{this.rows}</div>
			</div>
		)
	}

	get rows() {
		const { flats } = this.props
		if (!flats) return null

		return Object.keys(flats)
			.reverse()
			.map((value) => {
				return (
					<div key={value} className="section-view__row">
						{this.flats(value)}
					</div>
				)
			})
	}

	flats = (floor) => {
		const { flats, active } = this.props
		if (!flats) return null
		const flats_res = flats

		flats_res[floor].sort((a, b) => {
			return a.number - b.number
		})

		return flats_res[floor].map((flat) => {
			return (
				<div key={flat.id} className={flat.id === active ? 'section-view__cell active' : 'section-view__cell'}>
					<div className="section-view__num disable">
						<span />
					</div>
				</div>
			)
		})
	}
}

export default connect(
	(state, props) => ({
		loaded: state.flats.loaded,
		flats: sectionFlatsMapSelector(state, props)
	}),
	{}
)(Scheme)
