import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'simplebar'
import 'simplebar/dist/simplebar.min.css'
import Row from './row'
import { sortBank } from '../../actions'
import { hit } from '../common/metrics'
import $ from 'jquery'

class Table extends Component {
	state = {
		order: {
			rate: 'none',
			payment: 'none',
			term: 'none',
			family: 'none'
		}
	}
	componentDidMount() {
		let header_height = $('.header .box').outerHeight()
		let header__area = $('.header__area')
		if (header__area.length) {
			header_height = header__area.outerHeight()
		}
		let fixed_banks_tr = $('.fixed-banks .fixed-tr')
		let fixed_banks_height = fixed_banks_tr.outerHeight()
		let fixed_banks = $('.fixed-banks')
		let banks_head_top = fixed_banks.offset().top - header_height
		let win = $(window)
		let st = 0

		// Internet Explorer 6-11
		const isIE = /*@cc_on!@*/ false || !!document.documentMode

		// Edge 20+
		const isEdge = !isIE && !!window.StyleMedia

		this.resize()
		window.addEventListener('resize', this.resize)
		window.addEventListener('scroll', this.scroll)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.resize)
		window.removeEventListener('scroll', this.scroll)
	}
	resize = () => {
		let header_height = $('.header .box').outerHeight()
		let header__area = $('.header__area')
		if (header__area.length) {
			header_height = header__area.outerHeight()
		}
		let fixed_banks_tr = $('.fixed-banks .fixed-tr')
		let fixed_banks_height = fixed_banks_tr.outerHeight()
		let fixed_banks = $('.fixed-banks')
		let banks_head_top = fixed_banks.offset().top - header_height
		let win = $(window)
		let st = 0

		// Internet Explorer 6-11
		const isIE = /*@cc_on!@*/ false || !!document.documentMode

		// Edge 20+
		const isEdge = !isIE && !!window.StyleMedia

		this.fix_banks_head()

		if (header__area.length) {
			header_height = header__area.outerHeight()
		} else {
			header_height = $('.header .box').outerHeight()
		}
		fixed_banks_height = fixed_banks_tr.outerHeight()
		banks_head_top = fixed_banks.offset().top - header_height
		if (fixed_banks.hasClass('fixed')) {
			fixed_banks.attr('style', '')
			fixed_banks_tr.attr('style', '')
			this.fix_banks_head()
		}
	}
	scroll = () => {
		if (this.props.items.length) {
			let header_height = $('.header .box').outerHeight()
			let header__area = $('.header__area')
			if (header__area.length) {
				header_height = header__area.outerHeight()
			}
			let fixed_banks_tr = $('.fixed-banks .fixed-tr')
			let fixed_banks_height = fixed_banks_tr.outerHeight()
			let fixed_banks = $('.fixed-banks')
			let banks_head_top = fixed_banks.offset().top - header_height
			let win = $(window)
			let st = 0

			// Internet Explorer 6-11
			const isIE = /*@cc_on!@*/ false || !!document.documentMode

			// Edge 20+
			const isEdge = !isIE && !!window.StyleMedia

			st = win.scrollTop()
			if (isIE || isEdge) {
				fixed_banks_tr.css({
					top: isEdge ? $(window).scrollTop() - header_height - 50 : header_height,
					left: isIE && !isEdge ? fixed_banks.offset().left : '',
					width: fixed_banks.outerWidth()
				})
			}
			if (st > banks_head_top && st > 0) {
				this.fix_banks_head()
				fixed_banks.addClass('fixed')
			} else {
				fixed_banks.removeClass('fixed')
				fixed_banks.attr('style', '')
				fixed_banks_tr.attr('style', '')
			}
		}
	}

	componentDidUpdate() {
		if (!this.props.items.length) return false
		let header_height = $('.header .box').outerHeight()
		let header__area = $('.header__area')
		if (header__area.length) {
			header_height = header__area.outerHeight()
		}
		let fixed_banks_tr = $('.fixed-banks .fixed-tr')
		let fixed_banks_height = fixed_banks_tr.outerHeight()
		let fixed_banks = $('.fixed-banks')
		let banks_head_top = fixed_banks.offset().top - header_height
		let win = $(window)
		let st = 0

		// Internet Explorer 6-11
		const isIE = /*@cc_on!@*/ false || !!document.documentMode

		// Edge 20+
		const isEdge = !isIE && !!window.StyleMedia

		$('.fixed-banks-out').scroll(() => {
			let left = $('.fixed-banks-out').scrollLeft() - 30
			fixed_banks_tr.css('left', -left)
		})
	}

	fix_banks_head() {
		let header_height = $('.header .box').outerHeight()
		let header__area = $('.header__area')
		if (header__area.length) {
			header_height = header__area.outerHeight()
		}
		let fixed_banks_tr = $('.fixed-banks .fixed-tr')
		let fixed_banks_height = fixed_banks_tr.outerHeight()
		let fixed_banks = $('.fixed-banks')
		let banks_head_top = fixed_banks.offset().top - header_height
		let win = $(window)
		let st = 0

		// Internet Explorer 6-11
		const isIE = /*@cc_on!@*/ false || !!document.documentMode

		// Edge 20+
		const isEdge = !isIE && !!window.StyleMedia

		fixed_banks.css({
			'padding-top': fixed_banks_height
		})
		if (!fixed_banks_tr.attr('style')) {
			fixed_banks.find('th').attr('style', '')
			fixed_banks.find('td').attr('style', '')

			fixed_banks
				.find('tbody')
				.find('tr')
				.eq(0)
				.find('td')
				.each(function(index) {
					let width = $(this).outerWidth()
					if (
						width <
						fixed_banks_tr
							.find('th')
							.eq(index)
							.outerWidth()
					) {
						width = fixed_banks_tr
							.find('th')
							.eq(index)
							.outerWidth()
					}
					fixed_banks_tr
						.find('th')
						.eq(index)
						.css('min-width', width)
					$(this).css('min-width', width)

					fixed_banks_tr
						.find('th')
						.eq(index)
						.outerWidth(width)
					$(this).outerWidth(width)
				})
			fixed_banks_tr.css({
				//'top':$(window).scrollTop()+header_height - 10,
				top: isEdge ? $(window).scrollTop() - header_height - 50 : header_height,
				left: fixed_banks.offset().left,
				width: fixed_banks.outerWidth()
			})
		}
	}

	render() {
		if (!this.props.items.length) return null
		const { order } = this.state
		return (
			<div className={'fixed-banks-out'}>
				<table className={'fixed-banks'}>
					<thead>
						<tr className={'fixed-tr'}>
							<th>Банк</th>
							<th
								sort="rate"
								order={order.rate}
								className={'soter-header sort-' + order.rate}
								onClick={this.handleSort}
							>
								<span>Ставка</span>
							</th>
							{!this.props.commerce && (
								<th
									sort="family"
									order={order.family}
									className={'soter-header sort-' + order.family}
									onClick={this.handleSort}
								>
									<span>Семейная ипотека</span>
								</th>
							)}
							<th
								sort="payment"
								order={order.payment}
								className={'soter-header sort-' + order.payment}
								onClick={this.handleSort}
							>
								<span>Первоначальный взнос</span>
							</th>
							<th
								sort="term"
								order={order.term}
								className={'soter-header sort-' + order.term}
								onClick={this.handleSort}
							>
								<span>Срок {this.props.detail ? 'кредитования' : 'кредита'}</span>
							</th>
						</tr>
					</thead>
					<tbody>{this.rows}</tbody>
				</table>
			</div>
		)
	}

	get rows() {
		return this.props.items.map((item, index) => {
			if (this.props.visibleLength && index > this.props.visibleLength) return null
			return (
				<Row
					key={item.id}
					item={item}
					detail={this.props.detail}
					bank={this.props.bank || this.props.items[0]}
				/>
			)
		})
	}

	handleSort = (ev) => {
		const field = ev.currentTarget.getAttribute('sort')
		const order = ev.currentTarget.getAttribute('order') === 'desc' ? 'asc' : 'desc'

		this.props.sortBank(field, order)

		this.setState({
			order: {
				rate: 'none',
				payment: 'none',
				term: 'none',
				family: 'family',
				[field]: this.state.order[field] === 'desc' ? 'asc' : 'desc'
			}
		})
	}
}

export default connect(null, { sortBank })(Table)
