import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import AboutMenuList from './about-menu-list'
import AboutPreviewList from './about-prev'
import AboutSliderList from './about-slider'
import AboutFlatChooseList from './about-choose-flat'
import AboutAdvList from './about-advants'
import Loader from '../common/loader'
import { loadAbout } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'

class About extends Component {
	componentDidMount() {
		document.title = 'Все о ЖК «Новое Бутово»'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Жилой комплекс «Новое Бутово» отличается хорошей транспортной доступностью — рядом находятся магистрали и развязки, в пешей доступности от ЖК располагаются остановки общественного транспорта, станция метро «Бунинская аллея». В ближайшем будущем рядом с жилым комплексом появится маршрут скоростного трамвая'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadAbout } = this.props
		if (!loading && !loaded) loadAbout()
	}
	render() {
		const { loading, loaded, about, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<section className="content__block about">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>О проекте</h1>
						<div className="breadcrumb__links">
							<NavLink to="/">Главная</NavLink> / О проекте
						</div>
					</div>
				</div>

				{!mobile && <AboutMenuList />}
				<Fade>
					<AboutPreviewList block={about.block} />
				</Fade>
				<AboutSliderList slider={about.slider} mobile={mobile} />
				<AboutFlatChooseList />
				<AboutAdvList />
			</section>
		)
	}
}

export default connect(
	(state) => ({
		loading: state.about.loading,
		loaded: state.about.loaded,
		about: state.about.data,
		mobile: state.mobile
	}),
	{ loadAbout }
)(About)
