import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ScrollTop from '../common/scroll-top'
import Panel from '../realty/panel'
import Loader from '../common/loader'
import Button from '../common/button'
import Fade from 'react-reveal/Fade'
import { loadMore } from '../../actions'
import { filtratedFlatsSelector, filtersSelector } from '../../selectors'

import AboutAdvList from '../About/about-advants'
import Additional from '../realty/additional'
import Filters from '../flats-filter'
import Table from '../table'
import { hit } from '../common/metrics'

class FlatList extends Component {
	componentDidMount() {
		document.title =
			'ЖК «Новое Бутово». Подобрать квартиру по параметрам в Москве, 15 минут пешком до метро Бунинская аллея.'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Жилой комплекс «Новое Бутово» - это уютный микрорайон в самом экологичном месте столицы. Здесь Вы сможете подобрать себе квартиру по следующим параметрам: корпус, количество комнат, площадь, этаж, стоимость, отделка'
			)
		hit(window.location.pathname)
	}
	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="params">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Подобрать квартиру</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> / По параметрам
						</div>
					</div>
				</div>
				<div className="content">
					<Panel />
					<Fade>
						<div>
							<Filters />
							{this.body}
						</div>
					</Fade>
				</div>
				<Additional />
				<AboutAdvList />
			</div>
		)
	}

	get body() {
		const { flats, visibleLength, mobile } = this.props

		return (
			<div>
				<div className="flats-result-info">
					{/*
					{this.declOfNum(flats.length, ['Найдена', 'Найдены', 'Найдено'])} <span>{flats.length} </span>{' '}
					{this.declOfNum(flats.length, ['свободная', 'свободные', 'свободных'])}{' '}
					{this.declOfNum(flats.length, ['квартира', 'квартиры', 'квартир'])}
				*/}
				</div>
				<div className="flat-list">
					<Table flats={flats} visibleLength={visibleLength} />
					{this.btn}
				</div>
			</div>
		)
	}

	get btn() {
		if (this.props.visibleLength >= this.props.flats.length - 1) return null
		return (
			<div className="btn-wrap">
				<Button onClick={this.props.loadMore}>Показать еще</Button>
			</div>
		)
	}

	declOfNum = (number, titles) => {
		var cases = [2, 0, 1, 1, 1, 2]
		return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
	}
}

export default connect(
	(state) => ({
		loading: state.flats.loading,
		loaded: state.flats.loaded,
		flats: filtratedFlatsSelector(state),
		visibleLength: filtersSelector(state).visibleLength,
		mobile: state.mobile
	}),
	{ loadMore }
)(FlatList)
