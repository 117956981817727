import {
	SUCCESS,
	START,
	LOAD_PROGRESS,
	TOGGLE_PROGRESS_GALLERY,
	CHANGE_PROGRESS_YEAR,
	PLAY_PROGRESS_VIDEO,
	CHANGE_PROGRESS_MONTH,
	CHANGE_PROGRESS_CORPUS
} from '../constants'

const defaultObjects = {
	albums: [],
	videos: [],
	filter: {
		year: {
			value: '',
			label: 'Все года'
		},
		month: {
			value: '',
			label: 'Все месяцы'
		},
		corpus: {
			value: '',
			label: 'Все объекты'
		}
	},
	loading: false,
	loaded: false,
	openId: null,
	playId: null
}

export default (state = defaultObjects, action) => {
	const { type, response, payload } = action

	switch (type) {
		case LOAD_PROGRESS + START:
			return { ...state, loading: true }

		case LOAD_PROGRESS + SUCCESS:
			return { ...state, loading: false, loaded: true, albums: response.albums, videos: response.videos }

		case CHANGE_PROGRESS_CORPUS:
			return {
				...state,
				filter: {
					...state.filter,
					year: defaultObjects.filter.year,
					month: defaultObjects.filter.month,
					corpus: payload.selected
				}
			}

		case CHANGE_PROGRESS_YEAR:
			return {
				...state,
				filter: {
					...state.filter,
					month: defaultObjects.filter.month,
					year: payload.selected
				}
			}

		case CHANGE_PROGRESS_MONTH:
			return {
				...state,
				filter: { ...state.filter, month: payload.selected }
			}
		case TOGGLE_PROGRESS_GALLERY:
			return { ...state, openId: payload.id }

		case PLAY_PROGRESS_VIDEO:
			return { ...state, playId: payload.id }

		default:
			return state
	}
}
