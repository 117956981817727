import React, { Component } from 'react'

export default class Video extends Component {
	componentDidUpdate(oldProps) {
		const { play, el } = this.props
		if (play === oldProps.play) return

		el.id === play ? this.refs.vidRef.play() : this.refs.vidRef.pause()
	}

	render() {
		const { el, index } = this.props
		if (!el.video) return null
		return (
			<div className="video">
				<video width="100%" height="100%" muted ref="vidRef" poster={el.img} className={`video-${index}`}>
					<source src={el.video} type="video/mp4" />
				</video>
				<span
					value={el.id}
					className={this.props.play === el.id ? 'video-overlay hidden' : 'video-overlay ico-36'}
					onClick={this.handleClick}
				>
					<span className="video-overlay__title" dangerouslySetInnerHTML={{ __html: el.title }} />
				</span>
			</div>
		)
	}

	handleClick = (ev) => this.props.toggle(ev.target.getAttribute('value'))
}
