import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { changeDocsType, changeDocsCorpus, changeDocsYear } from '../../../actions'
import {
	docsTypeOptionsSelector,
	docsCorpusOptionsSelector,
	docsYearOptionsSelector,
	docsFilterSelector
} from '../../../selectors'

class Filter extends Component {
	render() {
		const { filter } = this.props
		return (
			<div className="docs-filter box">
				<Select
					className="select"
					classNamePrefix="select"
					options={[{ label: 'Все документы', value: '' }, ...this.typeOptions]}
					value={filter.type}
					onChange={this.hangleTypeChange}
					placeholder="Все документы"
				/>
				<Select
					className="select"
					classNamePrefix="select"
					options={[{ label: 'Все корпуса', value: '' }, ...this.corpusOptions]}
					value={filter.corpus}
					onChange={this.hangleCorpusChange}
					placeholder="Все корпуса"
				/>
				<Select
					className="select"
					classNamePrefix="select"
					options={[{ label: 'Все года', value: '' }, ...this.yearOptions]}
					value={filter.year}
					onChange={this.hangleYearChange}
					placeholder="Все года"
				/>
			</div>
		)
	}

	hangleTypeChange = (selected) => this.props.changeDocsType(selected)

	hangleCorpusChange = (selected) => this.props.changeDocsCorpus(selected)

	hangleYearChange = (selected) => this.props.changeDocsYear(selected)

	get typeOptions() {
		const { type } = this.props
		return Object.keys(type).map((el) => ({ label: type[el][0].type, value: el }))
	}

	get corpusOptions() {
		return Object.keys(this.props.corpus).map((el) => ({ label: `Корпус ${el}`, value: el }))
	}
	get yearOptions() {
		return Object.keys(this.props.year).map((el) => ({ label: el, value: el }))
	}
}

export default connect(
	(state) => ({
		type: docsTypeOptionsSelector(state),
		corpus: docsCorpusOptionsSelector(state),
		year: docsYearOptionsSelector(state),
		filter: docsFilterSelector(state)
	}),
	{ changeDocsType, changeDocsCorpus, changeDocsYear }
)(Filter)
