import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputMask from 'react-input-mask'
import Modal from 'react-modal'
//import Modal from 'react-responsive-modal';
// import 'react-responsive-modal/styles.css'
import { closeSubscribeForm, sendSubscribeForm } from '../../actions'

class SubscribeModal extends Component {
	state = {
		email: '',
		name: '',
		check: 0,
		checkForm: false
	}

	componentDidUpdate(oldProps) {
		if (this.props.form.sent !== oldProps.form.sent && this.props.form.sent) {
			this.setState({
				email: '',
				name: '',
				check: 0,
				checkForm: false
			})
		}
	}

	render() {
		return (
			<div>
				{this.form}
				{this.success}
			</div>
		)
	}

	get success() {
		const { form, closeSubscribeForm } = this.props
		return (
			<Modal closeTimeoutMS={300} isOpen={form.sent} className="modalwin modalwin--thanks layout-thx">
				<div onClick={closeSubscribeForm} className="overlay__modal" />
				<div onClick={closeSubscribeForm} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">
						Спасибо! <br /> Вы успешно подписаны
					</div>
				</div>
			</Modal>
		)
	}

	get form() {
		const { form, closeSubscribeForm, mobile } = this.props
		return (
			<Modal closeTimeoutMS={300} className="modalwin modalwin--callback mail-modal" isOpen={form.open}>
				<div onClick={() => this.close()} className="overlay__modal" />
				<div onClick={() => this.close()} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">Подписка на новости</div>
					<form onSubmit={this.handleSubmit}>
						<div className={this.getClassName('name')}>
							<input
								type="text"
								placeholder="Имя"
								value={this.state.name}
								onChange={this.handleChange('name')}
							/>
						</div>
						<div className={this.getClassName('email')}>
							<input
								type="text"
								placeholder="E-mail"
								value={this.state.email}
								onChange={this.handleChange('email')}
							/>
						</div>
						<div className={`modal__input--checkbox ${this.getClassName('check')}`}>
							<label>
								<input
									onChange={this.handleCheckboxChange('check')}
									value={this.state.check}
									checked={this.state.check === 1}
									type="checkbox"
								/>
								<span className="icon-check-symbol" />Я согласен на обработку персональных данных и с
								условиями пользовательского соглашения
							</label>
						</div>
						<div className="modal__input modal__input--but">
							<button type="submit">
								<i className="ico-4" />
								Отправить
							</button>
						</div>
					</form>
				</div>
			</Modal>
		)
	}

	close() {
		setTimeout(() => {
			document.getElementById('header').removeAttribute('style')
			document.querySelector('body').removeAttribute('style')
			document.getElementById('menu').removeAttribute('style')
			window.scrollTo(0, document.querySelector('body').getAttribute('data-scroll'))
		}, 300)
		this.props.closeSubscribeForm()
	}

	handleSubmit = (ev) => {
		ev.preventDefault()

		if (!this.isValidForm()) {
			this.setState({
				checkForm: true
			})
			return false
		}

		if (window.Comagic && typeof window.Comagic == 'object') {
			window.Comagic.addOfflineRequest({
				name: this.state.name,
				email: this.state.email
			})
		}

		this.props.sendSubscribeForm({
			name: this.state.name,
			email: this.state.email,
			form: 'Подписаться на новости',
			w: 'form',
			id: 'subscribe',
			rel: this.props.data.rel
		})
	}

	isValidForm = () => ['email', 'name', 'check'].every(this.isValidField)

	isValidField = (type) => {
		if (limits[type] && limits[type].regex) return limits[type].regex.test(this.state[type])
		if (limits[type] && limits[type].min) {
			return this.state[type].length > limits[type].min
		}

		if (type === 'check') return !!this.state[type]
	}

	getClassName = (type) => {
		return (!this.state[type].length && !this.state.checkForm) || this.isValidField(type)
			? 'modal__input'
			: 'modal__input error'
	}

	handleChange = (type) => (ev) => {
		const { value } = ev.target
		this.setState({
			[type]: value
		})
	}

	handleCheckboxChange = (type) => (ev) => {
		const { value } = ev.target
		this.setState({
			[type]: +value === 0 ? 1 : 0
		})
	}
}

const limits = {
	email: {
		regex: /^[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+\.[A-Za-z]+$/
	},
	name: {
		regex: /^[а-яА-Яa-zA-Z ]+$/
	}
}

export default connect(
	(state) => ({
		data: state.form.data,
		form: state.form.subscribe,
		formPlaceType: state.form.formPlaceType,
		mobile: state.mobile
	}),
	{ closeSubscribeForm, sendSubscribeForm }
)(SubscribeModal)
