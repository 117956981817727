import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AboutAdvList from '../About/about-advants'
import AboutFlatChooseList from '../About/about-choose-flat'

import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import $ from 'jquery'
import { hit } from '../common/metrics'

export default class Panorama extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Аэрофотосъемка'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Аэрофотосъемка процесса строительства жилого комплекса «Новое Бутово» 2016-2019 г.'
			)
		hit(window.location.pathname)

		if (!$('body').is('.ready')) {
			setTimeout(() => {
				$('body')
					.removeClass('loading')
					.addClass('ready')
			}, 600)
		}
	}
	render() {
		return (
			<div className="content__block">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Аэрофотосъемка</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / Аэрофотосъемка
						</div>
					</div>
					<Fade>
						<div className="pano-wrap">
							<iframe
								title="panorama"
								className="panorama"
								src="https://www.novostroy-m.ru/files/novos/panorams/msk/zhk_novoe_butovo_spec/"
							/>
						</div>
					</Fade>
				</div>
				<AboutFlatChooseList />
				<AboutAdvList />
			</div>
		)
	}
}
