import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Loader from '../common/loader'
import Panel from '../realty/panel'
import Table from '../table'
import accounting from 'accounting'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import Scheme from './corpus-scheme'

import { loadFlat, openMailForm, openRequestForm, openCallForm, replacePage, setFormPlaceType } from '../../actions'
import { Link } from 'react-router-dom'

import { similarSelector, viewedSelector } from '../../selectors'
import $ from 'jquery'
import MortgageBlock from '../mortgage/mortgage'

class Flat extends Component {
	state = {
		similar: true,
		gtmSent: false
	}

	componentDidMount() {
		const { loaded, loadFlat, detail, flat: id } = this.props

		if (!loaded || !detail[id]) loadFlat(id)

		if (detail[id]) this.sendGtm(id, detail[id]['price'])
	}

	sendGtm = (listing_id, listing_totalvalue) => {
		var dataLayer = window.dataLayer || []
		dataLayer.push({
			listing_id,
			listing_pagetype: 'offerdetail',
			listing_totalvalue
		})
		this.setState({ gtmSent: true })
	}

	componentDidUpdate(oldProps, oldState) {
		const { detail, flat: id, loaded, loadFlat, error, replacePage } = this.props
		if (detail[id] && !this.state.gtmSent) this.sendGtm(id, detail[id]['price'])
		if (error) return replacePage('/error')
		if (oldProps.flat === id) return
		if (!loaded || !detail[id]) loadFlat(id)
	}

	render() {
		const { loading, flat: id, detail, section, mobile } = this.props
		const flat = detail[id]
		if (loading || !flat) return <Loader />

		console.log(flat)

		return (
			<div className="flat">
				<ScrollTop />
				{this.path}
				<div className="content">
					<Fade>
						<div>
							<Panel />
							<div className="content">
								<div className="back">
									{mobile ? (
										<Link to={`/realty/genplan/${flat['corpus']}/${section}`} className="ico-6">
											<span>Назад к выбору квартиры</span>
										</Link>
									) : (
										<Link to={`/realty/genplan/${flat['corpus']}/`} className="ico-6">
											<span>Назад к выбору квартиры</span>
										</Link>
									)}
								</div>

								{this.body}
								{!mobile || <div className="flat__bottom2">{this.planBottom}</div>}
								<div className="table-title">Ипотека</div>
								<MortgageBlock cost={flat.price - flat.promotion} />
								{this.state.similar ? this.similar : this.viewed}
							</div>
						</div>
					</Fade>
				</div>
			</div>
		)
	}

	get viewed() {
		const { viewed, mobile } = this.props
		return (
			<div>
				<div className="flat-list">
					<div className="table-title">
						Вы смотрели
						{mobile || (
							<span className="table-title_small" onClick={this.handleChangeTable}>
								<span>Похожие квартиры</span>
							</span>
						)}
					</div>
					<Table flats={viewed} visibleLength={5} />
					{!mobile || (
						<span className="table-title_small" onClick={this.handleChangeTable}>
							<span>Похожие квартиры</span>
						</span>
					)}
				</div>
			</div>
		)
	}

	get similar() {
		const { similar, viewed, mobile } = this.props
		if (!similar.length)
			return (
				<div>
					<div className="flat-list">
						<div className="table-title">Вы смотрели</div>
						<Table flats={viewed} visibleLength={5} />
					</div>
				</div>
			)
		return (
			<div>
				<div className="flat-list">
					<div className="table-title">
						Похожие квартиры
						{mobile || (
							<span className="table-title_small" onClick={this.handleChangeTable}>
								<span>Вы смотрели</span>
							</span>
						)}
					</div>
					<Table flats={similar} visibleLength={5} />
					{!mobile || (
						<span className="table-title_small" onClick={this.handleChangeTable}>
							<span>Вы смотрели</span>
						</span>
					)}
				</div>
			</div>
		)
	}

	handleChangeTable = () => {
		this.setState({
			similar: !this.state.similar
		})
		if (this.props.mobile) {
			$('html, body').animate({
				scrollTop: $('.table-title').offset().top - 80
			})
		}
	}

	get plan() {
		const { flat: id, detail } = this.props
		const flat = detail[id]
		if (!flat.plan) return null

		return (
			<div className="plan-image">
				<img alt="" src={flat.plan} />
			</div>
		)
	}

	get path() {
		const { flat: id, detail } = this.props
		const flat = detail[id]

		return (
			<div className="box">
				<div className="breadcrumb">
					<h1 className={flat.promotion && +flat.promotion > 0 ? 'h1--promotion' : ''}>
						{flat['rooms'] === '0' ? 'Cтудия' : flat['rooms'] + ' - комнатная квартира '}{' '}
						<span>№ {flat['number']}</span>
						{flat.promotion && +flat.promotion > 0 ? (
							<div className="promotion-icon ico-64">Акция</div>
						) : null}
					</h1>
					<div className="breadcrumb__links">
						<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> /{' '}
						{flat['rooms'] === '0' ? 'Cтудия' : flat['rooms'] + ' - комнатная квартира '}{' '}
						<span>№ {flat['number']}</span>
					</div>
				</div>
			</div>
		)
	}

	handleRequestForm = () => {
		const { flat: id, detail } = this.props
		const flat = detail[id]
		this.props.openRequestForm(flat)
		this.props.setFormPlaceType('Страница квартиры')
	}

	openCallForm = () => {
		this.props.openCallForm()
		this.props.setFormPlaceType('Страница квартиры')
	}

	handleMailForm = () => {
		this.props.openMailForm(this.props.flat, 'flatplan')
		this.props.setFormPlaceType('Страница квартиры')
	}

	get body() {
		const { flat: id, detail, section, mobile } = this.props
		const flat = detail[id]
		// if(!flat.price) return <div className="flat__body"><div className="flat__right"><div className="flat__price"><p>Продана</p></div></div></div>
		return (
			<div className="flat__body">
				<div className="flat__left">
					{this.plan}

					<div className="flat__bottom">
						{mobile || this.planBottom}
						<div className="compass" />
					</div>
				</div>
				<div className="flat__right">
					<div className="flat__price">
						{flat.promotion && +flat.promotion > 0 ? (
							<div>
								<div className="flat__price__top">
									<div className="flat__price__title">цена без скидки</div>
									{accounting.formatNumber(flat.price, 0, ' ')} <span>руб.</span>
								</div>
								<div className="flat__price__bottom">
									<div className="flat__price__title">
										скидка - {accounting.formatNumber(flat.promotion, 0, ' ')} руб.
									</div>
									{accounting.formatNumber(flat.price - flat.promotion, 0, ' ')} <span>руб.</span>
								</div>
							</div>
						) : flat.price ? (
							<div>
								{accounting.formatNumber(flat.price, 0, ' ')} <span>руб.</span>
							</div>
						) : (
							<p>Продана</p>
						)}
					</div>
					<div className="flat__info">
						<div className="flat__info__item">
							<div className="flat__info__title">№ квартиры:</div>
							<div className="flat__info__body">{flat['number']}</div>
						</div>
						<div className="flat__info__item">
							<div className="flat__info__title">Корпус:</div>
							<div className="flat__info__body">{flat['corpus']}</div>
						</div>
						<div className="flat__info__item">
							<div className="flat__info__title">Этаж:</div>
							<div className="flat__info__body">{flat['floor']}</div>
						</div>
						{flat['rooms'] != 0 && (
							<div className="flat__info__item">
								<div className="flat__info__title">Количество комнат:</div>
								<div className="flat__info__body">{flat['rooms']}</div>
							</div>
						)}
						<div className="flat__info__item">
							<div className="flat__info__title">Общая площадь:</div>
							<div className="flat__info__body">
								{accounting.formatNumber(flat['square'], 0, '')} м<sup>2</sup>
							</div>
						</div>
						<div className="flat__info__item">
							<div className="flat__info__title">Отделка:</div>
							<div className="flat__info__body">{flat['decoration'] == 1 ? 'Да' : 'Нет'}</div>
						</div>
						<div className="flat__info__item">
							<div className="flat__info__title">Преимущества:</div>
							<div className="flat__info__body flat__info__body">
								{flat['utp'].map((el, index) => (
									<p key={'utp' + index}>{el}</p>
								))}
							</div>
						</div>
					</div>
					{flat.price ? (
						<div className="flat__buttons--wrap">
							<div className="flat__buttons">
								<div className="flat__btn" onClick={this.handleRequestForm}>
									Оставить заявку
								</div>
								<div className="flat__btn" onClick={this.openCallForm}>
									Заказать звонок
								</div>
							</div>
							<div className="flat__links">
								<a
									className="flat__link ico-5"
									href={flat.files.pdf}
									target="_blank"
									rel="noopener noreferrer"
								>
									<span>Скачать PDF</span>
								</a>
								<div className="flat__link ico-4" onClick={this.handleMailForm}>
									<span>Отправить E-mail</span>
								</div>
							</div>
						</div>
					) : null}
					<div className="flat__description" dangerouslySetInnerHTML={{ __html: flat['description'] }} />
				</div>
			</div>
		)
	}

	get planBottom() {
		const { flat: id, detail, section } = this.props
		const flat = detail[id]

		return (
			<Fragment>
				<div className="flat__bottom__plan">
					<span className="flat__bottom__title">План этажа</span>
					<span
						className="flat__bottom__plan__bg"
						style={{ backgroundImage: `url(${flat['floor_plan']})` }}
					/>
				</div>
				<Link to={`/realty/genplan/${flat['corpus']}/${section}`} className="flat__bottom__plan">
					<span className="flat__bottom__title">План секции</span>
					<span className="flat__bottom__plan__bg">
						<Scheme section={section} corpus={flat['corpus']} active={id} />
					</span>
				</Link>
				<Link to={`/realty/genplan/${flat['corpus']}/${section}`} className="flat__bottom__plan">
					<span className="flat__bottom__title">Генплан</span>
					<span
						className="flat__bottom__plan__bg flat__bottom__plan__bg--genplan"
						style={{ backgroundImage: `url(/img/genplan_mini/${flat['corpus']}_${section}.svg)` }}
					/>
				</Link>
			</Fragment>
		)
	}
}

export default connect(
	(state, ownProps) => ({
		loading: state.flats.loadingFlat,
		loaded: state.flats.loadedFlat,
		detail: state.flats.detail,
		similar: similarSelector(state, ownProps),
		viewed: viewedSelector(state),
		error: state.flats.error,
		mobile: state.mobile
	}),
	{ loadFlat, openMailForm, openRequestForm, openCallForm, replacePage, setFormPlaceType }
)(Flat)
