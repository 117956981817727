import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'simplebar'
import 'simplebar/dist/simplebar.min.css'
import Row from './row'
import { sortCommerceFlats } from '../../../actions'

class Table extends Component {
	state = {
		order: {
			number: 'none',
			corpus: 'none',
			square: 'none',
			price: 'none',
			promotion: 'none'
		}
	}

	render() {
		if (!this.props.flats.length) return null
		const { order } = this.state

		return (
			<div data-simplebar>
				<table>
					<thead>
						<tr>
							<th
								sort="number"
								order={order.number}
								className={'soter-header sort-' + order.number}
								onClick={this.handleSort}
							>
								<span>№ помещения</span>
							</th>
							<th
								sort="corpus"
								order={order.corpus}
								className={'soter-header sort-' + order.corpus}
								onClick={this.handleSort}
							>
								<span>Корпус</span>
							</th>
							<th
								sort="square"
								order={order.square}
								className={'soter-header sort-' + order.square}
								onClick={this.handleSort}
							>
								<span>
									Площадь, м<sup>2</sup>
								</span>
							</th>
							<th>Отделка</th>
							<th
								sort="price"
								order={order.price}
								className={'soter-header sort-' + order.price}
								onClick={this.handleSort}
							>
								<span>Стоимость, руб.</span>
							</th>
							<th
								sort="promotion"
								order={order.promotion}
								className={'soter-header sort-' + order.promotion}
								onClick={this.handleSort}
							>
								<span>Акция, руб.</span>
							</th>
							<th>Скачать PDF</th>
						</tr>
					</thead>
					<tbody>{this.flats}</tbody>
				</table>
			</div>
		)
	}

	get flats() {
		return this.props.flats.map((flat, index) => {
			if (this.props.visibleLength && index > this.props.visibleLength) return null

			return <Row key={flat.id} flat={flat} />
		})
	}

	handleSort = (ev) => {
		const field = ev.currentTarget.getAttribute('sort')
		const order = ev.currentTarget.getAttribute('order') === 'desc' ? 'asc' : 'desc'

		this.props.sortCommerceFlats(field, order)

		this.setState({
			order: {
				number: 'none',
				corpus: 'none',
				square: 'none',
				price: 'none',
				promotion: 'none',
				[field]: this.state.order[field] === 'desc' ? 'asc' : 'desc'
			}
		})
	}
}

export default connect(null, { sortCommerceFlats })(Table)
