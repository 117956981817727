import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader from '../common/loader'
import { loadFaq, getPage } from '../../actions'
import { faqSelector } from '../../selectors'
import Fade from 'react-reveal/Fade'
import ScrollTop from '../common/scroll-top'
import { hit } from '../common/metrics'
import $ from 'jquery'

class Faq extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Вопросы и ответы. Страница ' + this.props.page
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Здесь Вы можете ознакомиться с вопросами и ответами ЖК «Новое Бутово». ' +
					this.props.page +
					' страница'
			)
		hit(window.location.pathname)

		const { loading, loaded, loadFaq } = this.props
		if (!loading && !loaded) loadFaq()
	}

	render() {
		const { loading, loaded, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />
		return (
			<div className="content__block faq">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Вопросы и ответы</h1>
						<div className="breadcrumb__links">
							<NavLink to="/">Главная</NavLink> / Вопросы и ответы
						</div>
					</div>
					<Fade>
						<div className="faq__body"> {this.body} </div>
					</Fade>
					<div className="news__paginator">{this.paginator}</div>
				</div>
			</div>
		)
	}

	get body() {
		const { page, faq } = this.props
		const offset = (page - 1) * limit
		return faq
			.filter((el, i) => !page || (i >= offset && i < offset + limit))
			.map((item) => {
				return (
					<div key={item.id} className="faq__item">
						<div className="faq__title" onClick={(e) => this.open(e)}>
							<span dangerouslySetInnerHTML={{ __html: item['question'] }} />
						</div>
						<div className="faq__content" dangerouslySetInnerHTML={{ __html: item['answer'] }} />
					</div>
				)
			})
	}

	get paginator() {
		const total = this.props.faq.length
		if (total <= limit) return null
		return new Array(Math.floor((total - 1) / limit) + 1).fill().map((_, i) => (
			<NavLink key={i} to={`/faq/${i + 1}`}>
				<span>{i + 1}</span>
			</NavLink>
		))
	}

	open = (e) => {
		var title = $(e.target)
		var item = title.closest('.faq__item')
		var content = item.find('.faq__content')
		if (!item.hasClass('faq__item_active')) {
			$('.faq__content').slideUp()
			$('.faq__item').removeClass('faq__item_active')
			item.addClass('faq__item_active')
			content.slideDown()
			if (this.props.mobile)
				setTimeout(() => {
					$('html, body').animate({ scrollTop: $('.faq__item_active').offset().top - $('.header').height() })
				}, 500)
		} else {
			item.removeClass('faq__item_active')
			content.slideUp()
		}
	}
}

const limit = 9

export default connect(
	(state, props) => ({
		faq: faqSelector(state),
		loading: state.faqPage.loading,
		loaded: state.faqPage.loaded,
		mobile: state.mobile
	}),
	{ loadFaq, getPage }
)(Faq)
