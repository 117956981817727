import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { closePopupScheme } from '../../../actions'

class PopupScheme extends Component {
	render() {
		const { popup } = this.props
		return (
			<Modal
				closeTimeoutMS={300}
				className="modalwin modalwin--scheme"
				isOpen={popup.open}
				onAfterOpen={this.afterOpenModal}
				onRequestClose={this.closeModal}
			>
				<div onClick={this.closeModal} className="overlay__modal" />
				<div onClick={this.closeModal} className="close__modal" />
				<div className="modalwin__box">
					<div className="modalwin__row">
						<div className="modalwin__top">
							<div className="modal__title">
								Схема прохождения трассы
								<br />
								Воскресенское-Каракашево-Щербинка
							</div>
							<p>
								Началось строительство дороги от поселения Воскресенское до города Щербинка в Новой
								Москве. Она обеспечит перпендикулярное направление между Калужским и Варшавским шоссе.
								Длина магистрали составит 11 км. На ней будет по две полосы для движения в каждом
								направлении. Разрешенная максимальная скорость по автодороге составит 70 км в час. Для
								поворотов налево построят дополнительные полосы. Для пешеходов также обустроят широкие
								тротуары, на остановках общественного транспорта появятся заездные «карманы». Дорогу
								оборудуют современной автоматизированной системой управления движением транспорта и
								установят 16 светофоров.
							</p>
						</div>
					</div>
					<div className="modalwin__row">
						<div className="modalwin__bottom">
							<div>
								<img src="/img/commerce/scheme.jpg" />
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	}

	afterOpenModal = () => {
		let outer = document.createElement('div')
		outer.style.visibility = 'hidden'
		outer.style.width = '100px'
		outer.style.msOverflowStyle = 'scrollbar'
		document.body.appendChild(outer)
		let widthNoScroll = outer.offsetWidth
		outer.style.overflow = 'scroll'
		let inner = document.createElement('div')
		inner.style.width = '100%'
		outer.appendChild(inner)
		let widthWithScroll = inner.offsetWidth
		outer.parentNode.removeChild(outer)
		document.getElementsByClassName('header')[0].style.paddingRight = widthNoScroll - widthWithScroll + 'px'
		if (!this.props.mobile)
			document.getElementsByTagName('menu')[0].style.right = widthNoScroll - widthWithScroll + 'px'
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop
		if (this.props.mobile) {
			document.body.setAttribute('data-scroll', scrollTop)
			document.body.style.position = 'fixed'
			document.body.style.top = -scrollTop + 'px'
		}
		document.body.style.paddingRight = widthNoScroll - widthWithScroll + 'px'
	}

	closeModal = () => {
		this.props.closePopupScheme()
		setTimeout(() => {
			document.getElementsByClassName('header')[0].removeAttribute('style')
			if (!this.props.mobile) document.getElementsByTagName('menu')[0].removeAttribute('style')
			document.body.removeAttribute('style')
			if (this.props.mobile) {
				window.scrollTo(0, document.body.getAttribute('data-scroll'))
			}
		}, 300)
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile,
		popup: state.commerce.popupSheme
	}),
	{ closePopupScheme }
)(PopupScheme)
