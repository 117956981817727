import { SORT_COMMERCE_FLATS } from '../constants'

const defaultFilters = {
	visibleLength: 10,
	sort: {
		field: null,
		order: 'desc'
	}
}

export default (filters = defaultFilters, action) => {
	const { type, payload } = action

	switch (type) {
		case SORT_COMMERCE_FLATS:
			return {
				...defaultFilters,
				sort: {
					field: payload.field,
					order: payload.order
				}
			}

		default:
			return filters
	}
}
