import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

export default class Mortgage extends Component {
	render() {
		return (
			<Fragment>
				<span className="home__item__bg" style={{ backgroundImage: `url(${this.props.el.img})` }}></span>
				<div className="main__item--comfort main__item--tc">
					<div className="main__item--title" dangerouslySetInnerHTML={{ __html: this.props.el.title }} />
					<a
						className="main__item--but main__item--but__white"
						href="https://novobutovo.ru/news/15-korpus-poluchil-razreshenie-na-vvod-v-ekspluatatsiyu"
						target="_blank"
					>
						<i className="icon-i_house"></i>
						<p>Подробнее</p>
					</a>
				</div>
			</Fragment>
		)
	}
}
