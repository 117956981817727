import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { getPage } from '../../actions'

class Select2 extends Component {
	handleChange = (selected) => {
		this.props.getPage('/parking/' + selected.value)
	}

	handleMobileChange = (ev) => {
		this.props.getPage('/parking/' + ev.target.value)
	}

	render() {
		return this.props.mobile ? (
			<div className="select-mobile">
				<select value={this.props.id} onChange={this.handleMobileChange}>
					{this.mobileOptions}
				</select>
				<span className="select__indicator select__indicator--mobile" />
			</div>
		) : (
			<Select
				className="select"
				classNamePrefix="select"
				options={this.options}
				value={this.value}
				onChange={this.handleChange}
			/>
		)
	}

	get mobileOptions() {
		return this.props.list.map((el) => (
			<option key={el} value={el}>
				{el + ' этаж'}
			</option>
		))
	}

	get options() {
		return this.props.list.map((el) => ({ label: el + ' этаж', value: el }))
	}

	get value() {
		return this.props.list.map((el) => {
			if (el === this.props.id) {
				return {
					value: el,
					label: el + ' этаж'
				}
			}
		})
	}
}

export default connect(
	(state, props) => ({
		mobile: state.mobile
	}),
	{ getPage }
)(Select2)
