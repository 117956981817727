import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import $ from 'jquery'

class Sld extends Component {
	state = {
		index: 1
	}
	componentDidUpdate() {
		if (!this.props.initialSlide) return
		this.slider.slickGoTo(this.props.initialSlide, true)
	}

	componentDidMount() {
		this.setDots()
	}

	setDots = () => {
		const current = $(this.refs.container)
			.find('.slick-dots')
			.find('.slick-active')
		current.siblings().removeClass('slick-active-l1 slick-active-l2')
		current.next().addClass('slick-active-l1')
		current
			.next()
			.next()
			.addClass('slick-active-l2')
		current.prev().addClass('slick-active-l1')
		current
			.prev()
			.prev()
			.addClass('slick-active-l2')
	}

	render() {
		const { items } = this.props
		const options = {
			touchThreshold: 10000,
			dots: false,
			infinite: true,
			speed: 600,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			draggable: false,
			beforeChange: (current, next) => {
				if (this.props.video && this.props.stopVideo) {
					this.props.stopVideo(null)
				}
				if (this.props.counter && !this.props.options.fade) this.setState({ index: next + 1 })
			},
			afterChange: () => {
				this.setDots()
			},
			...this.props.options
		}

		return (
			<div ref="container" className={`${this.props.counter ? 'slider slider__counter' : 'slider'}`}>
				<Slider ref={(slider) => (this.slider = slider)} {...options}>
					{items || this.items}
				</Slider>
				{!this.props.counter || (
					<div className="slider__iterator">
						{this.state.index} / {this.props.items.length}
					</div>
				)}
			</div>
		)
	}

	get items() {
		const { images } = this.props
		if (!images) return null
		return images.map((img, index) => {
			return (
				<div className="slider__item" key={index}>
					<div>
						<div style={{ backgroundImage: `url(${img})` }} />
					</div>
				</div>
			)
		})
	}
}

export default Sld
