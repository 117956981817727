import React, { Component } from 'react'
import { connect } from 'react-redux'
import CorpusFilter from './corpus-filter'
import SquareFilter from './square-filter'
import PriceFilter from './price-filter'
import PromotionFilter from './promotion-filter'
import { clearCommerceFilter } from '../../../actions'

class Filters extends Component {
	render() {
		return (
			<div>
				<div className="filter">
					<div className="filter__field">
						<div className="filter__field__title">Корпус</div>
						<CorpusFilter />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">
							Площадь, м<sup>2</sup>
						</div>
						<SquareFilter />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Стоимость, млн руб.</div>
						<PriceFilter />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">&nbsp;</div>
						<PromotionFilter />
					</div>

					<div className="filter__field clear-filter" onClick={this.props.clearCommerceFilter}>
						<span>Сбросить</span>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(null, { clearCommerceFilter })(Filters)
