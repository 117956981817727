import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class HomeMap extends Component {
	render() {
		return (
			<div>
				<div className="map map_static" />
				<div className="main__item--map">
					<div className="main__item--map__in" dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
					<div className="main__item--map__but">
						<Link className="main__item--but main__item--but__white" to="/realty/params">
							<i className="icon-i_metro"> </i>
							<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
						</Link>
					</div>
				</div>
			</div>
		)
	}
}
