import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Photos extends Component {
	render() {
		return (
			<div>
				<div className="main__item--photo1 landscape"> </div>
				<div className="main__item--photo2 landscape"> </div>
				<div className="main__item--photo3 landscape"> </div>
				<div className="main__item--photo">
					<div className="main__item--title">
						<div dangerouslySetInnerHTML={{ __html: this.props.el.title }} />
						<p dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
					</div>
					<Link className="main__item--but main__item--but__brush" to="/realty/decoration">
						<i className="icon-i_brush"> </i>
						<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
					</Link>
				</div>
			</div>
		)
	}
}
