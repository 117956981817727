import React, { Component } from 'react'
import { connect } from 'react-redux'
import './style.scss'
import DraggableCore from 'react-draggable'
import Map from './map'
import News from './news'
import Video from './video'
import Photos from './photos'
import Comfort from './comfort'
import Ecology from './ecology'
import Life from './life'
import FindFlat from './findflat'
import FlatPrice from './flatprice'
import Mortgage from './mortgage'
import Tc from './tc'
import Ny from './ny'
import Ny2 from './ny2'
import Ny3 from './ny3'
import PresentSingle from './present-singel'

import { itemsSelector, itemsSelectorFix, itemsSelector214, itemsSelector215 } from '../../selectors'

let URI = document.location.hostname === 'localhost' ? 'http://butovo.multicontent.ru/' : ''

let _scrollTimeout = null

class Main extends Component {
	state = {
		width: null,
		height: null,
		maxLeft: null,
		maxTop: null,
		current: null,
		left: null,
		right: null,
		top: null,
		bottom: null,
		top_left: null,
		top_right: null,
		bottom_left: null,
		bottom_right: null,
		grid: 0,
		position: { left: 0, top: 0 },
		animated: []
	}

	componentDidMount() {
		window.addEventListener('resize', this._onResize)
		this.setState({ ...this.getSize() })
	}

	componentDidUpdate(oldProps) {
		if (this.props.items !== oldProps.items) {
			window.addEventListener('resize', this._onResize)
			this.setState({ ...this.getSize() })
		}
	}

	render() {
		const { items, items2 } = this.props
		const { height, width, grid, position, top, left, right, bottom } = this.state
		const dragHandlers = {
			onDrag: this.onDrag,
			cancel: '.owl-carousel, .slick-slider, a, button',
			position: { x: parseInt(position.left), y: parseInt(position.top) }
		}

		return (
			<div className="home">
				<div>
					<DraggableCore {...dragHandlers}>
						<div
							onWheel={this.mouseWhell}
							className="home__container"
							style={{
								width: width * grid,
								height: height * grid
							}}
						>
							{this.list}
						</div>
					</DraggableCore>
				</div>
				{top === null || (
					<span type="top" onClick={this.handleArrowClick} className="home__arrow home__arrow__top">
						<i className="ico-11" />
						{!items[top]['thumbs'] || (
							<span
								className="home__arrow__thumbs home__arrow__thumbs__top"
								style={{ backgroundImage: `url(${URI}${items[top]['thumbs']})` }}
							/>
						)}
					</span>
				)}
				{bottom === null || (
					<span type="bottom" onClick={this.handleArrowClick} className="home__arrow home__arrow__bottom">
						<i className="ico-9" />
						{!items[bottom]['thumbs'] || (
							<span
								className="home__arrow__thumbs home__arrow__thumbs__bottom"
								style={{ backgroundImage: `url(${URI}${items[bottom]['thumbs']})` }}
							/>
						)}
					</span>
				)}
				{left === null || (
					<span type="left" onClick={this.handleArrowClick} className="home__arrow home__arrow__left">
						<i className="ico-10" />
						{!items[left]['thumbs'] || (
							<span
								className="home__arrow__thumbs  home__arrow__thumbs__left"
								style={{ backgroundImage: `url(${URI}${items[left]['thumbs']})` }}
							/>
						)}
					</span>
				)}
				{right === null || (
					<span type="right" onClick={this.handleArrowClick} className="home__arrow  home__arrow__right">
						<i className="ico-8" />
						{!items[right]['thumbs'] || (
							<span
								className="home__arrow__thumbs  home__arrow__thumbs__right"
								style={{ backgroundImage: `url(${URI}${items[right]['thumbs']})` }}
							/>
						)}
					</span>
				)}
			</div>
		)
	}

	setBox = (side) => {
		const current = this.state[side]

		if (current === null || this.state.dragging) return

		clearTimeout(_scrollTimeout)
		_scrollTimeout = setTimeout(() => {
			this.setState({ dragging: false })
		}, 1000)

		this.setState({
			dragging: true,
			position: this.state.positions[current],
			current,
			animated: [...this.state.animated, current],
			...this.getIdx(current)
		})
	}

	mouseWhell = (e) => {
		e.preventDefault()
		const side = this.getSide(e, true)
		if (!side) return
		this.setBox(side)
	}

	onDrag = (e, ui) => {
		const side = this.getSide(ui)
		if (!side) return
		this.setBox(side)
	}

	getSide = (e, reverse) => {
		let side = null
		if (Math.abs(e.deltaY) > 3 && Math.abs(e.deltaX) < 3) {
			if (e.deltaY < 0) {
				side = reverse ? 'top' : 'bottom'
			}
			if (e.deltaY > 0) {
				side = reverse ? 'bottom' : 'top'
			}
		}

		if (Math.abs(e.deltaX) > 3 && Math.abs(e.deltaY) < 3) {
			if (e.deltaX < 0) {
				side = reverse ? 'left' : 'right'
			}
			if (e.deltaX > 0) {
				side = reverse ? 'right' : 'left'
			}
		}

		if (Math.abs(e.deltaX) > 3 && Math.abs(e.deltaY) > 3) {
			if (e.deltaX < 0 && e.deltaY < 0) {
				side = reverse ? 'top_left' : 'bottom_right'
			}
			if (e.deltaX > 0 && e.deltaY < 0) {
				side = reverse ? 'top_right' : 'bottom_left'
			}
			if (e.deltaX < 0 && e.deltaY > 0) {
				side = reverse ? 'bottom_left' : 'top_right'
			}
			if (e.deltaX > 0 && e.deltaY > 0) {
				side = reverse ? 'bottom_right' : 'top_left'
			}
		}
		return side
	}

	get list() {
		const { items, data, item214, item215 } = this.props
		const { height, width, current, animated } = this.state

		let el236

		return items.map((el, i) => {
			if (el.id === '215' || el.id === '214' || el.id === '248' || el.id === '247') return null
			return (
				<div key={i} className="home__item" style={{ width: width, height: height }}>
					<div
						className={
							current + 1 === i + 1 || animated.includes(i) ? `animated ${el.animation}` : el.animation
						}
					>
						{/*el.id !== '236' && el.id !== '269' && (
							<span className="home__item__bg" style={{ backgroundImage: `url(${URI}${el['img']})` }} />
						)*/}
						<span className="home__item__bg" style={{ backgroundImage: `url(${URI}${el['img']})` }} />

						{(el.id === '236' || el.id === '269') && <PresentSingle el={el} />}
						{(el.id === '202' || el.id === '244') && <Ny3 el={el} />}
						{(el.id === '213' || el.id === '228') && <Ny el={el} />}
						{(el.id === '212' || el.id === '229') && <Ny2 el={el} />}
						{el.type === 'mortgage' && <Mortgage el={el} />}
						{el.type === 'news' && <News news={data.news} />}
						{el.type === 'map' && <Map el={el} id={`map_${i + 1}`} />}
						{el.type === 'video' && <Video el={el} play={current + 1 === i + 1} />}
						{el.type === 'photos' && <Photos el={el} />}
						{el.type === 'comfort' && <Comfort el={el} />}
						{el.type === 'ecology' && <Ecology el={el} />}
						{el.type === 'life' && <Life el={el} />}
						{el.type === 'findflat' && <FindFlat el={el} />}
						{el.type === 'flatprice' && <FlatPrice el={el} />}
						{el.type === 'tc' && <Tc el={el} />}
					</div>
				</div>
			)
		})
	}

	handleArrowClick = (ev) => {
		const type = ev.currentTarget.getAttribute('type')
		const current = this.state[type]
		if (current === null) return false

		this.setState({
			current,
			position: this.state.positions[current],
			...this.getIdx(current)
		})
	}

	getSize = () => {
		const { items } = this.props
		const { innerWidth, innerHeight } = window
		const width = innerWidth - 0.25 * innerWidth // 25% - отступы по макету
		const height = width / 1.8 // ratio = 1300/720
		const grid = Math.ceil(Math.sqrt(items.length))
		const maxLeft = width * (grid - 1) - 0.25 * innerWidth
		const maxTop = height * (grid - 1) - (innerHeight - 120 - height) // 120 - высота шапки

		const current = Math.floor(items.length / 2)
		const positions = this.getPositions(grid, width, height, maxLeft, maxTop)

		return {
			width,
			height,
			maxLeft,
			maxTop,
			current,
			grid,
			positions,
			position: positions[current],
			...this.getIdx(current, grid)
		}
	}

	getIdx = (current, grid = this.state.grid) => {
		const { items } = this.props
		const top = items[current - grid] ? current - grid : null
		const bottom = items[current + grid] ? current + grid : null

		return {
			top,
			bottom,
			left: items[current - 1] && current % grid !== 0 ? current - 1 : null,
			right: items[current + 1] && (current + 1) % grid !== 0 ? current + 1 : null,
			top_left: items[top - 1] && top % grid !== 0 ? top - 1 : null,
			top_right: items[top + 1] && (top + 1) % grid !== 0 ? top + 1 : null,
			bottom_left: items[bottom - 1] && bottom % grid !== 0 ? bottom - 1 : null,
			bottom_right: items[bottom + 1] && (bottom + 1) % grid !== 0 ? bottom + 1 : null
		}
	}

	getPositions = (grid, width, height, maxLeft = this.state.maxLeft, maxTop = this.state.maxTop) => {
		const { items } = this.props

		return items.map((el, idx) => {
			const col = Math.ceil(idx % grid)
			const row = Math.floor(idx / grid)
			const top = -row * height + (window.innerHeight - 120 - height) / 2
			const left = -col * width + (0.25 * window.innerWidth) / 2

			return {
				top: maxTop && Math.abs(top) >= maxTop ? -maxTop : top >= 0 ? 0 : top,
				left: maxLeft && Math.abs(left) >= maxLeft ? -maxLeft : left >= 0 ? 0 : left
			}
		})
	}

	_onResize = () => {
		this.setState({ ...this.getSize() })
	}
}

export default connect(
	(state, props) => ({
		loading: state.home.loading,
		loaded: state.home.loaded,
		data: state.home.data,
		//items: itemsSelector(state),
		items: itemsSelectorFix(state),
		item214: itemsSelector214(state),
		item215: itemsSelector215(state)
	}),
	{}
)(Main)
