import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadPurchase } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Button from '../common/button'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'

class Purchase extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Условия покупки'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Вы можете приобрести жилье в «Новом Бутово», оформив ДДУ или договор купли-продажи, если дом уже построен и сдан. Способы оплаты: 100% оплата, беспроцентная рассрочка при первоначальном взносе от 50%, ипотека от крупнейших банков страны'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadPurchase } = this.props
		if (!loading && !loaded) loadPurchase()
	}
	render() {
		const { loading, loaded, purchase } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<section className="content__block purchase">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Условия покупки</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / Условия покупки
						</div>
					</div>
					<Fade>
						<div>
							<div className="purchase__block">{this.mortgage}</div>
							{purchase && purchase.promotions.length > 0 && (
								<>
									<div className="title">Акции</div>
									<div className="purchase__promotions">{this.promotions}</div>
									<div className="promotions__btn">
										<Button to="/purchase/promotions">Все акции</Button>
									</div>
								</>
							)}
						</div>
					</Fade>
				</div>
			</section>
		)
	}

	get mortgage() {
		const { mortgage } = this.props.purchase
		if (!mortgage) return null

		return (
			<div className="purchase__item">
				<div
					className="purchase__item__image"
					style={{ backgroundImage: `url(${this.props.mobile ? mortgage.mobile_img : mortgage.img})` }}
				/>
				<div className="purchase__item__body">
					<div className="purchase__item__title" dangerouslySetInnerHTML={{ __html: mortgage.title }} />
					<div className="purchase__item__text" dangerouslySetInnerHTML={{ __html: mortgage.text }} />
					<Button to="/purchase/mortgage/flats">подробнее</Button>
				</div>
			</div>
		)
	}

	// get mortgage() {
	// 	const { mortgage } = this.props.purchase
	// 	if (!mortgage) return null
	// 	return (
	// 		<div className="purchase__item purchase__item_mortgage">
	// 			<div
	// 				className="purchase__item__image"
	// 				style={{ backgroundImage: `url(${this.props.mobile ? mortgage.mobile_img : mortgage.img})` }}
	// 			/>
	// 			<div className="purchase__item__body">
	// 				<div className="purchase__item__title" dangerouslySetInnerHTML={{ __html: mortgage.title }} />
	// 				<div className="purchase__item__text" dangerouslySetInnerHTML={{ __html: mortgage.text }} />
	// 				<Button to="/purchase/mortgage">подробнее</Button>
	// 			</div>
	// 		</div>
	// 	)
	// }

	get payment() {
		const { payment } = this.props.purchase
		if (!payment) return null
		return (
			<div className="purchase__item purchase__item_payment">
				<div
					className="purchase__item__image"
					style={{ backgroundImage: `url(${this.props.mobile ? payment.mobile_img : payment.img})` }}
				/>
				<div className="purchase__item__body">
					<div className="purchase__item__title" dangerouslySetInnerHTML={{ __html: payment.title }} />
					<div className="purchase__item__text" dangerouslySetInnerHTML={{ __html: payment.text }} />
					<Button to="/purchase/payment">подробнее</Button>
				</div>
			</div>
		)
	}

	get promotions() {
		const { promotions } = this.props.purchase
		if (!promotions) return null
		return promotions.map((el) => (
			<Link to={`/purchase/promotions/${el.id}`} key={el.id} className="promotions__item">
				<div className="promotions__item__image">
					<span style={{ backgroundImage: `url(${el.img})` }} />
				</div>
				<div className="promotions__item__body">
					<div className="promotions__item__title">
						<span dangerouslySetInnerHTML={{ __html: el.title }} />
					</div>
					<div className="promotions__item__text" dangerouslySetInnerHTML={{ __html: el.text }} />
				</div>
			</Link>
		))
	}
}

export default connect(
	(state) => ({
		loading: state.purchase.loading,
		loaded: state.purchase.loaded,
		purchase: state.purchase.data,
		mobile: state.mobile
	}),
	{ loadPurchase }
)(Purchase)
