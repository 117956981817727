import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PriceFilter from './price-filter'
import Price from './price'
import ContributionFilter from './contribution-filter'
import Contribution from './contribution'
import TermFilter from './term-filter'
import Term from './term'
import Rate from './rate'
import RateFamily from './rate-family'
import { clearFilter, getPage, loadCommerce, loadFlats } from '../../actions'
import { filtratedMortgageSelector } from '../../selectors'
import { NavLink } from 'react-router-dom'
import accounting from 'accounting'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'

const links = {
	flats: {
		title: 'Жилая',
		icon: '13'
	},
	commerce: {
		title: 'Коммерческая',
		icon: '9'
	}
}

class Filters extends Component {
	componentDidMount() {
		const { loading, loadingFlats, loadCommerce, loadFlats } = this.props
		if (!loading) loadCommerce()
		if (!loadingFlats) loadFlats()
	}

	render() {
		const path = this.props.match.path
		const { loading, loaded, loadedFlats, mortgage } = this.props
		if (!loaded || !loadedFlats) return null

		return (
			<div>
				{!this.props.cost &&
					(!this.props.mobile ? (
						<div className="realty-panel">
							<div className="realty-panel__item">
								<NavLink
									to="/purchase/mortgage/flats"
									className={path.includes('flats') && 'active'}
									aria-current="page"
									onClick={this.handleScroll}
								>
									<span className="realty-panel__icon1 iconsnew-13">Жилая</span>
								</NavLink>
							</div>
							<div className="realty-panel__item">
								<NavLink to="/purchase/mortgage/commerce" onClick={this.handleScroll}>
									<span className="realty-panel__icon2 iconsnew-9">Коммерческая</span>
								</NavLink>
							</div>
							<div className="realty-panel__item"></div>
						</div>
					) : (
						this.mobilePanel
					))}
				{path.includes('commerce') ? (
					<div className="filter">
						<div className="filter__field filter__field-slider">
							<div className="filter__field__title">Стоимость, {!this.props.cost && 'млн'} руб.</div>
							{this.props.cost ? (
								<div className="filter__number">{accounting.formatNumber(this.props.cost, 0, ' ')}</div>
							) : (
								<PriceFilter />
							)}
						</div>
						<div className="filter__field filter__field-slider">
							<div className="filter__field__title">Первоначальный взнос, %</div>
							<ContributionFilter />
						</div>
						<div className="filter__field filter__field-slider">
							<div className="filter__field__title">Срок, лет</div>
							<TermFilter />
						</div>

						<div className="filter__field filter__field-slider">
							<div className="filter__field__title">Ежемесячный платёж, руб.</div>
							<div className="filter__number">{accounting.formatNumber(this.payment(), 0, ' ')}</div>
						</div>
					</div>
				) : (
					this.flatsFilter
				)}
			</div>
		)
	}

	get flatsFilter() {
		// if (!this.props.mortgage.commerce) return null
		return (
			<Fragment>
				<div className="filter rltc-filter">
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Стоимость, {!this.props.cost && 'млн'} руб.</div>
						{this.props.cost ? (
							<div className="filter__number">{accounting.formatNumber(this.props.cost, 0, ' ')}</div>
						) : (
							<Price />
						)}
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Первоначальный взнос, %</div>
						<Contribution />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Срок, мес.</div>
						<Term />
					</div>
				</div>
				<div className="filter m-filter rltc-filter2">
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Ставка, %</div>
						<Rate />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Льготная ставка, %</div>
						<RateFamily />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Ежемесячный платеж льготного периода, руб.</div>
						<div className="filter__number">
							{accounting.formatNumber(this.payment2()['preferential'], 0, ' ')}
						</div>
					</div>

					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Ежемесячный платёж, руб.</div>
						<div className="filter__number">
							{accounting.formatNumber(this.payment2()['monthly'], 0, ' ')}
						</div>
					</div>
				</div>
			</Fragment>
		)
	}

	handleScroll = (id) => {
		$('body').addClass('no-scroll')
		if (this.props.mobile) {
			setTimeout(() => {
				$('html, body').animate({ scrollTop: $('.select-mobile').offset().top - $('.header').height() }, 600)
			}, 100)
		} else {
			$('html, body').animate({ scrollTop: $('.realty-panel').offset().top - $('.header').height() }, 600)
		}
	}

	payment() {
		const { mortgage, filtrated } = this.props
		const bank = mortgage.bank || filtrated[0]
		let yearProsent // Годовая процентная ставка
		if (bank.id === '193') {
			yearProsent =
				mortgage.contribution >= 20 && mortgage.contribution < 30
					? 12.5
					: mortgage.contribution >= 30 && mortgage.contribution < 40
					? 12
					: 11.5
		} else if (bank.id === '200') {
			yearProsent = 13.99
		} else {
			yearProsent = mortgage.contribution >= 20 ? 13 : 12
		}
		let cost = this.props.cost || mortgage.price * 1000000, // стоимость
			ratePerPeriod = (yearProsent / 12) * 0.01, // Процентная ставка по ссуде
			numberOfPayments = mortgage.term * 12, // Срок, мес.
			presentValue = cost - (cost * mortgage.contribution) / 100 // Сумма займа

		let result = -this.pmt(ratePerPeriod, numberOfPayments, presentValue)
		return result
	}

	payment2() {
		const { mortgage } = this.props
		const C3 = this.props.cost || mortgage.price2 * 1000000
		const C4 = (C3 * mortgage.payment) / 100
		const C6 = C3 - C4
		const C7 = mortgage.rate
		const C8 = mortgage.family_rate
		const C9 = mortgage.term2

		const preferential = this.pmt(C8 / 12 / 100, C9, -C6)
		const monthly = this.pmt(C7 / 12 / 100, C9, -C6)
		return { preferential, monthly }
	}

	pmt(ratePerPeriod, numberOfPayments, presentValue, futureValue, type) {
		futureValue = typeof futureValue !== 'undefined' ? futureValue : 0
		type = typeof type !== 'undefined' ? type : 0
		if (ratePerPeriod != 0.0) {
			let q = Math.pow(1 + ratePerPeriod, numberOfPayments)
			return -(ratePerPeriod * (futureValue + q * presentValue)) / ((-1 + q) * (1 + ratePerPeriod * type))
		} else if (numberOfPayments != 0.0) {
			return -(futureValue + presentValue) / numberOfPayments
		}
		return 0
	}

	get mobilePanel() {
		const path = this.props.match.path
		const selected = path.includes('flats') ? 'flats' : 'commerce'
		return (
			<div className="select-mobile select-mobile--realty select-mobile--break">
				<span className="select-mobile__title">
					<span>{links[selected].title}</span>
				</span>
				<select value={'/purchase/mortgage/' + selected} onChange={this.handleChange}>
					<option value="/purchase/mortgage/flats">Жилая</option>
					<option value="/purchase/mortgage/commerce">Коммерческая</option>
				</select>
				<i className={`select-mobile__icon  iconsnew-${links[selected].icon}`} />
				<span className="select__indicator select__indicator--mobile" />
			</div>
		)
	}

	handleChange = (ev) => {
		this.props.getPage(ev.target.value)
		this.handleScroll()
	}
}

export default connect(
	(state) => ({
		loading: state.commerce.loading,
		loaded: state.commerce.loaded,
		mobile: state.mobile,
		mortgage: state.mortgageFilter,
		filtrated: filtratedMortgageSelector(state),
		loadingFlats: state.flats.loading,
		loadedFlats: state.flats.loaded
	}),
	{ clearFilter, getPage, loadCommerce, loadFlats }
)(withRouter(Filters))
