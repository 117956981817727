import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Loader from '../common/loader'
import { loadMortgage, setCommerce } from '../../actions'
import { sortedMortgageSelector, filtratedMortgageSelector } from '../../selectors'
import Table from './table'
import Filters from '../calculate'
import { withRouter } from 'react-router-dom'

class MortgageBlock extends Component {
	componentDidMount() {
		const { loading, loaded, loadMortgage } = this.props
		if (!loading && !loaded) loadMortgage()
		this.props.setCommerce(this.props.match.path.includes('commerce'))
	}

	componentDidUpdate(oldProps) {
		if (this.props.match.path !== oldProps.match.path)
			this.props.setCommerce(this.props.match.path.includes('commerce'))
	}

	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return null
		return (
			<Fragment>
				<Filters cost={this.props.cost} />
				<div className="flat-list">{this.table}</div>
			</Fragment>
		)
	}

	get table() {
		const path = this.props.match.path
		const { filtrated, banks, mortgage } = this.props
		if (!filtrated) return null
		return (
			<Table
				items={filtrated}
				commerce={path.includes('commerce') ? true : false}
				detail={this.props.cost ? true : false}
				bank={mortgage.bank}
			/>
		)
	}
}

export default connect(
	(state) => ({
		loading: state.mortgage.loading,
		loaded: state.mortgage.loaded,
		mortgage: state.mortgageFilter,
		filtrated: filtratedMortgageSelector(state),
		banks: sortedMortgageSelector(state),
		mobile: state.mobile
	}),
	{ loadMortgage, setCommerce }
)(withRouter(MortgageBlock))
