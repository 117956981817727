import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Button({ children, ...rest }) {
	if (rest.to)
		return (
			<NavLink className="btn" {...rest}>
				<span>{children}</span>
			</NavLink>
		)
	return (
		<div className="btn" {...rest}>
			<span>{children}</span>
		</div>
	)
}
