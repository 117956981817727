import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import 'rc-slider/assets/index.css'
import { changeMortgageСontribution } from '../../actions'
import accounting from 'accounting'
import { contributionMortgageSelector } from '../../selectors'
const SliderWithTooltip = Slider.createSliderWithTooltip(Slider)

class СontributionFilter extends Component {
	handleChange = (value) => {
		this.props.changeMortgageСontribution(value)
	}

	render() {
		const { contribution } = this.props
		const value = [contribution || 50]
		return (
			<SliderWithTooltip
				min={20}
				max={100}
				value={value}
				step={1}
				onChange={this.handleChange}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
			/>
		)
	}
}

export default connect(
	(state) => ({
		contribution: state.mortgageFilter.contribution
		//contribution: contributionMortgageSelector(state)
	}),
	{ changeMortgageСontribution }
)(СontributionFilter)
