import { LOAD_FAQ, SUCCESS, START } from '../constants'

const defaultObjects = {
	entities: [],
	total: null,
	loading: false,
	loaded: false,
	error: null
}

export default (state = defaultObjects, action) => {
	const { type, response, payload, error } = action

	switch (type) {
		case LOAD_FAQ + START:
			return { ...state, loading: true }

		case LOAD_FAQ + SUCCESS:
			return {
				...state,
				entities: response,
				total: response.length,
				loading: false,
				loaded: true
			}

		default:
			return state
	}
}
