import React, { Component } from 'react'
import { connect } from 'react-redux'
import accounting from 'accounting'
import { openParkingForm } from '../../../actions'

class Row extends Component {
	render() {
		const { place } = this.props
		if (!place) return null
		const price = accounting.formatNumber(place.price, 0, ' ')

		return (
			<tr className="flat-list__item">
				<td onClick={this.handleClick}>{place.number}</td>
				{/*<td onClick={this.handleClick}>{place.floor}</td>*/}
				<td onClick={this.handleClick}>{place.square}</td>
				<td onClick={this.handleClick}>{price}</td>
			</tr>
		)
	}

	handleClick = (ev) => {
		this.props.openParkingForm(this.props.place)
	}
}

export default connect(null, { openParkingForm })(Row)
