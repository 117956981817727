import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'simplebar'
import 'simplebar/dist/simplebar.min.css'
import Row from './row'
import accounting from 'accounting'
import { openParkingForm } from '../../../actions'

class Table extends Component {
	state = {
		order: {
			number: 'none',
			corpus: 'none',
			floor: 'none',
			square: 'none',
			rooms: 'none',
			price: 'none'
		}
	}

	render() {
		if (!this.props.list.length) return null
		const { order } = this.state

		return (
			<div data-simplebar>
				<table>
					<thead>
						<tr>
							<th>
								<span>№ места</span>
							</th>
							{/*<th>
								<span>Этаж</span>
							</th>*/}
							<th>
								<span>
									Площадь, м<sup>2</sup>
								</span>
							</th>
							<th>
								<span>Стоимость, руб.</span>
							</th>
						</tr>
					</thead>
					<tbody>{this.flats}</tbody>
				</table>
			</div>
		)
	}

	get flats() {
		return this.props.list.map((flat, index) => {
			if (this.props.visibleLength && index > this.props.visibleLength) return null

			return <Row key={flat.id} place={flat} />
		})
	}
}

export default connect(null, { openParkingForm })(Table)
