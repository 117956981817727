import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter, Redirect } from 'react-router-dom'
import { buildingsMapSelector, sectionsInCorpus } from '../../selectors'
import { getPage } from '../../actions'
import Plan from './scheme'

class Section extends Component {
	render() {
		const { loading, loaded } = this.props
		if (loading || !loaded) return null

		return <div>{this.sectionList}</div>
	}

	get body() {
		const { buildings, corpus } = this.props
		const list = Object.keys(buildings)
		const position = list.indexOf(corpus)
		const prev = position > 0 ? list[position - 1] : null
		const next = position < list.length - 1 ? list[position + 1] : null
		return (
			<div className="corpus-list">
				<div className="corpus-list__title">
					<div className="section__arrow ico-6">
						{!prev || <Link to={`/realty/genplan/${prev}/1`} className="ico-6" />}
					</div>
					<div className="corpus-list__title__text">
						<span>{corpus}</span> корпус
					</div>
					<div className="section__arrow ico-7">
						{!next || <Link to={`/realty/genplan/${next}/1`} className="ico-7" />}
					</div>
				</div>
				{this.plans(list, position, buildings)}
			</div>
		)
	}

	plans = (list, position, buildings) => {
		return (
			<div className="corpus-list__body">
				{list.map((el, i) => {
					return (
						<div key={el} className={`corpus-list__body__item ${i === position ? 'active' : ''}`}>
							<div className="corpus-plan">
								<Plan sections={buildings[el]['sections']} img={buildings[el]['plan']} />
							</div>
							<span>{el}</span>
						</div>
					)
				})}
			</div>
		)
	}

	get sectionList() {
		const { buildings, corpus, section, sections, getPage, mobile, hideHead } = this.props
		const position = sections.indexOf(section)
		const prev = position > 0 ? sections[position - 1] : null
		const next = position < sections.length - 1 ? sections[position + 1] : null

		if (!mobile) {
			if (position === -1) return <Redirect to={`/realty/genplan/${corpus}/${sections[0]}`} />
		}

		return (
			<div className="section-list">
				{hideHead ? (
					<div></div>
				) : (
					<div className="section-list__title">
						<div className="section__arrow ico-6">
							{!prev || <Link to={`/realty/genplan/${corpus}/${prev}`} className="ico-6" />}
						</div>
						<div className="corpus-list__title__text">
							<span>{section}</span> секция
						</div>
						<div className="section__arrow ico-7">
							{!next || <Link to={`/realty/genplan/${corpus}/${next}`} className="ico-7" />}
						</div>
					</div>
				)}
				<div className="section-list__body">
					<Plan
						key={section}
						sections={buildings[corpus]['sections']}
						img={buildings[corpus]['plan']}
						active={section}
						corpus={corpus}
						isSection={true}
						getPage={getPage}
						sectionsInstock={sections}
					/>
				</div>
			</div>
		)
	}
}

export default connect(
	(state, props) => ({
		loading: state.genplan.loading,
		loaded: state.genplan.loaded,
		buildings: buildingsMapSelector(state),
		sections: sectionsInCorpus(state, props)
	}),
	{ getPage }
)(withRouter(Section))
