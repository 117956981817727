import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import Panel from '../realty/panel'
import Buildings from './buildings'
import Sections from './sections'
import Fade from 'react-reveal/Fade'

import { sectionChangeRooms, loadGenplan } from '../../actions'
import {
	sectionFlatsMapSelector,
	sectionFilterRoomsSelector,
	corpusFlatsMapSelector,
	corpusFlatsMapAllFloorsSelector,
	corpusFilterRoomsSelector
} from '../../selectors'

import AboutAdvList from '../About/about-advants'
import Additional from '../realty/additional'
import Filter from './filter'
//import Filters from '../flats-filter'
import FilterDesktop from './filter-desktop'

import ScrollTop from '../common/scroll-top'
import Button from '../common/button'
import Modal from 'react-modal'
import accounting from 'accounting'

class Section extends Component {
	state = {
		open: false
	}

	componentDidMount() {
		const { loadGenplan, loadingGenplan, loadedGenplan } = this.props

		if (!loadedGenplan && !loadingGenplan) loadGenplan()
	}

	componentDidUpdate(oldProps) {
		const { loadGenplan, loadingGenplan, loadedGenplan } = this.props

		if (!loadedGenplan && !loadingGenplan) loadGenplan()
	}

	render() {
		const { loading, loaded, section, corpus, filterRooms, filtres, mobile, filterRoomsCorpus } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="section">
				<ScrollTop />
				{this.path}
				<div className="content">
					<Panel />
					<Fade>
						<div>
							<div className="back">
								{mobile ? (
									<Link to={'/realty/genplan/' + corpus} className="ico-6">
										<span>Назад к выбору секции</span>
									</Link>
								) : (
									<Link to="/realty" className="ico-6">
										<span>Назад к выбору корпуса</span>
									</Link>
								)}
							</div>
							<div className="section__content section__content--desktop">
								<div className="section__left section__left--desktop">
									{mobile && Object.entries(filterRooms).length ? (
										<div className="section__filter">
											<div className="section__filter__title">Квартиры в продаже</div>
											<Filter filtres={filtres} filterRooms={filterRooms} />
										</div>
									) : Object.entries(filterRoomsCorpus).length ? (
										<div className="section__filter">
											<div className="section__filter__title">Квартиры в продаже</div>
											<FilterDesktop filtres={filtres} filterRoomsCorpus={filterRoomsCorpus} />
										</div>
									) : null}
									<Buildings corpus={corpus} />
								</div>

								{mobile ? (
									<div className="section__middle">
										<div className="section__title">
											<span>{corpus}</span> корпус, <span>{section}</span> секция
										</div>
										{this.body}
									</div>
								) : (
									<div className="section__middle--desktop">
										<div className="section__body section__body--nums">
											<div className="section-view">{this.bodyDesktopFloors}</div>
										</div>
										{this.bodyDesktop}
									</div>
								)}
								{/*
								<div className="section__right">
									<Buildings corpus={corpus} />
									<Sections section={section} corpus={corpus} />
								</div>
								*/}
							</div>
						</div>
					</Fade>
				</div>
				{/*
				<Additional />
				<AboutAdvList />
				*/}
				{this.getModalMobile(this.state.flat)}
			</div>
		)
	}
	get bodyDesktop() {
		const { flatsCorpus } = this.props
		if (!flatsCorpus) return null
		return Object.keys(flatsCorpus).map((key) => {
			return (
				<div key={key} className="section__body">
					<div className="section__title">
						<span>{key}</span> секция
					</div>
					<div className="section-view">{this.rowsDesktop(flatsCorpus[key], key)}</div>
				</div>
			)
		})
	}
	get bodyDesktopFloors() {
		const { flatsAllFloors } = this.props
		if (!flatsAllFloors) return null
		return Object.keys(flatsAllFloors)
			.reverse()
			.map((key) => {
				return (
					<div key={key} className="section-view__row">
						<div className="section-view__cell__floor">
							<div className="section-view__num">
								<span>{key}</span>
							</div>
						</div>
					</div>
				)
			})
	}
	rowsDesktop = (row, sect) => {
		const { flatsAllFloors } = this.props
		if (!row) return null
		return Object.keys(flatsAllFloors)
			.reverse()
			.map((key) => {
				return (
					<div key={key} className="section-view__row">
						{this.flats(key, sect)}
					</div>
				)
			})
	}

	get body() {
		return (
			<div className="section__body">
				<div className="section-view">{this.rows}</div>
			</div>
		)
	}

	get rows() {
		const { flats } = this.props
		if (!flats) return null
		return Object.keys(flats)
			.reverse()
			.map((value) => {
				return (
					<div key={value} className="section-view__row">
						<div className="section-view__cell__floor">
							<div className="section-view__num">
								<span>{value}</span>
							</div>
						</div>
						{this.flats(value)}
					</div>
				)
			})
	}

	flats = (floor, sect) => {
		const { flats, mobile, flatsCorpus } = this.props
		let flats_res = flats
		if (!mobile) {
			flats_res = flatsCorpus[sect]
		}
		if (!flats_res) return null
		if (!flats_res[floor]) return ''

		flats_res[floor].sort((a, b) => {
			return a.number - b.number
		})

		return flats_res[floor].map((flat) => {
			return (
				<div
					data-id={flat.number}
					key={flat.id}
					className={`section-view__cell ${this.getClassname(flat.rooms)} ${
						flat.instock === '1' ? '' : ' disable'
					}`}
				>
					{flat.instock === '1' ? (
						<Link
							to={`/realty/genplan/${flat.corpus}/${flat.section}/${flat.id}`}
							className="section-view__num"
							onClick={!mobile ? this.openModal(flat) : ''}
						>
							<span>{flat.rooms.toString().replace('0', 'C')}</span>
							{+flat.promotion ? <span className="section-view__cell__promotion" /> : null}
						</Link>
					) : (
						<div className="section-view__num" onClick={!mobile ? this.openModal(flat) : ''}>
							<span>{flat.rooms.toString().replace('0', 'C')}</span>
						</div>
					)}
					{mobile || <div className="section-view__flat">{this.getModal(flat)}</div>}
				</div>
			)
		})
	}
	getModal = (flat) => {
		if (!flat) return null
		return (
			<div>
				<div className="section-view__flat__title">
					квартира - <span>{flat.number}</span>
				</div>
				<div className="section-view__flat__body">
					<div className="flat__info">
						<div className="flat__info__item">
							<div className="flat__info__title">Комнат:</div>
							<div className="flat__info__body">{flat.rooms.toString().replace('0', 'Cтудия')}</div>
						</div>
						<div className="flat__info__item">
							<div className="flat__info__title">Площадь:</div>
							<div className="flat__info__body">
								{flat.square} м<sup>2</sup>
							</div>
						</div>
						<div className="flat__info__item">
							<div className="flat__info__title">Статус:</div>
							<div className="flat__info__body">{flat.instock === '1' ? 'Свободна' : 'Продана'}</div>
						</div>
						<div className="flat__info__item">
							<div className="flat__info__title">Отделка:</div>
							<div className="flat__info__body">{flat.decoration === '1' ? 'Да' : 'Нет'}</div>
						</div>
						<div className="flat__info__item flat__info__item--border flat__info__item--price">
							<div className="flat__info__title">Стоимость:</div>
							<div className="flat__info__body">
								{accounting.formatNumber(flat.price, 0, ' ')} <span>руб</span>
							</div>
						</div>
						{flat.promotion && +flat.promotion > 0 ? (
							<div className="flat__info__item flat__info__item--promotion">
								<div className="flat__info__title">Акция:</div>
								<div className="flat__info__body">
									{accounting.formatNumber(flat.price - flat.promotion, 0, ' ')} <span>руб</span>
								</div>
							</div>
						) : null}
					</div>
					{flat.promotion && +flat.promotion > 0 ? <div className="promotion-icon ico-64">Акция</div> : null}
				</div>
			</div>
		)
	}

	getModalMobile = (flat) => {
		if (!flat) return null
		return (
			<Modal closeTimeoutMS={300} className="modalwin modalwin--callback" isOpen={this.state.open}>
				<div onClick={this.toggleModal} className="overlay__modal" />
				<div onClick={this.toggleModal} className="close__modal" />
				<div className="modalwin__box">
					{this.getModal(flat)}
					{flat.instock === '1' ? (
						<div className="section-modal-btn">
							<Button to={`/realty/genplan/${flat.corpus}/${flat.section}/${flat.id}`}>Подробнее</Button>
						</div>
					) : null}
				</div>
			</Modal>
		)
	}

	toggleModal = (ev) => {
		this.setState({
			open: !this.state.open
		})
	}

	openModal = (flat) => (ev) => {
		if (this.props.mobile) {
			ev.preventDefault()
			this.setState({
				open: true,
				flat: flat
			})
		}
	}

	getClassname = (rooms) => (this.props.filtres.includes(rooms) ? 'active' : '')

	get path() {
		const { section, corpus } = this.props

		return (
			<div className="box">
				<div className="breadcrumb">
					<h1>Подобрать квартиру</h1>
					<div className="breadcrumb__links">
						{/*<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> / Секция{' '}*/}
						<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> / Корпус{' '}
						<span> {corpus}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	(state, props) => ({
		loading: state.flats.loading,
		loaded: state.flats.loaded,

		//loadingCorpus: state.flatsCorpus.loadingCorpus,
		//loadedCorpus: state.flatsCorpus.loadedCorpus,

		flatsCorpus: corpusFlatsMapSelector(state, props),
		flatsAllFloors: corpusFlatsMapAllFloorsSelector(state, props),

		flats: sectionFlatsMapSelector(state, props),

		filtres: state.sectionFilter.rooms,
		loadedGenplan: state.genplan.loaded,
		loadingGenplan: state.genplan.loading,
		filterRooms: sectionFilterRoomsSelector(state, props),
		filterRoomsCorpus: corpusFilterRoomsSelector(state, props),

		mobile: state.mobile
	}),
	{ sectionChangeRooms, loadGenplan }
)(Section)
