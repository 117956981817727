import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadDecoration } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import AboutAdvList from '../About/about-advants'
import AboutFlatChooseList from '../About/about-choose-flat'
import Button from '../common/button'
import Slider from '../common/slider'
import { hit } from '../common/metrics'

class Decoration extends Component {
	componentDidMount() {
		document.title = 'Квартиры в ЖК «Новое Бутово» с отделкой в стиле модерн'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Модерн отличается практичностью, вниманием к деталям, современностью. Этот стиль живет и развивается вместе с Вами. Для интерьера используют простую и функциональную мебель, для декора — качественный текстиль, изысканную фурнитуру, элементы с растительной тематикой.'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadDecoration } = this.props
		if (!loading && !loaded) loadDecoration()
	}

	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="decoration">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Отделка</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/realty/genplan">Подобрать квартиру</Link> /{' '}
							<Link to="/realty/decoration">Отделка</Link> / Стиль модерн
						</div>
					</div>
				</div>
				<Fade>
					<div className="box">{this.first}</div>
				</Fade>
				<div className="box">
					<div className="title">Детали отделки</div>
					<Slider items={this.slider} options={{ arrows: true }} counter={true} />
					<div className="title">
						3D-планировка квартиры <br /> в стиле модерн
					</div>
					{this.plan}
				</div>
				<div className="content">
					<div className="title colorpink">Другие стили</div>
					{this.styles}
				</div>
				<AboutFlatChooseList />
				<AboutAdvList />
			</div>
		)
	}

	get plan() {
		const { style3 } = this.props.decoration
		return (
			<div className="decoration__3d">
				<img alt="" src={style3.plan} />
			</div>
		)
	}

	get first() {
		const { style3 } = this.props.decoration
		return (
			<div className="styles">
				<div className="styles__item styles__item--large style--3">
					<div className="styles__item__image">
						<span style={{ backgroundImage: `url(${style3.img_large})` }} />
					</div>
					<div className="styles__item__body">
						<div className="styles__item__title">
							<span dangerouslySetInnerHTML={{ __html: style3.title }} />
						</div>
						<div className="styles__item__text" dangerouslySetInnerHTML={{ __html: style3.text }} />
					</div>
				</div>
			</div>
		)
	}

	get slider() {
		const { style3 } = this.props.decoration
		if (!style3.slider) return []
		return style3.slider.map((el) => {
			return (
				<div className="decoration-slider__item" key={el.id}>
					<div className="decoration-slider__item__body" dangerouslySetInnerHTML={{ __html: el.text }} />
					<div className="decoration-slider__item__img">
						<span style={{ backgroundImage: `url(${el.img})` }} />
					</div>
				</div>
			)
		})
	}

	get styles() {
		const { style1, style2 } = this.props.decoration
		return (
			<div className="styles">
				<Link to="/realty/scandinavian" className="styles__item styles__item--middle">
					<div className="styles__item__image">
						<span style={{ backgroundImage: `url(${style1.img_middle})` }} />
					</div>
					<div className="styles__item__body">
						<div className="styles__item__title">
							<span dangerouslySetInnerHTML={{ __html: style1.title }} />
						</div>
						<div className="styles__item__text" dangerouslySetInnerHTML={{ __html: style1.text }} />
						<Button>Подробнее о стиле</Button>
					</div>
				</Link>
				<Link to="/realty/fusion" className="styles__item styles__item--middle style--2">
					<div className="styles__item__image">
						<span style={{ backgroundImage: `url(${style2.img_middle})` }} />
					</div>
					<div className="styles__item__body">
						<div className="styles__item__title">
							<span dangerouslySetInnerHTML={{ __html: style2.title }} />
						</div>
						<div className="styles__item__text" dangerouslySetInnerHTML={{ __html: style2.text }} />
						<Button>Подробнее о стиле</Button>
					</div>
				</Link>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		loading: state.decoration.loading,
		loaded: state.decoration.loaded,
		decoration: state.decoration.data
	}),
	{ loadDecoration }
)(Decoration)
