import { LOAD_PROMOTIONS, LOAD_PROMOTION, SUCCESS, START, FAIL } from '../constants'

const defaultObjects = {
	data: null,
	loading: false,
	loaded: false,
	detail: {},
	loadingDetail: false,
	error: null
}

export default (state = defaultObjects, action) => {
	const { type, response, payload, error } = action

	switch (type) {
		case LOAD_PROMOTIONS + START:
			return { ...state, loading: true }

		case LOAD_PROMOTIONS + SUCCESS:
			return {
				...state,
				data: response,
				loading: false,
				loaded: true
			}

		case LOAD_PROMOTION + START:
			return { ...state, loadingDetail: true }

		case LOAD_PROMOTION + SUCCESS:
			return {
				...state,
				detail: {
					...state.detail,
					[payload.id]: response
				},
				loadingDetail: false
			}

		case LOAD_PROMOTION + FAIL:
			return {
				...state,
				error
			}

		default:
			return state
	}
}
