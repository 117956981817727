import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from '../../common/loader'
import Scheme from './scheme'
import { loadCommerceCorpus, getPage, replacePage } from '../../../actions'
import { Link } from 'react-router-dom'
import Panel from '../panel'
import ScrollTop from '../../common/scroll-top'
import Fade from 'react-reveal/Fade'
import AboutAdvList from '../../About/about-advants'
import { filtratedCommerceCorpusSelector } from '../../../selectors'

class Corpus extends Component {
	state = {
		floorCurrent: 1
	}

	componentDidMount() {
		const { loadingDetail, loadCommerceCorpus, detail, corpus: id } = this.props
		if (!loadingDetail && !detail[id]) loadCommerceCorpus(id)
	}

	componentDidUpdate(oldProps) {
		const { loadingDetail, loadCommerceCorpus, detail, corpus: id, error, replacePage } = this.props

		if (error) return replacePage('/error')
		if (oldProps.corpus === id) return
		if (!loadingDetail && !detail[id]) loadCommerceCorpus(id)
	}

	render() {
		const { loading, loadingDetail, corpus: id, detail } = this.props
		const corpus = detail[id]
		if (loading || loadingDetail) return <Loader />
		if (!corpus) return null

		return (
			<div className="commerce-genplan">
				<ScrollTop />
				{this.path}
				<div className="content">
					<Panel />
					<Fade>
						<div>
							<div className="back">
								<Link to="/commerce" className="ico-6">
									<span>Назад к выбору корпуса</span>
								</Link>
							</div>
							{this.body}
						</div>
					</Fade>
				</div>
				<AboutAdvList isCommerce />
			</div>
		)
	}

	get path() {
		const { corpus: id } = this.props

		return (
			<div className="box">
				<div className="breadcrumb">
					<h1>Коммерческие помещения</h1>
					<div className="breadcrumb__links">
						<Link to="/">Главная</Link> / <Link to="/commerce">Коммерческие помещения</Link> / Корпус {id}
					</div>
				</div>
			</div>
		)
	}
	get planBottom() {
		const { corpus: id, detail } = this.props
		const corpus = detail[id]

		return (
			<div className="flat__bottom__plan">
				<span className="flat__bottom__title">Генплан</span>
				<span
					className="flat__bottom__plan__bg flat__bottom__plan__bg--complex"
					style={{ backgroundImage: `url(/img/commerce_genplan_mini/${id}.svg)` }}
				/>
			</div>
		)
	}

	get scheme() {
		const { corpus: id, detail, flats, getPage, mobile } = this.props
		const corpus = detail[id]
		if (!corpus.floor_plans)
			return <Scheme img={corpus.plan} flats={flats} getPage={getPage} mobile={this.props.mobile} id={id} />

		return (
			<div className={`tabs floor-${this.state.floorCurrent}`}>
				<div className="tabs__nav">{this.tabNav}</div>
				<Fade key={this.state.floorCurrent}>
					<Scheme
						img={this.getFloorPlan(corpus.floor_plans)}
						flats={flats}
						getPage={getPage}
						mobile={this.props.mobile}
						floor={this.state.floorCurrent}
					/>
				</Fade>
			</div>
		)
	}

	getFloorPlan = (floor_plans) => floor_plans.find((el) => +el.floor === +this.state.floorCurrent).plan

	get tabNav() {
		const { detail, corpus: id } = this.props
		return detail[id].floor_plans.map((el, i) => (
			<div
				key={i}
				className={+el.floor === +this.state.floorCurrent ? 'tabs__nav__item__active' : 'tabs__nav__item'}
				floor={el.floor}
				onClick={this.tabChange}
			>
				этаж {el.floor}
			</div>
		))
	}

	tabChange = (ev) => {
		const floor = ev.target.getAttribute('floor')
		this.setState({ floorCurrent: floor })
	}

	get body() {
		const { corpus: id, detail, flats, getPage, mobile } = this.props
		const corpus = detail[id]

		return (
			<div className="commerce__corpus-body">
				<div className="commerce__corpus-left">
					<div>
						{this.scheme}
						<div className="flat__bottom">
							{mobile || this.planBottom}
							<div className="compass" />
						</div>
					</div>
				</div>
				<div className="commerce__corpus-right">
					{!corpus.img || (
						<div className="commerce__corpus-image">
							<span style={{ backgroundImage: `url(${corpus.img})` }} />
						</div>
					)}
					<div className="commerce__corpus-name">
						{corpus.number} <span>корпус</span>
					</div>
					<div className="commerce__corpus-address">
						{!corpus.address || (
							<div>
								Aдрес: <span>{corpus.address}</span>
							</div>
						)}
						{!corpus.text_ready || corpus.text_ready}
					</div>
					<div
						className="commerce__corpus-description"
						dangerouslySetInnerHTML={{ __html: corpus.description }}
					/>
				</div>
			</div>
		)
	}
}

export default connect(
	(state, props) => ({
		flats: filtratedCommerceCorpusSelector(state, props),
		loading: state.commerce.loading,
		loaded: state.commerce.loaded,
		loadingDetail: state.commerce.loadingCommerceCorpus,
		detail: state.commerce.corpus,
		error: state.commerce.corpusError,
		mobile: state.mobile
	}),
	{ getPage, loadCommerceCorpus, replacePage }
)(Corpus)
