import { LOAD_INFRA, SUCCESS, START } from '../constants'

const defaultObjects = {
	entities: [],
	object: null,
	metro: [],
	loading: false,
	loaded: false
}

export default (state = defaultObjects, action) => {
	const { type, response } = action

	switch (type) {
		case LOAD_INFRA + START:
			return { ...state, loading: true }

		case LOAD_INFRA + SUCCESS:
			return {
				...state,
				entities: response.infra,
				object: response.object,
				metro: response.metro,
				loading: false,
				loaded: true
			}

		default:
			return state
	}
}
