import {
	LOAD_DOCS,
	SUCCESS,
	START,
	CHANGE_DOCS_PAGE,
	CHANGE_DOCS_TYPE,
	CHANGE_DOCS_CORPUS,
	CHANGE_DOCS_YEAR
} from '../constants'

const defaultObjects = {
	entities: [],
	loading: false,
	loaded: false,
	filter: {
		limit: 6,
		page: 1,
		type: {
			value: '',
			label: 'Все документы'
		},
		corpus: {
			value: '',
			label: 'Все корпуса'
		},
		year: {
			value: '',
			label: 'Все года'
		}
	}
}

export default (state = defaultObjects, action) => {
	const { type, response, payload } = action

	switch (type) {
		case LOAD_DOCS + START:
			return { ...state, loading: true }

		case LOAD_DOCS + SUCCESS:
			return {
				...state,
				entities: response,
				loading: false,
				loaded: true
			}

		case CHANGE_DOCS_PAGE:
			return { ...state, filter: { ...state.filter, page: payload.selected } }

		case CHANGE_DOCS_TYPE:
			return { ...state, filter: { ...state.filter, page: 1, corpus: '', year: '', type: payload.selected } }

		case CHANGE_DOCS_CORPUS:
			return { ...state, filter: { ...state.filter, page: 1, year: '', corpus: payload.selected } }

		case CHANGE_DOCS_YEAR:
			return { ...state, filter: { ...state.filter, page: 1, year: payload.selected } }

		default:
			return state
	}
}
