import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Loader from '../../common/loader'
import Panel from '../panel'
import Table from '../table'
import accounting from 'accounting'
import ScrollTop from '../../common/scroll-top'
import Gallery from './gallery'
import Fade from 'react-reveal/Fade'
import { openPopupScheme } from '../../../actions'
import { withRouter } from 'react-router-dom'

import {
	loadCommerceFlat,
	openMailForm,
	openRequestForm,
	openCallForm,
	replacePage,
	setFormPlaceType
} from '../../../actions'
import { Link } from 'react-router-dom'

import { similarCommerceSelector, viewedCommerceSelector } from '../../../selectors'
import $ from 'jquery'

import MortgageBlock from '../../mortgage/mortgage'

class Flat extends Component {
	state = {
		floorCurrent: 1,
		similar: true
	}

	componentDidMount() {
		const { loading, loadCommerceFlat, detail, id } = this.props

		if (!loading && !detail[id]) loadCommerceFlat(id)
	}

	componentDidUpdate(oldProps) {
		const { detail, id, loading, loadCommerceFlat, error, replacePage } = this.props

		if (error) return replacePage('/error')
		if (oldProps.id === id) return
		if (!loading && !detail[id]) loadCommerceFlat(id)
	}

	render() {
		const { loading, id, detail, mobile } = this.props
		const flat = detail[id]
		if (loading) return <Loader />
		if (!flat) return null

		console.log(flat)

		return (
			<div className="flat">
				<ScrollTop />
				{this.path}
				<div>
					<Fade>
						<div>
							<div className="content">
								<Panel lastUrl={this.props.history.location.state} />
							</div>
							<div className="content">
								<div className="back">
									<Link to={`/commerce/${flat['corpus']}`} className="ico-6">
										<span>Назад к выбору помещения</span>
									</Link>
								</div>

								{this.body}
								{!mobile || <div className="flat__bottom2">{this.planBottom}</div>}
								<div className="table-title">Ипотека</div>
								<MortgageBlock cost={flat.price - flat.promotion} />
								{/*<div className="flat-list">{this.table}</div>*/}
								{this.state.similar ? this.similar : this.viewed}
							</div>
						</div>
					</Fade>
				</div>
			</div>
		)
	}

	get table() {
		const { banks } = this.props
		if (!banks) return null
		return <Table items={banks} />
	}

	get viewed() {
		const { viewed, mobile } = this.props
		return (
			<div>
				<div className="flat-list handle-scroll">
					<div className="table-title">
						Вы смотрели
						{mobile || (
							<span className="table-title_small" onClick={this.handleChangeTable}>
								<span>Похожие помещения</span>
							</span>
						)}
					</div>
					<Table flats={viewed} visibleLength={5} />
					{!mobile || (
						<span className="table-title_small" onClick={this.handleChangeTable}>
							<span>Похожие помещения</span>
						</span>
					)}
				</div>
			</div>
		)
	}

	get similar() {
		const { similar, viewed, mobile } = this.props
		if (!similar.length)
			return (
				<div>
					<div className="flat-list handle-scroll">
						<div className="table-title">Вы смотрели</div>
						<Table flats={viewed} visibleLength={5} />
					</div>
				</div>
			)
		return (
			<div>
				<div className="flat-list handle-scroll">
					<div className="table-title">
						Похожие помещения
						{mobile || (
							<span className="table-title_small" onClick={this.handleChangeTable}>
								<span>Вы смотрели</span>
							</span>
						)}
					</div>
					<Table flats={similar} visibleLength={5} />
					{!mobile || (
						<span className="table-title_small" onClick={this.handleChangeTable}>
							<span>Вы смотрели</span>
						</span>
					)}
				</div>
			</div>
		)
	}

	handleChangeTable = () => {
		this.setState({
			similar: !this.state.similar
		})
		console.log($('.handle-scroll').length)
		if (this.props.mobile && $('.handle-scroll').length) {
			$('html, body').animate({
				scrollTop: $('.handle-scroll').offset().top - 80
			})
		}
	}

	get plan() {
		const { id, detail } = this.props
		const flat = detail[id]
		if (!flat.plan) return null

		if (!flat.plan2)
			return (
				<div className="plan-image">
					<img alt="" src={flat.plan} />

					<div className="routeLink" onClick={() => this.props.openPopupScheme()}>
						<i className="iconsnew-11"></i>
						<span>трасса Воскресенское-Каракашево-Щербинка</span>
					</div>
				</div>
			)

		return (
			<div className="tabs tabs-inner">
				{this.tabNav}
				<Fade key={this.state.floorCurrent}>
					<div className="plan-image">
						<img alt="" src={this.getFloorPlan(flat)} />

						<div className="routeLink" onClick={() => this.props.openPopupScheme()}>
							<i className="iconsnew-11"></i>
							<span>трасса Воскресенское-Каракашево-Щербинка</span>
						</div>
					</div>
				</Fade>
			</div>
		)
	}

	getFloorPlan = (flat) => {
		if (+this.state.floorCurrent === 2 && flat.plan2) return flat.plan2
		return flat.plan
	}

	get tabNav() {
		return (
			<div className="tabs__nav">
				<div
					className={1 === +this.state.floorCurrent ? 'tabs__nav__item__active' : 'tabs__nav__item'}
					floor="1"
					onClick={this.tabChange}
				>
					этаж 1
				</div>
				<div
					className={2 === +this.state.floorCurrent ? 'tabs__nav__item__active' : 'tabs__nav__item'}
					floor="2"
					onClick={this.tabChange}
				>
					этаж 2
				</div>
			</div>
		)
	}

	tabChange = (ev) => {
		const floor = ev.target.getAttribute('floor')
		this.setState({ floorCurrent: floor })
	}

	get path() {
		const { id, detail } = this.props
		const flat = detail[id]

		return (
			<div className="box">
				<div className="breadcrumb">
					<h1>
						Коммерческое помещение <span>№ {flat['number']}</span>
					</h1>
					<div className="breadcrumb__links">
						<Link to="/">Главная</Link> / <Link to="/commerce">Подобрать помещение</Link> / Помещение{' '}
						<span>№ {flat['number']}</span>
					</div>
				</div>
			</div>
		)
	}

	handleRequestForm = () => {
		const { id, detail } = this.props
		const flat = detail[id]
		this.props.openRequestForm(flat)
		this.props.setFormPlaceType('Страница коммерческого помещения')
	}

	openCallForm = () => {
		this.props.openCallForm()
		this.props.setFormPlaceType('Страница коммерческого помещения')
	}

	handleMailForm = () => {
		this.props.openMailForm(this.props.id, 'flatplan')
		this.props.setFormPlaceType('Страница коммерческого помещения')
	}

	get body() {
		const { id, detail, mobile } = this.props
		const flat = detail[id]
		console.log(flat)
		return (
			<div className="flat__body">
				<div className="flat__left">
					<div className="commerce__left">
						{this.plan}
						<Gallery gallery={flat.gallery} mobile={mobile} />
					</div>
					<div className="flat__bottom">
						{mobile || this.planBottom}
						<div className="compass" />
					</div>
				</div>
				<div className="flat__right">
					<div className="flat__price">
						{flat.promotion && +flat.promotion > 0 ? (
							<div>
								<div className="flat__price__top">
									<div className="flat__price__title">цена без скидки</div>
									{accounting.formatNumber(flat.price, 0, ' ')} <span>руб.</span>
								</div>
								<div className="flat__price__bottom">
									<div className="flat__price__title">
										скидка - {accounting.formatNumber(flat.promotion, 0, ' ')} руб.
									</div>
									{accounting.formatNumber(flat.price - flat.promotion, 0, ' ')} <span>руб.</span>
								</div>
							</div>
						) : flat.price ? (
							<div>
								{accounting.formatNumber(flat.price, 0, ' ')} <span>руб.</span>
							</div>
						) : (
							<p>Продана</p>
						)}
					</div>
					<div className="flat__info">
						{flat['corpus'] ? (
							<div className="flat__info__item">
								<div className="flat__info__title">Корпус:</div>
								<div className="flat__info__body">{flat['corpus']}</div>
							</div>
						) : null}
						{flat['square'] ? (
							<div className="flat__info__item">
								<div className="flat__info__title">Площадь:</div>
								<div className="flat__info__body">
									{accounting.formatNumber(flat['square'], 0, '')} м<sup>2</sup>
								</div>
							</div>
						) : null}
						{flat['height'] ? (
							<div className="flat__info__item">
								<div className="flat__info__title">Высота потолка:</div>
								<div className="flat__info__body">
									{accounting.formatNumber(flat['height'], 1, ',', ',')} м
								</div>
							</div>
						) : null}
						{flat['power'] ? (
							<div className="flat__info__item">
								<div className="flat__info__title">Электрическая мощн.:</div>
								<div className="flat__info__body">
									{flat['power']} кВт
									<br /> с возможностью увеличения
								</div>
							</div>
						) : null}
					</div>
					{/*<div className="flat__present">
						<i className="iconsnew-12"></i> Два машиноместа в подарок
						<br />
						при покупке нежилого помещения!
					</div>*/}
					<div className="flat__buttons">
						<div className="flat__btn" onClick={this.handleRequestForm}>
							Оставить заявку
						</div>
						<div className="flat__btn" onClick={this.openCallForm}>
							Заказать звонок
						</div>
					</div>
					<div className="flat__links">
						<a className="flat__link ico-5" href={flat.files.pdf} target="_blank" rel="noopener noreferrer">
							<span>Скачать PDF</span>
						</a>
						<div className="flat__link ico-4" onClick={this.handleMailForm}>
							<span>Отправить E-mail</span>
						</div>
					</div>
					<div className="flat__description" dangerouslySetInnerHTML={{ __html: flat['description'] }} />
				</div>
			</div>
		)
	}

	get planBottom() {
		const { id, detail } = this.props
		const flat = detail[id]
		return (
			<Fragment>
				<Link to={`/commerce/${flat['corpus']}`} className="flat__bottom__plan">
					<span className="flat__bottom__title">План этажа</span>
					<span className="flat__bottom__plan__bg" style={{ backgroundImage: `url(${flat.floor_plan})` }} />
				</Link>
				<Link to={`/commerce/`} className="flat__bottom__plan">
					<span className="flat__bottom__title">Генплан</span>
					<span
						className="flat__bottom__plan__bg flat__bottom__plan__bg--large"
						style={{ backgroundImage: `url(${flat.complex_plan})` }}
					/>
				</Link>
			</Fragment>
		)
	}
}

export default connect(
	(state, ownProps) => ({
		loading: state.commerce.loadingCommerceFlat,
		detail: state.commerce.detail,
		similar: similarCommerceSelector(state, ownProps),
		viewed: viewedCommerceSelector(state),
		error: state.commerce.error,
		mobile: state.mobile
	}),
	{ loadCommerceFlat, openMailForm, openRequestForm, openCallForm, replacePage, setFormPlaceType, openPopupScheme }
)(withRouter(Flat))
