import React, { Component } from 'react'
import $ from 'jquery'

export default class Arrow extends Component {
	render() {
		return (
			<div className="mobile-arrow" onClick={this.handleClick}>
				<i className="ico-62" />
			</div>
		)
	}

	handleClick = (ev) => {
		const el = $(ev.currentTarget)
		$('html, body').animate({
			scrollTop: el.offset().top
		})
	}
}
