import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadDecoration } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import AboutAdvList from '../About/about-advants'
import AboutFlatChooseList from '../About/about-choose-flat'
import Slider from '../common/slider'
import { hit } from '../common/metrics'

class Decoration extends Component {
	componentDidMount() {
		document.title =
			'ЖК «Новое Бутово». Подобрать квартиру с отделкой в Москве, 15 минут пешком до метро Бунинская аллея.'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Квартиры с отделкой в ЖК «Новое Бутово» - это решение для тех, кто ценит время, комфорт и хочет избавить себя от бесконечного ремонта и лишних затрат. При выборе квартиры с отделкой вы переезжаете сразу в новое полностью отремонтированное жилье'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadDecoration } = this.props
		if (!loading && !loaded) loadDecoration()
	}
	render() {
		const { loading, loaded, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="decoration">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Отделка</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/realty/genplan">Подобрать квартиру</Link> / Отделка
						</div>
					</div>
				</div>
				<Fade>
					<div className="content">
						{this.descr}
						{this.styles}
					</div>
				</Fade>
				<div className="box">
					{mobile ? (
						<div className="decoration-slider1">
							<Slider items={this.block} options={{ arrows: true }} />
						</div>
					) : (
						<div className="block">{this.block}</div>
					)}

					<div className="icons__title">
						Отделка <span>«ПОД КЛЮЧ»</span> включает
					</div>
					{mobile ? (
						<div className="decoration-slider2">
							<Slider items={this.icons} options={{ arrows: true }} />
						</div>
					) : (
						<div className="icons">{this.icons}</div>
					)}
				</div>
				<AboutFlatChooseList />
				<AboutAdvList />
			</div>
		)
	}

	get descr() {
		const { descr } = this.props.decoration
		if (!descr) return null
		return <div className="decoration__descr" dangerouslySetInnerHTML={{ __html: descr }} />
	}

	get styles() {
		const { style1, style2, style3 } = this.props.decoration
		return (
			<div className="styles">
				<Link to="/realty/scandinavian" className="styles__item">
					<div className="styles__item__image">
						<span style={{ backgroundImage: `url(${style1.img})` }} />
					</div>
					<div className="styles__item__body">
						<div className="styles__item__title">
							<span dangerouslySetInnerHTML={{ __html: style1.title }} />
						</div>
						<div className="styles__item__text" dangerouslySetInnerHTML={{ __html: style1.text }} />
					</div>
				</Link>
				<Link to="/realty/fusion" className="styles__item style--2">
					<div className="styles__item__image">
						<span style={{ backgroundImage: `url(${style2.img})` }} />
					</div>
					<div className="styles__item__body">
						<div className="styles__item__title">
							<span dangerouslySetInnerHTML={{ __html: style2.title }} />
						</div>
						<div className="styles__item__text" dangerouslySetInnerHTML={{ __html: style2.text }} />
					</div>
				</Link>
				<Link to="/realty/modern" className="styles__item style--3">
					<div className="styles__item__image">
						<span style={{ backgroundImage: `url(${style3.img})` }} />
					</div>
					<div className="styles__item__body">
						<div className="styles__item__title">
							<span dangerouslySetInnerHTML={{ __html: style3.title }} />
						</div>
						<div className="styles__item__text" dangerouslySetInnerHTML={{ __html: style3.text }} />
					</div>
				</Link>
			</div>
		)
	}

	get block() {
		const { block } = this.props.decoration
		if (!block.length) return null
		return block.map((el, i) => {
			return (
				<div key={el.id} className="block__item">
					<div className="block__item__image">
						<span style={{ backgroundImage: `url(${el.img})` }} />
					</div>
					<div className="block__item__text">
						<i className={`block__item__icon ico-${38 + i}`} />
						<div dangerouslySetInnerHTML={{ __html: el.text }} />
					</div>
				</div>
			)
		})
	}

	get icons() {
		const { icons } = this.props.decoration
		if (!icons.length) return null
		// const ico_num = ['1', '2', '3']
		return icons.map((el, i) => {
			return (
				<div key={el.id} className="icons__item">
					<i className={`icons__item__icon ico-${40 + i}`} />
					<div className="icons__item__text" dangerouslySetInnerHTML={{ __html: el.text }} />
				</div>
			)
		})
	}
}

export default connect(
	(state) => ({
		loading: state.decoration.loading,
		loaded: state.decoration.loaded,
		decoration: state.decoration.data,
		mobile: state.mobile
	}),
	{ loadDecoration }
)(Decoration)
