import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

export default class Mortgage extends Component {
	render() {
		return (
			<Fragment>
				<div className="main__item--mortgage main__item--ny main__item--ny2">
					<div className="main__item--text main__item--text2">
						<div className="ny-title2">
							<div dangerouslySetInnerHTML={{ __html: this.props.el.title }} />
						</div>
						<div className="ny-counter" dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
					</div>
					<Link className="main__item--but main__item--but__white" to="/realty/genplan">
						<i className="icon-i_house" />
						<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
					</Link>
				</div>
			</Fragment>
		)
	}
}
