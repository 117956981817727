import React from 'react'
import { Link } from 'react-router-dom'

export default class LoadersList extends React.Component {
	render() {
		return (
			<div className="flat-choose">
				<div className="box">
					<div className="box__title">Подобрать квартиру</div>
					<div className="flat-choose__links">
						<Link to="/realty/genplan">
							<i className="icon-i_flat1" />
							<span>на генплане</span>
						</Link>
						<Link to="/realty/params">
							<i className="icon-i_flat2" />
							<span>по параметрам</span>
						</Link>
						<Link to="/realty/layout">
							<i className="icon-i_flat3" />
							<span>по видам планировки</span>
						</Link>
					</div>
				</div>
			</div>
		)
	}
}
