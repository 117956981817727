import React, { Component } from 'react'
import SVG from 'svg.js/dist/svg.min.js'
import $ from 'jquery'

export default class FloorSvg extends Component {
	componentDidMount() {
		if (!this.container) return null
		this.handleLoad()
	}

	componentDidUpdate() {
		$(this.container)
			.find('svg')
			.remove()
		this.handleLoad()
	}

	shouldComponentUpdate(props) {
		return props.list !== this.props.list
	}

	render() {
		const { img } = this.props
		return (
			<div ref={this.setContainerRef} className="parking-scheme">
				<img src={img} alt="" id="floor-img" />
			</div>
		)
	}

	setContainerRef = (ref) => {
		this.container = ref
	}

	createSvg = (width, height) => {
		const { el } = this.props
		this._draw = SVG(this.container).size(width, height)
		this._draw.viewbox(0, 0, width, height)
		if (!el['coords']) return false

		const coords = el['coords'].split(',')
		const x1 = coords[0]
		const y1 = coords[1]
		const x2 = coords[2]
		const y2 = coords[3]

		this._draw
			.polygon([x1, y1, x2, y1, x2, y2, x1, y2])
			.fill('#e2068c')
			.stroke('#fff')
	}

	handleLoad = (callback) => {
		$.get(
			this.props.img,
			(svgxml) => {
				var attrs = svgxml.documentElement.attributes
				this.createSvg(parseInt(attrs.width.value), parseInt(attrs.height.value))
			},
			'xml'
		)
	}
}
