import { LOAD_NEWS, LOAD_NEW, FAIL, CHANGE_NEWS_YEAR, SUCCESS, START } from '../constants'

const defaultObjects = {
	entities: [],
	total: null,
	loading: false,
	loaded: false,
	filter: {
		year: null
	},

	detail: {},
	loadingNew: false,
	error: null
}

export default (state = defaultObjects, action) => {
	const { type, response, payload, error } = action

	switch (type) {
		case LOAD_NEWS + START:
			return { ...state, loading: true }

		case LOAD_NEWS + SUCCESS:
			return {
				...state,
				entities: response,
				total: response.length,
				loading: false,
				loaded: true
			}

		case CHANGE_NEWS_YEAR:
			return { ...state, filter: { ...state.filter, year: payload.selected } }

		case LOAD_NEW + START:
			return { ...state, loadingNew: true, loadedNew: false }

		case LOAD_NEW + SUCCESS:
			return {
				...state,
				detail: {
					...state.detail,
					[payload.id]: response
				},
				loadingNew: false,
				loadedNew: true
			}

		case LOAD_NEW + FAIL:
			return {
				...state,
				error
			}
		default:
			return state
	}
}
