import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1
}

export default class HomeNews extends React.Component {
	showList() {
		return this.props.news.map((el) => {
			return (
				<div key={el.id} className="news__slider--item">
					<div className="news__slider--item--title">{el.name}</div>
					<div dangerouslySetInnerHTML={{ __html: el.date }} />
					<br />
					<br />
					<div className="news__slider--item--text" dangerouslySetInnerHTML={{ __html: el.descr }} />
					<br />
					<Link to={`/news/${el.alias}`} className="news__slider--item--link">
						Подробнее
					</Link>
				</div>
			)
		})
	}

	render() {
		return (
			<div className="main__item--news">
				<div className="main__item--title">НОВОСТИ</div>
				<div className="news__slider">
					<Slider {...settings}>{this.showList()}</Slider>
				</div>
			</div>
		)
	}
}
