import { SUCCESS, START, LOAD_GALLERY, TOGGLE_GALLERY } from '../constants'

const defaultObjects = {
	entities: [],
	loading: false,
	loaded: false,
	openId: null
}

export default (state = defaultObjects, action) => {
	const { type, response, payload } = action

	switch (type) {
		case LOAD_GALLERY + START:
			return { ...state, loading: true }

		case LOAD_GALLERY + SUCCESS:
			return { ...state, loading: false, loaded: true, entities: response }

		case TOGGLE_GALLERY:
			return { ...state, openId: payload.id }

		default:
			return state
	}
}
