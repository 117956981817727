import { CHANGE_INFRA_GROUP } from '../constants'

const defaultFilters = {
	selected: []
}

export default (filters = defaultFilters, action) => {
	const { type, payload } = action

	switch (type) {
		case CHANGE_INFRA_GROUP:
			return { ...filters, selected: payload.selected }

		default:
			return filters
	}
}
