import React, { Component } from 'react'
import Slider from 'react-slick'
import Modal from 'react-modal'
import { NavLink } from 'react-router-dom'

Modal.setAppElement('#main')

export default class AboutSliderList extends Component {
	state = {
		open: false,
		openId: null
	}

	openSliderModal = (ev) => {
		ev.preventDefault()
		this.setState({
			openId: ev.currentTarget.getAttribute('value'),
			open: true
		})
	}

	closeSliderModal = (e) => {
		e.preventDefault()
		this.setState({
			open: false,
			openId: null
		})
	}

	showList = () => {
		return this.props.slider.map((item) => {
			return (
				<div key={item.id} className="content__block--slider__item">
					<div value={item.id} onClick={this.openSliderModal} className="video__type">
						<i className="icon-play" />
						<img src={item.img} alt="content_slider" className="slider__item--img" />
					</div>
				</div>
			)
		})
	}

	get video() {
		const { openId } = this.state
		return this.props.slider.filter((el) => el.id === openId && el.video)[0]
	}

	render() {
		const settings = this.props.mobile
			? {
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					className: 'slider variable-width'
			  }
			: {
					slidesToShow: 3,
					className: 'slider variable-width'
			  }
		return (
			<div className="content__block--slider">
				{!this.video || (
					<Modal
						closeTimeoutMS={300}
						className="modalwin about-video"
						isOpen={this.state.open}
						onRequestClose={this.closeSliderModal}
					>
						<NavLink to="#" onClick={this.closeSliderModal} className="close__modal" />

						<div className="modalwin__box modalwin__box--video">
							<div>
								<video width="100%" height="100%" controls={true} autoPlay>
									<source src={this.video['video']} type="video/mp4" />
								</video>
							</div>
						</div>
					</Modal>
				)}

				<div className="box">
					<Slider {...settings}>{this.showList()}</Slider>
				</div>
			</div>
		)
	}
}
