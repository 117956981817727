import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

export default class FindFlat extends Component {
	render() {
		return (
			<Fragment>
				<div className="main__item--findflat">
					<div className="main__item--title" dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
					<Link className="main__item--but main__item--but__star" to="/purchase/promotions">
						<i className="icon-star"> </i>
						<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
					</Link>
					{/*
					<Link className="main__item--but main__item--but__star" to="/purchase/promotions/210">
						<i className="icon-star"> </i>
						<p>Выгода до 315 тысяч рублей</p>
					</Link>*/}
				</div>
			</Fragment>
		)
	}
}
