import { LOAD_HOME, SUCCESS, START } from '../constants'

const defaultObjects = {
	data: {
		news: [],
		items: []
	},
	loading: false,
	loaded: false
}

export default (state = defaultObjects, action) => {
	const { type, response } = action

	switch (type) {
		case LOAD_HOME + START:
			return { ...state, loading: true }

		case LOAD_HOME + SUCCESS:
			return {
				...state,
				data: response,
				loading: false,
				loaded: true
			}

		default:
			return state
	}
}
