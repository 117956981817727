import React, { Component } from 'react'
import { connect } from 'react-redux'
import InputMask from 'react-input-mask'
import Modal from 'react-modal'
import { closeQuestionForm, sendQuestionForm } from '../../actions'

class Question extends Component {
	state = {
		email: '',
		name: '',
		ddu: '',
		phone: '',
		message: '',
		check: 0,
		checkForm: false
	}

	componentDidUpdate(oldProps) {
		if (this.props.form.sent !== oldProps.form.sent && this.props.form.sent) {
			this.setState({
				email: '',
				name: '',
				ddu: '',
				phone: '',
				message: '',
				check: 0,
				checkForm: false
			})
		}
	}

	render() {
		return (
			<div>
				{this.form}
				{this.success}
			</div>
		)
	}

	get success() {
		const { form, closeQuestionForm } = this.props
		return (
			<Modal closeTimeoutMS={300} isOpen={form.sent} className="modalwin modalwin--thanks layout-thx">
				<div onClick={closeQuestionForm} className="overlay__modal" />
				<div onClick={closeQuestionForm} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">
						Спасибо! <br /> Мы свяжемся с вами <br /> в ближайшее время
					</div>
				</div>
			</Modal>
		)
	}

	get form() {
		const { form, closeQuestionForm, mobile } = this.props
		return (
			<Modal closeTimeoutMS={300} className="modalwin modalwin--callback mail-modal" isOpen={form.open}>
				<div onClick={closeQuestionForm} className="overlay__modal" />
				<div onClick={closeQuestionForm} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">Задать вопрос</div>
					<form onSubmit={this.handleSubmit}>
						<div className={this.getClassName('name')}>
							<input
								type="text"
								placeholder="Имя"
								value={this.state.name}
								onChange={this.handleChange('name')}
							/>
						</div>
						<div className={this.getClassName('email')}>
							<input
								type="text"
								placeholder="E-mail"
								value={this.state.email}
								onChange={this.handleChange('email')}
							/>
						</div>
						<div className={this.getClassName('ddu')}>
							<input
								type="text"
								placeholder={
									mobile
										? 'Номер ДДУ или ваш адрес в ЖК'
										: 'Номер ДДУ или ваш адрес в ЖК «Новое Бутово»'
								}
								value={this.state.ddu}
								onChange={this.handleChange('ddu')}
							/>
						</div>
						<div className={this.getClassName('phone')}>
							<InputMask
								type="tel"
								value={this.state.phone}
								onChange={this.handleChange('phone')}
								placeholder="Телефон"
								mask="+7 (999) 999-99-99"
							/>
						</div>
						<div className={this.getClassName('message')}>
							<input
								type="text"
								placeholder="Сообщение"
								value={this.state.message}
								onChange={this.handleChange('message')}
							/>
						</div>

						<div className={`modal__input--checkbox ${this.getClassName('check')}`}>
							<label>
								<input
									onChange={this.handleCheckboxChange('check')}
									value={this.state.check}
									checked={this.state.check === 1}
									type="checkbox"
								/>
								<span className="icon-check-symbol" />Я согласен на обработку персональных данных и с
								условиями пользовательского соглашения
							</label>
						</div>
						<div className="modal__input modal__input--but">
							<button type="submit">
								<i className="ico-4" />
								Отправить
							</button>
						</div>
					</form>
				</div>
			</Modal>
		)
	}

	handleSubmit = (ev) => {
		ev.preventDefault()

		if (!this.isValidForm()) {
			this.setState({
				checkForm: true
			})
			return false
		}

		if (window.Comagic && typeof window.Comagic == 'object') {
			window.Comagic.addOfflineRequest({
				name: this.state.name,
				email: this.state.email,
				phone: this.state.phone,
				message: this.state.message
			})
		}

		this.props.sendQuestionForm({
			name: this.state.name,
			phone: this.state.phone,
			email: this.state.email,
			ddu: this.state.ddu,
			message: this.state.message,
			form: 'Задать вопрос',
			w: 'form',
			id: 'question',
			rel: this.props.data.rel
		})
	}

	isValidForm = () => ['email', 'name', 'phone', 'ddu', 'message', 'check'].every(this.isValidField)

	isValidField = (type) => {
		if (limits[type] && limits[type].regex) return limits[type].regex.test(this.state[type])
		if (limits[type] && limits[type].min) {
			return this.state[type].length > limits[type].min
		}

		if (type === 'check') return !!this.state[type]
	}

	getClassName = (type) => {
		return (!this.state[type].length && !this.state.checkForm) || this.isValidField(type)
			? 'modal__input'
			: 'modal__input error'
	}

	handleChange = (type) => (ev) => {
		const { value } = ev.target
		this.setState({
			[type]: value
		})
	}

	handleCheckboxChange = (type) => (ev) => {
		const { value } = ev.target
		this.setState({
			[type]: +value === 0 ? 1 : 0
		})
	}
}

const limits = {
	phone: {
		regex: /^[0-9()\-+ ]+$/
	},
	email: {
		regex: /^[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+\.[A-Za-z]+$/
	},
	name: {
		regex: /^[а-яА-Яa-zA-Z ]+$/
	},
	ddu: {
		min: 3
	},
	message: {
		min: 5
	}
}

export default connect(
	(state) => ({
		data: state.form.data,
		form: state.form.question,
		formPlaceType: state.form.formPlaceType,
		mobile: state.mobile
	}),
	{ closeQuestionForm, sendQuestionForm }
)(Question)
