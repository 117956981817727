import React, { Component } from 'react'
import SVG from 'svg.js/dist/svg.min.js'
import $ from 'jquery'
import accounting from 'accounting'
const URL = document.location.hostname === 'localhost' ? 'http://butovo.multicontent.ru/' : ''

export default class FloorSvg extends Component {
	componentDidMount() {
		if (!this.container) return null
		this.handleLoad()
	}

	componentDidUpdate() {
		$(this.container)
			.find('svg')
			.remove()
		this.handleLoad()
	}

	shouldComponentUpdate(props) {
		return props.flats !== this.props.flats || props.floor !== this.props.floor
	}

	render() {
		const { img, id } = this.props
		return (
			<div ref={this.setContainerRef} className={`corpus-scheme corpus-scheme__${id}`}>
				<img src={URL + img} alt="" id="floor-img" />
			</div>
		)
	}

	setContainerRef = (ref) => {
		this.container = ref
	}

	createSvg = (width, height) => {
		const { flats, getPage, mobile, floor = 1, id, corpus } = this.props
		this._draw = SVG(this.container).size(width, height)
		this._draw.viewbox(0, 0, width, height)
		const isHorisontal = width - height > 0
		let rect, rect2
		let line
		let title, num, corpus1, corpus2, square1, square2, height1, height2, power1, power2, price, oldPrice

		let wsize = isHorisontal
			? [
					{ w: 389, h: 346 },
					{ w: 372, h: 266 }
			  ]
			: [
					{ w: 289, h: 246 },
					{ w: 272, h: 176 }
			  ]

		let fsize = isHorisontal ? [22, 40, 18] : [18, 36, 14]

		let size1 = isHorisontal
			? [
					{ x: -389, y: 46 },
					{ x: -380, y: 119 },
					{ x: -25, y: 7 },
					{ x: -370, y: 59 },
					{ x: -18, y: 57 },
					{ x: -360, y: 160 },
					{ x: -32, y: 160 },
					{ x: -360, y: 190 },
					{ x: -32, y: 190 },
					{ x: -360, y: 220 },
					{ x: -32, y: 220 },
					{ x: -360, y: 250 },
					{ x: -32, y: 250 },
					{ x: -200, y: 290 },
					{ x: -200, y: 285 },
					{ x: -200, y: 315 }
			  ]
			: [
					{ x: -289, y: 46 },
					{ x: -280, y: 109 },
					{ x: -25, y: 7 },
					{ x: -270, y: 59 },
					{ x: -18, y: 57 },
					{ x: -260, y: 139 },
					{ x: -32, y: 139 },
					{ x: -260, y: 160 },
					{ x: -32, y: 160 },
					{ x: -260, y: 181 },
					{ x: -32, y: 181 },
					{ x: -260, y: 202 },
					{ x: -32, y: 202 },
					{ x: -144, y: 245 },
					{ x: -144, y: 232 },
					{ x: -144, y: 252 }
			  ]

		if (id === '11') {
			wsize = [
				{ w: 229, h: 190 },
				{ w: 212, h: 130 }
			]

			fsize = [12, 25, 10]

			size1 = [
				{ x: -229, y: 46 },
				{ x: -220, y: 95 },
				{ x: -25, y: 7 },
				{ x: -210, y: 59 },
				{ x: -18, y: 57 },
				{ x: -200, y: 110 },
				{ x: -32, y: 110 },
				{ x: -200, y: 130 },
				{ x: -32, y: 130 },
				{ x: -200, y: 150 },
				{ x: -32, y: 150 },
				{ x: -200, y: 170 },
				{ x: -32, y: 170 },
				{ x: -114, y: 195 },
				{ x: -114, y: 188 },
				{ x: -114, y: 208 }
			]
		}

		flats.map((el, i) => {
			if (!el['coords'] && (!el['floor_coords'] || (el['floor_coords'] && !el['floor_coords'].length)))
				return false
			const floor_coords = el['floor_coords']
				? el['floor_coords'].find((el) => +el.floor === +floor).coords
				: null
			let coords = floor_coords ? floor_coords : +floor === 2 ? '' : el['coords']

			const instock = el['instock'] === '1'
			const cursorClass = instock ? '' : 'cursor-default'
			const formatOldPrice = accounting.formatNumber(el.price, 0, ' ')
			const formatPrice = accounting.formatNumber(el.price - el.promotion, 0, ' ')
			const x = +coords.split(',')[0]
			const y = +coords.split(',')[1]
			const position = [x, y]

			const polygon = this._draw
				.polygon(coords)
				.fill(instock ? { color: '#442463', opacity: 0.7 } : 'transparent')
				.on('mouseenter', () => {
					polygon.fill(instock ? { color: '#e2068c', opacity: 1 } : 'transparent').addClass(cursorClass)
					if (!instock) return false

					if (!mobile) {
						rect.fill(instock ? '#e2068c' : 'transparent')
							.move(...position)
							.addClass('active')
							.dx(size1[0].x)
							.dy(size1[0].y)

						rect2
							.move(...position)
							.addClass('active')
							.dx(size1[1].x)
							.dy(size1[1].y)

						line.load(instock ? '/img/commerce/arrow1.svg' : '/img/commerce/arrow2.svg', 24, 51)
							.move(...position)
							.addClass('active')
							.dx(size1[2].x)
							.dy(size1[2].y)

						title
							.move(...position)
							.addClass('active')
							.dx(size1[3].x)
							.dy(size1[3].y)

						const el_num = el.number === 'ДОУ' ? el.number : `№${el.number}`

						num.text((add) => {
							add.tspan(el_num)
						})
							.move(...position)
							.addClass('active')
							.dx(size1[4].x)
							.dy(size1[4].y)

						corpus1
							.move(...position)
							.addClass('active')
							.dx(size1[5].x)
							.dy(size1[5].y)

						corpus2
							.text((add) => {
								add.tspan(el.corpus)
							})
							.addClass('active')
							.move(...position)
							.dx(size1[6].x)
							.dy(size1[6].y)

						square1
							.move(...position)
							.addClass('active')
							.dx(size1[7].x)
							.dy(size1[7].y)

						square2
							.text((add) => {
								add.tspan(`${el.square} м2`)
							})
							.addClass('active')
							.move(...position)
							.dx(size1[8].x)
							.dy(size1[8].y)

						height1
							.move(...position)
							.addClass('active')
							.dx(size1[9].x)
							.dy(size1[9].y)

						height2
							.text((add) => {
								add.tspan(el.height + ' м')
							})
							.addClass('active')
							.move(...position)
							.dx(size1[10].x)
							.dy(size1[10].y)

						power1
							.move(...position)
							.addClass('active')
							.dx(size1[11].x)
							.dy(size1[11].y)

						power2
							.text((add) => {
								add.tspan(`${el.power} кВт`)
							})
							.addClass('active')
							.move(...position)
							.dx(size1[12].x)
							.dy(size1[12].y)

						if (+el.promotion) {
							console.log(size1[13])
							oldPrice
								.text((add) => {
									add.tspan(formatOldPrice)
								})
								.fill('#442463')
								.size(fsize[0] - 1)
								.addClass('line-through active')
								.move(...position)
								.dx(size1[14].x)
								.dy(size1[14].y)

							price
								.text((add) => {
									add.tspan(formatPrice)
								})
								.fill('#e2068c')
								.size(fsize[0] + 2)
								.addClass('active')
								.move(...position)
								.dx(size1[15].x)
								.dy(size1[15].y)
						} else {
							oldPrice
								.text((add) => {
									add.tspan(formatPrice)
								})
								.fill('#e2068c')
								.size(fsize[0])
								.addClass('active')
								.move(...position)
								.dx(size1[13].x)
								.dy(size1[13].y)
						}
					}
				})

				.on('mouseout', () => {
					polygon.fill(instock ? { color: '#442463', opacity: 0.7 } : 'transparent')
					rect.removeClass('active')
					rect2.removeClass('active')
					line.removeClass('active')
					title.removeClass('active')
					num.removeClass('active')
					corpus1.removeClass('active')
					corpus2.removeClass('active')
					square1.removeClass('active')
					square2.removeClass('active')
					height1.removeClass('active')
					height2.removeClass('active')
					power1.removeClass('active')
					power2.removeClass('active')
					price.removeClass('active')
					oldPrice.removeClass('line-through').removeClass('active')
				})

				.on('click', (ev) => {
					if (!instock) return false
					getPage(`/commerce/${el['corpus']}/${el['id']}`)
				})
		})

		// this._draw.image(this.props.img, width, height)

		if (!flats.length) return

		line = this._draw.image('/img/realty/arrow1.svg', 31, 39).addClass('arrow')

		rect = this._draw.rect(wsize[0].w, wsize[0].h)

		rect2 = this._draw.rect(wsize[1].w, wsize[1].h).fill('#fff')

		title = this._draw
			.text((add) => {
				add.tspan('Коммерческое')
				add.tspan('помещение').newLine()
			})
			.size(fsize[0])
			.font({ fill: '#fff', family: 'Acrom', anchor: 'start', leading: '1em' })

		num = this._draw
			.text((add) => {
				add.tspan('№1')
			})
			.size(fsize[1])
			.font({ fill: '#fff', family: 'Acrom', anchor: 'end', leading: '1em' })

		corpus1 = this._draw
			.text((add) => {
				add.tspan('Корпус')
			})
			.size(fsize[2])
			.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })

		corpus2 = this._draw
			.text('')
			.addClass('text-decoration')
			.size(fsize[2])
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'end', leading: '1.5em' })

		height1 = this._draw
			.text((add) => {
				add.tspan('Высота потолков')
			})
			.size(fsize[2])
			.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })

		height2 = this._draw
			.text('')
			.addClass('text-decoration')
			.size(fsize[2])
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'end', leading: '1.5em' })

		square1 = this._draw
			.text((add) => {
				add.tspan('Площадь')
			})
			.size(fsize[2])
			.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })

		square2 = this._draw
			.text('')
			.addClass('text-decoration')
			.size(fsize[2])
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'end', leading: '1.5em' })

		power1 = this._draw
			.text((add) => {
				add.tspan('Эл. мощности')
			})
			.size(fsize[2])
			.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })

		power2 = this._draw
			.text('')
			.addClass('text-decoration')
			.size(fsize[2])
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'end', leading: '1.5em' })

		oldPrice = this._draw
			.text('')
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'middle', leading: '1.5em', weight: 600 })

		price = this._draw
			.text('')
			.font({ fill: '#e2068c', family: 'Acrom', anchor: 'middle', leading: '1.5em', weight: 600 })
	}

	handleLoad = () => {
		const img = new Image()
		img.src = URL + this.props.img
		img.onload = () => {
			this.createSvg(parseInt(img.width), parseInt(img.height))
		}

		// if (URL) setTimeout(() => this.createSvg(parseInt(959), parseInt(674)), 3000)
	}
}
