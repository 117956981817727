export const START = '_START'
export const SUCCESS = '_SUCCESS'
export const FAIL = '_FAIL'
export const CLOSE = '_CLOSE'
export const OPEN = '_OPEN'
export const IS_MOBILE = 'IS_MOBILE'
export const LOAD_GENPLAN = 'LOAD_GENPLAN'
export const GET_FLAT = 'GET_FLAT'
export const GET_FLATS = 'GET_FLATS'
export const SORT_FLATS = 'SORT_FLATS'
export const FORM_MAIL = 'FORM_MAIL'
export const FORM_REQUEST = 'FORM_REQUEST'
export const FORM_CALL = 'FORM_CALL'
export const FORM_REQUEST_LAYOUT = 'FORM_REQUEST_LAYOUT'
export const LOAD_SECTIONS = 'LOAD_SECTIONS'
export const SECTION_CHANGE_ROOMS = 'SECTION_CHANGE_ROOMS'
export const LOAD_ABOUT = 'LOAD_ABOUT'
export const CHANGE_NEWS_YEAR = 'CHANGE_NEWS_YEAR'
export const LOAD_NEWS = 'LOAD_NEWS'
export const LOAD_NEW = 'LOAD_NEW'
export const LOAD_HOME = 'LOAD_HOME'

export const LOAD_INFRA = 'LOAD_INFRA'
export const CHANGE_INFRA_GROUP = 'CHANGE_INFRA_GROUP'
export const LOAD_DEVELOPER = 'LOAD_DEVELOPER'
export const LOAD_RESIDENTAL = 'LOAD_RESIDENTAL'
export const LOAD_GALLERY = 'LOAD_GALLERY'
export const TOGGLE_GALLERY = 'TOGGLE_GALLERY'
export const LOAD_DOCS = 'LOAD_DOCS'
export const CHANGE_DOCS_PAGE = 'CHANGE_DOCS_PAGE'
export const CHANGE_DOCS_TYPE = 'CHANGE_DOCS_TYPE'
export const CHANGE_DOCS_CORPUS = 'CHANGE_DOCS_CORPUS'
export const CHANGE_DOCS_YEAR = 'CHANGE_DOCS_YEAR'
export const LOAD_PROGRESS = 'LOAD_PROGRESS'
export const TOGGLE_PROGRESS_GALLERY = 'TOGGLE_PROGRESS_GALLERY'
export const CHANGE_PROGRESS_YEAR = 'CHANGE_PROGRESS_YEAR'
export const PLAY_PROGRESS_VIDEO = 'PLAY_PROGRESS_VIDEO'
export const CHANGE_PROGRESS_CORPUS = 'CHANGE_PROGRESS_CORPUS'
export const CHANGE_PROGRESS_MONTH = 'CHANGE_PROGRESS_MONTH'

export const CHANGE_ROOMS = 'CHANGE_ROOMS'
export const CHANGE_CORPUS = 'CHANGE_CORPUS'
export const CHANGE_SQUARE = 'CHANGE_SQUARE'
export const CHANGE_FLOOR = 'CHANGE_FLOOR'
export const CHANGE_PRICE = 'CHANGE_PRICE'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const LOAD_MORE = 'LOAD_MORE'
export const SORT_PARAMS_FLATS = 'SORT_PARAMS_FLATS'
export const CHANGE_DECORATION = 'CHANGE_DECORATION'
export const CHANGE_VIEW = 'CHANGE_VIEW'
export const CLEAR_FILTER_LAYOUT = 'CLEAR_FILTER_LAYOUT'
export const CLEAR_FILTER_PARAMS = 'CLEAR_FILTER_PARAMS'
export const GET_LAYOUT = 'GET_LAYOUT'
export const CHANGE_LAYOUT_DECORATION = 'CHANGE_LAYOUT_DECORATION'
export const LOAD_PURCHASE = 'LOAD_PURCHASE'
export const LOAD_MORTGAGE = 'LOAD_MORTGAGE'
export const SORT_BANK = 'SORT_BANK'
export const LOAD_PAYMENT = 'LOAD_PAYMENT'
export const LOAD_PROMOTIONS = 'LOAD_PROMOTIONS'
export const LOAD_PROMOTION = 'LOAD_PROMOTION'
export const GET_COMMERCE_FLAT = 'GET_COMMERCE_FLAT'
export const GET_COMMERCE = 'GET_COMMERCE'

export const CHANGE_COMMERCE_CORPUS = 'CHANGE_COMMERCE_CORPUS'
export const CHANGE_COMMERCE_SQUARE = 'CHANGE_COMMERCE_SQUARE'
export const CHANGE_COMMERCE_PRICE = 'CHANGE_COMMERCE_PRICE'
export const CLEAR_COMMERCE_FILTER = 'CLEAR_COMMERCE_FILTER'
export const LOAD_COMMERCE_MORE = 'LOAD_COMMERCE_MORE'
export const GET_COMMERCE_CORPUS = 'GET_COMMERCE_CORPUS'
export const SORT_COMMERCE_FLATS = 'SORT_COMMERCE_FLATS'
export const GET_PARKING = 'GET_PARKING'
export const FORM_PARKING = 'FORM_PARKING'
export const GET_DECORATION = 'GET_DECORATION'
export const LOAD_CONTACTS = 'LOAD_CONTACTS'
export const LOAD_PRIVACY = 'LOAD_PRIVACY'
export const LOAD_AGREEMENT = 'LOAD_AGREEMENT'
export const FORM_DATA = 'FORM_DATA'
export const CHANGE_PROMOTION = 'CHANGE_PROMOTION'
export const SET_FORM_PLACE_TYPE = 'SET_FORM_PLACE_TYPE'

export const POPUP_SCHEME = 'POPUP_SCHEME'
export const CHANGE_MORTAGE_PRICE = 'CHANGE_MORTAGE_PRICE'
export const CHANGE_MORTAGE_CONTRIBUTION = 'CHANGE_MORTAGE_CONTRIBUTION'
export const CHANGE_MORTAGE_TERM = 'CHANGE_MORTAGE_TERM'
export const CHANGE_MORTAGE_BANK = 'CHANGE_MORTAGE_BANK'
export const POPUP_INSTALLMENTS = 'POPUP_INSTALLMENTS'
export const PENDING = 'PENDING'
export const REALTY = 'REALTY'
export const SET_COMMERCE = 'SET_COMMERCE'
export const CHANGE_COMMERCE_PROMOTION = 'CHANGE_COMMERCE_PROMOTION'
export const CHANGE_MORTAGE_RATE = 'CHANGE_MORTAGE_RATE'
export const CHANGE_MORTAGE_FAMILY_RATE = 'CHANGE_MORTAGE_FAMILY_RATE'
export const QUESTION = 'QUESTION'

export const LOAD_FAQ = 'LOAD_FAQ'

export const FORM_SUBSCRIBE = 'FORM_SUBSCRIBE'
