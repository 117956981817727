import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getPage } from '../../actions'
import { withRouter } from 'react-router-dom'

const links = {
	genplan: {
		title: 'На генплане',
		icon: '1'
	},
	params: {
		title: 'По параметрам',
		icon: '2'
	},
	layout: {
		title: 'По видам планировок',
		icon: '3'
	}
}
class Panel extends Component {
	render() {
		if (this.props.mobile) return <div>{this.mobilePanel}</div>
		return (
			<div className="realty-panel">
				<div className="realty-panel__item">
					<NavLink to="/realty/genplan">
						<span className="realty-panel__icon1 ico-1">На генплане</span>
					</NavLink>
				</div>
				<div className="realty-panel__item">
					<NavLink to="/realty/params">
						<span className="realty-panel__icon2 ico-2">По параметрам</span>
					</NavLink>
				</div>
				<div className="realty-panel__item">
					<NavLink to="/realty/layout">
						<span className="realty-panel__icon3 ico-3">По видам планировок</span>
					</NavLink>
				</div>
			</div>
		)
	}
	get mobilePanel() {
		const path = this.props.match.path
		const selected = path.includes('layout') ? 'layout' : path.includes('params') ? 'params' : 'genplan'

		return (
			<div className="select-mobile select-mobile--realty select-mobile--break">
				<span className="select-mobile__title">
					<span>{links[selected].title}</span>
				</span>
				<select value={'/realty/' + selected} onChange={this.handleChange}>
					<option value="/realty/genplan">На генплане</option>
					<option value="/realty/params">По параметрам</option>
					<option value="/realty/layout">По видам планировок</option>
				</select>
				<i className={`select-mobile__icon  ico-${links[selected].icon}`} />
				<span className="select__indicator select__indicator--mobile" />
			</div>
		)
	}

	handleChange = (ev) => {
		this.props.getPage(ev.target.value)
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile
	}),
	{ getPage }
)(withRouter(Panel))
