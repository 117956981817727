import {
	LOAD_MORTGAGE,
	SUCCESS,
	START,
	SORT_BANK,
	CHANGE_MORTAGE_PRICE,
	CHANGE_MORTAGE_CONTRIBUTION,
	CHANGE_MORTAGE_TERM,
	CHANGE_MORTAGE_BANK,
	REALTY,
	SET_COMMERCE,
	CHANGE_MORTAGE_FAMILY_RATE,
	CHANGE_MORTAGE_RATE
} from '../constants'

const defaultFilters = {
	sort: {
		field: null,
		order: 'desc'
	},
	price: 20,
	contribution: 50,
	term: 6,

	commerce: false,
	price2: 6,
	payment: 50,
	term2: 72,
	rate: 11,
	family_rate: 6,

	bank: null
}

export default (filters = defaultFilters, action) => {
	const { type, response, payload } = action

	switch (type) {
		case CHANGE_MORTAGE_PRICE:
			return { ...filters, price: payload.price }

		case CHANGE_MORTAGE_CONTRIBUTION:
			return { ...filters, contribution: payload.contribution }

		case CHANGE_MORTAGE_TERM:
			return { ...filters, term: payload.term }

		case CHANGE_MORTAGE_BANK:
			return { ...filters, bank: payload.bank }

		case SORT_BANK:
			return {
				...filters,
				sort: {
					field: payload.field,
					order: payload.order
				}
			}

		case CHANGE_MORTAGE_PRICE + REALTY:
			return { ...filters, price2: payload.price2 }

		case CHANGE_MORTAGE_CONTRIBUTION + REALTY:
			return { ...filters, payment: payload.payment }

		case CHANGE_MORTAGE_TERM + REALTY:
			return { ...filters, term2: payload.term2 }

		case CHANGE_MORTAGE_RATE + REALTY:
			return { ...filters, rate: payload.rate }

		case CHANGE_MORTAGE_FAMILY_RATE + REALTY:
			return { ...filters, family_rate: payload.family_rate }

		case SET_COMMERCE:
			return {
				...filters,
				bank: null,
				commerce: payload.commerce
			}

		default:
			return filters
	}
}
