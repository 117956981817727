import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPage, changeMortgageBank } from '../../actions'

class Row extends Component {
	render() {
		const { item } = this.props
		if (!item) return null
		return (
			<tr
				className={
					this.props.commerce && this.props.bank && this.props.bank.id === item.id
						? 'flat-list__item flat-list__item_choosen'
						: 'flat-list__item'
				}
				onClick={() => this.changeBank(item)}
			>
				<td>
					<img src={item.img} alt="" />
				</td>
				<td>{item.rate ? 'от ' + item.rate.replace('.', ',') + ' %' : '-'}</td>
				{!this.props.commerce && <td>{item.family ? item.family.replace('.', ',') + ' %' : '-'}</td>}
				<td>{item.payment} %</td>
				<td>До {item.term} лет</td>
			</tr>
		)
	}

	changeBank(bank) {
		this.props.changeMortgageBank(bank)
	}
}

export default connect(
	(state) => ({
		commerce: state.mortgageFilter.commerce
	}),
	{ getPage, changeMortgageBank }
)(Row)
