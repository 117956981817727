import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changePromotion } from '../../actions'

class PromotionFilter extends Component {
	render() {
		const { promotion } = this.props
		return (
			<div className="params-decoration-field">
				<div>
					<div value="1" className={promotion === '1' ? 'check active' : 'check'} onClick={this.handleChange}>
						<span>Акция</span>
						<i />
					</div>
				</div>
			</div>
		)
	}

	handleChange = (e) => {
		const checked = e.currentTarget.classList.contains('active')
		this.props.changePromotion(checked ? null : e.currentTarget.getAttribute('value'))
	}
}

export default connect(
	(state) => ({
		promotion: state.filters.promotion
	}),
	{ changePromotion }
)(PromotionFilter)
