import { SUCCESS, START, GET_LAYOUT } from '../constants'

const defaultObjects = {
	entities: [
		{
			id: null,
			name: null,
			plan: null,
			descr: null
		}
	],
	loading: false,
	loaded: false
}

export default (state = defaultObjects, action) => {
	const { type, response } = action

	switch (type) {
		case GET_LAYOUT + START:
			return { ...state, loading: true }

		case GET_LAYOUT + SUCCESS:
			return { ...state, loading: false, loaded: true, entities: response }

		default:
			return state
	}
}
