import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import 'rc-slider/assets/index.css'
import { changeMortgageTerm } from '../../actions'
import { termMortgageSelector } from '../../selectors'
const SliderWithTooltip = Slider.createSliderWithTooltip(Slider)

class TermFilter extends Component {
	handleChange = (value) => {
		this.props.changeMortgageTerm(value)
	}

	render() {
		const { term } = this.props
		const value = [term || 6]
		return (
			<SliderWithTooltip
				min={3}
				max={15}
				value={value}
				onChange={this.handleChange}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
			/>
		)
	}
}

export default connect(
	(state) => ({
		term: state.mortgageFilter.term
	}),
	{ changeMortgageTerm }
)(TermFilter)
