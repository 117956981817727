import { START, SUCCESS, FAIL, PENDING } from '../constants'
import $ from 'jquery'
const domain = document.location.hostname === 'localhost' ? 'http://butovo.multicontent.ru' : ''
const API = domain + '/ajax/json.php'
const FORM_API = domain + '/pdf/'

export default (store) => (next) => (action) => {
	const { callAPI, form, type, w, ...rest } = action

	if (!callAPI) return next(action)

	next({
		type: type + START,
		...rest
	})

	if (form) {
		$.get(FORM_API, { ...callAPI })
			.done((response) => response.data)
			.done((response) => next({ ...rest, type: type + SUCCESS, response }))
			.fail((error) => next({ ...rest, type: type + FAIL, error }))
	} else {
		next({ ...rest, type: type + PENDING })
		$.get(API, { ...callAPI })
			.done((response) => response.data)
			.done((response) => next({ ...rest, type: type + SUCCESS, response }))
			.fail((error) => next({ ...rest, type: type + FAIL, error }))
	}
}
