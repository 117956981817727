import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Comfort extends Component {
	render() {
		return (
			<div className="main__item--comfort">
				<div className="main__item--title" dangerouslySetInnerHTML={{ __html: this.props.el.title }} />
				<div className="mw400" dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
				<Link
					className="main__item--but"
					to={{ pathname: '/about/residential', state: { scrollTo: 'icons-scroll-healper' } }}
				>
					<i className="ico-60"> </i>
					<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
				</Link>
			</div>
		)
	}
}
