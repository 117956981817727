import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader from '../common/loader'
import { loadNews, changeNewsYear, getPage, openSubscribeForm } from '../../actions'
import { filtratedNewsSelector, newsYearOptionsSelector, newsYearSelector } from '../../selectors'
import Fade from 'react-reveal/Fade'
import ScrollTop from '../common/scroll-top'
import { hit } from '../common/metrics'

class News extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Новости. Страница ' + this.props.page
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Здесь Вы можете ознакомиться с новостями ЖК «Новое Бутово». ' + this.props.page + ' страница'
			)
		hit(window.location.pathname)

		const { loading, loaded, loadNews } = this.props
		if (!loading && !loaded) loadNews()
	}

	render() {
		const { loading, loaded, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />
		return (
			<div className="content__block news">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Новости</h1>
						<div className="breadcrumb__links">
							<NavLink to="/">Главная</NavLink> / Новости
						</div>
					</div>
					{mobile && (
						<span className="btn btn_subscribe" onClick={() => this.openModal()}>
							Подписаться на новости
						</span>
					)}
					{mobile ? this.mobilePanel : <div className="news__panel">{this.panel}</div>}
					<Fade key={this.props.selectedYear}>
						<div className="news__wrap">
							{!mobile && (
								<span className="btn btn_subscribe" onClick={() => this.openModal()}>
									Подписаться на новости
								</span>
							)}
							<div className="news__body"> {this.body} </div>
						</div>
					</Fade>
					<div className="news__paginator">{this.paginator}</div>
				</div>
			</div>
		)
	}

	openModal() {
		let outer = document.createElement('div')
		outer.style.visibility = 'hidden'
		outer.style.width = '100px'
		outer.style.msOverflowStyle = 'scrollbar'
		document.body.appendChild(outer)
		let widthNoScroll = outer.offsetWidth
		outer.style.overflow = 'scroll'
		let inner = document.createElement('div')
		inner.style.width = '100%'
		outer.appendChild(inner)
		let widthWithScroll = inner.offsetWidth
		outer.parentNode.removeChild(outer)

		let scroll = window.pageYOffset

		document
			.getElementById('header')
			.setAttribute('style', 'width:calc(100% - ' + (widthNoScroll - widthWithScroll) + 'px)')
		document.querySelector('body').setAttribute('data-scroll', scroll)
		document
			.querySelector('body')
			.setAttribute(
				'style',
				'width:calc(100% - ' +
					(widthNoScroll - widthWithScroll) +
					'px);position:fixed;overflow:hidden;top:' +
					-scroll +
					'px'
			)
		document.getElementById('menu').setAttribute('style', 'right:' + (widthNoScroll - widthWithScroll) + 'px;')

		this.props.openSubscribeForm()
	}

	get mobilePanel() {
		const { selectedYear: selected } = this.props
		return (
			<div className="select-mobile">
				<select value={selected ? selected : ''} onChange={this.handleMobileChange}>
					{this.mobileOptions}
				</select>
				<i className="select-mobile__icon ico-61" />
				<span className="select__indicator select__indicator--mobile" />
			</div>
		)
	}

	handleMobileChange = (e) => {
		const { changeNewsYear, getPage } = this.props
		changeNewsYear(e.target.value)
		getPage('/news/1')
	}

	get mobileOptions() {
		const { options } = this.props
		return Object.keys(options)
			.reverse()
			.map((year) => (
				<option key={year} value={year}>
					{year} год
				</option>
			))
	}

	get panel() {
		const { selectedYear: selected, options } = this.props
		return Object.keys(options)
			.reverse()
			.map((year) => (
				<Link
					key={year}
					to="/news/1"
					onClick={this.handleChangeYear}
					value={year}
					className={selected === year ? 'active' : ''}
				>
					<span>{year}</span>
				</Link>
			))
	}

	handleChangeYear = (e) => {
		const value = e.currentTarget.getAttribute('value')
		this.props.changeNewsYear(value)
	}

	get body() {
		const { page, filtrated } = this.props
		const offset = (page - 1) * limit
		return filtrated
			.filter((el, i) => !page || (i >= offset && i < offset + limit))
			.map((item) => {
				return (
					<div key={item.id} className="news__item">
						<span className="news__item__image">
							<span style={{ backgroundImage: `url(${item.img})` }} />
						</span>
						<span className="news__date">{item['date']}</span>
						<span className="news__name">
							<Link to={`/news/${item.alias}`}>
								<span>{item['name']}</span>
							</Link>
						</span>
						<span className="news__description" dangerouslySetInnerHTML={{ __html: item['descr'] }} />
						<span className="news__btn">
							<Link to={`/news/${item.alias}`}>Подробнее</Link>
						</span>
					</div>
				)
			})
	}

	get paginator() {
		const total = this.props.filtrated.length
		if (total <= limit) return null
		return new Array(Math.floor((total - 1) / limit) + 1).fill().map((_, i) => (
			<NavLink key={i} to={`/news/${i + 1}`}>
				<span>{i + 1}</span>
			</NavLink>
		))
	}
}

const limit = 4

export default connect(
	(state, props) => ({
		filtrated: filtratedNewsSelector(state),
		options: newsYearOptionsSelector(state),
		loading: state.newsPage.loading,
		loaded: state.newsPage.loaded,
		selectedYear: newsYearSelector(state),
		mobile: state.mobile
	}),
	{ loadNews, changeNewsYear, getPage, openSubscribeForm }
)(News)
