import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
ReactGA.initialize('UA-111406359-1', { debug: false })

const history = createBrowserHistory()
history.listen((location, action) => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

export default history
