import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import reducer from '../reducers'
import history from '../history'
import api from '../middlewares/api'
import viewed from '../middlewares/viewed'
import commerce from '../middlewares/viewed-commerce'

const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose

const enhancer = composeEnhancers(applyMiddleware(thunk, routerMiddleware(history), api, viewed, commerce))

const store = createStore(reducer, enhancer)

//for DEV
window.store = store

export default store
