import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import $ from 'jquery'

class ScrollTop extends Component {
	componentDidMount() {
		const menu = $('.content__menu')
		if (this.props.mobile && menu.length) {
			window.scrollTo(0, 0)
			//$('html, body').animate({ scrollTop: menu.offset().top + menu.outerHeight() - 80 }, 600)
		} else {
			window.scrollTo(0, 0)
		}
	}

	componentDidUpdate(oldProps) {
		if (this.props.location !== oldProps.location) {
			$('html, body').animate({ scrollTop: 0 }, 600)
		}
	}

	render() {
		return null
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile
	}),
	{}
)(withRouter(ScrollTop))
