import { SUCCESS, START, GET_PARKING } from '../constants'

const defaultObjects = {
	entities: [
		{
			id: null,
			number: null,
			floor: null,
			square: null,
			price: null,
			coords: null,
			instock: null
		}
	],
	loading: false,
	loaded: false
}

export default (state = defaultObjects, action) => {
	const { type, response } = action

	switch (type) {
		case GET_PARKING + START:
			return { ...state, loading: true }

		case GET_PARKING + SUCCESS:
			return { ...state, loading: false, loaded: true, entities: response }

		default:
			return state
	}
}
