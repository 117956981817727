export default [
	{
		id: 1,
		img: '/img/logo_items/logo_i1.svg'
	},
	{
		id: 2,
		img: '/img/logo_items/logo_i2.svg'
	},
	{
		id: 3,
		img: '/img/logo_items/logo_i3.svg'
	},

	{
		id: 4,
		img: '/img/logo_items/logo_i4.svg'
	},

	{
		id: 5,
		img: '/img/logo_items/logo_i5.svg'
	},

	{
		id: 6,
		img: '/img/logo_items/logo_i6.svg'
	},

	{
		id: 7,
		img: '/img/logo_items/logo_i7.svg'
	},

	{
		id: 8,
		img: '/img/logo_items/logo_i8.svg'
	}
]
