import React from 'react'
import { NavLink } from 'react-router-dom'
import { aboutMenu } from './data'

function Menu() {
	return aboutMenu.map((el) => {
		return (
			<NavLink key={el.id} to={el.href}>
				<i className={'icon-i' + el.id} />
				<span dangerouslySetInnerHTML={{ __html: el.name }} />
			</NavLink>
		)
	})
}

export default Menu
