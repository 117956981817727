import React, { Component } from 'react'
import Slider from '../../common/slider'

class Gallery extends Component {
	state = {
		open: false,
		current: null
	}

	render() {
		return (
			<div className="commerce-gallery">
				<div className="commerce-gallery__thumbs">{this.gallery}</div>
				{this.modal}
			</div>
		)
	}

	get gallery() {
		const { gallery, mobile } = this.props
		if (!gallery.length) return null
		return (
			<Slider
				items={this.items}
				options={{ arrows: true, slidesToShow: 3, slidesToScroll: 1, vertical: mobile ? false : true }}
			/>
		)
	}

	get items() {
		return this.props.gallery.map((el, i) => (
			<div className="slider__item" key={el.id} onClick={this.handleToggle} current={i}>
				<div>
					<div style={{ backgroundImage: `url(${el.thumb})` }} />
				</div>
			</div>
		))
	}

	get modal() {
		return (
			<div className={this.state.open ? 'modal active' : 'modal'}>
				<div className="modal-overlay" onClick={this.handleToggle} />
				<div className="modal__body">
					<div className="modal__inner">
						<div className="close__modal" onClick={this.handleToggle} />
						<Slider
							ref="slider"
							initialSlide={this.state.current}
							items={this.modalItems}
							options={{ arrows: true }}
							key={this.props.openId}
						/>
					</div>
				</div>
			</div>
		)
	}

	get modalItems() {
		return this.props.gallery.map((el, i) => (
			<div className="slider__item" key={el.id}>
				<div>
					<div style={{ backgroundImage: `url(${el.large})` }}>
						<div className="slider__item__gallery-text">
							<div dangerouslySetInnerHTML={{ __html: el.title }} />
							<div>
								<span>{i + 1}</span> / {this.props.gallery.length}
							</div>
						</div>
					</div>
				</div>
			</div>
		))
	}

	handleToggle = (ev) => {
		this.setState({
			open: !this.state.open,
			current: ev.currentTarget.getAttribute('current')
		})
		// this.refs.slider.slickGoTo(this.props.someIndex);
	}
}

export default Gallery
