import React, { Component } from 'react'
import { connect } from 'react-redux'
import RoomsFilter from './rooms-filter'
import CorpusFilter from './corpus-filter'
import FloorFilter from './floor-filter'
import SquareFilter from './square-filter'
import PriceFilter from './price-filter'
import DecorationFilter from './decoration-filter'
import PromotionFilter from './promotion-filter'
import { clearFilter } from '../../actions'

class Filters extends Component {
	render() {
		return (
			<div>
				<div className="filter">
					<div className="filter__field">
						<div className="filter__field__title">Кол-во комнат</div>
						<RoomsFilter />
					</div>
					<div className="filter__field">
						<div className="filter__field__title">Корпус</div>
						<CorpusFilter />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">
							Площадь, м<sup>2</sup>
						</div>
						<SquareFilter />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Этаж</div>
						<FloorFilter />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Стоимость, млн руб.</div>
						<PriceFilter />
					</div>
				</div>
				<div className="filter-bottom">
					<DecorationFilter />
					<PromotionFilter />
					<div className="clear-filter" onClick={this.props.clearFilter}>
						<span>Сбросить</span>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(null, { clearFilter })(Filters)
