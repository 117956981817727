import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeLayoutDecoration } from '../../actions'

class DecorationRadioFilter extends Component {
	render() {
		const { decoration } = this.props

		return (
			<div className="filter__radio__box">
				<div className="filter__radio__field">
					<div
						value="1"
						className={decoration === '1' ? 'check active' : 'check'}
						onClick={this.handleChange}
					>
						<div className="filter__field__title">С отделкой</div>
						<i />
					</div>
				</div>
				<div className="filter__radio__field">
					<div
						value="0"
						className={decoration === '0' ? 'check active' : 'check'}
						onClick={this.handleChange2}
					>
						<div className="filter__field__title">Без отделки</div>
						<i />
					</div>
				</div>
			</div>
		)
	}

	handleChange = (ev) =>
		this.props.changeLayoutDecoration(this.props.decoration === '1' ? null : ev.currentTarget.getAttribute('value'))

	handleChange2 = (ev) =>
		this.props.changeLayoutDecoration(this.props.decoration === '0' ? null : ev.currentTarget.getAttribute('value'))
}

export default connect(
	(state) => ({
		decoration: state.filters.layoutDecoration
	}),
	{ changeLayoutDecoration }
)(DecorationRadioFilter)
