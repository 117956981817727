import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { buildingsMapSelector, sectionsInCorpus } from '../../selectors'
import { sectionChangeRooms, getPage } from '../../actions'
import Plan from './scheme'

class Corpus extends Component {
	render() {
		const { loading, loaded } = this.props
		if (loading || !loaded) return null

		return <div>{this.body}</div>
	}

	get body() {
		const { buildings, corpus, sections, hidePlan } = this.props
		const list = this.inStock()
		const position = list.indexOf(corpus)
		const prev = position > 0 ? list[position - 1] : null
		const next = position < list.length - 1 ? list[position + 1] : null
		return (
			<div className="corpus-list">
				<div className="corpus-list__title">
					<div className="section__arrow ico-6">
						{!prev || <Link to={`/realty/genplan/${prev}/`} className="ico-6" onClick={this.handleChane} />}
					</div>
					<div className="corpus-list__title__text">
						<span>{corpus}</span> корпус
					</div>
					<div className="section__arrow ico-7">
						{!next || <Link to={`/realty/genplan/${next}/`} className="ico-7" onClick={this.handleChane} />}
					</div>
				</div>
				{this.plans(list, position, buildings, hidePlan)}
			</div>
		)
	}

	inStock = () => {
		const { buildings } = this.props
		return Object.keys(buildings).filter((el) => {
			return buildings[el]['rooms'].reduce((acc, a) => +a + +acc) > 0
		})
	}

	plans = (list, position, buildings, hidePlan) => {
		if (hidePlan) {
			return false
		}
		return (
			<div className="corpus-list__body">
				{list.map((el, i) => {
					return (
						<div
							key={el}
							className={`corpus-list__body__item corpus-list__body__item${el}  ${
								i === position ? 'active' : ''
							}`}
							value={el}
							onClick={this.handleClick}
						>
							<div className="corpus-plan">
								<img src={buildings[el]['plan']} />
								{/*<Plan sections={buildings[el]['sections']} img={buildings[el]['plan']} />*/}
							</div>
							{/*<span>{el}</span>*/}
						</div>
					)
				})}
			</div>
		)
	}

	handleClick = (ev) => this.props.getPage(`/realty/genplan/${ev.currentTarget.getAttribute('value')}/1`)

	handleChane = () => this.props.sectionChangeRooms([])
}

export default connect(
	(state, props) => ({
		loading: state.genplan.loading,
		loaded: state.genplan.loaded,
		buildings: buildingsMapSelector(state),
		sections: sectionsInCorpus(state, props)
	}),
	{ sectionChangeRooms, getPage }
)(Corpus)
