import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AboutMenuList from '../about-menu-list'
import Loader from '../../common/loader'
import { loadResidental } from '../../../actions'
import ScrollTop from '../../common/scroll-top'
import AboutFlatChooseList from '../about-choose-flat'
import AboutAdvList from '../about-advants'
import Fade from 'react-reveal/Fade'
import Slider from '../../common/slider'
import { hit } from '../../common/metrics'
import $ from 'jquery'

class Residental extends Component {
	componentDidMount() {
		document.title = 'Подробно о ЖК «Новое Бутово»'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'В жилом комплексе «Новое Бутово» уже построены и заселены 12 корпусов, обустроена внутренняя инфраструктура, детские площадки и прогулочные зоны, работают социальные объекты, кафе и магазины, достраиваются последние корпуса'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadResidental } = this.props
		if (!loading && !loaded) loadResidental()

		if (loaded && this.props.location.state && this.props.location.state.scrollTo) {
			$('html, body').animate({
				scrollTop: $('.' + this.props.location.state.scrollTo).offset().top - $('.header').height() - 50
			})
		}
	}
	componentDidUpdate() {
		const { loaded } = this.props
		if (loaded && this.props.location.state && this.props.location.state.scrollTo) {
			$('html, body').animate({
				scrollTop: $('.' + this.props.location.state.scrollTo).offset().top - $('.header').height() - 50
			})
		}
	}
	render() {
		const { loading, loaded, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<section className="content__block residental">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>О проекте</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/about">О проекте</Link> / О жилом комплексе
						</div>
					</div>
				</div>
				<AboutMenuList />
				<Fade>{this.block}</Fade>
				<span className="icons-scroll-healper" />
				{mobile ? (
					<div className="box residental-slider">
						<Slider items={this.icons} options={{ arrows: true }} />
					</div>
				) : (
					<div className="residental__icons box">{this.icons}</div>
				)}
				<AboutFlatChooseList />
				<AboutAdvList />
			</section>
		)
	}

	get block() {
		const { residental, mobile } = this.props
		if (!residental.title) return null
		return (
			<div className="residental__block box" style={{ backgroundImage: `url(${residental.img})` }}>
				{!mobile || (
					<div className="residental__block__image" style={{ backgroundImage: `url(${residental.img})` }} />
				)}

				<div className="residental__text">
					<div>
						<div className="residental__title" dangerouslySetInnerHTML={{ __html: residental.title }} />
						<div dangerouslySetInnerHTML={{ __html: residental.text }} />
					</div>
				</div>
			</div>
		)
	}

	get icons() {
		const icons = [
			{
				title: 'Готовая развитая инфраструктура',
				ico: 1
			},
			{
				title: 'Благоприятная экология (в окружении лесопарков)',
				ico: 27
			},
			{
				title: 'Московская прописка',
				ico: 28
			},
			{
				title: 'Метро в пешей доступности',
				ico: 26
			},
			{
				title: 'Три вида дизайнерской отделки',
				ico: 29
			},
			{
				title: 'Многоуровневый паркинг',
				ico: 30
			},
			{
				title: 'Продуманные планировки',
				ico: 31
			},
			{
				title: 'Надежный застройщик',
				ico: 32
			}
		]
		return icons.map((el, i) => {
			return (
				<div className="residental__icon" key={i}>
					<span className={`ico-${el.ico}`}>
						<span>{el.title}</span>
					</span>
				</div>
			)
		})
	}
}

export default connect(
	(state) => ({
		loading: state.residental.loading,
		loaded: state.residental.loaded,
		residental: state.residental.data,
		mobile: state.mobile
	}),
	{ loadResidental }
)(Residental)
