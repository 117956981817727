import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import { priceMinSelector, priceMaxSelector } from '../../selectors'
import { changeMortgagePrice2 } from '../../actions'
import accounting from 'accounting'
import 'rc-slider/assets/index.css'
const Range = Slider.createSliderWithTooltip(Slider.Range)
const SliderWithTooltip = Slider.createSliderWithTooltip(Slider)

class PriceFilter extends Component {
	handleChange = (value) => {
		this.props.changeMortgagePrice2(value)
	}

	render() {
		const { price } = this.props
		return (
			<SliderWithTooltip
				min={Math.floor(this.props.min * 10) / 10}
				max={this.props.max}
				value={price}
				onChange={this.handleChange}
				step={0.1}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
			/>
		)
	}
}

export default connect(
	(state) => ({
		price: state.mortgageFilter.price2,
		min: priceMinSelector(state),
		max: priceMaxSelector(state)
	}),
	{ changeMortgagePrice2 }
)(PriceFilter)
