import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeLayoutDecoration } from '../../actions'

class DecorationFilter extends Component {
	render() {
		const { decoration } = this.props

		return (
			<div className="params-decoration-field">
				<div>
					<div
						value="1"
						className={decoration === '1' ? 'check active' : 'check'}
						onClick={this.handleChange}
					>
						<span>С отделкой</span>
						<i />
					</div>
				</div>
				<div>
					<div
						value="0"
						className={decoration === '0' ? 'check active' : 'check'}
						onClick={this.handleChange}
					>
						<span>Без отделки</span>
						<i />
					</div>
				</div>
			</div>
		)
	}

	handleChange = (e) => {
		const checked = e.currentTarget.classList.contains('active')
		this.props.changeLayoutDecoration(checked ? null : e.currentTarget.getAttribute('value'))
	}
}

export default connect(
	(state) => ({
		decoration: state.filters.layoutDecoration
	}),
	{ changeLayoutDecoration }
)(DecorationFilter)
