import { createSelector } from 'reselect'
import { objectToMap, arrayToMap, compare, distinct } from './utils'
import React from 'react'

const sortingTable = (items, sort) => {
	return items.sort((a, b) => {
		let a1 = Number(parseFloat(a[sort.field]).toFixed(2)) || 0
		let b1 = Number(parseFloat(b[sort.field]).toFixed(2)) || 0

		return sort.order === 'desc' ? a1 - b1 : b1 - a1
	})
}
export const sortSelector = (state) => state.sorting
export const flatsSelector = (state) => state.flats.entities
export const detailSelector = (state) => state.flats.detail
export const flatSelector = (_, props) => props.flat
export const viewedIdsSelector = (state) => state.flats.viewed

export const corpusSelector = (_, props) => props.corpus
export const sectionSelector = (_, props) => props.section

export const filtersSelector = (state) => state.filters
export const filterCorpusSelector = (state) => state.filters.corpus

export const flatsOnsaleSelector = createSelector(flatsSelector, (flats) =>
	flats.filter((object) => object.instock === '1')
)

export const sectionsInCorpus = createSelector(flatsOnsaleSelector, corpusSelector, (flats, corpus) => {
	const filtrated = flats.filter((object) => object.corpus === corpus)
	return Object.keys(arrayToMap(filtrated, 'section'))
})

// export const sectionOptions = createSelector(
// 	flatsOnsaleSelector,
// 	(flats) =>
// 		distinct(flats, 'corpus')
// 			.slice()
// 			.sort((a, b) => compare(a['corpus'], b['corpus']))
// )

export const corpusOptions = createSelector(flatsOnsaleSelector, (flats) =>
	distinct(flats, 'corpus')
		.slice()
		.sort((a, b) => compare(a['corpus'], b['corpus']))
)
export const roomsOptions = createSelector(flatsOnsaleSelector, (flats) =>
	distinct(flats, 'rooms')
		.slice()
		.sort((a, b) => compare(a['rooms'], b['rooms']))
)

export const floorMinSelector = createSelector(flatsOnsaleSelector, (flats) => {
	return flats.reduce((min, el) => Math.min(min, el['floor']), flats[0]['floor'])
})

export const floorMaxSelector = createSelector(flatsOnsaleSelector, (flats) => {
	return flats.reduce((max, el) => Math.max(max, el['floor']), flats[0]['floor'])
})

export const squareMinSelector = createSelector(flatsOnsaleSelector, (flats) => {
	return parseFloat(flats.reduce((min, el) => Math.min(min, el['square']), flats[0]['square']))
})
export const squareMaxSelector = createSelector(flatsOnsaleSelector, (flats) => {
	return parseFloat(flats.reduce((max, el) => Math.max(max, el['square']), flats[0]['square']))
})

export const priceMinSelector = createSelector(flatsOnsaleSelector, (flats) => {
	return parseFloat(flats.reduce((min, el) => Math.min(min, el['price']), flats[1]['price']) / 1000000)
})
export const priceMaxSelector = createSelector(flatsOnsaleSelector, (flats) => {
	return parseFloat(flats.reduce((max, el) => Math.max(max, el['price']), flats[0]['price']) / 1000000)
})
export const filtratedFlatsSelector = createSelector(flatsOnsaleSelector, filtersSelector, (flats, filters) => {
	const { rooms, corpus, floor, square, price, sort, layoutDecoration, promotion } = filters

	if (!flats) return null

	const filtrated = flats.filter((object) => {
		return (
			// (!decoration || decoration === object.decoration) &&
			(!promotion || (promotion === '1' && +object.promotion > 0)) &&
			(!layoutDecoration || layoutDecoration === object.decoration) &&
			(!rooms.length || rooms.find((value) => value === object.rooms)) &&
			(!corpus.length || corpus.find((value) => value === object.corpus)) &&
			(!floor.from || !floor.to || (object.floor >= floor.from && object.floor <= floor.to)) &&
			(!square.from || !square.to || (object.square >= square.from && object.square <= square.to)) &&
			(!price.to ||
				(parseFloat(object.price / 1000000) >= price.from && parseFloat(object.price / 1000000) <= price.to))
		)
	})

	return sort.field ? sortingTable(filtrated, sort) : filtrated
})

export const layoutSelector = (state) => state.layout.entities
export const layoutMapSelector = createSelector(layoutSelector, (objects) => arrayToMap(objects, 'id'))
export const layoutIdSelector = (_, props) => props.id

export const layoutList = createSelector(flatsOnsaleSelector, filtersSelector, (flats, filters) => {
	const { rooms, price, layoutDecoration, promotion } = filters
	if (!flats) return null

	return flats.filter((object) => {
		return (
			(!promotion || (promotion === '1' && +object.promotion > 0)) &&
			(!layoutDecoration || layoutDecoration === object.decoration) &&
			(!rooms.length || rooms.find((value) => value === object.rooms)) &&
			(!price.to ||
				(parseFloat(object.price / 1000000) >= price.from && parseFloat(object.price / 1000000) <= price.to))
		)
	})
})
export const filtratedLayoutFlatsSelector = createSelector(
	layoutList,
	layoutIdSelector,
	sortSelector,
	(flats, id, sorting) => {
		const { sort } = sorting
		const list = flats.filter((flat) => id === flat.layout)

		return sort.field ? sortingTable(list, sort) : list
	}
)
export const layoutMapFiltratedSelector = createSelector(layoutList, (flats) => objectToMap(flats, 'layout'))
export const layoutMapAllSelector = createSelector(flatsOnsaleSelector, (flats) => objectToMap(flats, 'layout'))

export const similarSelector = createSelector(
	flatsSelector,
	detailSelector,
	flatSelector,
	sortSelector,
	(flats, detail, id, sorting) => {
		if (!detail[id]) return

		const { sort } = sorting

		const list = flats.filter((flat) => {
			return detail[id]['similar'].includes(flat.id)
		})

		return sort.field ? sortingTable(list, sort) : list
	}
)

export const viewedSelector = createSelector(
	flatsSelector,
	viewedIdsSelector,
	sortSelector,
	(flats, viewed, sorting) => {
		const { sort } = sorting
		const list = flats.filter((flat) => {
			return viewed.includes(flat.id)
		})

		return sort.field ? sortingTable(list, sort) : list
	}
)

export const buildingsSelector = (state) => state.genplan.response.buildings
export const buildingsMapSelector = createSelector(buildingsSelector, (buildings) => {
	if (!buildings) return []
	return arrayToMap(buildings, 'num')
})

export const sectionFlatsSelector = createSelector(
	flatsSelector,
	corpusSelector,
	sectionSelector,
	(flats, corpus, section) => {
		if (!flats) return null

		return flats.filter((flat) => {
			return flat.corpus === corpus && flat.section === section
		})
	}
)

export const corpusFlatsSelector = createSelector(flatsSelector, corpusSelector, sectionSelector, (flats, corpus) => {
	if (!flats) return null

	return flats.filter((flat) => {
		return flat.corpus === corpus
	})
})

export const corpusFlatsMapSelector = createSelector(corpusFlatsSelector, (flats) => {
	if (!flats) return null
	flats = objectToMap(flats, 'section')

	Object.keys(flats).map((objectKey, index) => {
		flats[objectKey] = objectToMap(flats[objectKey], 'floor')
	})

	return flats
})

export const corpusFlatsMapAllFloorsSelector = createSelector(corpusFlatsSelector, (flats) => {
	if (!flats) return null
	return objectToMap(flats, 'floor')
})

export const sectionFlatsMapSelector = createSelector(sectionFlatsSelector, (flats) => {
	if (!flats) return null

	return objectToMap(flats, 'floor')
})

export const sectionFlatsInstockSelector = createSelector(sectionFlatsSelector, (flats) => {
	if (!flats) return null

	return flats.filter((el) => el.instock === '1')
})

export const sectionFilterRoomsSelector = createSelector(sectionFlatsInstockSelector, (flats) => {
	if (!flats) return null

	return objectToMap(flats, 'rooms')
})

export const corpusFlatsInstockSelector = createSelector(corpusFlatsSelector, (flats) => {
	if (!flats) return null

	return flats.filter((el) => el.instock === '1')
})
export const corpusFilterRoomsSelector = createSelector(corpusFlatsInstockSelector, (flats) => {
	if (!flats) return null

	return objectToMap(flats, 'rooms')
})

export const newsSelector = (state) => state.newsPage.entities
export const newsYearSelector = (state) => state.newsPage.filter.year
export const newsPageSelector = (_, props) => props.page

export const newsIdsSelector = createSelector(newsSelector, (news) => news.map((e) => e.alias))

export const newsYearOptionsSelector = createSelector(newsSelector, (news) => objectToMap(news, 'year'))

export const filtratedNewsSelector = createSelector(newsSelector, newsYearSelector, (news, year) => {
	return news.filter((el, i) => {
		return !year || year === el.year
	})
})
const items = (state) => state.home.data.items

export const itemsSelector = createSelector(items, (items) => {
	if (items.length === 25) return items
	let i = -1
	return new Array(25).fill().map(() => {
		i++
		if (!items[i]) i = 0
		return items[i]
	})
})
export const itemsSelectorFix = createSelector(items, (items) => {
	let itemsFix = []
	for (let t = 0; t < items.length; t++) {
		if (items[t].id !== '214' && items[t].id !== '215' && items[t].id !== '247' && items[t].id !== '248') {
			itemsFix.push(items[t])
		}
	}
	if (itemsFix.length === 25) return items
	let i = -1
	return new Array(25).fill().map(() => {
		i++
		if (!itemsFix[i]) i = 0
		return itemsFix[i]
	})
})
export const itemsSelector214 = createSelector(items, (items) => {
	if (!items) return null
	return items.find((e) => e.id === '214' || e.id === '247')
})

export const itemsSelector215 = createSelector(items, (items) => {
	if (!items) return null
	return items.find((e) => e.id === '215' || e.id === '248')
})

export const infraSelector = (state) => state.infra.entities
export const infraFiltersSelector = (state) => state.infraFilter

export const filtratedInfraSelector = createSelector(infraSelector, infraFiltersSelector, (points, filters) => {
	if (!points) return null

	return points.filter((point) => {
		return !filters.selected.length || filters.selected.find((value) => value === point.category_id)
	})
})

export const albumsSelector = (state) => state.gallery.entities
export const albumIdSelector = (state) => state.gallery.openId
// export const albumIdSelector = (_, props) => props.id

export const gallerySelector = createSelector(albumsSelector, (albums) => {
	if (!albums.length) return null
	return albums.reduce((acc, e) => [...acc, ...e.images], [])
})

export const albumGallarySelector = createSelector(albumsSelector, albumIdSelector, (albums, id) => {
	if (!albums.length || !id) return null
	return albums.find((e) => e.id === id)['images']
})

export const docsSelector = (state) => state.docs.entities
export const docsFilterSelector = (state) => state.docs.filter

export const docsTypeOptionsSelector = createSelector(docsSelector, (docs) => objectToMap(docs, 'type_id'))

export const docsCorpusOptionsSelector = createSelector(docsSelector, docsFilterSelector, (docs, filter) => {
	const { type } = filter
	const filtrated = docs.filter((el) => !type.value || el.type_id === type.value)
	return objectToMap(filtrated, 'corpus')
})

export const docsYearOptionsSelector = createSelector(docsSelector, docsFilterSelector, (docs, filter) => {
	const { type, corpus } = filter
	const filtrated = docs.filter(
		(el) => (!type.value || el.type_id === type.value) && (!corpus.value || el.corpus === corpus.value)
	)
	return objectToMap(filtrated, 'year')
})

export const docsFiltratedSelector = createSelector(docsSelector, docsFilterSelector, (docs, filter) => {
	const { type, corpus, year } = filter
	return docs.filter(
		(el) =>
			(!type.value || el.type_id === type.value) &&
			(!corpus.value || el.corpus === corpus.value) &&
			(!year.value || el.year === year.value)
	)
})

export const docsSortedSelector = createSelector(docsFiltratedSelector, docsFilterSelector, (docs, filter) => {
	const { limit, page } = filter
	const offset = (page - 1) * limit

	const sorted = docs
		.slice()
		.sort((a, b) => compare(a['type'], b['type']))
		.filter((el, i) => !page || (i >= offset && i < offset + limit))

	return objectToMap(sorted, 'type')
})

export const progressVideoSelector = (state) => state.progress.videos
export const progressPlaySelector = (state) => state.progress.playId

export const progressPhotoSelector = (state) => state.progress.albums
export const progressIdSelector = (state) => state.progress.openId

export const progressFilterSelector = (state) => state.progress.filter
export const progressYearSelector = (state) => state.progress.filter.year
export const progressMonthSelector = (state) => state.progress.filter.month
export const progressCorpusSelector = (state) => state.progress.filter.corpus

export const progressCorpusOptionsSelector = createSelector(progressPhotoSelector, (objects) => {
	return distinct(objects, 'name')
})

export const progressPhotoListSelector = createSelector(
	progressPhotoSelector,
	progressCorpusSelector,
	(objects, corpus) => {
		const filtrated = objects.filter((el, i) => !corpus.value || corpus.value === el.name)
		return filtrated.reduce((acc, value) => [...acc, ...value['images']], [])
	}
)

export const progressYearOptionsSelector = createSelector(progressPhotoListSelector, (objects) => {
	return distinct(objects, 'year')
})

export const progressMonthOptionsSelector = createSelector(
	progressPhotoListSelector,
	progressYearSelector,
	(objects, year) => {
		const filtrated = objects.filter((el, i) => !year.value || year.value === el.year)
		return distinct(filtrated, 'month')
	}
)

export const filtratedProgressSelector = createSelector(
	progressPhotoSelector,
	progressYearSelector,
	progressMonthSelector,
	progressCorpusSelector,
	(objects, year, month, corpus) => {
		return objects.filter((el, i) => {
			return (
				(!corpus.value || corpus.value === el.name) &&
				el.images.filter(
					(el) => (!year.value || year.value === el.year) && (!month.value || month.value === el.month)
				).length
			)
		})
	}
)

export const progressMapSelector = createSelector(progressPhotoSelector, (objects) => {
	if (!objects) return null
	return arrayToMap(objects, 'id')
})
export const progressGallerySelector = createSelector(
	progressMapSelector,
	progressIdSelector,
	progressYearSelector,
	progressMonthSelector,
	(objects, id, year, month) => {
		if (!objects || !id) return null
		return objects[id]['images'].filter(
			(el) => (!year.value || year.value === el.year) && (!month.value || month.value === el.month)
		)
	}
)

export const mortgageSelector = (state) => state.mortgage.entities
export const mortgageSortSelector = (state) => state.mortgageFilter.sort
export const filterMortgageSelector = (state) => state.mortgageFilter

export const sortedMortgageSelector = createSelector(mortgageSelector, mortgageSortSelector, (list, sort) => {
	const clone = list.slice()
	return sort.field ? sortingTable(clone, sort) : list
})
export const priceMortgageMinSelector = createSelector(mortgageSelector, (list) => {
	return parseFloat(list.reduce((min, el) => Math.min(min, el['price']), list[1]['price']) / 1000000)
})
export const priceMortgageMaxSelector = createSelector(mortgageSelector, (list) => {
	return parseFloat(list.reduce((max, el) => Math.max(max, el['price']), list[0]['price']) / 1000000)
})
export const contributionMortgageSelector = createSelector(mortgageSelector, mortgageSortSelector, (list, sort) => {
	if (!list) return null
	const filtrated = list.filter((el) => el.contribution <= sort.contribution)
	return sort.field ? sortingTable(filtrated, sort) : filtrated
})

export const termMortgageSelector = createSelector(mortgageSelector, mortgageSortSelector, (list, sort) => {
	if (!list) return null
	const filtrated = list.filter((el) => el.term <= sort.term)
	return sort.field ? sortingTable(filtrated, sort) : filtrated
})
export const filtratedMortgageSelector = createSelector(mortgageSelector, filterMortgageSelector, (list, filters) => {
	const { term, contribution, price, sort, commerce, term2, payment, rate, family_rate } = filters

	if (!list) return []

	const filtrated = list.filter((object) => {
		if (commerce)
			return (
				(!term || object.term >= term) && (!contribution || object.payment <= contribution) && object.commerce
			)

		return (
			!object.commerce &&
			(!payment || (+object.payment <= +payment && (!object.payment_to || +object.payment_to > +payment))) &&
			(!term2 || +object.term >= term2 / 12) &&
			(!rate || +object.rate <= +rate) &&
			(!family_rate || !object.family || +object.family <= +family_rate)
		)
	})
	return sort.field ? sortingTable(filtrated, sort) : filtrated
})

//commerce

export const commerceSelector = (state) => state.commerce.entities
// export const detailSelector = (state) => state.flats.detail
// export const flatSelector = (_, props) => props.flat

// export const corpusCommerceSelector = (_, props) => props.corpus

export const filterCommerceSelector = (state) => state.commerceFilter
// export const filterCommerceCorpusSelector = (state) => state.commerceFilters.corpus

export const commerceOnsaleSelector = createSelector(commerceSelector, (flats) =>
	flats.filter((object) => object.instock === '1')
)

export const commerceCorpusOptions = createSelector(commerceOnsaleSelector, (flats) =>
	distinct(flats, 'corpus')
		.slice()
		.sort((a, b) => compare(a['corpus'], b['corpus']))
)
export const squareCommerceMinSelector = createSelector(commerceOnsaleSelector, (flats) => {
	return parseFloat(flats.reduce((min, el) => Math.min(min, el['square']), flats[0]['square']))
})
export const squareCommerceMaxSelector = createSelector(commerceOnsaleSelector, (flats) => {
	return parseFloat(flats.reduce((max, el) => Math.max(max, el['square']), flats[0]['square']))
})
export const priceCommerceMinSelector = createSelector(commerceOnsaleSelector, (flats) => {
	if (!flats.length) return null
	return parseFloat(flats.reduce((min, el) => Math.min(min, el['price']), flats[1]['price']) / 1000000)
})
export const priceCommerceMaxSelector = createSelector(commerceOnsaleSelector, (flats) => {
	if (!flats.length) return null
	return parseFloat(flats.reduce((max, el) => Math.max(max, el['price']), flats[0]['price']) / 1000000)
})
export const filtratedCommerceSelector = createSelector(
	commerceOnsaleSelector,
	filterCommerceSelector,
	(flats, filters) => {
		const { corpus, square, price, sort, promotion } = filters

		if (!flats) return null

		const filtrated = flats.filter((object) => {
			return (
				(!promotion || (promotion === '1' && +object.promotion > 0)) &&
				(!corpus.length || corpus.find((value) => value === object.corpus)) &&
				(!square.from || !square.to || (object.square >= square.from && object.square <= square.to)) &&
				(!price.to ||
					(parseFloat(object.price / 1000000) >= price.from &&
						parseFloat(object.price / 1000000) <= price.to))
			)
		})

		return sort.field ? sortingTable(filtrated, sort) : filtrated
	}
)

export const commerceCorpusSelector = (_, props) => props.corpus
export const filtratedCommerceCorpusSelector = createSelector(
	commerceSelector,
	commerceCorpusSelector,
	(flats, corpus) => {
		if (!flats) return null

		return flats.filter((object) => object.corpus === corpus)
	}
)

export const detailCommerceSelector = (state) => state.commerce.detail
export const idSelector = (_, props) => props.id
export const sortCommerceSelector = (state) => state.sortCommerce
export const viewedCommerceIdsSelector = (state) => state.commerce.viewed

export const similarCommerceSelector = createSelector(
	commerceSelector,
	detailCommerceSelector,
	idSelector,
	sortCommerceSelector,
	(flats, detail, id, sorting) => {
		if (!detail[id]) return

		const { sort } = sorting

		const list = flats.filter((flat) => {
			return detail[id]['similar'].includes(flat.id)
		})

		return sort.field ? sortingTable(list, sort) : list
	}
)

export const viewedCommerceSelector = createSelector(
	commerceSelector,
	viewedCommerceIdsSelector,
	sortCommerceSelector,
	(flats, viewed, sorting) => {
		const { sort } = sorting
		const list = flats.filter((flat) => {
			return viewed.includes(flat.id)
		})

		return sort.field ? sortingTable(list, sort) : list
	}
)
export const parkingSelector = (state) => state.parking.entities
export const floorParkingSelector = (_, props) => props.floor
export const filtratedParkingSelector = createSelector(parkingSelector, floorParkingSelector, (parking, floor) =>
	parking.filter((el) => el.floor === floor && el.instock === '1')
)

export const parkingMinPriceSelector = createSelector(filtratedParkingSelector, (parking) => {
	if (!parking.length) return null
	return parking.reduce((min, el) => Math.min(min, el['price']), parking[0]['price'])
})

export const parkingMidPriceSelector = createSelector(filtratedParkingSelector, (parking) => {
	if (!parking.length) return null
	return parking.reduce((max, el) => Math.max(max, el['price']), parking[0]['price'])
	// return parking.reduce((max, el) => Math.max(max, el['price']), parking[0]['price'])/2
})

export const parkingMinSquareSelector = createSelector(filtratedParkingSelector, (parking) => {
	if (!parking.length) return null
	return parking.reduce((min, el) => Math.min(min, el['square']), parking[0]['square'])
})

export const parkingMidSquareSelector = createSelector(filtratedParkingSelector, (parking) => {
	if (!parking.length) return null
	return parking.reduce((max, el) => Math.max(max, el['square']), parking[0]['square'])
	// return parking.reduce((max, el) => Math.max(max, el['square']), parking[0]['square'])/2
})

export const parkingFloorsSelector = createSelector(parkingSelector, (parking) => {
	return Object.keys(objectToMap(parking, 'floor'))
})

export const faqSelector = (state) => state.faqPage.entities
export const faqPageSelector = (_, props) => props.page
