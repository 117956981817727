import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ScrollTop from '../common/scroll-top'
import Panel from '../realty/panel'
import Loader from '../common/loader'
import { loadMore, loadLayout } from '../../actions'
import { layoutMapFiltratedSelector, layoutMapAllSelector, layoutMapSelector } from '../../selectors'
import Fade from 'react-reveal/Fade'
import accounting from 'accounting'

import AboutAdvList from '../About/about-advants'
import Additional from '../realty/additional'
import Filters from '../flats-filter/layout-filter'
import { arrayToMap } from '../../selectors/utils'
import { hit } from '../common/metrics'

class LayoutList extends Component {
	componentDidMount() {
		document.title =
			'ЖК «Новое Бутово». Подобрать квартиру по видам планировки в Москве, 15 минут пешком до метро Бунинская аллея.'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Жилой комплекс «Новое Бутово» - это уютный микрорайон в самом экологичном месте столицы. Здесь Вы сможете подобрать себе квартиру по видам планировки'
			)
		hit(window.location.pathname)
		const { loadedLayout, loadingLayout, loadLayout } = this.props
		if (!loadedLayout && !loadingLayout) loadLayout()
	}

	render() {
		const { loading, loaded, loadedLayout, loadingLayout } = this.props
		if (loading || loadingLayout) return <Loader />
		if (!loaded || !loadedLayout) return null

		return (
			<div className="params layout">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Подобрать квартиру</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> / По параметрам
						</div>
					</div>
				</div>
				<div className="content">
					<Panel />
					<Fade>
						<div>
							<Filters layout={true} />
							<div className="layout__body">{this.body}</div>
						</div>
					</Fade>
				</div>
				<Additional />
				<AboutAdvList />
			</div>
		)
	}

	get body() {
		const { layoutMap, layoutMapAll, layout } = this.props
		if (!layoutMap || !layout) return null
		if (Object.keys(layoutMap).length === 0) return <div className="flats-result-info">Ничего не найдено</div>
		return Object.keys(layoutMap).map((id) => {
			if (!layoutMap[id] || !layout[id]) return null
			return (
				<Link to={`/realty/layout/${id}`} className="layout__item" key={id}>
					<div className="layout__plan">
						<span style={{ backgroundImage: `url(${layout[id].plan})` }} />
						{this.getDescr(layoutMapAll[id], layout[id])}
					</div>
					<div className="layout__flats">
						<div>{layoutMap[id].length}</div>
						<span>{this.declOfNum(layoutMap[id].length, ['свободная', 'свободные', 'свободных'])}</span>
						<br />
						<span>{this.declOfNum(layoutMap[id].length, ['квартира', 'квартиры', 'квартир'])}</span>
					</div>
				</Link>
			)
		})
	}

	getDescr = (list, item) => {
		return (
			<div className="layout__descr">
				<div>
					<div>
						Доступна в корпусах <span>№ {Object.keys(arrayToMap(list, 'corpus')).join(', ')}</span>
					</div>
					<div>
						Стоимость от:{' '}
						<span>{accounting.formatNumber(this.getMinValue(list, 'price'), 0, ' ')} руб.</span>
					</div>
					<div>
						Площадь от: <span>{this.getMinValue(list, 'square')} м2</span>
					</div>
				</div>
			</div>
		)
	}

	getMinValue = (list, field) => list.reduce((min, el) => Math.min(min, el[field]), list[0][field])

	declOfNum = (number, titles) => {
		var cases = [2, 0, 1, 1, 1, 2]
		return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
	}
}

export default connect(
	(state) => ({
		loading: state.flats.loading,
		loaded: state.flats.loaded,
		loadingLayout: state.layout.loading,
		loadedLayout: state.layout.loaded,
		layoutMap: layoutMapFiltratedSelector(state),
		layoutMapAll: layoutMapAllSelector(state),
		layout: layoutMapSelector(state)
	}),
	{ loadMore, loadLayout }
)(LayoutList)
