import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Genplan from '../genplan'
import Flat from '../flat'
import Section from '../section'
import Corpus from '../corpus'
import Mail from '../realty/forms/mail'
import Request from '../realty/forms/request'
import RequestLayout from '../realty/forms/request-layout'

import Params from '../params'
import { loadFlats } from '../../actions'
import Layout from '../layout'
import LayoutDetail from '../layout/detail'
import Decoration from '../decoration'
import Scandinavian from '../scandinavian'
import Modern from '../modern'
import Fusion from '../fusion'

class Realty extends Component {
	componentDidMount() {
		const { loading, loaded, loadFlats } = this.props
		if (!loading && !loaded) loadFlats()
	}

	render() {
		return (
			<div className="content__block realty">
				<Route path="/realty" exact component={this.getGenplan} />
				<Route path="/realty/genplan" exact component={Genplan} />
				<Route path="/realty/params" exact component={Params} />
				<Route path="/realty/layout" exact component={Layout} />
				<Route path="/realty/decoration" exact component={Decoration} />
				<Route path="/realty/scandinavian" exact component={Scandinavian} />
				<Route path="/realty/modern" exact component={Modern} />
				<Route path="/realty/fusion" exact component={Fusion} />

				<Route path="/realty/layout/:id" exact component={this.getLayout} />
				<Route path="/realty/genplan/:corpus" exact component={this.getCorpus} />
				<Route path="/realty/genplan/:corpus/:section" exact component={this.getSection} />
				<Route path="/realty/genplan/:corpus/:section/:flat" exact component={this.getFlat} />
				<Mail location={this.props.location.pathname} />
				<Request />
				<RequestLayout />
			</div>
		)
	}

	getFlat = ({ match }) => {
		return <Flat {...match.params} key={match.flat} />
	}

	getLayout = ({ match }) => {
		return <LayoutDetail {...match.params} key={match.id} />
	}
	getCorpus({ match, mobile }) {
		//if (!match.params.section) return <Redirect to={`/realty/genplan/${match.params.corpus}`} />
		return <Corpus {...match.params} key={match.corpus} />
	}
	getSection({ match }) {
		//if (!match.params.section) return <Redirect to={`/realty/genplan/${match.params.corpus}`} />
		return <Section {...match.params} key={match.section} />
	}

	getGenplan() {
		return <Redirect to={`/realty/genplan`} />
	}
}

export default connect(
	(state) => ({
		loading: state.flats.loading,
		loaded: state.flats.loaded
	}),
	{ loadFlats }
)(Realty)
