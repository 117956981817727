import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

export default class Mortgage extends Component {
	render() {
		return (
			<Fragment>
				<div className="ny-title">
					<div dangerouslySetInnerHTML={{ __html: this.props.el.title }} />
				</div>
				<div className="main__item--mortgage main__item--ny">
					<div className="main__item--text">
						Последние квартиры <br />
						в завершенном микрорайоне
						<div className="ny-counter" dangerouslySetInnerHTML={{ __html: this.props.el.text }} />
					</div>
					<Link className="main__item--but main__item--but__white" to="/realty/genplan">
						<i className="icon-i_house" />
						<p dangerouslySetInnerHTML={{ __html: this.props.el.btn_text }} />
					</Link>
				</div>
			</Fragment>
		)
	}
}
