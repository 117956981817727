import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getPage } from '../../actions'
import { withRouter } from 'react-router-dom'

const links = {
	commerce: {
		title: 'На генплане',
		icon: '1'
	},
	'commerce-params': {
		title: 'По параметрам',
		icon: '2'
	}
}
class Panel extends Component {
	render() {
		if (this.props.mobile) return <div>{this.mobilePanel}</div>
		return (
			<div className="realty-panel">
				<div className="realty-panel__item">
					<NavLink
						to="/commerce"
						exact
						className={!this.props.lastUrl || this.props.lastUrl !== 'commerce-params' ? 'active' : ''}
					>
						<span className="realty-panel__icon1 ico-1">На генплане</span>
					</NavLink>
				</div>
				<div className="realty-panel__item">
					<NavLink
						to="/commerce-params"
						exact
						className={this.props.lastUrl === 'commerce-params' ? 'active' : ''}
					>
						<span className="realty-panel__icon2 ico-2">По параметрам</span>
					</NavLink>
				</div>
				<div className="realty-panel__item" />
			</div>
		)
	}
	get mobilePanel() {
		const path = this.props.match.path
		const selected = path.includes('commerce-params') ? 'commerce-params' : 'commerce'

		return (
			<div className="select-mobile select-mobile--realty select-mobile--break">
				<span className="select-mobile__title">
					<span>{links[selected].title}</span>
				</span>
				<select value={'/' + selected} onChange={this.handleChange}>
					<option value="/commerce">На генплане</option>
					<option value="/commerce-params">По параметрам</option>
				</select>
				<i className={`select-mobile__icon  ico-${links[selected].icon}`} />
				<span className="select__indicator select__indicator--mobile" />
			</div>
		)
	}

	handleChange = (ev) => {
		this.props.getPage(ev.target.value)
	}
}

export default connect(
	(state) => ({
		mobile: state.mobile
	}),
	{ getPage }
)(withRouter(Panel))
