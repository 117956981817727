import React, { Component } from 'react'
import { connect } from 'react-redux'
import RoomsFilter from './rooms-filter'
import PriceFilter from './price-filter'
import PromotionFilter from './promotion-filter'
import DecorationRadioFilter from './decorations-radio-filter'

import { clearFilter } from '../../actions'

class Filters extends Component {
	render() {
		return (
			<div className="filter-layout">
				<div className="filter">
					<div className="filter__field">
						<div className="filter__field__title">Кол-во комнат</div>
						<RoomsFilter />
					</div>
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">Стоимость, млн руб.</div>
						<PriceFilter />
					</div>
					<DecorationRadioFilter />
					<div className="filter__field filter__field-slider">
						<div className="filter__field__title">&nbsp;</div>
						<div className="clear-filter" onClick={this.props.clearFilter}>
							<span>Сбросить</span>
						</div>
					</div>
				</div>
				<div className="filter-bottom">
					<PromotionFilter />
				</div>
			</div>
		)
	}
}

export default connect(null, { clearFilter })(Filters)
