import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AboutMenuList from '../about-menu-list'
import Loader from '../../common/loader'
import { loadProgress, toggleProgressGallery, playProgressVideo } from '../../../actions'
import Fade from 'react-reveal/Fade'
import Filter from './filter'
import Video from './video'
import Slider from '../../common/slider'
import ScrollTop from '../../common/scroll-top'
import { hit } from '../../common/metrics'

import {
	filtratedProgressSelector,
	progressVideoSelector,
	progressIdSelector,
	progressGallerySelector,
	progressFilterSelector,
	progressPlaySelector
} from '../../../selectors'

class Progress extends Component {
	state = {
		playId: false
	}

	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Фотографии хода строительства'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Здесь Вы можете ознакомиться с фотографиями хода строительства жилого комплекса «Новое Бутово»'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadProgress } = this.props
		if (!loading && !loaded) loadProgress()
	}

	componentDidUpdate(_, oldState) {
		const { play } = this.state
		if (play !== oldState.play) {
			play ? this.refs.vidRef.play() : this.refs.vidRef.pause()
		}
	}

	render() {
		const { loading, loaded, id, filter } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />
		return (
			<Fragment>
				<section className="content__block gallery progress">
					<ScrollTop />
					<div className="box">
						<div className="breadcrumb">
							<h1>О проекте</h1>
							<div className="breadcrumb__links">
								<Link to="/">Главная</Link> / <Link to="/about">О проекте</Link> / Дневник стройки
							</div>
						</div>
					</div>
					<AboutMenuList />
					<Fade key={id}>
						<div className="gallery-slider">{this.gallery}</div>
					</Fade>
					<div className="content">
						<div className="progress-title">
							Фотографии <span>хода строительства</span>
						</div>
						<Filter />
						<Fade key={filter.year.value + filter.month.value + filter.corpus.value}>
							<div className="albums">{this.albums}</div>
						</Fade>
					</div>
				</section>

				{this.modal}
			</Fragment>
		)
	}

	get gallery() {
		const { video, playProgressVideo } = this.props
		if (!video) return null
		return (
			<Slider
				video={true}
				stopVideo={playProgressVideo}
				items={this.items}
				options={{ dots: false, arrows: true }}
			/>
		)
	}

	get items() {
		const { video, play, playProgressVideo } = this.props
		return video.map((el, i) => (
			<div className="slider__item" key={i}>
				<div>
					<div>
						<Video el={el} play={play} toggle={playProgressVideo} />
					</div>
				</div>
			</div>
		))
	}

	get albums() {
		const { photo } = this.props
		return photo.map((el) => (
			<div key={el.id} className="album" onClick={this.handleToggle} value={el.id}>
				<div className="album__cover">
					<span style={{ backgroundImage: `url(${el.cover})` }} />
					<i className="ico-34">
						Развернуть фотографию
						<br />
						на весь экран
					</i>
				</div>
				<div className="album__name">
					<div className="album__name__date">{el.date}</div>
					{el.corpus ? `Корпус ${el.corpus} - ${el.name}` : el.name}
				</div>
			</div>
		))
	}

	get modal() {
		const { mobile, openId } = this.props

		return (
			<div
				className={
					this.props.openId ? 'modal modal-gallery modal-progress-gallery active' : 'modal modal-gallery'
				}
			>
				<div className="modal-overlay" onClick={this.handleToggle} />
				<div className="modal__body">
					<div className="modal__inner">
						<div className="close__modal" onClick={this.handleToggle} />
						<Slider
							items={this.modalItems}
							options={{
								arrows: mobile ? false : true,
								dots: mobile ? true : false,
								lazyLoad: true,
								fade: true
							}}
							key={openId}
						/>
					</div>
				</div>
			</div>
		)
	}

	get modalItems() {
		if (!this.props.gallery) return null

		return this.props.gallery.map((el, i) => (
			<div className="slider__item" key={i}>
				<div>
					<div className={el.contain ? 'slider__item-contain' : null}>
						<div className="slider__item-bg" style={{ backgroundImage: `url(${el.img})` }} />
						<div className="slider__item__gallery-text">
							<div dangerouslySetInnerHTML={{ __html: el.title }} />
							<div>
								<span>{i + 1}</span> / {this.props.gallery.length}
							</div>
						</div>
					</div>
				</div>
			</div>
		))
	}

	handleToggle = (ev) => {
		const id = ev.currentTarget.getAttribute('value')
		this.props.toggleProgressGallery(id)
	}
}

export default connect(
	(state, props) => ({
		loading: state.progress.loading,
		loaded: state.progress.loaded,
		photo: filtratedProgressSelector(state),
		video: progressVideoSelector(state),
		openId: progressIdSelector(state),
		gallery: progressGallerySelector(state),
		filter: progressFilterSelector(state),
		play: progressPlaySelector(state),
		mobile: state.mobile
	}),
	{ loadProgress, toggleProgressGallery, playProgressVideo }
)(Progress)
