import React, { Component, Fragment } from 'react'
import SVG from 'svg.js/dist/svg.min.js'
import $ from 'jquery'
import 'simplebar'
import 'simplebar/dist/simplebar.min.css'
const buildings_done = [
	// {
	// 	num: 1,
	// 	position: [390, 339],
	// 	coords: [
	// 		368,
	// 		342,
	// 		398,
	// 		338,
	// 		417,
	// 		380,
	// 		529,
	// 		348,
	// 		545,
	// 		367,
	// 		549,
	// 		418,
	// 		494,
	// 		418,
	// 		447,
	// 		418,
	// 		416,
	// 		445,
	// 		398,
	// 		449,
	// 		379,
	// 		400
	// 	]
	// },
	// {
	// 	num: 2,
	// 	position: [504, 260],
	// 	coords: [420, 281, 555, 245, 598, 310, 602, 360, 575, 369, 554, 322, 440, 355, 429, 334]
	// },
	{ num: 3, position: [777, 280], coords: [702, 302, 823, 265, 832, 282, 832, 318, 712, 355, 697, 340] },
	{
		num: 4,
		position: [734, 196],
		coords: [651, 281, 652, 220, 792, 179, 824, 216, 822, 261, 800, 269, 786, 250, 690, 278, 694, 311, 670, 315]
	},
	{
		num: 6,
		position: [897, 148],
		coords: [827, 165, 957, 126, 975, 148, 962, 160, 953, 201, 938, 200, 839, 233, 824, 218]
	},
	{
		num: 5,
		position: [971, 210],
		coords: [925, 212, 961, 205, 963, 161, 982, 156, 1026, 205, 1015, 259, 933, 286, 916, 271]
	},
	{
		num: 7,
		position: [558, 62],
		coords: [452, 86, 596, 55, 610, 80, 619, 135, 596, 137, 584, 123, 504, 143, 481, 107, 460, 111]
	},
	{ num: 8, position: [480, 142], coords: [464, 118, 481, 113, 513, 163, 558, 158, 574, 226, 510, 242, 466, 169] },
	{
		num: 9,
		position: [641, 148],
		coords: [590, 147, 619, 142, 616, 114, 633, 110, 652, 140, 653, 201, 602, 218, 592, 207]
	},
	// { num: 10, position: [681, 41], coords: [668, 56, 782, 28, 790, 40, 790, 95, 680, 124, 670, 114] },
	// {
	// 	num: 11,
	// 	position: [874, 25],
	// 	coords: [816, 30, 867, 19, 920, 73, 912, 132, 856, 147, 849, 134, 857, 78, 845, 94, 824, 98, 814, 86]
	// },
	{ num: 12, position: [744, 100], coords: [716, 120, 823, 101, 837, 119, 837, 159, 722, 188, 710, 175] }
	// {num:13, position:[]},
]
const genplan_icons = [
	{
		icon: 6,
		title: 'Поликлиника',
		position: [967, 341]
	},
	{
		icon: 3,
		title: 'Детский сад',
		position: [728, 358]
	},
	{
		icon: 2,
		title: 'Школа',
		position: [308, 139]
	},
	{
		icon: 4,
		title: 'Детская площадка',
		position: [545, 100]
	},
	{
		icon: 3,
		title: 'Детский сад',
		position: [367, 196]
	},
	{
		icon: 4,
		title: 'Детская площадка',
		position: [829, 47]
	},
	{
		icon: 4,
		title: 'Детская площадка',
		position: [865, 202]
	},
	{
		icon: 4,
		title: 'Детская площадка',
		position: [950, 287]
	},
	{
		icon: 4,
		title: 'Детская площадка',
		position: [760, 429]
	},
	{
		icon: 4,
		title: 'Детская площадка',
		position: [565, 474]
	},
	{
		icon: 5,
		title: 'Торговый центр',
		position: [1085, 100]
	}
]
export default class Scheme extends Component {
	componentDidMount() {
		if (!this.container) return null
		this.handleLoad()
	}

	componentDidUpdate() {
		$(this.container)
			.find('svg')
			.remove()
		this.handleLoad()
	}

	shouldComponentUpdate(props) {
		return props.buildings !== this.props.buildings
	}

	render() {
		const { img, mobile } = this.props
		return (
			<Fragment>
				{mobile ? (
					<div data-simplebar>
						<div ref={this.setContainerRef} className="genplan-scheme">
							<img src={img} alt="" id="genplan-img" />
						</div>
					</div>
				) : (
					<div ref={this.setContainerRef} className="genplan-scheme">
						<img src={img} alt="" id="genplan-img" />
					</div>
				)}
			</Fragment>
		)
	}

	setContainerRef = (ref) => {
		this.container = ref
	}

	getSection = (array) => {
		if (array.length) {
			return Math.min(...array)
		}
		return 1
	}

	createSvg = (width, height) => {
		const { parking, buildings, getPage, changeRoomsFilter } = this.props

		this._draw = SVG(this.container).size(width, height)
		this._draw.viewbox(0, 0, width, height)

		buildings_done.map((el, i) => {
			const position = el.position

			const polygon = this._draw
				.polygon(el['coords'])
				.fill({ opacity: 0 })
				.on('mouseenter', () => move())
				.on('mouseout', () => out())

			const arrow = this._draw
				.image('/img/realty/arrow3.svg', 0, 0)
				.loaded(function(loader) {
					this.size(0, 0)
				})
				.move(...position)
				.dx(0)
				.dy(0)

			const rect2 = this._draw
				.rect(30, 30)
				.fill('#b37db7')
				.move(...position)
				.addClass('pointer-none')

			const rect = this._draw
				.rect(30, 30)
				.fill('#b37db7')
				.move(...position)
				.addClass('pointer-none')

			const text = this._draw
				.text((add) => {
					add.tspan(el.num)
				})
				.size(15)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'middle', leading: '1em' })
				.move(...position)
				.dx(15)
				.dy(6)
				.addClass('pointer-none')

			const text_num = this._draw
				.text((add) => {
					add.tspan(el['num'])
				})
				.size(30)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'middle', leading: '1em' })
				.move(...position)
				.dx(32)
				.dy(-40)
				.addClass('pointer-none hidden')

			const text_status = this._draw
				.text((add) => {
					add.tspan('сдан')
				})
				.size(10)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'middle', leading: '1em' })
				.move(...position)
				.dx(33)
				.dy(-10)
				.addClass('pointer-none hidden')

			const move = () => {
				arrow
					.move(...position)
					.dx(0)
					.dy(-20)
					.size(24, 51)
				text.addClass('hidden')
				rect2
					.size(64, 60)
					.move(...position)
					.dx(0)
					.dy(-50)
					.removeClass('hidden')
				rect.fill('transparent')
				text_num.removeClass('hidden')
				text_status.removeClass('hidden')
			}

			const out = () => {
				text.removeClass('hidden')
				arrow
					.size(0, 0)
					.move(...position)
					.dx(0)
					.dy(0)
				rect2
					.size(30, 30)
					.move(...position)
					.dx(0)
					.dy(0)
				rect.fill('#b37db7')
				text_num.addClass('hidden')
				text_status.addClass('hidden')
			}
		})

		if (!buildings.length) return

		const roomsTitls = {
			0: 'Студия',
			1: '1-комнатные',
			2: '2-комнатные',
			3: '3-комнатные'
		}
		buildings.map((el, i) => {
			// const sum = el['rooms'].reduce((acc, a) => +a + +acc)
			// if (sum === 0) return false
			if (!el['coords']) return false
			const position = el.position
			const done = +el['done'] === 1

			const polygon = this._draw
				.polygon(el['coords'])
				.fill(done ? '#442463' : '#e2068c')
				.stroke({ width: 5, color: done ? '#442463' : '#e2068c', opacity: 0 })
				.fill({ opacity: 0 })
				.on('mouseenter', () => move())
				.on('mouseout', () => out())

				.on('click', (ev) => {
					$('html, body').scrollTop(0)
					if (el['num'] === '2' || el['num'] === '10' || el['num'] === '11') {
						getPage(`/commerce/${el['num']}/`)
						return false
					}
					getPage(`/realty/genplan/${el['num']}/`)
				})

			const arrow = this._draw
				.image(done ? '/img/realty/arrow2.svg' : '/img/realty/arrow1.svg', 24, 51)
				.move(...position)
				.dx(20)
				.dy(-40)
				.addClass('pointer-none')

			const rect = this._draw
				.rect(64, 60)
				.fill(done ? '#442463' : '#e2068c')
				.move(...position)
				.dx(20)
				.dy(-70)
				.on('mouseenter', () => move())
				.on('mouseout', () => out())

			const rect3 = this._draw
				.rect(164, 70)
				.fill('transparent')
				.move(...position)
				.dx(20)
				.dy(-70)
				.on('mouseenter', () => move())
				.on('mouseout', () => out())
			const action = this._draw
				.image('/img/action.svg', 117, 59)
				.move(...position)
				.dx(-35)
				.dy(-318)
				.addClass(el.action === '1' ? 'pointer-none hidden' : 'hidden3')

			const rect2 = this._draw
				.rect(0, 0)
				.fill('#fff')
				.move(...position)
				.dx(28)
				.dy(-56)
				.addClass('pointer-none rect-fast hidden')

			const text_num = this._draw
				.text((add) => {
					add.tspan(el['num'])
				})
				.size(30)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'middle', leading: '1em' })
				.move(...position)
				.dx(52)
				.dy(-40)

			const text_status = this._draw
				.text((add) => {
					add.tspan(done ? 'сдан' : 'строится')
				})
				.size(10)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'middle', leading: '1em' })
				.move(...position)
				.dx(53)
				.dy(-25)

			const text_num_large = this._draw
				.text((add) => {
					add.tspan(el['num'])
				})
				.addClass('hidden')
				.size(24)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'end', leading: '1em' })
				.move(...position)
				.dx(135)
				.dy(-244)

			const text_num_descr = this._draw
				.text((add) => {
					add.tspan('корпус')
				})
				.addClass('hidden')
				.size(18)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'start', leading: '1em' })
				.move(...position)
				.dx(140)
				.dy(-246)

			const text_info_title = this._draw
				.text((add) => {
					add.tspan('В продаже:')
				})
				.addClass('hidden')
				.size(14)
				.font({ fill: '#000', family: 'Acrom', anchor: 'middle', weight: 500, leading: '1em' })
				.move(...position)
				.dx(160)
				.dy(-190)

			let idx = 0
			const roomsNum = el['rooms'].find((r, i) => {
				if (+r > 0) idx = i + ''
				return +r > 0
			})

			const rooms_title = this._draw
				.text((add) => {
					let newline = 0
					el['rooms'].map((room, i) => {
						if (+room === 0) return null
						const idx = i + ''
						if (newline > 0) {
							add.tspan(roomsTitls[idx])
								.newLine()
								.on('click', (ev) => {
									changeRoomsFilter(idx)
									const sec = this.getSection(el.roomsToSection[idx])
									getPage(`/realty/genplan/${el['num']}/${sec}`)
								})
						} else {
							add.tspan(roomsTitls[idx]).on('click', (ev) => {
								changeRoomsFilter(idx)
								const sec = this.getSection(el.roomsToSection[idx])
								getPage(`/realty/genplan/${el['num']}/${sec}`)
							})
						}
						newline++
					})

					if (el['commerce']) {
						add.tspan('Помещения:')
							.newLine()
							.addClass(+el['commerce'] === 0 ? 'commerce-hidden' : '')
							.on('click', (ev) => {
								getPage(`/commerce/${el['num']}`)
							})
					}
				})
				.addClass('clickable hidden')
				.size(14)
				.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })
				.move(...position)
				.dx(45)
				.dy(-155)
				.on('mouseenter', () => move())

			const rooms = this._draw
				.text((add) => {
					let newline = 0
					el['rooms'].map((room, i) => {
						if (+room === 0) return null
						const idx = i + ''
						if (newline > 0) {
							add.tspan(room)
								.newLine()
								.on('click', (ev) => {
									changeRoomsFilter(idx)
									const sec = this.getSection(el.roomsToSection[idx])
									getPage(`/realty/genplan/${el['num']}/${sec}`)
								})
						} else {
							add.tspan(room).on('click', (ev) => {
								changeRoomsFilter(idx)
								const sec = this.getSection(el.roomsToSection[idx])
								getPage(`/realty/genplan/${el['num']}/${sec}`)
							})
						}
						newline++
					})

					if (el['commerce']) {
						add.tspan(el['commerce'])
							.newLine()
							.addClass(+el['commerce'] === 0 ? 'commerce-hidden' : '')
							.on('click', (ev) => {
								getPage(`/commerce/${el['num']}`)
							})
					}
				})
				.addClass('text-decoration hidden')
				.size(14)
				.font({ fill: '#e2068c', family: 'Acrom', anchor: 'start', leading: '1.5em' })
				.move(...position)
				.dx(260)
				.dy(-155)
				.on('mouseenter', () => move())

			const move = () => {
				if (this.props.mobile) {
					$('html, body').scrollTop(0)
					getPage(`/realty/genplan/${el['num']}/`)
					return false
				}
				$('polygon').addClass('hidden2')
				$('rect').addClass('hidden2')
				$('image').addClass('hidden2')
				$('text').addClass('hidden2')
				$('circle').addClass('hidden2')

				rect3.removeClass('hidden2')

				polygon
					.fill({ opacity: 0.3 })
					.stroke({ opacity: 1 })
					.removeClass('hidden')
					.removeClass('hidden2')

				rect.size(290, 245)
					.move(...position)
					.dy(-280)
					.dx(20)
					.removeClass('hidden')
					.removeClass('hidden2')

				rect2
					.size(274, 175)
					.move(...position)
					.dx(28)
					.dy(-218)
					.removeClass('hidden')
					.removeClass('hidden2')

				action.removeClass('hidden').removeClass('hidden2')

				arrow.removeClass('hidden2')

				text_num.addClass('hidden')
				text_status.addClass('hidden')
				text_num_large.removeClass('hidden').removeClass('hidden2')
				text_num_descr.removeClass('hidden').removeClass('hidden2')

				text_info_title.removeClass('hidden').removeClass('hidden2')
				rooms_title.removeClass('hidden').removeClass('hidden2')
				rooms.removeClass('hidden').removeClass('hidden2')
			}

			const out = () => {
				$('polygon').removeClass('hidden2')
				$('rect').removeClass('hidden2')
				$('image').removeClass('hidden2')
				$('text').removeClass('hidden2')
				$('circle').removeClass('hidden2')

				rect.size(64, 60)
					.move(...position)
					.dx(20)
					.dy(-70)

				rect2
					.size(0, 0)
					.move(...position)
					.dx(28)
					.dy(-56)
					.addClass('hidden')

				polygon.fill({ opacity: 0 }).stroke({ opacity: 0 })

				text_num.removeClass('hidden')
				text_status.removeClass('hidden')
				text_num_large.addClass('hidden')
				text_num_descr.addClass('hidden')
				action.addClass('hidden')

				text_info_title.addClass('hidden')
				rooms_title.addClass('hidden')
				rooms.addClass('hidden')
			}

			return true
		})

		genplan_icons.map((el, i) => {
			const position = el.position

			const circle = this._draw
				.circle(45)
				.fill('#442463')
				.move(...position)
				.on('mouseenter', () => move())
				.on('mouseout', () => out())

			const icon = this._draw.image('/img/realty/icons/' + el.icon + '.svg', 45, 45).move(...position)

			const arrow = this._draw
				.image('/img/realty/arrow1.svg', 0, 0)
				.loaded(function(loader) {
					this.size(0, 0)
				})
				.move(...position)
				.dx(27)
				.dy(-50)
				.addClass('pointer-none')

			const rect = this._draw
				.rect(0, 0)
				.fill('#e2068c')
				.move(...position)
				.dx(27)
				.dy(0)

			const text = this._draw
				.text((add) => {
					add.tspan(el.title)
				})
				.size(14)
				.font({ fill: '#fff', family: 'Acrom', anchor: 'middle', leading: '1em' })
				.move(...position)
				.dx(120)
				.dy(-61)
				.addClass('hidden')

			const move = () => {
				circle.fill('#e2068c')
				arrow
					.move(...position)
					.dx(27)
					.dy(-50)
					.size(24, 51)
				text.removeClass('hidden')
				rect.size(184, 60)
					.move(...position)
					.dx(27)
					.dy(-85)
			}

			const out = () => {
				text.addClass('hidden')
				circle.fill('#442463')
				arrow
					.size(0, 0)
					.move(...position)
					.dx(27)
					.dy(0)
				rect.size(0, 0)
					.move(...position)
					.dx(27)
					.dy(0)
			}
		})

		if (!parking.length) return
		parking.map((el, i) => {
			const position = el.position

			const circle = this._draw
				.circle(45)
				.fill('#442463')
				.addClass('pointer-none')
				.move(...position)

			const rect4 = this._draw
				.rect(45, 45)
				.fill('transparent')
				.move(...position)
				.dx(0)
				.dy(0)
				.addClass('cursor-pointer')
				.on('mouseenter', () => move())
				.on('mouseout', () => out())

				.on('click', (ev) => {
					$('html, body').scrollTop(0)
					getPage(`/parking/1`)
				})

			const icon = this._draw.image('/img/realty/icons/' + el.icon + '.svg', 45, 45).move(...position)

			const arrow = this._draw
				.image('/img/realty/arrow1.svg', 0, 0)
				.loaded(function(loader) {
					this.size(0, 0)
				})
				.move(...position)
				.dx(27)
				.dy(-35)
				.addClass('pointer-none')

			const rect = this._draw
				.rect(0, 0)
				.fill('#e2068c')
				.move(...position)
				.dx(27)
				.dy(0)
				.addClass('pointer-none')

			const rect2 = this._draw
				.rect(0, 0)
				.fill('#fff')
				.move(...position)
				.dx(35)
				.dy(0)
				.addClass('pointer-none')

			const text_title = this._draw
				.text((add) => {
					add.tspan('В продаже:')
				})
				.addClass('pointer-none hidden')
				.size(14)
				.font({ fill: '#000', family: 'Acrom', anchor: 'middle', weight: 500, leading: '1em' })
				.move(...position)
				.dx(115)
				.dy(-150)

			const title = this._draw
				.text((add) => {
					add.tspan('Паркинг:')
				})
				.addClass('clickable hidden pointer-none')
				.size(14)
				.font({ fill: '#000', family: 'Acrom', anchor: 'start', leading: '1.5em' })
				.move(...position)
				.dx(50)
				.dy(-105)
				.on('mouseenter', () => move())

			const count = this._draw
				.text((add) => {
					add.tspan(12)
				})
				.addClass('text-decoration hidden pointer-none')
				.size(14)
				.font({ fill: '#e2068c', family: 'Acrom', anchor: 'start', leading: '1.5em' })
				.move(...position)
				.dx(170)
				.dy(-105)

			const move = () => {
				circle.fill('#e2068c')
				arrow
					.move(...position)
					.dx(27)
					.dy(-35)
					.size(24, 51)
				rect.size(184, 140)
					.move(...position)
					.dx(27)
					.dy(-175)
				rect2
					.fill('#fff')
					.size(168, 124)
					.move(...position)
					.dx(35)
					.dy(-167)

				rect4
					.size(213, 245)
					.move(...position)
					.dx(0)
					.dy(-200)

				text_title.removeClass('hidden')
				title.removeClass('hidden')
				count.removeClass('hidden')
			}

			const out = () => {
				circle.fill('#442463')
				arrow
					.size(0, 0)
					.move(...position)
					.dx(27)
					.dy(0)
				rect.size(0, 0)
					.move(...position)
					.dx(27)
					.dy(0)
				rect2
					.fill('transparent')
					.size(0, 0)
					.move(...position)
					.dx(35)
					.dy(0)

				rect4
					.size(45, 45)
					.move(...position)
					.dx(0)
					.dy(0)

				text_title.addClass('hidden')
				title.addClass('hidden')
				count.addClass('hidden')
			}
		})

		this._draw
			.image('/img/realty/compass.svg', 125, 125)
			.move(width - 155, height - 151)
			.addClass('compass')
	}

	handleLoad = (callback) => {
		const genplan = this.container.querySelector('img')

		if (genplan.naturalHeight) {
			this.createSvg(genplan.naturalWidth, genplan.naturalHeight)
			return
		}

		genplan.onload = () => {
			this.createSvg(genplan.naturalWidth, genplan.naturalHeight)
		}
	}
}
