import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from '../common/loader'
import Scheme from './scheme'
import { loadParking, openParkingForm, setFormPlaceType } from '../../actions'
import { Link } from 'react-router-dom'
import ScrollTop from '../common/scroll-top'
import Table from './table'
import Fade from 'react-reveal/Fade'
import AboutAdvList from '../About/about-advants'
import Select from './select'
import Form from './form'
import accounting from 'accounting'
import { hit } from '../common/metrics'
import {
	filtratedParkingSelector,
	parkingMinPriceSelector,
	parkingMidPriceSelector,
	parkingMinSquareSelector,
	parkingMidSquareSelector,
	parkingFloorsSelector
} from '../../selectors'

class Parking extends Component {
	componentDidMount() {
		const { loading, loaded, loadParking, floor } = this.props
		if (!loading && !loaded) loadParking()

		document.title = `ЖК «Новое Бутово». Паркинг. Этаж ${floor}`
		document
			.querySelector('meta[name=description]')
			.setAttribute('content', `Здесь Вы можете выбрать машиноместо на ${floor} этаже и забронировать его!`)
		hit(window.location.pathname)
	}

	componentDidUpdate(oldProps) {
		const { loading, loaded, loadParking, floor } = this.props
		if (!loading && !loaded) loadParking()

		if (oldProps.floor !== floor) {
			document.title = `ЖК «Новое Бутово». Паркинг. Этаж ${floor}`
			document
				.querySelector('meta[name=description]')
				.setAttribute('content', `Здесь Вы можете выбрать машиноместо на ${floor} этаже и забронировать его!`)
			hit(window.location.pathname)
		}
	}

	render() {
		const { loading, loaded, floor, filtrated, mobile } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />
		return (
			<div className="content__block parking">
				<ScrollTop />
				{this.path}
				{this.panel}
				<div className="content">
					{mobile ? (
						<div className="flat-list">
							<Table list={filtrated} />
						</div>
					) : (
						<Fade key={floor}>{this.body}</Fade>
					)}
					<AboutAdvList />
				</div>
				<Form />
			</div>
		)
	}

	get body() {
		const { floor, filtrated, priceMid, openParkingForm, setFormPlaceType } = this.props
		return (
			<div className="parking__body">
				<Scheme
					img={`/img/parking/${floor}.svg`}
					list={filtrated}
					priceMid={priceMid}
					openParkingForm={openParkingForm}
					setFormPlaceType={setFormPlaceType}
				/>
			</div>
		)
	}

	get panel() {
		const { priceMin, priceMid, squareMin, squareMid, floorsList } = this.props
		// const single = +priceMin > +priceMid
		return (
			<div className="box">
				<div className="parking-panel">
					<div className="parking-select">
						<Select list={floorsList} id={this.props.floor} />
					</div>
					<div className="parking-panel__item parking-panel__item--1">
						от {accounting.formatNumber(squareMin, 1, ',', ',')} м<sup>2</sup> /{' '}
						{accounting.formatNumber(priceMin, 0, ' ')} руб.
					</div>
					<div className="parking-panel__item parking-panel__item--2">
						{accounting.formatNumber(squareMid, 1, ',', ',')} м<sup>2</sup> /{' '}
						{accounting.formatNumber(priceMid, 0, ' ')} руб.
					</div>
					<div className="parking-panel__item parking-panel__item--3">занято</div>
				</div>
			</div>
		)
	}

	get path() {
		return (
			<div className="box">
				<div className="breadcrumb">
					<h1>Паркинг</h1>
					<div className="breadcrumb__links">
						<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> / Паркинг
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	(state, props) => ({
		filtrated: filtratedParkingSelector(state, props),
		priceMin: parkingMinPriceSelector(state, props),
		priceMid: parkingMidPriceSelector(state, props),
		squareMin: parkingMinSquareSelector(state, props),
		squareMid: parkingMidSquareSelector(state, props),
		floorsList: parkingFloorsSelector(state),
		loading: state.parking.loading,
		loaded: state.parking.loaded,
		mobile: state.mobile
	}),
	{ loadParking, openParkingForm, setFormPlaceType }
)(Parking)
