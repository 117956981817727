import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import { priceCommerceMinSelector, priceCommerceMaxSelector } from '../../selectors'
import { changeMortgagePrice, loadCommerce } from '../../actions'
import accounting from 'accounting'
import 'rc-slider/assets/index.css'
const Range = Slider.createSliderWithTooltip(Slider.Range)
const SliderWithTooltip = Slider.createSliderWithTooltip(Slider)

class PriceFilter extends Component {
	handleChange = (value) => {
		const selected = {
			from: value[0],
			to: value[1]
		}
		this.props.changeMortgagePrice(value)
	}

	render() {
		const { price } = this.props
		//const value = [from || Math.floor(this.props.min * 10) / 10, to || this.props.max]
		const value = [price || 20]
		return (
			<SliderWithTooltip
				min={Math.floor(this.props.min * 10) / 10}
				max={this.props.max}
				value={value}
				onChange={this.handleChange}
				step={0.1}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
			/>
		)
	}
}

export default connect(
	(state) => ({
		price: state.mortgageFilter.price,
		min: priceCommerceMinSelector(state),
		max: priceCommerceMaxSelector(state)
	}),
	{ changeMortgagePrice, loadCommerce }
)(PriceFilter)
