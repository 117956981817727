import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ScrollTop from '../common/scroll-top'
import Panel from '../realty/panel'
import Loader from '../common/loader'
import Fade from 'react-reveal/Fade'
import Button from '../common/button'
import { loadMore, loadLayout, openMailForm, openRequestLayoutForm, openCallForm } from '../../actions'
import {
	filtratedLayoutFlatsSelector,
	filtersSelector,
	layoutMapSelector,
	layoutMapAllSelector,
	layoutMapFiltratedSelector
} from '../../selectors'
import accounting from 'accounting'
import { arrayToMap } from '../../selectors/utils'

import AboutAdvList from '../About/about-advants'
import Additional from '../realty/additional'
import Table from '../table'

class LayoutDetail extends Component {
	componentDidMount() {
		const { loadedLayout, loadingLayout, loadLayout } = this.props
		if (!loadedLayout && !loadingLayout) loadLayout()
	}

	render() {
		const { loading, loaded, loadedLayout, loadingLayout } = this.props
		if (loading || loadingLayout) return <Loader />
		if (!loaded || !loadedLayout) return null
		return (
			<div className="params layout-detail">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Подобрать квартиру</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/realty">Подобрать квартиру</Link> / По параметрам
						</div>
					</div>
				</div>
				<div className="content">
					<Panel />
					<Fade>
						<div>
							<div className="back">
								<Link to="/realty/layout" className="ico-6">
									<span>Назад к выбору планировки</span>
								</Link>
							</div>
							{this.layout}
							{this.body}
							{this.similar.length ? (
								<div className="layout-similar box">
									<div className="flats-result-info">Похожие планировки</div>
									<div className="layout__body">{this.similar}</div>
								</div>
							) : null}
						</div>
					</Fade>
				</div>
				<Additional />
				<AboutAdvList />
			</div>
		)
	}

	get layout() {
		const { layout, layoutMapAll, id } = this.props
		if (!layout) return null
		const current = layout[id]

		return (
			<div className="flat__body">
				<div className="flat__left">{this.plan}</div>
				<div className="flat__right">
					{this.getDescr(layoutMapAll[id], current)}
					<div className="flat__description">{current['descr']}</div>
					<div className="flat__buttons">
						<div className="flat__btn" onClick={this.handleRequestForm}>
							Оставить заявку
						</div>
						<div className="flat__btn" onClick={this.props.openCallForm}>
							Заказать звонок
						</div>
					</div>
					<div className="flat__links">
						<a
							className="flat__link ico-5"
							href={current.files.pdf}
							target="_blank"
							rel="noopener noreferrer"
						>
							<span>Скачать PDF</span>
						</a>
						<div className="flat__link ico-4" onClick={this.handleMailForm}>
							<span>Отправить E-mail</span>
						</div>
					</div>
				</div>
			</div>
		)
	}

	get plan() {
		const { id, layout } = this.props
		const current = layout[id]
		if (!current.plan) return null

		return (
			<div className="plan-image">
				<img alt="" src={current.plan} />
			</div>
		)
	}

	getDescr = (list, item) => {
		return (
			<div className="flat__info">
				<div className="flat__info__item">
					<div className="flat__info__title">Доступна в корпусах</div>
					<div className="flat__info__body">№ {Object.keys(arrayToMap(list, 'corpus')).join(', ')}</div>
				</div>
				<div className="flat__info__item">
					<div className="flat__info__title">Стоимость от:</div>
					<div className="flat__info__body">
						{accounting.formatNumber(this.getMinValue(list, 'price'), 0, ' ')} руб.
					</div>
				</div>
				<div className="flat__info__item">
					<div className="flat__info__title">Площадь от:</div>
					<div className="flat__info__body">{this.getMinValue(list, 'square')} м2</div>
				</div>
			</div>
		)
	}

	getSimilarDescr = (list, item) => {
		return (
			<div className="layout__descr">
				<div>
					<div>
						Доступна в корпусах <span>№ {Object.keys(arrayToMap(list, 'corpus')).join(', ')}</span>
					</div>
					<div>
						Стоимость от:{' '}
						<span>{accounting.formatNumber(this.getMinValue(list, 'price'), 0, ' ')} руб.</span>
					</div>
					<div>
						Площадь от: <span>{this.getMinValue(list, 'square')} м2</span>
					</div>
				</div>
			</div>
		)
	}

	getMinValue = (list, field) => list.reduce((min, el) => Math.min(min, el[field]), list[0][field])

	get body() {
		const { flats, visibleLength, mobile } = this.props
		return (
			<div>
				<div className="flats-result-info">
					{/*
					{this.declOfNum(flats.length, ['Найдена', 'Найдены', 'Найдено'])} <span>{flats.length} </span>{' '}
					{this.declOfNum(flats.length, ['свободная', 'свободные', 'свободных'])}{' '}
					{this.declOfNum(flats.length, ['квартира', 'квартиры', 'квартир'])}
				*/}
				</div>
				<div className="flat-list">
					<Table flats={flats} visibleLength={visibleLength} />
					{this.btn}
				</div>
			</div>
		)
	}

	get btn() {
		if (this.props.visibleLength >= this.props.flats.length - 1) return null
		return (
			<div className="btn-wrap">
				<Button onClick={this.props.loadMore}>Показать еще</Button>
			</div>
		)
	}

	get similar() {
		const { layoutMap, layoutMapAll, layout, id } = this.props
		const current = layout[id]
		if (!current || !current.similar) return null
		return current.similar.map((id) => {
			if (!layoutMap[id] || !layout[id]) return null
			return (
				<Link to={`/realty/layout/${id}`} className="layout__item" key={id}>
					<div className="layout__plan">
						<span style={{ backgroundImage: `url(${layout[id].plan})` }} />
						{this.getSimilarDescr(layoutMapAll[id], layout[id])}
					</div>
					<div className="layout__flats">
						<div>{layoutMap[id].length}</div>
						<span>{this.declOfNum(layoutMap[id].length, ['свободная', 'свободные', 'свободных'])}</span>
						<br />
						<span>{this.declOfNum(layoutMap[id].length, ['квартира', 'квартиры', 'квартир'])}</span>
					</div>
				</Link>
			)
		})
	}

	declOfNum = (number, titles) => {
		var cases = [2, 0, 1, 1, 1, 2]
		return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
	}

	handleRequestForm = () => {
		const { id, layout } = this.props
		this.props.openRequestLayoutForm(layout[id])
	}

	handleMailForm = () => {
		this.props.openMailForm(this.props.id, 'layout')
	}
}

export default connect(
	(state, props) => ({
		loading: state.flats.loading,
		loaded: state.flats.loaded,
		visibleLength: filtersSelector(state).visibleLength,
		loadingLayout: state.layout.loading,
		loadedLayout: state.layout.loaded,
		layout: layoutMapSelector(state),
		layoutMap: layoutMapFiltratedSelector(state),
		layoutMapAll: layoutMapAllSelector(state),
		flats: filtratedLayoutFlatsSelector(state, props),
		mobile: state.mobile
	}),
	{ loadMore, loadLayout, openMailForm, openRequestLayoutForm, openCallForm }
)(LayoutDetail)
