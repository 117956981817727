import * as Cookies from 'js-cookie'

export default (store) => (next) => (action) => {
	const { viewed, id, ...rest } = action

	if (!viewed) return next(action)

	if (!Cookies.get('viewed')) {
		Cookies.set('viewed', [], { path: '' })
	}

	let viewedList = JSON.parse(Cookies.get('viewed'))

	if (id && !viewedList.includes(id)) {
		Cookies.set('viewed', [...viewedList, id], { path: '' })
	}

	viewedList = JSON.parse(Cookies.get('viewed'))

	next({
		...rest,
		viewed: viewedList
	})
}
