import React, { Component } from 'react'
import Modal from 'react-modal'

export default class MapInner extends Component {
	state = {
		value: '',
		open: false,
		error: false
	}
	componentDidMount() {
		if (!window.ymaps) return null
		const ymaps = window.ymaps
		this.markers = []

		ymaps.ready(() => {
			const { point, mobile } = this.props
			const coords = [point['lat'], point['lng']]
			this.map = new ymaps.Map(
				'contacts-map',
				{
					center: coords,
					zoom: 16,
					controls: []
				},
				{
					searchControlProvider: 'yandex#search'
				}
			)

			const zoomControl = new ymaps.control.ZoomControl({
				options: {
					size: 'small',
					position: mobile
						? {
								top: 10,
								left: 10
						  }
						: {
								right: 20,
								bottom: 50
						  }
				}
			})
			this.map.controls.add(zoomControl)
			this.map.behaviors.disable('scrollZoom')
			const marker = new ymaps.Placemark(
				coords,
				{ title: this.props.point.title },
				{
					iconLayout: 'default#imageWithContent',
					iconImageHref: '/img/contacts/marker.png',
					iconContentLayout: ymaps.templateLayoutFactory.createClass(
						`<div class="contacts-market-title"><span>Офис продаж<br/>$[properties.title]</span></div>`
					),
					iconContentOffset: [7, 0],
					iconImageSize: mobile ? [200, 109] : [289, 156],
					iconImageOffset: mobile ? [0, -109] : [0, -156]
				}
			)
			this.map.geoObjects.add(marker)

			if (mobile) {
				const offset = -60
				let pixelCenter = this.map.getGlobalPixelCenter(coords)
				pixelCenter = [pixelCenter[0] - offset, pixelCenter[1]]
				const geoCenter = this.map.options.get('projection').fromGlobalPixels(pixelCenter, this.map.getZoom())
				this.map.setCenter(geoCenter)
			}
		})
	}

	render() {
		const { point } = this.props
		console.log(point.address)
		return (
			<div ref={this.setContainerRef} className="contacts-map">
				<div className="contacts-map__block">
					<div className="contacts-map__block__in">
						<div>
							<h2>ОФИС ПРОДАЖ</h2>
							<div dangerouslySetInnerHTML={{ __html: point.address }} />
						</div>
						<div>
							<h2>Часы работы</h2>
							<div dangerouslySetInnerHTML={{ __html: point.time }} />
						</div>
						<div>
							<h2>GPS координаты</h2>
							<div dangerouslySetInnerHTML={{ __html: point.coords }} />
						</div>
						<div>
							<h2>Отдел заселения</h2>
							<div dangerouslySetInnerHTML={{ __html: point.call_center }} />
						</div>
						<div className="contacts-map__social">
							<a
								href="https://vk.com/novobutovo2018"
								target="_blank"
								className="contacts-map__soc ico-55"
								rel="noopener noreferrer"
							>
								vk
							</a>
							<a
								href="https://www.instagram.com/novobutovo/"
								target="_blank"
								className="contacts-map__soc ico-56"
								rel="noopener noreferrer"
							>
								inst
							</a>
						</div>
					</div>
					<div onClick={this.toggleModal} className="contacts-lnk">
						<i className="ico-57" />
						<span>Построить маршрут</span>
					</div>
				</div>
				<div id="contacts-map" />
				{this.modal}
			</div>
		)
	}

	setContainerRef = (ref) => {
		this.container = ref
	}

	createRoute = () => {
		if (!this.state.value) {
			this.setState({
				error: true
			})
			return false
		}
		const ymaps = window.ymaps
		const { point, mobile } = this.props
		this.route = new ymaps.multiRouter.MultiRoute(
			{
				referencePoints: [this.state.value, [point['lat'], point['lng']]],
				params: {
					routingMode: 'auto'
				}
			},
			{
				useMapMargin: true,
				zoomMargin: mobile ? [80, 100, 100, 0] : [150, 0, 0, 390],
				boundsAutoApply: true,
				wayPointStartIconLayout: 'default#image',
				wayPointStartIconImageHref: '/img/marker2.png',
				iconImageSize: mobile ? [51, 79] : [81, 109],
				iconImageOffset: mobile ? [-1, -79] : [-1, -109],
				wayPointFinishVisible: false,
				routeStrokeWidth: 6,
				routeStrokeColor: '#b05bb6',
				routeActiveStrokeWidth: 6,
				routeActiveStrokeColor: '#b05bb6',
				balloonPanelMaxMapArea: 0,
				balloonOffset: [-3, -25]
			}
		)
		this.map.geoObjects.add(this.route)
		this.setState({
			open: false,
			error: false
		})
	}

	get modal() {
		return (
			<Modal closeTimeoutMS={300} className="modalwin modalwin--callback contacts-modal" isOpen={this.state.open}>
				<div onClick={this.toggleModal} className="overlay__modal" />
				<div onClick={this.toggleModal} className="close__modal" />
				<div className="modalwin__box">
					<div className="modal__title">Построить маршрут</div>
					<div className="contacts-inputs">
						<div className={this.state.error ? 'modal__input error' : 'modal__input'}>
							<input
								type="text"
								placeholder="Введите адрес"
								value={this.state.value}
								onChange={this.handleChange}
							/>
						</div>
						<div className="modal__input--but" onClick={this.createRoute}>
							<i className="ico-7" />
						</div>
					</div>
				</div>
			</Modal>
		)
	}

	toggleModal = () => {
		this.setState({
			open: !this.state.open
		})
	}

	handleChange = (ev) => {
		this.setState({
			value: ev.target.value
		})
	}
}
