import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import AboutMenuList from '../about-menu-list'
import Loader from '../../common/loader'
import { loadDocs, changeDocsPage } from '../../../actions'
import { docsFiltratedSelector, docsSortedSelector, docsFilterSelector } from '../../../selectors'
import ScrollTop from '../../common/scroll-top'
import Filter from './filters'
import { hit } from '../../common/metrics'
import Fade from 'react-reveal/Fade'

class Docs extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Документы'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Договор долевого участия, проектные декларации, разрешение на ввод в эксплуатацию, разрешение на строительство'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadDocs } = this.props
		if (!loading && !loaded) loadDocs()
	}
	render() {
		const { loading, loaded, filter } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<section className="content__block docs">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>О проекте</h1>
						<div className="breadcrumb__links">
							<NavLink to="/">Главная</NavLink> / <NavLink to="/about">О проекте</NavLink> / Документы
						</div>
					</div>
				</div>
				<AboutMenuList />
				<Filter />
				<Fade key={filter.type.value + '_' + filter.corpus.value + '_' + filter.year.value}>
					<div className="content">{this.body}</div>
				</Fade>
				<div className="paginator">{this.paginator}</div>
			</section>
		)
	}

	getItems = (items) => {
		return items.map((item, i) => {
			return (
				<div key={i} className="docs__item ico-35">
					<div className="docs__date">{item['date']}</div>
					<a href={item['doc']} target="_blank" rel="noopener noreferrer" className="docs__name">
						<span>Корпус {item['corpus']}</span>
					</a>
					<div className="docs__description">{item['descr']}</div>
					<div className="docs__btn">
						<a href={item['doc']} target="_blank" rel="noopener noreferrer">
							скачать
						</a>
					</div>
				</div>
			)
		})
	}

	get body() {
		return Object.keys(this.props.sorted).map((item, i) => (
			<div className="docs__group" key={i}>
				<div className="docs__group__name">{item}</div>
				<div className="docs__group__body">{this.getItems(this.props.sorted[item])}</div>
			</div>
		))
	}

	get paginator() {
		const { limit, filtrated, page } = this.props
		const total = filtrated.length
		if (total <= limit) return null

		return new Array(Math.floor((total - 1) / limit) + 1).fill().map((_, i) => (
			<span onClick={this.handleClick} value={i + 1} key={i} className={i + 1 === +page ? 'active' : ''}>
				<span>{i + 1}</span>
			</span>
		))
	}

	handleClick = (ev) => {
		this.props.changeDocsPage(ev.currentTarget.getAttribute('value'))
	}
}

export default connect(
	(state) => ({
		loading: state.docs.loading,
		loaded: state.docs.loaded,
		limit: state.docs.filter.limit,
		page: state.docs.filter.page,
		filtrated: docsFiltratedSelector(state),
		sorted: docsSortedSelector(state),
		filter: docsFilterSelector(state)
	}),
	{ loadDocs, changeDocsPage }
)(Docs)
