import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../common/loader'
import { loadPrivacy } from '../../actions'
import ScrollTop from '../common/scroll-top'
import Fade from 'react-reveal/Fade'
import { hit } from '../common/metrics'

class Privacy extends Component {
	componentDidMount() {
		document.title = 'ЖК «Новое Бутово». Политика конфиденциальности'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Администрация сайта оставляет за собой право в одностороннем порядке вносить любые изменения в Политику конфиденциальности без предварительного уведомления Пользователя. Актуальный текст Политики конфиденциальности размещен на данной странице.'
			)
		hit(window.location.pathname)
		const { loading, loaded, loadPrivacy } = this.props
		if (!loading && !loaded) loadPrivacy()
	}
	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="content__block privacy">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Политика конфиденциальности</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / Политика конфиденциальности
						</div>
					</div>
					<Fade>{this.body}</Fade>
				</div>
			</div>
		)
	}

	get body() {
		return <div dangerouslySetInnerHTML={{ __html: this.props.privacy.data }} />
	}
}

export default connect(
	(state) => ({
		loading: state.privacy.loading,
		loaded: state.privacy.loaded,
		privacy: state.privacy.data
	}),
	{ loadPrivacy }
)(Privacy)
