import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ScrollTop from '../../common/scroll-top'
import Panel from '../panel'
import Loader from '../../common/loader'
import Button from '../../common/button'
import Fade from 'react-reveal/Fade'
import { loadCommerceMore } from '../../../actions'
import { filtratedCommerceSelector, filterCommerceSelector } from '../../../selectors'
import AboutAdvList from '../../About/about-advants'

import Filters from '../filters'
import Table from '../table'
import { hit } from '../../common/metrics'

class FlatList extends Component {
	componentDidMount() {
		document.title =
			'ЖК «Новое Бутово». Подобрать коммерческое помещение по параметрам в Москве, 15 минут пешком до метро Бунинская аллея.'
		document
			.querySelector('meta[name=description]')
			.setAttribute(
				'content',
				'Здесь можно подобрать по различным параметрам нежилые коммерческие помещения в новостройке ЖК «Новое Бутово».'
			)
		hit(window.location.pathname)
	}

	render() {
		const { loading, loaded } = this.props
		if (loading) return <Loader />
		if (!loaded) return <Loader />

		return (
			<div className="params">
				<ScrollTop />
				<div className="box">
					<div className="breadcrumb">
						<h1>Коммерческие помещения</h1>
						<div className="breadcrumb__links">
							<Link to="/">Главная</Link> / <Link to="/commerce">Коммерческое помещение</Link> / По
							параметрам
						</div>
					</div>
				</div>
				<div className="content">
					<Panel lastUrl={'commerce-params'} />
					<Fade>
						<div>
							<Filters />
							{this.body}
							<AboutAdvList isCommerce />
						</div>
					</Fade>
				</div>
			</div>
		)
	}

	get body() {
		const { flats, visibleLength, mobile } = this.props
		return (
			<div>
				<div className="flats-result-info">
					{/*
					Найдено <span>{flats.length} </span>{' '}
					{this.declOfNum(flats.length, ['свободное', 'свободных', 'свободных'])}{' '}
					{this.declOfNum(flats.length, ['помещение', 'помещения', 'помещений'])}
				*/}
				</div>
				<div className="flat-list">
					<Table flats={flats} visibleLength={visibleLength} />
					{this.btn}
				</div>
			</div>
		)
	}

	get btn() {
		if (this.props.visibleLength >= this.props.flats.length - 1) return null
		return (
			<div className="btn-wrap">
				<Button onClick={this.props.loadCommerceMore}>Показать еще</Button>
			</div>
		)
	}

	declOfNum = (number, titles) => {
		var cases = [2, 0, 1, 1, 1, 2]
		return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
	}
}

export default connect(
	(state) => ({
		loading: state.commerce.loading,
		loaded: state.commerce.loaded,
		flats: filtratedCommerceSelector(state),
		visibleLength: filterCommerceSelector(state).visibleLength,
		mobile: state.mobile
	}),
	{ loadCommerceMore }
)(FlatList)
