import React, { Component } from 'react'
import { connect } from 'react-redux'
import accounting from 'accounting'
import { getPage } from '../../actions'

class Row extends Component {
	render() {
		const { flat } = this.props
		if (!flat) return null
		const price = accounting.formatNumber(flat.price, 0, ' ')
		const price_promotion = +flat.promotion ? accounting.formatNumber(flat.price - flat.promotion, 0, ' ') : '-'
		return (
			<tr className="flat-list__item">
				<td corpus={flat.corpus} section={flat.section} id={flat.id} onClick={this.handleGetPage}>
					{flat.number}
				</td>
				<td corpus={flat.corpus} section={flat.section} id={flat.id} onClick={this.handleGetPage}>
					{flat.corpus}
				</td>
				<td corpus={flat.corpus} section={flat.section} id={flat.id} onClick={this.handleGetPage}>
					{flat.floor}
				</td>
				<td corpus={flat.corpus} section={flat.section} id={flat.id} onClick={this.handleGetPage}>
					{flat.square}
				</td>
				<td corpus={flat.corpus} section={flat.section} id={flat.id} onClick={this.handleGetPage}>
					{flat.rooms === '0' ? 'C' : flat.rooms}
				</td>
				<td corpus={flat.corpus} section={flat.section} id={flat.id} onClick={this.handleGetPage}>
					{flat.decoration === '1' ? 'Да' : 'Нет'}
				</td>
				<td corpus={flat.corpus} section={flat.section} id={flat.id} onClick={this.handleGetPage}>
					{price}
				</td>
				<td
					corpus={flat.corpus}
					section={flat.section}
					id={flat.id}
					onClick={this.handleGetPage}
					className="td-promotion"
				>
					{price_promotion}
				</td>
				{flat.files && flat.files.pdf ? (
					<td corpus={flat.corpus} section={flat.section} id={flat.id}>
						<a
							href={flat.files.pdf}
							target="_blank"
							rel="noopener noreferrer"
							className="flat-list__item__icon ico-5"
						>
							&nbsp;
						</a>
					</td>
				) : null}
			</tr>
		)
	}

	handleGetPage = (ev) => {
		const corpus = ev.currentTarget.getAttribute('corpus')
		const section = ev.currentTarget.getAttribute('section')
		const id = ev.currentTarget.getAttribute('id')
		this.props.getPage(`/realty/genplan/${corpus}/${section}/${id}`)
	}
}

export default connect(null, { getPage })(Row)
