import { SECTION_CHANGE_ROOMS } from '../constants'

const defaultFilters = {
	rooms: []
}

export default (filters = defaultFilters, action) => {
	const { type, payload } = action

	switch (type) {
		case SECTION_CHANGE_ROOMS:
			return {
				...filters,
				rooms: payload.rooms
			}

		default:
			return filters
	}
}
