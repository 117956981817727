import { SUCCESS, START, GET_FLAT, GET_FLATS, FAIL } from '../constants'

const defaultObjects = {
	entities: [
		{
			id: null,
			number: null,
			rooms: null,
			floor: null,
			square: null,
			price: null,
			plan: null,
			corpus: null,
			section: null,
			on_sale: null
		}
	],
	loading: false,
	loaded: false,

	detail: {},
	loadingFlat: false,
	loadedFlat: false,
	viewed: [],
	error: null
}

export default (state = defaultObjects, action) => {
	const { type, payload, response, viewed, error } = action

	switch (type) {
		case GET_FLATS + START:
			return { ...state, loading: true }

		case GET_FLATS + SUCCESS:
			return { ...state, loading: false, loaded: true, entities: response }

		case GET_FLAT + START:
			return { ...state, loadingFlat: true }

		case GET_FLAT + SUCCESS:
			return {
				...state,
				viewed,
				detail: {
					...state.detail,
					[payload.id]: response
				},
				loadingFlat: false,
				loadedFlat: true
			}

		case GET_FLAT + FAIL:
			return {
				...state,
				error
			}

		default:
			return state
	}
}
