import React, { Component } from 'react'
import Slider from 'rc-slider'
import { connect } from 'react-redux'
import 'rc-slider/assets/index.css'
import { changeСontribution } from '../../actions'
import accounting from 'accounting'
import { contributionSelector } from '../../selectors'
const SliderWithTooltip = Slider.createSliderWithTooltip(Slider)

class СontributionFilter extends Component {
	handleChange = (value) => {
		console.log(value)
		this.props.changeСontribution(value)
	}

	render() {
		const { payment } = this.props
		return (
			<SliderWithTooltip
				min={10}
				max={100}
				value={payment}
				step={1}
				onChange={this.handleChange}
				allowCross={false}
				tipProps={{ visible: true, placement: 'bottom' }}
			/>
		)
	}
}

export default connect(
	(state) => ({
		payment: state.mortgageFilter.payment
	}),
	{ changeСontribution }
)(СontributionFilter)
